import React, { useEffect, useState } from "react";
import "./Vehicle.css";
import { useNavigate, useParams } from "react-router-dom";
import CustomAdvanceTable from "../../../../../components/customAdvanceTable/CustomAdvanceTable";
import { useDispatch, useSelector } from "react-redux";
import {
  filterVehicleData,
  setAllVehicles,
  updateDefaultChecked,
  updateSelectedTab,
} from "../../../../../store/vehicleStore";
import {
  deleteVehicle,
  getAllVehicles,
} from "../../../../../api/apiServices/vehicleApi";
import { setRefresh } from "../../../../../store/usersStore";
import ResponseHandler from "../../../../../services/ResponseHandler";
import CustomChip from "../../../../../components/customChip/CustomChip";
import CustomSpin from "../../../../../components/customSpin/CustomSpin";
import { LocalStorageServices } from "../../../../../services/localStorageServices";
import ToolbarSearch from "./toolbarSearch/ToolbarSearch";
import SaleStatus from "../../../../../components/saleStatus/SaleStatus";
import CustomModal from "../../../../../components/customModal/CustomModal";
import { saleitemList } from "../../../../../constant/SaleStatusList";

function Vehicles() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { role } = useParams();
  const [open, setOpen] = React.useState(false);
  const [selectedId,setSelectedId]=useState(null)
  const [rowChecked, setRowChecked] = useState(-1);
 
  
  const defaultColumnChecked = useSelector(
    (state) => state.vehicleStore.defaultChecked
  );
  const vehiclesData = useSelector((state) => state.vehicleStore.Vehicles);

  const wholeVehicleData = useSelector(
    (state) => state.vehicleStore.WholeVehicles
  );
  const tableLoading = useSelector(
    (state) => state.vehicleStore.vehicleTableLoading
  );

  const [deletevehicle, setDeletevehicle] = useState({
    id: "",
    loading: false,
  });

  const updateDefaultCheckedColumn = (data) => {
    dispatch(updateDefaultChecked(data));
  };

  useEffect(() => {
    dispatch(getAllVehicles());
  }, []);

  const handleClickOpen = (id) => {
    setSelectedId(id)
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(setRefresh())
    setOpen(false);
  };
  const UpdateDeleteDetails = (id, loading) => {
    setDeletevehicle({ ...deletevehicle, ["id"]: id, ["loading"]: loading });
  };
  const handleDelete = (rowKey) => {
    UpdateDeleteDetails(rowKey, true);
    dispatch(deleteVehicle(rowKey))
      .then((response) => {
        dispatch(getAllVehicles());
        ResponseHandler(response);
      })
      .catch((error) => {
        ResponseHandler(error);
      })
      .finally(() => {
        UpdateDeleteDetails(rowKey, false);
      });
  };

  const updateRowChecked = (index) => {
    if (index === rowChecked) {
      setRowChecked(-1);
    } else {
      setRowChecked(index);
    }
  };
  const Columns = [
    { title: "#", dataIndex: "key", key: "key" },
    {
      title: "Plate or Board",
      dataIndex: "plate_or_board",
      key: "plate_or_board",
    },
    { title: "Brand", dataIndex: "brand", key: "brand" },
    { title: "Color", dataIndex: "color", key: "color" },
    { title: "Year", dataIndex: "year", key: "year" },
    { title: "Location", dataIndex: "map_location", key: "map_location" },
    { title: "Price", dataIndex: "price", key: "price" },
    { title: "Fuel", dataIndex: "fuel", key: "fuel" },
    { title: "Liquid", dataIndex: "liquid", key: "liquid" },
    { title: "Address", dataIndex: "address", key: "address" },
    { title: "Amount", dataIndex: "amount", key: "amount" },
    {
      title: "Contract Value",
      dataIndex: "contract_value",
      key: "contract_value",
    },
    { title: "Date", dataIndex: "date", key: "date" },
    { title: "Latitude", dataIndex: "latitude", key: "latitude" },
    { title: "Longitude", dataIndex: "longitude", key: "longitude" },
    { title: "Official", dataIndex: "official", key: "official" },
    { title: "Order", dataIndex: "order", key: "order" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "Requested By", dataIndex: "requested_by", key: "requested_by" },
    {
      title: "Type of Appraisal",
      dataIndex: "type_of_appraisal",
      key: "type_of_appraisal",
    },
    { title: "Actual State", dataIndex: "actual_state", key: "actual_state" },
    {
      title: "Application Name",
      dataIndex: "application_name",
      key: "application_name",
    },
    {
      title: "Identification Number",
      dataIndex: "identification_number",
      key: "identification_number",
    },
    { title: "Issue Date", dataIndex: "issue_date", key: "issue_date" },
    { title: "Owner Name", dataIndex: "owner_name", key: "owner_name" },
    { title: "Tax Status", dataIndex: "tax_status", key: "tax_status" },
    {
      title: "Treasure Value",
      dataIndex: "treasure_value",
      key: "treasure_value",
    },
    { title: "Ability", dataIndex: "ability", key: "ability" },
    { title: "Cabin", dataIndex: "cabin", key: "cabin" },
    { title: "Category", dataIndex: "category", key: "category" },
    { title: "Ceiling", dataIndex: "ceiling", key: "ceiling" },
    { title: "Chassis No", dataIndex: "chassis_no", key: "chassis_no" },
    { title: "Converted", dataIndex: "converted", key: "converted" },
    { title: "Feature", dataIndex: "feature", key: "feature" },
    { title: "Gross Weight", dataIndex: "gross_weight", key: "gross_weight" },
    { title: "Inspected By", dataIndex: "inspected_by", key: "inspected_by" },
    { title: "Length", dataIndex: "length", key: "length" },
    { title: "Mileage", dataIndex: "mileage", key: "mileage" },
    { title: "Series", dataIndex: "series", key: "series" },
    { title: "Style", dataIndex: "style", key: "style" },
    { title: "Traction", dataIndex: "traction", key: "traction" },
    { title: "Transmission", dataIndex: "transmission", key: "transmission" },
    { title: "Use", dataIndex: "use", key: "use" },
    { title: "VIN", dataIndex: "vin", key: "vin" },
    { title: "Weight", dataIndex: "weight", key: "weight" },
    {
      title: "Weight Trailer",
      dataIndex: "weight_trailer",
      key: "weight_trailer",
    },
    {
      title: "Year of Manufacture",
      dataIndex: "year_of_manufacture",
      key: "year_of_manufacture",
    },
    { title: "Displacement", dataIndex: "displacement", key: "displacement" },
    { title: "Engine No", dataIndex: "engine_no", key: "engine_no" },
    { title: "Model", dataIndex: "model", key: "model" },
    {
      title: "No of Cylinder",
      dataIndex: "no_of_cylinder",
      key: "no_of_cylinder",
    },
    { title: "Orgin", dataIndex: "orgin", key: "orgin" },
    { title: "Power", dataIndex: "power", key: "power" },
    { title: "RTV", dataIndex: "rtv", key: "rtv" },
    { title: "Series No", dataIndex: "series_no", key: "series_no" },
    { title: "Appraisal", dataIndex: "appraisal", key: "appraisal" },
    {
      title: "Appraisal Reference",
      dataIndex: "appraisal_reference",
      key: "appraisal_reference",
    },
    {
      title: "General State of Conversation",
      dataIndex: "general_state_of_conversation",
      key: "general_state_of_conversation",
    },
    {
      title: "Identification Card",
      dataIndex: "identification_card",
      key: "identification_card",
    },
    {
      title: "Inspection Date",
      dataIndex: "inspection_date",
      key: "inspection_date",
    },
    { title: "Manifacture", dataIndex: "manifacture", key: "manifacture" },
    {
      title: "Observation and Note",
      dataIndex: "observation_and_note",
      key: "observation_and_note",
    },
    { title: "Order", dataIndex: "order", key: "order" },
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Sale Status", dataIndex: "sale_status", key: "sale_status", action: (row) => (
      <p
      className="tagLayout"
        style={{
          border:
          row?.sale_status == 0? "2px solid #0A8A50": 
          row?.sale_status == 1? "2px solid #E63E4B":
          row?.sale_status == 2? "2px solid #4ca5fb":'2px solid #FFB84C',
          color: row?.sale_status == 0? "#0A8A50": 
          row?.sale_status == 1? "#E63E4B":
          row?.sale_status == 2? "#4ca5fb":'#FFB84C'
        }}
      >
        {row.sale_status!==null?saleitemList.filter((item)=>item.value==row.sale_status).map((item)=>item.label):"Incompleted" }
      </p>
    ), },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      action: (row) => (
        <p
          style={{
            color:
              row.status === "Completed"
                ? "green"
                : row.status === "In Progress"
                ? "#ff5929"
                : "blue",
            width: "100px",
            fontWeight: 500,
          }}
        >
          {row.status}
        </p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      action: (row) => (
        <div className={Privilages && Privilages.url_role === "Agent" ? "vechicle_chipset_agent" : "vechicle_chipset"}>
          {row.status==="Completed" && 
                Privilages && (Privilages.url_role === "SuperAdmin" ||  Privilages.url_role === "Admin")?<>
          <CustomChip
                text="Sale"
                bgColor="#228b22"
                color="white"
                onClick={()=>{
                  handleClickOpen(row.id)
                }}
              />
          </>:<></>}

          {Privilages &&
          Privilages?.Tasks &&
          Privilages?.Tasks.some((item) => item === "View") ? (
            <CustomChip
              text="Task"
              bgColor="#573CFA"
              color="white"
              onClick={() => {
                navigate(
                  `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${
                    row.id
                  }/vehicle_tasks`
                );
              }}
            />
          ) : (
            <></>
          )}

          <CustomChip
            text="View"
            bgColor="#F8893C"
            color="white"
            onClick={() => {
              dispatch(updateSelectedTab(0));
              const url =
                Privilages && Privilages.url_role === "Agent"
                  ? `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${
                      row.id
                    }/AgentVehicle_View`
                  : `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${
                      row.id
                    }/update_vehicle?tk=${0}&view_edit=${true}`;
              navigate(url);
            }}
          />

          {Privilages &&
          Privilages?.Vehicle &&
          Privilages?.Vehicle.some((item) => item === "Delete") ? (
            <CustomChip
              loading={
                deletevehicle.id === row.id ? deletevehicle.loading : false
              }
              text="Delete"
              bgColor="#ED5967"
              color="white"
              onClick={() => handleDelete(row.id)}
            />
          ) : (
            <></>
          )}
          {role === "Agent" ? (
            <>
              {/* <CustomChip
              disabled={JSON.parse(row.isApplied)}
                text="Apply"
                bgColor="#ED5967"
                color="white"
                onClick={() => {
                  navigate(
                    `/${LocalStorageServices.getUserRole()}/home/vehicle/${
                      row.id
                    }/apply?module=vehicle`
                  );
                }}
              /> */}
            </>
          ) : (
            <></>
          )}
          
        </div>
      ),
    },
  ];
  const datas = () => {
    const result =
      vehiclesData &&
      vehiclesData.map((item, index) => {
        return {
          key: index + 1,
          id: item.vbd_id,
          plate_or_board: item.vd_plate_or_board,
          brand: item.ved_brand,
          color: item.vd_color,
          year: item.vad_year,
          map_location: item.vad_map_location,
          value: item.vod_value,
          fuel: item.fl_fuel,
          liquid: item.ll_liquid,
          address: item.vad_address,
          amount: item.vad_amount,
          contract_value: item.vad_contract_value,
          date: item.vad_date,
          latitude: item.vad_latitude,
          longitude: item.vad_longitude,
          official: item.vad_official,
          order: item.vod_order,
          phone: item.vad_phone,
          requested_by: item.vad_requested_by,
          type_of_appraisal: item.vad_type_of_appraisal,
          actual_state: item.vbd_actual_state,
          application_name: item.vbd_application_name,
          identification_number: item.vbd_identification_number,
          issue_date: item.vbd_issue_date,
          owner_name: item.vbd_owner_name,
          tax_status: item.vbd_tax_status,
          treasure_value: item.vbd_treasure_value,
          ability: item.vd_ability,
          cabin: item.vd_cabin,
          category: item.vd_category,
          ceiling: item.vd_ceiling,
          chassis_no: item.vd_chassis_no,
          converted: item.vd_converted,
          feature: item.vd_feature,
          gross_weight: item.vd_gross_weight,
          inspected_by: item.vd_inspected_by,
          length: item.vd_length,
          mileage: item.vd_mileage,
          series: item.ved_series_no,
          style: item.vd_style,
          traction: item.vd_traction,
          transmission: item.vd_transmission,
          use: item.vd_use,
          vin: item.vd_vin,
          weight: item.vd_weight,
          weight_trailer: item.vd_weight_trailer,
          year_of_manufacture: item.vd_year_of_manufacture,
          displacement: item.ved_displacement,
          engine_no: item.ved_engine_no,
          model: item.ved_model,
          no_of_cylinder: item.ved_no_of_cylinder,
          orgin: item.ved_orgin,
          power: item.ved_power,
          rtv: item.ved_rtv,
          series_no: item.ved_series_no,
          appraisal: item.vod_appraisal,
          appraisal_reference: item.vod_appraisal_reference,
          general_state_of_conversation: item.vod_general_state_of_conversation,
          identification_card: item.vod_identification_card,
          inspection_date: item.vod_inspection_date,
          manifacture: item.vod_manifacture,
          observation_and_note: item.vod_observation_and_note,
          order: item.vod_order,
          type: item.vtl_type,
          status: item.sl_status,
          price:item.vad_amount,
          sale_status:item.vs_sale_status,
          isApplied:item?.is_applied
        };
      });
    return result;
  };

  const Privilages = useSelector((state) => state.userStore.Privilages);
  return (
    <div>
      <div className="vehicle_page_header">
        <p className="page_title">Vehicles</p>
        {Privilages &&
        Privilages?.Vehicle &&
        Privilages?.Vehicle.some((item) => item === "Add") ? (
          <button
            className="add_vehicle_button"
            onClick={() => {
              dispatch(updateSelectedTab(0));
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/add_vehicle`
              );
            }}
          >
            Add vehicle
          </button>
        ) : null}
      </div>
      {/* <ToolbarSearch /> */}
      <CustomSpin loading={tableLoading}>
        <CustomAdvanceTable
          toolbar={<ToolbarSearch />}
          columns={Columns}
          rows={datas()}
          defaultCheck={defaultColumnChecked}
          onChangeChecked={updateDefaultCheckedColumn}
          rowChecked={rowChecked}
          onchangeRowchecked={updateRowChecked}
        />
      </CustomSpin>
      <CustomModal
            open={open}
            handleClose={handleClose}
            body={
              <SaleStatus
                id={selectedId}
                type="vehicle"
                onClose={handleClose}
              />
            }
            title={'SALE'}
          />
    </div>
  );
}

export default Vehicles;
