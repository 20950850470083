import React, { useEffect, useState } from "react";
import "./EngineCharacteristicsForm.css";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import FormButton from "../../../../../../components/formButton/FormButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { getAllFuels } from "../../../../../../api/apiServices/fuelApi";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import CustomAutoComplete from "../../../../../../components/customAutoComplete/CustomAutoComplete";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getVehicleEngineDetailsId,
  updateVehicleEngineDetailsId,
} from "../../../../../../api/apiServices/vehicleApi";
import { RiFileEditFill } from "react-icons/ri";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
function EngineCharacteristicsForm({ updateIsEditable }) {
  const dispatch = useDispatch();
  const [Fuels, SetFuels] = useState([]);
  const [loading, SetLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iSViewAndEdit = queryParams.get("view_edit");
  const [Isview, SetIsview] = useState(false);
  useEffect(() => {
    getFuels();
    if (id) {
      dispatch(getVehicleEngineDetailsId(id))
        .then((response) => {
          setFormikData(response.data.data);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
    if (iSViewAndEdit) {
      SetIsview(true);
    }
  }, [id]);

  function getFuels() {
    dispatch(getAllFuels())
      .then((response) => {
        const data = response.map((item) => {
          return {
            label: item.fl_fuel,
            value: item.fl_id,
          };
        });
        SetFuels(data);
      })
      .catch((error) => {
        ResponseHandler(error);
      })
      .then((error) => {
        ResponseHandler(error);
      });
  }

  const initialValues = {
    engine_no: "",
    brand: "",
    series_no: "",
    model: "",
    displacement: "",
    no_of_cylinder: "",
    power: "",
    fuel: null,
    rtv: "",
    orgin: "",
  };
  function setFormikData(data) {
    const mappedData = Object.keys(initialValues).reduce((acc, key) => {
      const originalKey = `ved_${key}`;
      acc[key] = data[originalKey] || initialValues[key];
      return acc;
    }, {});
    formik.setValues({
      ...formik.values,
      ...mappedData,
    });
  }
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      engine_no: Yup.string(),
      brand: Yup.string(),
      series_no: Yup.string(),
      model: Yup.string(),
      displacement: Yup.string(),
      no_of_cylinder: Yup.string(),
      power: Yup.string(),
      rtv: Yup.string(),
      orgin: Yup.string(),
    }),
    onSubmit: (values) => {
      SetLoading(true);
      dispatch(updateVehicleEngineDetailsId(id, values))
        .then((response) => {
          ResponseHandler(response);
          if (iSViewAndEdit) {
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${id}/update_vehicle?tk=${3}&view_edit=${true}`
            );
          } else {
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${id}/update_vehicle?tk=${3}`
            );
          }
        })
        .catch((error) => {
          ResponseHandler(error);
        })
        .finally(() => {
          SetLoading(false);
        });
    },
  });

  const Privilages = useSelector((state) => state.userStore.Privilages);

  return (
    <div>
      <div className="EngineCharacteristics_form_main_Container">
        {Privilages &&
        Privilages?.Vehicle &&
        Privilages?.Vehicle.some((item) => item === "Update")
          ? Isview && (
              <div
                className="EngineCharacteristics_edit_details"
                onClick={() => {
                  SetIsview(false);
                  updateIsEditable(true);
                }}
              >
                <RiFileEditFill />
                <p>Edit</p>
              </div>
            )
          : null}

        <div className="EngineCharacteristics_formContainer">
          <p className="EngineCharacteristics_form_legend">
            Add Engine Details
          </p>
          <form id="EngineCharacteristicsform" onSubmit={formik.handleSubmit}>
            <div className="EngineCharacteristicsform_fields">
              <div>
                <div className="EngineCharacteristicsform_field_container">
                  <p className="EngineCharacteristicsform_fields_labels">
                    Engine No
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="engine_no"
                    placeholder="Enter Engine No"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.engine_no}
                    error={
                      formik.touched.engine_no &&
                      Boolean(formik.errors.engine_no)
                    }
                    helperText={
                      formik.touched.engine_no && formik.errors.engine_no
                    }
                  />
                </div>

                <div className="EngineCharacteristicsform_field_container">
                  <p className="EngineCharacteristicsform_fields_labels">
                    Serial No
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="series_no"
                    placeholder="Enter Serial No"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.series_no}
                    error={
                      formik.touched.series_no &&
                      Boolean(formik.errors.series_no)
                    }
                    helperText={
                      formik.touched.series_no && formik.errors.series_no
                    }
                  />
                </div>

                <div className="EngineCharacteristicsform_field_container">
                  <p className="EngineCharacteristicsform_fields_labels">
                    Displacement
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="displacement"
                    placeholder="Enter Displacement"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.displacement}
                    error={
                      formik.touched.displacement &&
                      Boolean(formik.errors.displacement)
                    }
                    helperText={
                      formik.touched.displacement && formik.errors.displacement
                    }
                  />
                </div>

                <div className="EngineCharacteristicsform_field_container">
                  <p className="EngineCharacteristicsform_fields_labels">
                    Power
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="power"
                    placeholder="Enter Power"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.power}
                    error={formik.touched.power && Boolean(formik.errors.power)}
                    helperText={formik.touched.power && formik.errors.power}
                  />
                </div>

                <div className="EngineCharacteristicsform_field_container">
                  <p className="EngineCharacteristicsform_fields_labels">RTV</p>
                  <CustomTextField
                    disabled={Isview}
                    name="rtv"
                    placeholder="Enter RTV"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.rtv}
                    error={formik.touched.rtv && Boolean(formik.errors.rtv)}
                    helperText={formik.touched.rtv && formik.errors.rtv}
                  />
                </div>
              </div>
              <div>
                <div className="EngineCharacteristicsform_field_container">
                  <p className="EngineCharacteristicsform_fields_labels">
                    Brand
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="brand"
                    placeholder="Enter Brand"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.brand}
                    error={formik.touched.brand && Boolean(formik.errors.brand)}
                    helperText={formik.touched.brand && formik.errors.brand}
                  />
                </div>

                <div className="EngineCharacteristicsform_field_container">
                  <p className="EngineCharacteristicsform_fields_labels">
                    Model
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="model"
                    placeholder="Enter Model"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.model}
                    error={formik.touched.model && Boolean(formik.errors.model)}
                    helperText={formik.touched.model && formik.errors.model}
                  />
                </div>

                <div className="EngineCharacteristicsform_field_container">
                  <p className="EngineCharacteristicsform_fields_labels">
                    No of Cylinders
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="no_of_cylinder"
                    placeholder="Enter No of Cylinders"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.no_of_cylinder}
                    error={
                      formik.touched.no_of_cylinder &&
                      Boolean(formik.errors.no_of_cylinder)
                    }
                    helperText={
                      formik.touched.no_of_cylinder &&
                      formik.errors.no_of_cylinder
                    }
                  />
                </div>

                <div className="EngineCharacteristicsform_field_container">
                  <p className="EngineCharacteristicsform_fields_labels">
                    Fuel
                  </p>
                  <CustomAutoComplete
                    disabled={Isview}
                    name="fuel"
                    placeholder="Select status"
                    options={Fuels}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "fuel",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      Fuels.find((fuel) => fuel.value === formik.values.fuel) ||
                      null
                    }
                    error={formik.touched.fuel && Boolean(formik.errors.fuel)}
                    helperText={formik.touched.fuel && formik.errors.fuel}
                  />
                </div>

                <div className="EngineCharacteristicsform_field_container">
                  <p className="EngineCharacteristicsform_fields_labels">
                    Origin
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="orgin"
                    placeholder="Enter Origin"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.orgin}
                    error={formik.touched.orgin && Boolean(formik.errors.orgin)}
                    helperText={formik.touched.orgin && formik.errors.orgin}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        {!Isview && (
          <div className="EngineCharacteristicsform_action">
            <FormButton
              id="EngineCharacteristicsCreateCancel"
              variant="outlined"
              loading={false}
              text="Cancel"
              onClick={() => {
                navigate(
                  `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/vehicles`
                );
              }}
            />
            <FormButton
              id="EngineCharacteristicsCreateSubmit"
              type="submit"
              form="EngineCharacteristicsform"
              variant="contained"
              loading={loading}
              text="Save & Continue"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default EngineCharacteristicsForm;
