import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers } from '../../../../../api/apiServices/userApis';
import './UserLists.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalStorageServices } from '../../../../../services/localStorageServices';
import { getUsersWithLastMessage } from '../../../../../api/apiServices/messageApis';
import ResponseHandler from '../../../../../services/ResponseHandler';
import dummy_avatar from '../../../../../assets/dummy_avatar.png';
import { Avatar, Badge, Chip, styled } from '@mui/material';

function UserLists() {
  const [Users, SetUsers] = useState([]);
  const [WholeUsers, SetWholeUsers] = useState([]);
  const [searchText, setSearchText] = useState('');
  const messageSended = useSelector((state) => state.messageStore.messageSended);
  const messageReceived = useSelector((state) => state.messageStore.messageReceived);
  const onlineUsers = useSelector((state) => state.messageStore.onlineUsers);
  const messageContainerIsUpdated = useSelector((state) => state.messageStore.messageContainerIsUpdated);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const user_id = queryParams.get("user");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedUserId, setSelectedUserId] = useState(null);

  const handleUserClick = (userId) => {
    setSelectedUserId(userId);
    navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/message?user=${userId}`);
  };
  useEffect(() => {
    dispatch(getUsersWithLastMessage()).then((response) => {
      SetUsers(response);
      SetWholeUsers(response);
      filterUsersList(searchText);
    }).catch((error) => {
      ResponseHandler(error);
    });
  }, [messageSended, messageReceived, messageContainerIsUpdated, onlineUsers]);

  useEffect(() => {
    filterUsersList(searchText);
  }, [WholeUsers]);

  const filterUsersList = (searchText) => {
    setSearchText(searchText);
    if (searchText.length !== 0) {
      let filterUsers = WholeUsers.filter((item) => item.ud_name.toLowerCase().includes(searchText.toLowerCase()));
      SetUsers(filterUsers);
    } else {
      SetUsers(WholeUsers);
    }
  };

  const CustomBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-dot': {
      top: 8,
      left: 28,
      width: 15,
      height: 15,
      borderRadius: '50%',
    },
  }));

  function OnlineUsersId(id) {
    return onlineUsers.some(item => item.userId === id);
  }

  return (
    <div className='message_users_list_super_container' onChange={(e) => {
      filterUsersList(e.target.value);
    }}>
      <input
        placeholder='Search'
        className='users_list_search'
        onChange={(e) => filterUsersList(e.target.value)}
      />
      <div className='message_users_list_main_container'>
      <>
      {Array.isArray(Users) && Users.length > 0 ? (
        searchText.length > 0 ? (
          Users.map((item) => (
            <div
              key={item.up_id}
              className='message_user_super_container'
              style={{ backgroundColor: selectedUserId === item.up_id ? '#f1f1f1' : 'white' }}
              onClick={() => handleUserClick(item.up_id)}
            >
              <div className='message_user_main_container'>
                <div className='message_user_main_container_1'>
                  <div>
                    {item.ud_profile_picture ? (
                      <CustomBadge variant="dot" color="success" invisible={!OnlineUsersId(item.up_id)}>
                        <Avatar src={item.ud_profile_picture || dummy_avatar} sx={{ width: 50, height: 50 }} />
                      </CustomBadge>
                    ) : (
                      <CustomBadge variant="dot" color="success" invisible={!OnlineUsersId(item.up_id)}>
                        <Avatar sx={{ width: 50, height: 50 }}>
                          {item.ud_name.charAt(0)}
                        </Avatar>
                      </CustomBadge>
                    )}
                  </div>
                  <div>
                    <p className='user_list_user_name'>{item.ud_name}</p>
                    {item.last_message && (
                      <p className='user_list_user_message'>
                        {item.m_from_id !== item.up_id
                          ? item.m_message_type === 1
                            ? "You: Voice"
                            : "You: " + item.last_message
                          : item.m_message_type === 1
                            ? "Voice"
                            : item.last_message}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  {item.un_read !== 0 && (
                    <Chip color="primary" label={item.un_read} size='small' />
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          Users.filter(item => item.last_message !== null && item.last_message !== undefined).map((item) => (
            <div
              key={item.up_id}
              className='message_user_super_container'
              style={{ backgroundColor: selectedUserId === item.up_id ? '#f1f1f1' : 'white' }}
              onClick={() => handleUserClick(item.up_id)}
            >
              <div className='message_user_main_container'>
                <div className='message_user_main_container_1'>
                  <div>
                    {item.ud_profile_picture ? (
                      <CustomBadge variant="dot" color="success" invisible={!OnlineUsersId(item.up_id)}>
                        <Avatar src={item.ud_profile_picture || dummy_avatar} sx={{ width: 50, height: 50 }} />
                      </CustomBadge>
                    ) : (
                      <CustomBadge variant="dot" color="success" invisible={!OnlineUsersId(item.up_id)}>
                        <Avatar sx={{ width: 50, height: 50 }}>
                          {item.ud_name.charAt(0)}
                        </Avatar>
                      </CustomBadge>
                    )}
                  </div>
                  <div>
                    <p className='user_list_user_name'>{item.ud_name}</p>
                    {item.last_message && (
                      <p className='user_list_user_message'>
                        {item.m_from_id !== item.up_id
                          ? item.m_message_type === 1
                            ? "You: Voice"
                            : "You: " + item.last_message
                          : item.m_message_type === 1
                            ? "Voice"
                            : item.last_message}
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  {item.un_read !== 0 && (
                    <Chip color="primary" label={item.un_read} size='small' />
                  )}
                </div>
              </div>
            </div>
          ))
        )
      ) : (
        <p>No users found</p>
      )}
    </>
      </div>
    </div>
  );
}

export default UserLists;
