import React, { useState } from "react";
import "./VehicleAppraisalAttachment.css";
import { Button, TextField } from "@mui/material";
import { FiUpload } from "react-icons/fi";
import CustomTextField from "../../../../../../../components/customTextField/CustomTextField";
import CancelIcon from "@mui/icons-material/Cancel";
import Pdf_Logo from "../../../../../../../assets/Pdf_logo.png";
import { Helpers } from "../../../../../../../services/Helpers";
function VehicleAppraisalAttachment({
  Isview,
  attachments,
  uploadAttachments,
  deleteAttachments,
}) {
  const [seletedAttachment, SetSelectedAttachment] = useState({
    filetype: "",
    title: "",
    file: "",
    uploadedDateTime: "",
    isPublish: false,
  });
  const [ValidText, SetValidText] = useState({
    IsNotValid: false,
    message: "",
  });
  const [validFile, SetValidFile] = useState({
    IsNotValid: false,
    message: "File is required",
  });
  function handleFileSelect(event) {
    const fileInput = event.target;
    const fileInfo = document.getElementById("fileInfo");
    if (fileInput.files.length > 0) {
      const fileName = fileInput.files[0].name;
      SetSelectedAttachment({
        ...seletedAttachment,
        ["file"]: fileInput.files[0],
        uploadedDateTime: new Date().toISOString(),
        filetype: fileInput.files[0].type.split("/")[1],
      });
      fileInfo.textContent = `Selected file: ${
        fileName.length > 5 ? fileName.slice(0, 5) + "..." : fileName
      }`;
    } else {
      fileInfo.textContent = "Choose file";
    }
    if (validFile.IsNotValid) {
      SetValidFile({ ...validFile, ["IsNotValid"]: false });
    }
  }

  function setAttachment(name, value) {
    SetSelectedAttachment({ ...seletedAttachment, [name]: value });
    if (ValidText.IsNotValid) {
      SetValidText({
        ...ValidText,
        ["IsNotValid"]: false,
        message: "Name is required",
      });
    }
  }
  function uploadFile() {
    const getExistingName = attachments.filter((item) =>
      seletedAttachment.title
        .trim()
        .toLowerCase()
        .includes(item.title.trim().toLowerCase())
    );
    if (
      !seletedAttachment.title.trim() &&
      seletedAttachment.file.toString().length <= 0
    ) {
      SetValidText({
        ...ValidText,
        ["IsNotValid"]: true,
        message: "Name is required",
      });
      SetValidFile({ ...validFile, ["IsNotValid"]: true });
    } else if (!seletedAttachment.title.trim()) {
      SetValidText({
        ...ValidText,
        ["IsNotValid"]: true,
        message: "Name is required",
      });
    } else if (seletedAttachment.file.toString().length <= 0) {
      SetValidFile({ ...validFile, ["IsNotValid"]: true });
    } else {
      uploadAttachments(seletedAttachment);
      SetSelectedAttachment({
        filetype: "",
        title: "",
        file: "",
        uploadedDateTime: "",
        isPublish: false,
      });
      const fileInfo = document.getElementById("fileInfo");
      fileInfo.textContent = "Choose file";
    }
  }
  function calculateBase64FileSize(base64String) {
    const base64WithoutPrefix = base64String.replace(
      /^(data:[^;]+;base64,)/,
      ""
    );
    const sizeInBytes =
      Math.ceil((base64WithoutPrefix.length * 3) / 4) -
      base64WithoutPrefix.split("=")[1].length;

    if (sizeInBytes < 1024) {
      return sizeInBytes + "Bytes";
    } else if (sizeInBytes < 1024 * 1024) {
      return (sizeInBytes / 1024).toFixed(2) + "KB";
    } else {
      return (sizeInBytes / (1024 * 1024)).toFixed(2) + "MB";
    }
  }
  function DeleteAttachment(index) {
    return deleteAttachments(index);
  }
  function isFileURL(file) {
    return typeof file === "string" && (file.startsWith("http://") || file.startsWith("https://"));
  }
  return (
    <div className="VehicleAppraisal-Main-Div">
      {!Isview && (
        <div className="input_file_container">
          <div id="task-input-file" style={{ position: "relative" }}>
            <label htmlFor="files" className="choose_vehicle_file_field">
              <FiUpload className="file_upload_icon" />
              <span id="fileInfo">Choose file</span>
            </label>

            <input
              id="files"
              style={{ display: "none" }}
              type="file"
              onChange={handleFileSelect}
            />
            {validFile.IsNotValid ? (
              <p
                style={{
                  position: "absolute",
                  color: "red",
                  visibility: validFile.IsNotValid ? "visible" : "hidden",
                }}
              >
                {validFile.message}
              </p>
            ) : null}
          </div>
          <div id="task-input-title" style={{ position: "relative" }}>
            <CustomTextField
              value={seletedAttachment.title}
              placeholder={"Title"}
              onChange={(e) => setAttachment("title", e.target.value)}
            />
            {ValidText.IsNotValid ? (
              <p
                style={{
                  position: "absolute",
                  color: "red",
                  visibility: ValidText.IsNotValid ? "visible" : "hidden",
                }}
              >
                {ValidText.message}
              </p>
            ) : null}
          </div>

          <div id="task-input-upload-btn">
            <Button
              size="small"
              variant="contained"
              onClick={uploadFile}
              style={{
                textTransform: "none",
                lineHeight: 2.0,
                fontSize: 15,
                alignItems: "center",
                fontFamily: "Inter",
              }}
            >
              Upload
            </Button>
          </div>
        </div>
      )}

      <div
        className="attachment_main_container_main"
        style={{ display: attachments?.length == 0 ? "none" : "flex" }}
      >
        <div
          className="attachment_main_container1"
          style={{ display: attachments?.length === 0 ? "none" : "flex" }}
        >
          {attachments &&
            attachments.map((item, index) => {
              return (
                <>
                  {item.filetype !== "pdf" && !Helpers.getUrlExtensions(item.file) && (
                    <div className="img-div-attachment">
                      <img
                        key={index}
                        src={
                          typeof item.file === 'string' && (item.file.startsWith('http://') || item.file.startsWith('https://'))
                            ? item.file
                            : URL.createObjectURL(item.file)
                        }
                        alt={`Uploaded Image ${index + 1}`}
                        className="image_attachment"
                      />
                      <p style={{ textAlign: "center" }}>{item.title}</p>
                      {!Isview && (
                        <div
                          className="attachment_close_icon"
                          onClick={() => {
                            DeleteAttachment(index);
                          }}
                        >
                          <CancelIcon className="attachemnt_cancelIcon" />
                        </div>
                      )}
                    </div>
                  )}
                  {Helpers.isVideoFile(item.filetype)&& (
              <div className="vedioattachment_container" key={index}>
                <video controls className="image_attachment">
                  <source
                     src={Helpers.isFileURL(item.file) ? item.file : URL.createObjectURL(item.file)}
                     type={`video/${item.filetype}`}
                  />
                  Your browser does not support the video tag.
                </video>
                <p style={{ textAlign: "center" }}>{item.title}</p>
                {!Isview && (
                  <div className="attachment_close_icon" onClick={() => DeleteAttachment(index)}>
                    <CancelIcon className="attachemnt_cancelIcon" />
                  </div>
                )}
              </div>
            )}
                </>
              );
            })}
        </div>
        <div
          className="attachment_main_container2"
          style={{ display: attachments?.length === 0 ? "none" : "flex" }}
        >
          {attachments &&
            attachments.map((item, index) => {
              return (
                <>
                  {item.filetype === "pdf" && (
                    <div className="file_attachment_container">
                      <div className="attachment_sub_container">
                        <img
                          key={index}
                          src={Pdf_Logo}
                          alt={`Uploaded Image ${index + 1}`}
                          className="file_attachment"
                        />
                        <div>
                          <p className="file_title">{item.title + ".pdf"}</p>
                        </div>
                      </div>
                      {!Isview && (
                        <div
                          className="attachment_close_icon"
                          onClick={() => {
                            DeleteAttachment(index);
                          }}
                        >
                          <CancelIcon className="attachemnt_cancelIcon" />
                        </div>
                      )}
                    </div>
                  )}
                </>
              );
            })}
        </div>
      </div>
      <div
        style={{
          fontSize: "14px",
          fontFamily: "Inter",
          padding: "6px",
        }}
      >
        {attachments?.length === 0 && <p>No attachments found.</p>}
      </div>
    </div>
  );
}

export default VehicleAppraisalAttachment;
