import React, { useEffect } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import CustomAutoComplete from '../../../../../../components/customAutoComplete/CustomAutoComplete';
import { getAllUsers } from '../../../../../../api/apiServices/userApis';
import FormButton from '../../../../../../components/formButton/FormButton';
import './ReAssignVehicleTaskForm.css'
import { getAllVehicleTasks, getVehicleTaskByTaskId, updateVehicleTaskAssigne } from '../../../../../../api/apiServices/vehicleApi';
import ResponseHandler from '../../../../../../services/ResponseHandler';
function ReAssignVehicleTaskForm({taskId,vehicleId,onclose}) {
    const dispatch=useDispatch()
    const users=useSelector((state)=>state.userStore.WholeUsers)
    useEffect(()=>{
        if(taskId){
            dispatch(getAllUsers())
            dispatch(getVehicleTaskByTaskId(taskId)).then((response)=>{
                formik.setValues({
                    ...formik.values,
                    re_assign:response.data.data.vt_assignto,
                  });
            }).catch((error)=>{
                ResponseHandler(error)
            })
        }
      },[taskId])
      function SetUsersDropdown(users){
        if(users && users.length>0){
          const filterUsers=users.filter(item=>item.url_role !=="Admin" && item.url_role !=="SuperAdmin" && item.url_role !=="Agent")
          const data =filterUsers.map((item) => {
            return {
              label: item.up_email,
              value: item.up_id,
            };
          });
          return data
        }else{
          return []
        }
      }
    let formik = useFormik({
        initialValues: {
            re_assign: null,
        },
        validationSchema: Yup.object({
            re_assign: Yup.string().required("Reassign is required")
        }),
        onSubmit: (values) => {
            dispatch(updateVehicleTaskAssigne(taskId,values,vehicleId)).then((response)=>{
                ResponseHandler(response)
                onclose(false)
            }).catch((error)=>{
                ResponseHandler(error)
            })
        }
      });
  return (
    <div className="update_vehicle_task_assigne_Form">
        <form id='vehicle_Reassign_taskform' onSubmit={formik.handleSubmit}>
                  <p className='update_vehicle_task_assigne_Form_label'>Assigned To</p>
                  <CustomAutoComplete
                    name="re_assign"
                    placeholder="Select assigned to"
                    options={SetUsersDropdown(users)}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "re_assign",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      SetUsersDropdown(users).find(
                        (re_assign) =>
                          re_assign.value === formik.values.re_assign
                      ) || null
                    }
                   
                    isOptionEqualToValue={(option, value) =>
                      option.value.toString() === value.value.toString()
                    }
                    error={
                      formik.touched.re_assign &&
                      Boolean(formik.errors.re_assign)
                    }
                    helperText={
                      formik.touched.re_assign && formik.errors.re_assign
                    }
                  />
                <div className="update_vehicle_task_assigne_Form_actions">
                <FormButton
                    id="vehicle_ReassignCreateCancel"
                    variant="outlined"
                    loading={false}
                    text="Cancel"
                    onClick={()=>{onclose(false)}}
                />
                <FormButton
                    id="vehicle_ReassignCreateSubmit"
                    type="submit"
                    form="vehicle_Reassign_taskform"
                    variant="contained"
                    text="Update Assigne"
                />
                </div>
        </form>
                
    </div>
  )
}

export default ReAssignVehicleTaskForm