import { createSlice } from "@reduxjs/toolkit";

export const customerSlices = createSlice({
  name: "customerStore",
  initialState: {
    allCustomersBackUp: [],
    allCustomers: [],
    filterCustomerDetails: {
      name: "",
      email: "",
      role: "",
    },
    allContactUsBackUp: [],
    allContactUs: [],
    filterContactUsDetails: {
      name: "",
      email: "",
      contactNumber: "",
    },
  },
  reducers: {
    setCustomers: (state, action) => {
      state.allCustomersBackUp = action.payload;
      state.allCustomers = action.payload;
    },
    setContactUs: (state, action) => {
      state.allContactUsBackUp = action.payload;
      state.allContactUs = action.payload;
    },
    
    setFilterCustomerDatas: (state, actions) => {
      const { name, value } = actions.payload;
      console.log({ name, value })
      state.filterCustomerDetails[name] = value;
    },
    setFilterContactUsDatas: (state, actions) => {
      const { name, value } = actions.payload;
      state.filterContactUsDetails[name] = value;
    },
    filterCustomerData: (state) => {
      let FilterCustomerDatas = [];
      if (state.filterCustomerDetails.name.trim()) {
        FilterCustomerDatas = state.allCustomersBackUp.filter((item) =>
          item.ud_name
            .toLowerCase()
            .includes(state.filterCustomerDetails.name.toLowerCase())
        );
      } else {
        FilterCustomerDatas = state.allCustomersBackUp;
      }
      if (state.filterCustomerDetails.email.trim()) {
        if (FilterCustomerDatas.length > 0) {
          FilterCustomerDatas = FilterCustomerDatas.filter((item) =>
            item.up_email
              .toLowerCase()
              .includes(state.filterCustomerDetails.email.toLowerCase())
          );
        } else {
          FilterCustomerDatas = state.allCustomersBackUp.filter((item) =>
            item.up_email
              .toLowerCase()
              .includes(state.filterCustomerDetails.email.toLowerCase())
          );
        }
      } else {
        if (FilterCustomerDatas.length === 0) {
          FilterCustomerDatas = state.allCustomersBackUp;
        }
      }

      if (state.filterCustomerDetails.role.trim()) {
        if (FilterCustomerDatas.length > 0) {
          FilterCustomerDatas = FilterCustomerDatas.filter((item) =>
            item.url_role
              .toLowerCase()
              .includes(state.filterCustomerDetails.role.toLowerCase())
          );
        } else {
          FilterCustomerDatas = state.allCustomersBackUp.filter((item) =>
            item.url_role
              .toLowerCase()
              .includes(state.filterCustomerDetails.role.toLowerCase())
          );
        }
      }
      state.allCustomers = FilterCustomerDatas;
    },

    filterContactUsData: (state) => {
      let FilteredContactUs = [];
      if (state.filterContactUsDetails.name.trim()) {
        FilteredContactUs = state.allContactUsBackUp.filter((item) =>
          item.cu_name
            .toLowerCase()
            .includes(state.filterContactUsDetails.name.toLowerCase())
        );
      } else {
        FilteredContactUs = state.allContactUsBackUp;
      }
      if (state.filterContactUsDetails.email.trim()) {
        if (FilteredContactUs.length > 0) {
          FilteredContactUs = FilteredContactUs.filter((item) =>
            item.cu_email
              .toLowerCase()
              .includes(state.filterContactUsDetails.email.toLowerCase())
          );
        } else {
          FilteredContactUs = state.allContactUsBackUp.filter((item) =>
            item.cu_email
              .toLowerCase()
              .includes(state.filterContactUsDetails.email.toLowerCase())
          );
        }
      } else {
        if (FilteredContactUs.length === 0) {
          FilteredContactUs = state.allContactUsBackUp;
        }
      }

      if (state.filterContactUsDetails.contactNumber.trim()) {
        if (FilteredContactUs.length > 0) {
          FilteredContactUs = FilteredContactUs.filter((item) =>
            item.cu_contact_number
              .toLowerCase()
              .includes(
                state.filterContactUsDetails.contactNumber.toLowerCase()
              )
          );
        } else {
          FilteredContactUs = state.allContactUsBackUp.filter((item) =>
            item.cu_contact_number
              .toLowerCase()
              .includes(
                state.filterContactUsDetails.contactNumber.toLowerCase()
              )
          );
        }
      }
      state.allContactUs = FilteredContactUs;
    },
    resetFilterCustomerData: (state) => {
      state.allCustomers = state.allCustomersBackUp;
      state.filterCustomerDetails = { name: "", email: "", role: "" };
    },

    resetFilterContactUsData: (state) => {
      state.allContactUs = state.allContactUsBackUp;
      state.filterContactUsDetails = { name: "", email: "", contactNumber: "" };
    },
  },
});

export const {
  setCustomers,
  setContactUs,
  setFilterCustomerDatas,
  setFilterContactUsDatas,
  filterCustomerData,
  filterContactUsData,
  resetFilterCustomerData,
  resetFilterContactUsData,
} = customerSlices.actions;

export default customerSlices.reducer;
