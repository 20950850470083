import ResponseHandler from "../../services/ResponseHandler"
import { ApiConfig } from "../apiConfig"

export const getAllHashTags = () => {
    return ApiConfig.get('get_hashtags')
        .then(response => response.data.data)
        .catch(error => {
            ResponseHandler(error);
            throw error;
        });
};