import React, { useEffect, useState } from "react";
import "./AgentSearchFilter.css";
import CustomTextField from "../../../../../components/customTextField/CustomTextField";
import CustomAutoComplete from "../../../../../components/customAutoComplete/CustomAutoComplete";
import { useFormik } from "formik";
import { Autocomplete, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { setRefresh } from "../../../../../store/usersStore";
import {
  filterAgentData,
  resetFilterData,
  setAgentFilterDatas,
  updateFilterDropdownData,
} from "../../../../../store/agentStore";

const translateText = async (text) => {
  const currentLang = localStorage.getItem('currentLanguage') || 'en';
  if (currentLang === 'es') {
    try {
      const response = await fetch(
        `https://api.mymemory.translated.net/get?q=${encodeURIComponent(text)}&langpair=es|en`
      );
      const data = await response.json();
      return data.responseData.translatedText;
    } catch (error) {
      console.error('Error translating text:', error);
      return text; // Fallback to original text if translation fails
    }
  }
  return text;
};

function AgentSearchFilter() {
  const dispatch = useDispatch();
  const AgentsDatas = useSelector((state) => state.agentStore.WholeAgents);
  const AgentsDropdownList = useSelector(
    (state) => state.agentStore.FilterDropDownList
  );
  const FilterData = useSelector((state) => state.agentStore.AgentFilterData);

  useEffect(() => {
    dispatch(updateFilterDropdownData());
  }, [AgentsDatas, dispatch]);

  const initialValues = {
    name: FilterData.name || "",
    email: FilterData.email || "",
    location: FilterData.location || "",
    status: FilterData.status || "",
    interest: FilterData.interest || "",
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      // Translate filter values if needed
      try {
        const translatedValues = {
          ...values,
          name: await translateText(values.name),
          location: await translateText(values.location),
          interest: await translateText(values.interest),
          status: await translateText(values.status),
        };
        console.log("Submitting translated values:", translatedValues);
        dispatch(setAgentFilterDatas(translatedValues));
        dispatch(filterAgentData());
      } catch (error) {
        console.error('Error in form submission:', error);
      }
    },
  });

  const [filterOpen, setFilterOpen] = useState(false);

  const inputStyles = {
    "& .MuiOutlinedInput-root": {
      fontSize: "14px !important",
      fontFamily: "Inter !important",
      padding: "0px",
      width: 200,
      "& fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important",
      },
      "&:hover fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important",
      },
      "& .MuiAutocomplete-input": {
        padding: "7.5px 4px 7.5px 9px !important",
        fontFamily: "Inter !important",
      },
    },
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const filterContainer = document.getElementById("filter_field_container");
      const icon = document.querySelector(".filter_icon_mobile");
      const target = event.target;

      if (
        filterContainer &&
        !filterContainer.contains(target) &&
        icon !== target &&
        !icon.contains(target)
      ) {
        setFilterOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterOpen]);

  const UpdateFieldVisibility = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = element.style.display === "none" || element.style.display === "" ? "block" : "";
    }
  };

  return (
    <div>
      <div className="filter_icon_mobile">
        <IconButton
          color="primary"
          onClick={() => {
            UpdateFieldVisibility("agent_filter_field_container");
            setFilterOpen(!filterOpen);
          }}
        >
          <FilterAltOutlinedIcon />
        </IconButton>
      </div>

      <div
        id="agent_filter_field_container"
        style={{ display: filterOpen ? "block" : "" }}
      >
        <div id="filter_field_container" className="filter_field_container">
          <form className="filter_tool_bar" onSubmit={formik.handleSubmit}>
            <div className="filter_tool_field_width">
              <TextField
                name="name"
                className="agentFilter_input"
                placeholder="Enter name"
                size="small"
                style={{ height: "50px" }}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                variant="outlined"
                sx={{
                  "& fieldset": { border: "none" },
                }}
              />
            </div>
            <div className="filter_tool_field_width">
              <Autocomplete
                componentsProps={{ paper: { sx: { wordBreak: "break-word" } } }}
                sx={{ ...inputStyles }}
                disablePortal
                name="email"
                options={AgentsDropdownList.email}
                getOptionLabel={(option) => option.label}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  formik.setFieldValue("email", newValue ? newValue.label : "");
                }}
                value={
                  AgentsDropdownList.email.find(
                    (email) => email.value === formik.values.email
                  ) || null
                }
                ListboxProps={{ style: { minHeight: 0, fontSize: 14 } }}
                menuProps={{ style: { minHeight: 0 } }}
                renderInput={(params) => (
                  <TextField {...params} label="" placeholder="Select email" />
                )}
              />
            </div>
            <div className="filter_tool_field_width">
              <TextField
                name="location"
                className="agentFilter_input"
                size="small"
                style={{ height: "50px" }}
                placeholder="Enter location"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.location}
                variant="outlined"
                sx={{
                  "& fieldset": { border: "none" },
                }}
              />
            </div>
            <div className="filter_tool_field_width">
              <Autocomplete
                componentsProps={{ paper: { sx: { wordBreak: "break-word" } } }}
                style={{ fontSize: "15px" }}
                sx={{
                  ...inputStyles,
                  height: "37px",
                }}
                disablePortal
                name="interest"
                options={AgentsDropdownList.interest}
                getOptionLabel={(option) => option.label}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "interest",
                    newValue ? newValue.value : ""
                  );
                }}
                value={
                  AgentsDropdownList.interest.find(
                    (interest) => interest.value === formik.values.interest
                  ) || null
                }
                ListboxProps={{ style: { minHeight: 0, fontSize: 14 } }}
                menuProps={{ style: { minHeight: 0 } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    placeholder="Select interest"
                  />
                )}
              />
            </div>
            <div className="filter_tool_field_width">
              <Autocomplete
                componentsProps={{ paper: { sx: { wordBreak: "break-word" } } }}
                sx={{ ...inputStyles }}
                disablePortal
                name="status"
                options={AgentsDropdownList.status}
                getOptionLabel={(option) => option.label}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "status",
                    newValue ? newValue.label : ""
                  );
                }}
                value={
                  AgentsDropdownList.status.find(
                    (status) => status.value === formik.values.status
                  ) || null
                }
                ListboxProps={{ style: { minHeight: 0, fontSize: 14 } }}
                menuProps={{ style: { minHeight: 0 } }}
                renderInput={(params) => (
                  <TextField {...params} label="" placeholder="Select status" />
                )}
              />
            </div>
            <button
              className="agent_Search_button"
              type="submit"
              style={{ height: "35px" }}
            >
              Search
            </button>
            <button
              type="button"
              className="agent_Reset_button"
              style={{ height: "35px" }}
              onClick={() => {
                const resetValues = {
                  name: "",
                  email: "",
                  location: "",
                  status: "",
                  interest: "",
                };
                dispatch(resetFilterData());
                formik.setValues({ ...formik.values, ...resetValues });
                setTimeout(() => {
                  dispatch(setRefresh());
                }, 500);
              }}
            >
              Reset
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default AgentSearchFilter;
