import React, { useState, useRef, useEffect } from "react";
import "./SearchFilter.css";
import { Autocomplete, TextField } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  searchFilterVehicleTaskData,
  setVehicleTaskFilterDatas,
  setVehicleTaskResetFilter,
  updateTaskFilterDropdown,
} from "../../../../../../store/vehicleStore";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import CustomDropDown from "../../../../../../components/customDropdown/CustomDropDown";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import IconButton from "@mui/material/IconButton";
import { setRefresh } from "../../../../../../store/usersStore";

function SearchFilter() {
  const dispatch = useDispatch();
  const wholeVehicleTaskData = useSelector(
    (state) => state.vehicleStore.WholeVehicleTasks
  );
  const VehicleTaskDropDownList = useSelector(
    (state) => state.vehicleStore.VehicleTaskFilterDropDownList
  );
  const FilterData = useSelector(
    (state) => state.vehicleStore.VehicleTaskFilterData
  );
  useEffect(() => {
    dispatch(updateTaskFilterDropdown());
  }, [wholeVehicleTaskData]);
  const inputStyles = {
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      fontSize: "15px !important",
      fontFamily: "Inter",
      width: 200,
      "& .MuiAutocomplete-input": {
        padding: "7.5px 4px 7.5px 9px !important",
        fontFamily: "Inter",
      },
      "& fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color
      },
      "&:hover fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color when focused
      },
    },
  };
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });
  const handleFieldClick = () => {
    togglePickerView("custom_date_picker_container");
  };

  const togglePickerView = (id) => {
    const element = document.getElementById(id);
    if (element) {
      if (element.style.display === "none" || element.style.display === "") {
        element.style.display = "block";
      } else {
        element.style.display = "";
      }
    }
  };
  window.addEventListener("click", function (e) {
    try {
      if (
        !document
          .getElementById("custom_date_picker_field")
          ?.contains(e.target) &&
        !document
          .getElementById("custom_date_picker_container")
          ?.contains(e.target)
      ) {
        const element = document.getElementById("custom_date_picker_container");
        if (element) {
          if ((element.style.display = "block")) {
            element.style.display = "";
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  });

  const translateText = async (text) => {
    const currentLang = localStorage.getItem("currentLanguage") || "en";
    console.log("Translating text:", text, "to language:", currentLang); // Debugging log

    if (currentLang === "es") {
      try {
        const response = await fetch(
          `https://api.mymemory.translated.net/get?q=${encodeURIComponent(
            text
          )}&langpair=es|en`
        );
        const data = await response.json();
        console.log("Translation response data:", data); // Debugging log
        return data.responseData.translatedText;
      } catch (error) {
        console.error("Error translating text:", error);
        return text; // Fallback to original text if translation fails
      }
    }
    return text;
  };

  const initialValues = {
    taskName: FilterData.taskName,
    assign_to: FilterData.assign_to,
    start_date: FilterData.start_date,
    end_date: FilterData.end_date,
    status: FilterData.status,
    priority: FilterData.priority,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values) => {
      console.log("Form values before translation:", values); // Debugging log
  
      const translatedValues = {
        ...values,
        taskName: values.taskName ? await translateText(values.taskName) : "",
        assign_to: values.assign_to ? await translateText(values.assign_to) : "",
        status: values.status ? await translateText(values.status) : "",
        priority: values.priority ? await translateText(values.priority) : "",
      };
  
      console.log("Form values after translation:", translatedValues); // Debugging log
  
      formik.setValues(translatedValues); // Update form state with translated values
      dispatch(setVehicleTaskFilterDatas(translatedValues));
      dispatch(setRefresh());
      dispatch(searchFilterVehicleTaskData());
    },
  });
  

  const handleSelect = (ranges) => {
    const start_date = ranges.selection.startDate.toLocaleDateString();
    const end_date = ranges.selection.endDate.toLocaleDateString();
    formik.setValues({ ...formik.values, start_date, end_date });
    setSelectionRange(ranges.selection);
  };

  const [filterOpen, setFilterOpen] = useState(false);
  useEffect(() => {
    const handleClickOutside = (event) => {
      const icon = document.querySelector(".filter_icon_mobile");
      const container = document.querySelector(".filter_task_container");
      const datePicker = document.querySelector(
        ".custom-date-picker.rdrDateRangePickerWrapper"
      );
      const isDatePickerOpen = datePicker && datePicker.style.display == "flex";
      if (
        !isDatePickerOpen &&
        !icon.contains(event.target) &&
        !container.contains(event.target)
      ) {
        const datePickerContainer = document.getElementById(
          "custom_date_picker_container"
        );
        if (datePickerContainer) {
          if (datePickerContainer.style.display !== "block") {
            setFilterOpen(false);
          }
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterOpen]);

  function UpdateFieldVisibility(id) {
    const element = document.getElementById(id);
    if (element) {
      if (element.style.display === "none" || element.style.display === "") {
        element.style.display = "block";
      } else {
        element.style.display = "";
      }
    }
  }
  return (
    <div>
      <div className="filter_icon_mobile">
        <IconButton
          color="primary"
          onClick={() => {
            UpdateFieldVisibility("vehicle_filter_task_container");
            setFilterOpen(!filterOpen);
          }}
        >
          <FilterAltOutlinedIcon />
        </IconButton>
      </div>
      <div
        id="vehicle_filter_task_container"
        style={{ display: filterOpen ? "block" : "" }}
      >
        <div id="filter_task_container" className="filter_task_container">
          <form
            className="vehile_task_filter_tool_bar"
            onSubmit={formik.handleSubmit}
          >
            <div className="vehile_task_filter_tool_field_width">
              <TextField
                name="taskName"
                value={formik.values.taskName}
                className="vehicle_Searchfilter_input"
                label=""
                placeholder="Search task name"
                id="outlined-size-small"
                size="small"
                onChange={formik.handleChange}
                variant="outlined"
                inputProps={{
                  style: {
                    padding: "5px 1px",
                  },
                }}
                sx={{
                  "& fieldset": { border: "none" },
                }}
              />
            </div>
            <div className="vehile_task_filter_tool_field_width">
              <Autocomplete
                name="assign_to"
                componentsProps={{ paper: { sx: { wordBreak: "break-word" } } }}
                sx={{ ...inputStyles }}
                disablePortal
                id="combo-box-demo"
                options={VehicleTaskDropDownList.assign_to}
                getOptionLabel={(option) => option.label}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "assign_to",
                    newValue ? newValue.label : ""
                  );
                }}
                value={
                  VehicleTaskDropDownList.assign_to.find(
                    (assign_to) => assign_to.value === formik.values.assign_to
                  ) || null
                }
                ListboxProps={{
                  style: {
                    minHeight: 0,
                    maxWidth: "100%",
                    fontSize: 15,
                    fontFamily: "Inter",
                  },
                }}
                menuProps={{ style: { minHeight: 0, fontFamily: "Inter" } }}
                renderInput={(params) => (
                  <TextField
                    style={{ fontFamily: "Inter" }}
                    {...params}
                    label=""
                    placeholder="Select assign to"
                  />
                )}
              />
            </div>
            <div>
              <div
                id="custom_date_picker_field"
                className="task_custom_date_picker_field"
                onClick={handleFieldClick}
              >
                <p style={{display:"flex",flexDirection:"row"}}>
                  <div>
                    {formik.values.start_date ? (
                      formik.values.start_date
                    ) : (
                      <span
                        className="customPlaceholder"
                        style={{ fontFamily: "Inter", fontSize: "15px" }}
                      >
                        Start date
                      </span>
                    )}
                  </div>
                  <p>-</p>
                  <div>
                    {formik.values.end_date ? (
                      formik.values.end_date
                    ) : (
                      <span
                        className="customPlaceholder"
                        style={{ fontFamily: "Inter" }}
                      >
                        End date
                      </span>
                    )}
                  </div>
                </p>
                <div>
                  {formik.values.start_date && formik.values.end_date && (
                    <div>
                      <div
                        style={{
                          fontSize: "20px",
                          color: "grey",
                          cursor: "pointer",
                          display: "inline-block",
                        }}
                        onClick={() => {
                          formik.setValues({
                            ...formik.values,
                            start_date: null,
                            end_date: null,
                          });
                          setSelectionRange({
                            startDate: new Date(),
                            endDate: new Date(),
                            key: "selection",
                          });
                        }}
                      >
                        <p>
                          <CloseIcon />
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div
                id={"custom_date_picker_container"}
                className="custom_date_picker_container"
              >
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={handleSelect}
                  className="custom-date-picker_task"
                />
              </div>
            </div>
            <div className="vehile_task_filter_tool_field_width">
              <Autocomplete
                name="priority"
                sx={{ ...inputStyles }}
                disablePortal
                id="combo-box-demo"
                options={VehicleTaskDropDownList.priority}
                getOptionLabel={(option) => option.label}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "priority",
                    newValue ? newValue.label : ""
                  );
                }}
                value={
                  VehicleTaskDropDownList.priority.find(
                    (priority) => priority.value === formik.values.priority
                  ) || null
                }
                ListboxProps={{
                  style: { minHeight: 0, fontSize: 15, fontFamily: "Inter" },
                }}
                menuProps={{ style: { minHeight: 0, fontFamily: "Inter" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    placeholder="Select priority"
                  />
                )}
              />
            </div>
            <div className="vehile_task_filter_tool_field_width">
              <Autocomplete
                name="status"
                sx={{ ...inputStyles }}
                disablePortal
                id="combo-box-demo"
                options={VehicleTaskDropDownList.status}
                getOptionLabel={(option) => option.label}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "status",
                    newValue ? newValue.label : ""
                  );
                }}
                value={
                  VehicleTaskDropDownList.status.find(
                    (status) => status.value === formik.values.status
                  ) || null
                }
                ListboxProps={{
                  style: { minHeight: 0, fontSize: 15, fontFamily: "Inter" },
                }}
                menuProps={{ style: { minHeight: 0, fontFamily: "Inter" } }}
                renderInput={(params) => (
                  <TextField {...params} label="" placeholder="Select status" />
                )}
              />
            </div>
            <button className="toolbar_Search_button" type="submit">
              Search
            </button>
            <button
              type="button"
              className="toolbar_Reset_button"
              onClick={() => {
                dispatch(setVehicleTaskResetFilter());
                setSelectionRange({
                  startDate: new Date(),
                  endDate: new Date(),
                  key: "selection",
                });
                formik.setValues({
                  ...formik.values,
                  taskName: "",
                  assign_to: null,
                  start_date: null,
                  end_date: null,
                  status: null,
                  priority: null,
                });
              }}
            >
              Reset
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SearchFilter;
