import React, { useEffect, useState } from "react";
import { BsShare } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import { PiEngineFill } from "react-icons/pi";
import { FaWeight } from "react-icons/fa";
import { Card, Grid, Typography } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from "react-router-dom";
import "./AgentVehicleView.css";
import { ImPower } from "react-icons/im";
import { ApiConfig } from "../../../../../../api/apiConfig";
import { FaMapMarkerAlt } from "react-icons/fa";
import VehicleMap from "./VehicleMap"; 
import VehicleImageSlide from "./VehicleSlideView";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoHeart } from "react-icons/io5";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import { useSelector } from "react-redux";
import CustomSpin from "../../../../../../components/customSpin/CustomSpin";
import Skeleton from "@mui/material/Skeleton";
import { useLocation } from "react-router-dom";
import { Helpers } from "../../../../../../services/Helpers";
function AgentVehicleView() {
  const navigate = useNavigate();
  const location = useLocation();
  // Added store for getting token (to check is logined or not)
  const token = useSelector((state) => state.userStore.token);
  const { id } = useParams();
  const [wishlistIds, setWishlistIds] = useState([]);
  const [vehicles, setVehicles] = useState([]);
  const [suggestedVehicles, setsuggestedVehicles] = useState([]);
  const [vehiclesdetails, setvehiclesdetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openmodal, setOpenModal] = useState(false);
  const [vehicleid, setvehicleid] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [VehiclesRecom, setVehiclesRecom] = useState([]);
  const [hasRecommendations, setHasRecommendations] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (state) => {
    setOpen(state);
    // getCustomerDetails();
  };

  const getvehicles = async () => {
    setLoading(true);
    try {
      if (id) {
        const result = await ApiConfig.get(
          `/customer/${id}/get_similar_vehicle`
        );
        const { status } = result;
        const { data } = result.data;
        if (status === 200) {
          setVehicles(data);
        } else {
          ResponseHandler(result);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };


  // const theme = useTheme();
  const [latitudeLongitude, SetlatitudeLongitude] = useState([0, 0]);

  const getvehicledetails = async () => {
    setLoading(true);
    try {
      if (id) {
        const result = await ApiConfig.get(
          `/customer/${id}/get_vehicle_details`
        );
        const { status } = result;
        const { data } = result.data;
        if (status === 200) {
          setvehiclesdetails(data);
          setvehicleid(data.Vehicle_Bank_Details.vbd_id);

          // ResponseHandler(result);
        } else {
          ResponseHandler(result);
        }
        // Extract latitude and longitude and update the state
        const { vad_latitude, vad_longitude } = data.Vehicle_Appraisal_Details;
        if (vad_latitude && vad_longitude) {
          // Modify this logic to show data in map
          SetlatitudeLongitude([
            parseFloat(vad_longitude),
            parseFloat(vad_latitude),
          ]);
        }
      }
    } catch (error) {
      ResponseHandler(error);
    } finally {
      setLoading(false); // This should be within the `try...catch` statement
    }
  };
  
  const [wishlist, SetWishlist] = useState([]);
 

  const [heartColors, setHeartColors] = useState({}); // State to store heart colors for each card



  const handleClosemodal = () => {
    setOpenModal(false);
    // Optionally, reset location state to avoid reopening modal on refresh
    window.history.replaceState({}, document.title);
  };
  useEffect(() => {
    getvehicledetails();
    getvehicles();
     window.scrollTo(0, 0);
  }, [id, token]);

  return (
    <>
      <CustomSpin loading={loading}>
      <div className="agent_view_header">
        <p className="page_title"> Vehicle ID: {vehiclesdetails?.Vehicle_Bank_Details?.vbd_id}</p>
        <div
          className="back_button"
          onClick={() => {
            navigate(-1);
          }}
        >
          <KeyboardBackspaceIcon />
          <p>back</p>
        </div>
      </div>

        <div className="Vehicleview_fullcontainer">
          <Grid container spacing={6} marginTop={-3}>
            <Grid item xs={12} md={8}>
              <VehicleImageSlide />
            </Grid>

            <Grid item xs={12} md={4}>
              <div className=" Vehicle_details_Titile">
                <div className="titile_details">
                  {" "}
                  {vehiclesdetails?.Vehicle_Bank_Details?.vs_sale_title}
                </div>
                
                {/* <SocialMediaShare
                  title={vehiclesdetails?.Vehicle_Bank_Details?.vs_sale_title}
                  shareUrl={window.location.href}
                /> */}
              </div>
              <Typography
                sx={{
                  color: "#9093A1",
                  fontWeight: 300,
                  fontSize: 14,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 8,
                  }}
                >
                  <IoLocationSharp />
                  {vehiclesdetails?.Vehicle_Appraisal_Details?.vad_map_location}
                </div>
              </Typography>
              <div className="custom-typography">
                <div className="price">
                  $ {vehiclesdetails?.Vehicle_Appraisal_Details?.vad_amount}
                </div>
                {/* <div className="text">
                  Vehicle ID {vehiclesdetails?.Vehicle_Bank_Details?.vbd_id}
                </div> */}
              </div>
              <div
                className="characteristics_container"
                // style={{ backgroundColor: "#F6FAFF", padding: "10px", width: 300 }}
              >
                <div
                  className="characteristics"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 6,
                  }}
                >
                  <PiEngineFill className="characteristics_icon" />
                  <p
                    style={{
                      marginTop: 12,
                      fontFamily: "Inter",
                      fontWeight: 600,
                      color: "#4A60A1 ",
                    }}
                  >
                    {
                      vehiclesdetails?.Vehicle_Engine_Characteristics
                        ?.ved_no_of_cylinder
                    }
                  </p>
                </div>

                <div
                  className="characteristics"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 6,
                  }}
                >
                  <ImPower className="characteristics_icon" />
                  <p
                    style={{
                      marginTop: 12,
                      fontFamily: "Inter",
                      fontWeight: 600,
                      color: "#4A60A1 ",
                    }}
                  >
                    {vehiclesdetails?.Vehicle_Engine_Characteristics?.ved_power}
                  </p>
                </div>
                <div
                  className="characteristics"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 6,
                  }}
                >
                  <FaWeight className="characteristics_icon" />
                  <p
                    style={{
                      marginTop: 12,
                      fontFamily: "Inter",
                      fontWeight: 600,
                      color: "#4A60A1 ",
                    }}
                  >
                    {
                      vehiclesdetails?.Vehicle_General_Characteristics
                        ?.vd_weight_trailer
                    }
                  </p>
                </div>
              </div>
              
              {/* {Array.isArray(vehiclesdetails) && vehiclesdetails.map((vehiclesdetail, index) => ( */}
              <Card
                sx={{
                  borderRadius: 4,
                  // margin: "10px 0",
                  p: 3,
                  boxShadow: " 1px 2px 5px rgba(0, 0, 0, 0.1)",
                  marginTop: 4,
                  overflow: "auto",
                }}
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: 600,
                    fontSize: 17,
                    // mt: 3,
                  }}
                >
                  Details
                </Typography>
                <Grid item xs={12} md={11}>
                  <div className="vwhicle_details">
                    <div className="vehicle_data">Price</div>
                    <div className="vehicle_value">
                      ${vehiclesdetails?.Vehicle_Appraisal_Details?.vad_amount}
                    </div>
                  </div>
                  <div className="vwhicle_details">
                    <div className="vehicle_data">Type</div>
                    <div className="vehicle_value">
                      {vehiclesdetails?.Vehicle_Bank_Details?.vtl_type}
                    </div>
                  </div>

                  <div className="vwhicle_details">
                    <div className="vehicle_data">Category</div>
                    <div className="vehicle_value">
                      {
                        vehiclesdetails?.Vehicle_General_Characteristics
                          ?.vd_category
                      }
                    </div>
                  </div>
                  <div className="vwhicle_details">
                    <div className="vehicle_data">Status</div>
                    <div className="vehicle_value">
                      {vehiclesdetails?.Vehicle_Bank_Details?.sl_status}
                    </div>
                  </div>
                  <div className="vwhicle_details">
                    <div className="vehicle_data">Owner Name</div>
                    <div className="vehicle_value">
                      {
                        vehiclesdetails?.Vehicle_Bank_Details
                          ?.vbd_application_name
                      }
                    </div>
                  </div>
                  <div className="vwhicle_details">
                    <div className="vehicle_data">Issue Date</div>
                    <div className="vehicle_value">
                      {vehiclesdetails?.Vehicle_Bank_Details?.vbd_issue_date}
                    </div>
                  </div>

                  <div className="vwhicle_details">
                    <div className="vehicle_data">ID No</div>
                    <div className="vehicle_value">
                      {
                        vehiclesdetails?.Vehicle_Bank_Details
                          ?.vbd_identification_number
                      }
                    </div>
                  </div>
                  <div className="vwhicle_details">
                    <div className="vehicle_data">Manufactured Year</div>
                    <div className="vehicle_value">
                      {
                        vehiclesdetails?.Vehicle_General_Characteristics
                          ?.vd_year_of_manufacture
                      }
                    </div>
                  </div>
                </Grid>
              </Card>
              {/* ))} */}
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  borderRadius: 4,
                  // margin: "10px 0",
                  p: 3,
                  boxShadow: " 1px 2px 5px rgba(0, 0, 0, 0.1)",
                  height: 350,                 
                   overflow: "auto",
                }}
              >
                <Grid item xs={12} md={8}>
                  <div className="card_titile">Vehicle Details</div>

                  <div className="vwhicle_details">
                    <div className="vehicle_data">Plate</div>
                    <div className="vehicle_value">
                      {
                        vehiclesdetails?.Vehicle_General_Characteristics
                          ?.vd_plate_or_board
                      }
                    </div>
                  </div>
                  <div className="vwhicle_details">
                    <div className="vehicle_data">Brand</div>
                    <div className="vehicle_value">
                      {
                        vehiclesdetails?.Vehicle_General_Characteristics
                          ?.vd_brand
                      }
                    </div>
                  </div>
                  <div className="vwhicle_details">
                    <div className="vehicle_data">Ceiling</div>
                    <div className="vehicle_value">
                      {" "}
                      {
                        vehiclesdetails?.Vehicle_General_Characteristics
                          ?.vd_ceiling
                      }
                    </div>
                  </div>
                  <div className="vwhicle_details">
                    <div className="vehicle_data">color</div>
                    <div className="vehicle_value">
                      {
                        vehiclesdetails?.Vehicle_General_Characteristics
                          ?.vd_color
                      }
                    </div>
                  </div>
                  <div className="vwhicle_details">
                    <div className="vehicle_data">Displacement</div>
                    <div className="vehicle_value">
                      {vehiclesdetails?.Vehicle_Appraisal_Details?.vad_amount}
                    </div>
                  </div>
                  <div className="vwhicle_details">
                    <div className="vehicle_data">Fuel</div>
                    <div className="vehicle_value">
                      {vehiclesdetails?.Vehicle_Engine_Characteristics?.fl_fuel}
                    </div>
                  </div>
                  <div className="vwhicle_details">
                    <div className="vehicle_data">Mileage</div>
                    <div className="vehicle_value">
                      {
                        vehiclesdetails?.Vehicle_General_Characteristics
                          ?.vd_mileage
                      }
                    </div>
                  </div>
                  <div className="vwhicle_details">
                    <div className="vehicle_data">Origin</div>
                    <div className="vehicle_value">
                      {" "}
                      {
                        vehiclesdetails?.Vehicle_Engine_Characteristics
                          ?.ved_orgin
                      }
                    </div>
                  </div>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  borderRadius: 4,
                  // margin: "10px 0",
                  p: 3,
                  boxShadow: " 1px 2px 5px rgba(0, 0, 0, 0.1)",
                  height: 350,
                  overflow: "auto",
                }}
              >
                <div className="card_titile">About Bank</div>
                <img
                  src={vehiclesdetails?.Vehicle_Bank_Details?.logo} // Assuming this contains the URL of the image
                  style={{
                    width: "50%",
                    height: 150,
                    overflow:"auto",
                    borderRadius: "7px",
                  }}
                />

                <div className="aboutbank_content">
                  {vehiclesdetails?.Vehicle_Bank_Details?.aonl_about}
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  borderRadius: 4,
                  // margin: "10px 0",
                  p: 3,
                  boxShadow: " 1px 2px 5px rgba(0, 0, 0, 0.1)",
                  height: 350,
                  overflow: "auto",
                }}
              >
                <div className="card_titile">About Vehicle</div>
                <div className="vehicle_about">
                  {vehiclesdetails?.Vehicle_General_Characteristics?.vd_feature}
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  borderRadius: 4,
                  // margin: "10px 0",
                  p: 3,
                  boxShadow: " 1px 2px 5px rgba(0, 0, 0, 0.1)",
                  height: 350,
                }}
              >
                <Grid container spacing={5}>
                  <Grid item xs={12} md={12}>
                    <div className="card_titile">Location</div>
                    <div className="vehicle_about">
                      <VehicleMap
                        locationCoordinates={latitudeLongitude}
                        style={{ borderRadius: 5 }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Card>

              {/* <Divider style={{ marginTop: "20px" }} /> */}
            </Grid>
           
            
          </Grid>
          <Grid item xs={12} md={12} p={1} marginTop={2}>
            <div className="card_titile">Disclaimer</div>
            <div className="disclaimer_content">
              What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the
              printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen book. It has survived not only five centuries, but
              also the leap into electronic typesetting, remaining essentially
              unchanged. It was popularised in the 1960s with the release of
              Letraset sheets containing Lorem Ipsum passages, and more recently
              with desktop publishing software like Aldus PageMaker including
              versions of Lorem Ipsum. Why do we use it? It is a long
              established fact that a reader will be distracted by the readable
              content of a page when looking at its layout.
            </div>

            {/* <Divider style={{ marginTop: "20px" }} /> */}
          </Grid>
          {/* <div className="Autobot_Maincontainer" onClick={handleClickOpen}>
            <img src={Autobot} className="image_bot" />
          </div>
          <div className="custom_modal_vehicle">
            <EnquiryModal
              open={open}
              handleClose={handleClose}
              body={
                <EnquiryForm onclose={handleClose} vehicle_id={vehicleid} />
              }
              title={"Enquiry"}
            />
            <BasicModal
              open={openmodal}
              handleClose={handleClosemodal}
              body={
                <RecommendedVehicles
                  onclose={handleClosemodal}
                  vehicle_id={vehicle_id}
                />
              }
              title={"Recommended Vehicles"}
            />
          </div> */}
        </div>
      </CustomSpin>
    </>
  );
}

export default AgentVehicleView;
