import { TextField } from "@mui/material";

function CustomTextField({
    name,
    type,
    placeholder,
    onChange,
    onBlur,
    value,
    error,
    helperText,
    FormHelperTextProps,
    multiline,
    maxRows,
    disabled
  }) {
    const inputStyles = {
        '& .MuiInputBase-input.MuiOutlinedInput-input': {
          padding: '6.5px 14px!important',
          fontSize: '15px!important',
          height: '25px!important'
        },
      };
  
      const outlinedInputStyles = {
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0F62FE !important',
          },
        },
      };
    return (
      <div style={{position:'relative'}}>
         <TextField
         disabled={disabled}
          multiline={multiline}
          maxRows={maxRows}
        className="custom_test_field"
        name={name}
        fullWidth
        autoComplete="off"
        size='small'
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        error={error}
        type={type}
        helperText={helperText}
        FormHelperTextProps={{
            style: { marginLeft: 0,position:'absolute',top:'36px' },
          ...FormHelperTextProps,
        }}
        variant="outlined"
        sx={{
            ...inputStyles,
            ...outlinedInputStyles,
          }}
      />
      </div>
     
    );
  }
  
  export default CustomTextField;