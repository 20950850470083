import React, { useState } from "react";
import {
  Button,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography,
  IconButton,
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useDispatch, useSelector } from "react-redux";
import { updateNotificationIsRead } from "../../../../../../api/apiServices/notificationApi";
import "./NotificationDropdown.css";
import { DateTimeConvertionService } from "../../../../../../services/DateTimeConvertionService";
function NoticationDropdown() {
  const notifications = useSelector((state) => state.mainStore.notifications);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    dispatch(updateNotificationIsRead());
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? "notification-popover" : undefined;
  return (
    <div>
      <IconButton onClick={handleClick}>
        <Badge
          badgeContent={
            notifications.filter((item) => item.n_is_read === 0).length
          }
          color="primary"
          className="customBadge"
        >
          <NotificationsIcon color="action" className="notificationIcon" />
        </Badge>
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        sx={{
          "& .MuiPopover-paper": {
            maxHeight: "300px", // Adjust the maximum height
            width: "400px", // Adjust the width
          },
        }}
      >
        <div className="notification-dropdown-container">
          {notifications &&
            notifications.length > 0 &&
            notifications.map((notification) => (
              <div
                key={notification.n_id}
                className="notification_dropdown_main_container"
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="notification_dropdown_email_dateTime">
                  <div>
                    <Avatar alt={notification.up_email} src={``}>
                      {notification.up_email.charAt(0)}
                    </Avatar>
                  </div>
                  <div style={{ marginLeft: "10px", width: "210px" }}>
                    <p className="notification_dropdown_email">
                      {notification.up_email}
                    </p>

                    <p className="notification_dropdown_message">
                      {notification.n_message}
                    </p>
                  </div>
                </div>
                <div>
                  <p className="notification_dropdown_dateTime">
                    {DateTimeConvertionService.DateTimeConverter(
                      notification.n_time_stamp
                    )}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </Popover>
    </div>
  );
}

export default NoticationDropdown;
