import { ApiConfig } from "../apiConfig"
import ResponseHandler from "../../services/ResponseHandler";
import { setAllFinancialSupport } from "../../store/LookUpStore";

export const getAllBanks=(data)=>{
    return  async(dispatch)=>{
        try{
            const response=await ApiConfig.get('financial_support_banks',data)
            dispatch(setAllFinancialSupport(response.data.data))
        }catch(error){
            ResponseHandler(error)
        }
    }
}
export const createFinancialSupportBank=(values)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.post('create_financial_support_bank',values)
           return response
        }catch(error){
            throw error
        }
    }
}
export const updateFinancialSupportBank=(id,values)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.put(`/${id}/update_financial_support_bank`,values)
           return response
        }catch(error){
            throw error
        }
    }
}

export const deleteFinancialSupportBank=(id)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.delete(`/${id}/delete_financial_support_bank`)
           return response
        }catch(error){
            throw error
        }
    }
}