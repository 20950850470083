import React, { useEffect, useState } from "react";
import CustomAttachmentContainer from "../../../../../../components/customAttachmentContainer/CustomAttachmentContainer";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import CustomTextArea from "../../../../../../components/customTextArea/CustomTextArea";
import CustomAutoComplete from "../../../../../../components/customAutoComplete/CustomAutoComplete";
import CustomDatePicker from "../../../../../../components/customDatePicker/CustomDatePicker";
import { getAllUsers } from "../../../../../../api/apiServices/userApis";
import { getAllPriority } from "../../../../../../api/apiServices/priorityApi";
import { getAllStatus } from "../../../../../../api/apiServices/statusApi";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import FormButton from "../../../../../../components/formButton/FormButton";
import "./PropertyTaskFormPage.css";
import {
  createNewPropertyTask,
  updatePropertyTask,
} from "../../../../../../api/apiServices/propertyApi";
function PropertyTaskFormPage({ PropertyTaskData }) {
  const dispatch = useDispatch();
  const { property_id, property_task_id } = useParams();
  const navigate = useNavigate();
  const [priorityDropDown, SetPriorityDropDownData] = useState([]);
  const [statusDropDown, SetStatusDropDownData] = useState([]);
  const [attachment, SetAttachment] = useState([]);
  const [loading, Setloading] = useState(false);
  const users = useSelector((state) => state.userStore.WholeUsers);
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iSViewAndEdit = queryParams.get("view_edit");
  const [Isview, SetIsview] = useState(false);
  const [DurationSetter, SetDurationSetter] = useState({
    StartDate: null,
    EndDate: null,
  });
  useEffect(() => {
    dispatch(getAllUsers());
    SetPriorityDropDown();
    SetStatusDropDown();
    if (PropertyTaskData) {
      formik.setValues({
        ...formik.values,
        taskName: PropertyTaskData.pt_taskname,
        taskDescription: PropertyTaskData.pt_taskdescription,
        assignTo: PropertyTaskData.pt_assignto,
        priority: PropertyTaskData.pt_priority,
        startDate: PropertyTaskData.pt_startdate,
        endDate: PropertyTaskData.pt_enddate,
        duration: PropertyTaskData.pt_duration,
        status: PropertyTaskData.pt_status,
        notes: PropertyTaskData.pt_notes,
      });
      SetDurationSetter({
        ...DurationSetter,
        ["StartDate"]: dayjs(PropertyTaskData.pt_startdate, "MM/DD/YYYY"),
        ["EndDate"]: dayjs(PropertyTaskData.pt_enddate, "MM/DD/YYYY"),
      });
      if (PropertyTaskData.pta_attachment) {
        SetAttachment(PropertyTaskData.pta_attachment);
      }
    }
  }, [PropertyTaskData]);

  function SetUsersDropdown(users) {
    if (users && users.length > 0) {
      const filterUsers = users.filter(
        (item) =>
          item.url_role !== "Admin" &&
          item.url_role !== "SuperAdmin" &&
          item.url_role !== "Agent"
      );
      const data = filterUsers.map((item) => {
        return {
          label: item.up_email,
          value: item.up_id,
        };
      });
      return data;
    } else {
      return [];
    }
  }
  function SetPriorityDropDown() {
    dispatch(getAllPriority())
      .then((response) => {
        const data = response.map((item) => {
          return {
            label: item.pl_priority,
            value: item.pl_id,
          };
        });
        SetPriorityDropDownData(data);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }
  function SetStatusDropDown() {
    dispatch(getAllStatus())
      .then((response) => {
        const data = response.map((item) => {
          return {
            label: item.sl_status,
            value: item.sl_id,
          };
        });
        SetStatusDropDownData(data);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }
  function uploadAttachment(data) {
    SetAttachment([...attachment, data]);
  }
  function deleteAttachmentAtIndex(index) {
    const files = attachment;
    files.splice(index, 1);
    SetAttachment([...files]);
  }
  const initialValues = {
    taskName: "",
    taskDescription: "",
    assignTo: null,
    priority: null,
    startDate: null,
    endDate: null,
    duration: "",
    status: null,
    notes: "",
  };
  const validationSchema = Yup.object({
    taskName: Yup.string().required("Task name is required"),
    status: Yup.string().nullable().required("Status is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append("fieldDetails", JSON.stringify(values));
      data.append("attachmentDetails", JSON.stringify(attachment));
      if (attachment && attachment.length > 0) {
        attachment.forEach((attachments) => {
          if (
            !(
              attachments.file.toString().startsWith("http://") ||
              attachments.file.toString().startsWith("https://")
            )
          ) {
            data.append(
              `propertyAttachments`,
              attachments.file,
              `${
                "PTA_" +
                `${
                  PropertyTaskData
                    ? PropertyTaskData.pt_property_id
                    : property_id
                }` +
                "-" +
                new Date().toISOString().replace(/:/g, "_") +
                "-" +
                `${attachments.title}` +
                "." +
                `${attachments.filetype}`
              }`
            );
          }
        });
      } else {
        data.append(`propertyAttachments`, null);
      }
      if (!PropertyTaskData) {
        Setloading(true);
        dispatch(createNewPropertyTask(property_id, data))
          .then((response) => {
            ResponseHandler(response);
            navigate(-1);
          })
          .catch((error) => {
            ResponseHandler(error);
          })
          .finally(() => {
            Setloading(false);
          });
      } else {
        Setloading(true);
        dispatch(updatePropertyTask(property_task_id, data))
          .then((response) => {
            ResponseHandler(response);
            navigate(-1);
          })
          .catch((error) => {
            ResponseHandler(error);
          })
          .finally(() => {
            Setloading(false);
          });
      }
    },
  });
  function durationGenerator(StartDate, EndDate) {
    SetDurationSetter({ ...DurationSetter, StartDate, EndDate });
    if (StartDate && EndDate) {
      const date1 = new Date(StartDate);
      const date2 = new Date(EndDate);
      const differenceMs = date2 - date1;
      const daysDifference = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
      if (daysDifference >= 0) {
        formik.setFieldValue("duration", daysDifference + 1);
      } else {
        formik.setFieldValue("duration", 0);
      }
    }
  }
  return (
    <div>
      <p className="Property_titile">
        {PropertyTaskData ? "Update Tasks/Jobs" : "Add Tasks/Jobs"}
      </p>
      <div className="property_task_form_main_Container">
        <div className="property_task_formContainer">
          <p className="property_task_form_legend">Add Details</p>
          <form id="property_taskform" onSubmit={formik.handleSubmit}>
            <div className="property_taskform_fields">
              <div>
                <div className="property_taskform_field_container">
                  <p className="property_taskform_fields_labels">Task Name</p>
                  <CustomTextField
                    name="taskName"
                    placeholder="Enter task name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.taskName}
                    error={
                      formik.touched.taskName && Boolean(formik.errors.taskName)
                    }
                    helperText={
                      formik.touched.taskName && formik.errors.taskName
                    }
                  />
                </div>
                <div className="property_taskform_field_container">
                  <p className="property_taskform_fields_labels">
                    Task Description
                  </p>
                  <CustomTextArea
                    maxRows={4}
                    name="taskDescription"
                    placeholder="Enter task description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.taskDescription}
                    error={
                      formik.touched.taskDescription &&
                      Boolean(formik.errors.taskDescription)
                    }
                    helperText={
                      formik.touched.taskDescription &&
                      formik.errors.taskDescription
                    }
                  />
                </div>
                <div className="property_taskform_field_container">
                  <p className="property_taskform_fields_labels">Assigned To</p>
                  <CustomAutoComplete
                    disabled={statusDropDown.find(
                      (status) =>
                        status.value === PropertyTaskData?.pt_status &&
                        status.label === "Completed"
                    )}
                    name="assignTo"
                    placeholder="Select assigned to"
                    options={SetUsersDropdown(users)}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "assignTo",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      SetUsersDropdown(users).find(
                        (assignTo) => assignTo.value === formik.values.assignTo
                      ) || null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.value.toString() === value.value.toString()
                    }
                    error={
                      formik.touched.assignTo && Boolean(formik.errors.assignTo)
                    }
                    helperText={
                      formik.touched.assignTo && formik.errors.assignTo
                    }
                  />
                </div>
                <div className="property_taskform_field_container">
                  <p className="property_taskform_fields_labels">Priority</p>
                  <CustomAutoComplete
                    name="priority"
                    placeholder="Select priority"
                    options={priorityDropDown}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "priority",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      priorityDropDown.find(
                        (priority) => priority.value === formik.values.priority
                      ) || null
                    }
                    error={
                      formik.touched.priority && Boolean(formik.errors.priority)
                    }
                    helperText={
                      formik.touched.priority && formik.errors.priority
                    }
                  />
                </div>
              </div>
              <div>
                <div className="property_taskform_field_container">
                  <div className="property_taskform_dualfields">
                    <div>
                      <p className="property_taskform_fields_labels">
                        Start Date
                      </p>
                      <CustomDatePicker
                        name="startDate"
                        onChange={(date) => {
                          formik.setFieldValue("startDate", date);
                          durationGenerator(date, DurationSetter.EndDate);
                        }}
                        onBlur={formik.handleBlur}
                        value={dayjs(formik.values.startDate, "MM/DD/YYYY")}
                        error={
                          formik.touched.startDate &&
                          Boolean(formik.errors.startDate)
                        }
                        helperText={
                          formik.touched.startDate && formik.errors.startDate
                        }
                      />
                    </div>
                    <div>
                      <p className="property_taskform_fields_labels">
                        End Date
                      </p>
                      <CustomDatePicker
                        disabled={formik.values.startDate ? false : true}
                        minDate={dayjs(formik.values.startDate, "MM/DD/YYYY")}
                        name="endDate"
                        onChange={(date) => {
                          formik.setFieldValue("endDate", date);
                          durationGenerator(DurationSetter.StartDate, date);
                        }}
                        onBlur={formik.handleBlur}
                        value={dayjs(formik.values.endDate, "MM/DD/YYYY")}
                        error={
                          formik.touched.endDate &&
                          Boolean(formik.errors.endDate)
                        }
                        helperText={
                          formik.touched.endDate && formik.errors.endDate
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="property_taskform_field_container">
                  <p className="property_taskform_fields_labels">
                    Duration (days)
                  </p>
                  <CustomTextField
                    disabled={true}
                    name="duration"
                    placeholder="Enter durartion"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.duration}
                    error={
                      formik.touched.duration && Boolean(formik.errors.duration)
                    }
                    helperText={
                      formik.touched.duration && formik.errors.duration
                    }
                  />
                </div>
                <div className="property_taskform_field_container">
                  <p className="property_taskform_fields_labels">Status</p>
                  <CustomAutoComplete
                    name="status"
                    placeholder="Select Status"
                    options={statusDropDown}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "status",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      statusDropDown.find(
                        (status) => status.value === formik.values.status
                      ) || null
                    }
                    error={
                      formik.touched.status && Boolean(formik.errors.status)
                    }
                    helperText={formik.touched.status && formik.errors.status}
                  />
                </div>
                <div className="property_taskform_field_container">
                  <p className="property_taskform_fields_labels">Notes</p>
                  <CustomTextArea
                    maxRows={4}
                    name="notes"
                    placeholder="Enter task description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.notes}
                    error={formik.touched.notes && Boolean(formik.errors.notes)}
                    helperText={formik.touched.notes && formik.errors.notes}
                  />
                </div>
              </div>
            </div>
            <CustomAttachmentContainer
              Isview={Isview}
              attachments={attachment}
              uploadAttachments={uploadAttachment}
              deleteAttachments={deleteAttachmentAtIndex}
            />
          </form>
        </div>
        <div className="property_taskform_action">
          <FormButton
            id="property_taskCreateCancel"
            variant="outlined"
            loading={false}
            text="Cancel"
            onClick={() => {
              navigate(-1);
            }}
          />
          <FormButton
            id="property_taskCreateSubmit"
            type="submit"
            form="property_taskform"
            variant="contained"
            text={PropertyTaskData ? "Update Task" : "Create Task"}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default PropertyTaskFormPage;
