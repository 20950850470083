import ResponseHandler from "../../services/ResponseHandler"
import { ApiConfig } from "../apiConfig"
import { SetAllApplicationOwnerNames } from "../../store/LookUpStore"

export const getAllApplicationOwnerNames=(data)=>{
    return  async(dispatch)=>{
        try{
          const response=await ApiConfig.get('application_owner_names',data);
          dispatch(SetAllApplicationOwnerNames(response.data.data))
          return response.data.data
        }catch(error){
            ResponseHandler(error)
            throw error
        }
    }
}
export const getApplicationOwnerNameById=(bank_id)=>{
    return  async()=>{
        try{
          const response=await ApiConfig.get(`/${bank_id}/application_owner_name`)
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const CreateNewBanks=(data)=>{
    return  async()=>{
        try{
          const response=await ApiConfig.post('application_owner_name/create_new_owner_name',data,{
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
           return response
        }catch(error){
            throw error
        }
    }
}

export const updateBankDetails=(bank_id,data)=>{
    return  async()=>{
        try{
          const response=await ApiConfig.put(`application_owner_name/${bank_id}/update_owner_name`,data,{
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          })
           return response
        }catch(error){
            throw error
        }
    }
}

export const deleteBankDetails=(bank_id)=>{
    return  async()=>{
        try{
          const response=await ApiConfig.delete(`/application_owner_name/${bank_id}/delete_owner_name`)
           return response
        }catch(error){
            throw error
        }
    }
}