import React, { useEffect, useState } from "react";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import FormButton from "../../../../../../components/formButton/FormButton";
import "./GeneralVehicleCharacteristics.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  getVehicleDetailsId,
  updateVehicleDetailsId,
} from "../../../../../../api/apiServices/vehicleApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import { RiFileEditFill } from "react-icons/ri";
import CustomTextArea from "../../../../../../components/customTextArea/CustomTextArea";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
function GeneralVehicleCharacteristics({ updateIsEditable }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iSViewAndEdit = queryParams.get("view_edit");
  const [loading, Setloading] = useState(false);
  const [Isview, SetIsview] = useState(false);
  useEffect(() => {
    if (id) {
      dispatch(getVehicleDetailsId(id))
        .then((response) => {
          setFormikData(response.data.data);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
    if (iSViewAndEdit) {
      SetIsview(true);
    }
  }, [id]);
  const initialValues = {
    plate_or_board: "",
    brand: "",
    category: "",
    series: "",
    inspected_by: "",
    body: "",
    traction: "",
    chassis_no: "",
    year_of_manufacture: "",
    mileage: "",
    cabin: "",
    length: "",
    ceiling: "",
    weight_trailer: "",
    color: "",
    converted: "",
    vin: "",
    style: "",
    ability: "",
    weight: "",
    gross_weight: "",
    transmission: "",
    use: "",
    feature: "",
  };
  function setFormikData(data) {
    const mappedData = Object.keys(initialValues).reduce((acc, key) => {
      const originalKey = `vd_${key}`;
      acc[key] = data[originalKey] || initialValues[key];
      return acc;
    }, {});
    formik.setValues({
      ...formik.values,
      ...mappedData,
    });
  }
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      plate_or_board: Yup.string(),
      brand: Yup.string(),
      category: Yup.string(),
      series: Yup.string(),
      inspected_by: Yup.string(),
      body: Yup.string(),
      traction: Yup.string(),
      chassis_no: Yup.string(),
      year_of_manufacture: Yup.string(),
      mileage: Yup.string(),
      cabin: Yup.string(),
      length: Yup.string(),
      ceiling: Yup.string(),
      weight_trailer: Yup.string(),
      color: Yup.string(),
      converted: Yup.string(),
      vin: Yup.string(),
      style: Yup.string(),
      ability: Yup.string(),
      weight: Yup.string(),
      gross_weight: Yup.string(),
      transmission: Yup.string(),
      use: Yup.string(),
      feature: Yup.string(),
    }),
    onSubmit: (values) => {
      Setloading(true);
      dispatch(updateVehicleDetailsId(id, values))
        .then((response) => {
          ResponseHandler(response);
          if (iSViewAndEdit) {
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${id}/update_vehicle?tk=${2}&view_edit=${true}`
            );
          } else {
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${id}/update_vehicle?tk=${2}`
            );
          }
        })
        .catch((error) => {
          ResponseHandler(error);
        })
        .finally(() => {
          Setloading(false);
        });
    },
  });

  const Privilages = useSelector((state) => state.userStore.Privilages);

  return (
    <div>
      <div className="GeneralVehicleCharacteristics_form_main_Container">
        {Privilages &&
        Privilages?.Vehicle &&
        Privilages?.Vehicle.some((item) => item === "Update")
          ? Isview && (
              <div
                className="GeneralVehicleCharacteristics_edit_details"
                onClick={() => {
                  SetIsview(false);
                  updateIsEditable(true);
                }}
              >
                <RiFileEditFill />
                <p>Edit</p>
              </div>
            )
          : null}

        <div className="GeneralVehicleCharacteristics_formContainer">
          <p className="GeneralVehicleCharacteristics_form_legend">
            Add Vehicle Details
          </p>
          <form
            id="GeneralVehicleCharacteristicsform"
            onSubmit={formik.handleSubmit}
          >
            <div className="GeneralVehicleCharacteristicsform_fields">
              <div>
                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Plate/Board
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="plate_or_board"
                    placeholder="Enter Plate/Board"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.plate_or_board}
                    error={
                      formik.touched.plate_or_board &&
                      Boolean(formik.errors.plate_or_board)
                    }
                    helperText={
                      formik.touched.plate_or_board &&
                      formik.errors.plate_or_board
                    }
                  />
                </div>
                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Brand
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="brand"
                    placeholder="Enter Brand"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.brand}
                    error={formik.touched.brand && Boolean(formik.errors.brand)}
                    helperText={formik.touched.brand && formik.errors.brand}
                  />
                </div>
                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Category
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="category"
                    placeholder="Enter category"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.category}
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                    helperText={
                      formik.touched.category && formik.errors.category
                    }
                  />
                </div>
                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Inspected by
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="inspected_by"
                    placeholder="Enter Inspected by"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.inspected_by}
                    error={
                      formik.touched.inspected_by &&
                      Boolean(formik.errors.inspected_by)
                    }
                    helperText={
                      formik.touched.inspected_by && formik.errors.inspected_by
                    }
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Series
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="series"
                    placeholder="Enter Series"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.series}
                    error={
                      formik.touched.series && Boolean(formik.errors.series)
                    }
                    helperText={formik.touched.series && formik.errors.series}
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Body
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="body"
                    placeholder="Enter Body"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.body}
                    error={formik.touched.body && Boolean(formik.errors.body)}
                    helperText={formik.touched.body && formik.errors.body}
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Traction
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="traction"
                    placeholder="Enter Traction"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.traction}
                    error={
                      formik.touched.traction && Boolean(formik.errors.traction)
                    }
                    helperText={
                      formik.touched.traction && formik.errors.traction
                    }
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Chassis No
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="chassis_no"
                    placeholder="Enter Chassis No"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.chassis_no}
                    error={
                      formik.touched.chassis_no &&
                      Boolean(formik.errors.chassis_no)
                    }
                    helperText={
                      formik.touched.chassis_no && formik.errors.chassis_no
                    }
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Year Manufacturing
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="year_of_manufacture"
                    placeholder="Enter Year Manufacturing"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.year_of_manufacture}
                    error={
                      formik.touched.year_of_manufacture &&
                      Boolean(formik.errors.year_of_manufacture)
                    }
                    helperText={
                      formik.touched.year_of_manufacture &&
                      formik.errors.year_of_manufacture
                    }
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Mileage
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="mileage"
                    placeholder="Enter Mileage"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mileage}
                    error={
                      formik.touched.mileage && Boolean(formik.errors.mileage)
                    }
                    helperText={formik.touched.mileage && formik.errors.mileage}
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Cabin
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="cabin"
                    placeholder="Enter Cabin"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cabin}
                    error={formik.touched.cabin && Boolean(formik.errors.cabin)}
                    helperText={formik.touched.cabin && formik.errors.cabin}
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Length
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="length"
                    placeholder="Enter Length"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.length}
                    error={
                      formik.touched.length && Boolean(formik.errors.length)
                    }
                    helperText={formik.touched.length && formik.errors.length}
                  />
                </div>
              </div>
              <div>
                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Ceiling
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="ceiling"
                    placeholder="Enter Ceiling"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.ceiling}
                    error={
                      formik.touched.ceiling && Boolean(formik.errors.ceiling)
                    }
                    helperText={formik.touched.ceiling && formik.errors.ceiling}
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Weight Trailer
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="weight_trailer"
                    placeholder="Enter Weight Trailer"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.weight_trailer}
                    error={
                      formik.touched.weight_trailer &&
                      Boolean(formik.errors.weight_trailer)
                    }
                    helperText={
                      formik.touched.weight_trailer &&
                      formik.errors.weight_trailer
                    }
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Color
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="color"
                    placeholder="Enter Color"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.color}
                    error={formik.touched.color && Boolean(formik.errors.color)}
                    helperText={formik.touched.color && formik.errors.color}
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Converted
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="converted"
                    placeholder="Enter Converted"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.converted}
                    error={
                      formik.touched.converted &&
                      Boolean(formik.errors.converted)
                    }
                    helperText={
                      formik.touched.converted && formik.errors.converted
                    }
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    VIN
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="vin"
                    placeholder="Enter VIN"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.vin}
                    error={formik.touched.vin && Boolean(formik.errors.vin)}
                    helperText={formik.touched.vin && formik.errors.vin}
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Style
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="style"
                    placeholder="Enter Style"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.style}
                    error={formik.touched.style && Boolean(formik.errors.style)}
                    helperText={formik.touched.style && formik.errors.style}
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Ability
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="ability"
                    placeholder="Enter Ability"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.ability}
                    error={
                      formik.touched.ability && Boolean(formik.errors.ability)
                    }
                    helperText={formik.touched.ability && formik.errors.ability}
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Weight
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="weight"
                    placeholder="Enter Weight"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.weight}
                    error={
                      formik.touched.weight && Boolean(formik.errors.weight)
                    }
                    helperText={formik.touched.weight && formik.errors.weight}
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Gross Weight
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="gross_weight"
                    placeholder="Enter Gross Weight"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.gross_weight}
                    error={
                      formik.touched.gross_weight &&
                      Boolean(formik.errors.gross_weight)
                    }
                    helperText={
                      formik.touched.gross_weight && formik.errors.gross_weight
                    }
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Transmission
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="transmission"
                    placeholder="Enter transmission"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.transmission}
                    error={
                      formik.touched.transmission &&
                      Boolean(formik.errors.transmission)
                    }
                    helperText={
                      formik.touched.transmission && formik.errors.transmission
                    }
                  />
                </div>

                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Use
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="use"
                    placeholder="Enter Use"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.use}
                    error={formik.touched.use && Boolean(formik.errors.use)}
                    helperText={formik.touched.use && formik.errors.use}
                  />
                </div>
                <div className="GeneralVehicleCharacteristicsform_field_container">
                  <p className="GeneralVehicleCharacteristicsform_fields_labels">
                    Features
                  </p>
                  <CustomTextArea
                    maxRows={5}
                    disabled={Isview}
                    name="feature"
                    placeholder="Enter Features"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.feature}
                    error={
                      formik.touched.feature && Boolean(formik.errors.feature)
                    }
                    helperText={formik.touched.feature && formik.errors.feature}
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
        {!Isview && (
          <div className="GeneralVehicleCharacteristicsform_action">
            <FormButton
              id="GeneralVehicleCharacteristicsCreateCancel"
              variant="outlined"
              text="Cancel"
              onClick={() => {
                navigate(
                  `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/vehicles`
                );
              }}
            />
            <FormButton
              id="GeneralVehicleCharacteristicsCreateSubmit"
              type="submit"
              form="GeneralVehicleCharacteristicsform"
              variant="contained"
              loading={loading}
              text="Save & Continue"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default GeneralVehicleCharacteristics;
