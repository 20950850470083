import React, { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { RiFileEditFill } from "react-icons/ri";
import { Divider } from "@mui/material";
import PropertyTaskAttachments from "../propertyTaskAttachments/PropertyTaskAttachments";
import "./PropertyTaskView.css";
import { useDispatch, useSelector } from "react-redux";
import CustomAutoComplete from "../../../../../../../components/customAutoComplete/CustomAutoComplete";
import { LocalStorageServices } from "../../../../../../../services/localStorageServices";
import { getAllStatus } from "../../../../../../../api/apiServices/statusApi";
import ResponseHandler from "../../../../../../../services/ResponseHandler";
import {
  updatePropertyTaskAttachmentByRunner,
  updatePropertyTaskStatusByRunner,
} from "../../../../../../../api/apiServices/propertyApi";
import CustomAttachmentContainerV1 from "../../../../../../../components/customAttachmentContainer_v1/CustomAttachmentContainerV1";
import FormButton from "../../../../../../../components/formButton/FormButton";
import PropertyNotes from "../propertyNotes/PropertyNotes";
import PropertyRunnerViewNotes from "../propertyRunnerNotes/PropertyRunnerNotes";
import { Button } from "@mui/material";
import CustomModal from "../../../../../../../components/customModal/CustomModal";

function PropertyTaskView({ PropertyTaskData, UpdateData }) {
  const { property_task_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Privilages = useSelector((state) => state.userStore.Privilages);
  const [statusDropDown, SetStatusDropDownData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [attachment, SetAttachment] = useState([]);
  const [EditAttachmentRunner, SetEditAttachmentRunner] = useState(false);
  const [loading, Setloading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandednotes, setIsExpandednotes] = useState(false);
  const maxLength = 300;

  const description =
  PropertyTaskData && PropertyTaskData.pt_taskdescription ? PropertyTaskData.pt_taskdescription.trim() : "--";

    const notes =
    PropertyTaskData && PropertyTaskData.pt_notes ? PropertyTaskData.pt_notes.trim() : "--";

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const renderDescription = () => {
    if (description === "--") {
      return "--";
    }

    if (isExpanded || description.length <= maxLength) {
      return description.match(/.{1,45}/g).map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    } else {
      return <>{description.substring(0, maxLength)}...</>;
    }
  };

  const handleTogglenotes = () => {
    setIsExpandednotes(!isExpandednotes);
  };

  const renderNotes = () => {
    if (notes === "--") {
      return "--";
    }

    if (isExpandednotes || notes.length <= maxLength) {
      return notes.match(/.{1,50}/g).map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    } else {
      return <>{notes.substring(0, maxLength)}...</>;
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false); // Close the modal
  };
  useEffect(() => {
    if (PropertyTaskData) {
      SetStatusDropDown();
    }
  }, [PropertyTaskData]);
  function SetStatusDropDown() {
    dispatch(getAllStatus())
      .then((response) => {
        const datas = response.map((item) => {
          return {
            label: item.sl_status,
            value: item.sl_id,
          };
        });
        if (PropertyTaskData) {
          const initialStatus = datas.find(
            (status) => status.label === PropertyTaskData.sl_status
          );
          setSelectedStatus(initialStatus?.value);
        }
        SetStatusDropDownData(datas);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }
  function updateStatus(task_id, task_status) {
    try {
      dispatch(updatePropertyTaskStatusByRunner(task_id, task_status))
        .then((response) => {
          ResponseHandler(response);
          UpdateData();
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    } catch (error) {
      ResponseHandler(error);
    }
  }
  function UpdateAttachments(task_id, property_id, attachments) {
    Setloading(true);
    const data = new FormData();
    data.append("attachmentDetails", JSON.stringify(attachments));
    if (attachment && attachment.length > 0) {
      attachment.forEach((attachment) => {
        data.append(
          `propertyAttachments`,
          attachment.file,
          `${
            "PTA_" +
            `${property_id}` +
            "_" +
            new Date().toISOString().replace(/:/g, "_") +
            "-" +
            attachment.title +
            "." +
            attachment.filetype
          }`
        );
      });
    } else {
      data.append(`propertyAttachments`, null);
    }
    dispatch(updatePropertyTaskAttachmentByRunner(task_id, data))
      .then((response) => {
        ResponseHandler(response);
        UpdateData();
        SetAttachment([]);
        SetEditAttachmentRunner(false);
      })
      .catch((error) => {
        ResponseHandler(error);
      })
      .finally(() => {
        Setloading(false);
      });
  }
  function uploadAttachment(data) {
    SetAttachment([...attachment, data]);
  }
  function deleteAttachmentAtIndex(index) {
    const files = attachment;
    files.splice(index, 1);
    SetAttachment([...files]);
  }

  return (
    <div>
      <div className="property_task__view_header">
        <p className="page_title">View Tasks/Jobs</p>
        <div
          className="property_task_view_back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <IoArrowBack />
          <p>Back</p>
        </div>
      </div>
      <div className="property_task_view_mainContainer">
        {Privilages &&
        Privilages?.Property &&
        Privilages?.Property.some((item) => item === "Update") ? (
          <div className="property_task_view_edit_action">
            <RiFileEditFill
              onClick={() => {
                navigate(
                  `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_task_id}/view_and_edit_property_task?edit=${true}`
                );
              }}
            />
          </div>
        ) : null}

        <div className="property_task_view_details_mainContainer">
          <div className="property_task_view_details_subContainer">
            <div>
              <div className="property_task_view_details_field_container_grid">
                <p className="property_task_view_details_field_label">
                  TaskName
                </p>
                <p className="property_task_view_details_field_value">
                  {PropertyTaskData
                    ? PropertyTaskData.pt_taskname.length > 0
                      ? PropertyTaskData.pt_taskname
                      .trim()
                          .match(/.{1,70}/g)
                          .map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))
                      : "--"
                    : "--"}
                </p>
              </div>
              <div className="property_task_view_details_field_container_grid">
                <p className="property_task_view_details_field_label">
                  Assigned To
                </p>
                <p className="property_task_view_details_field_value">
                  {PropertyTaskData
                    ? PropertyTaskData.up_email
                      ? PropertyTaskData.up_email
                      : "--"
                    : "--"}
                </p>
              </div>
              <div className="property_task_view_details_field_container_grid">
                <p className="property_task_view_details_field_label">Status</p>
                {Privilages?.Tasks.some((item) => item === "Update") &&
                Privilages.url_role === "Runner" &&
                PropertyTaskData?.sl_status !== "Completed" ? (
                  <>
                    <div className="property_task_view_details_custom_dropdown">
                      <CustomAutoComplete
                        name="status"
                        placeholder="Select Status"
                        options={statusDropDown}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          setSelectedStatus(newValue ? newValue.value : "");
                          updateStatus(PropertyTaskData.pt_id,newValue ? newValue.value : "");
                        }}
                        value={
                          statusDropDown.find(
                            (status) => status.value === selectedStatus
                          ) || null
                        }
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <p
                      className="property_task_view_details_field_value"
                      style={{
                        maxWidth: "140px",
                        padding: "4px 0px",
                        borderRadius: "5px",
                        textAlign: "center",
                        color:
                          PropertyTaskData &&
                          (PropertyTaskData.sl_status === "Completed"
                            ? "green"
                            : PropertyTaskData.sl_status === "In Progress"
                            ? "rgba(251, 188, 4, 1)"
                            : PropertyTaskData.sl_status === "New"
                            ? "blue"
                            : ""),
                        backgroundColor:
                          PropertyTaskData &&
                          (PropertyTaskData.sl_status === "Completed"
                            ? "rgba(50, 147, 111, 0.15)"
                            : PropertyTaskData.sl_status === "In Progress"
                            ? "rgba(255, 248, 227, 1)"
                            : PropertyTaskData.sl_status === "New"
                            ? "rgba(223, 234, 255, 1)"
                            : ""),
                        border:
                          PropertyTaskData &&
                          (PropertyTaskData.sl_status === "Completed"
                            ? "1px solid green"
                            : PropertyTaskData.sl_status === "In Progress"
                            ? "1px solid rgb(251, 188, 4)"
                            : PropertyTaskData.sl_status === "New"
                            ? "1px solid blue"
                            : ""),
                      }}
                    >
                      {PropertyTaskData
                        ? PropertyTaskData.sl_status
                          ? PropertyTaskData.sl_status
                          : "--"
                        : "--"}
                    </p>
                  </>
                )}
              </div>

              <div className="property_task_view_details_field_container_grid">
                <p className="property_task_view_details_field_label">
                  Start Date
                </p>
                <p className="property_task_view_details_field_value">
                  {PropertyTaskData
                    ? PropertyTaskData.pt_startdate
                      ? PropertyTaskData.pt_startdate
                      : "--"
                    : "--"}
                </p>
              </div>
              <div className="property_task_view_details_field_container_grid">
                <p className="property_task_view_details_field_label">
                  Due Date
                </p>
                <p className="property_task_view_details_field_value">
                  {PropertyTaskData
                    ? PropertyTaskData.pt_enddate
                      ? PropertyTaskData.pt_enddate
                      : "--"
                    : "--"}
                </p>
              </div>
              <div className="property_task_view_details_field_container_grid">
                <p className="property_task_view_details_field_label">
                  Priority
                </p>
                <p className="property_task_view_details_field_value">
                  {PropertyTaskData
                    ? PropertyTaskData.pl_priority
                      ? PropertyTaskData.pl_priority
                      : "--"
                    : "--"}
                </p>
              </div>
            </div>
            <Divider orientation="vertical" style={{ marginRight: "10px" }} />
            <div className="property_task_view_details_grid_Container">
              <div className="property_task_view_details_field_container_grid">
                <p className="property_task_view_details_field_label">
                  Description
                </p>
                <p className="property_task_view_details_field_value" style={{
                    wordBreak: "break-all",
                    overflowWrap: "break-word",
                    width: "100%",
                  }}>
                  {/* {PropertyTaskData
                   ? PropertyTaskData.pt_taskdescription &&
                    PropertyTaskData.pt_taskdescription.length > 0
                      ? PropertyTaskData.pt_taskdescription
                      .trim()
                          .match(/.{1,70}/g)
                          .map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))
                      : "--"
                    : "--"} */}
                    {renderDescription()}
                  {description.length > maxLength && (
                    <span
                      onClick={handleToggle}
                      style={{ color: "#1565c0", cursor: "pointer" }}
                    >
                      {isExpanded ? "Read Less" : "Read More"}
                    </span>
                  )}
                </p>
              </div>
              <div className="property_task_view_details_field_container_grid">
                <p className="property_task_view_details_field_label">Notes</p>
                <p className="property_task_view_details_field_value" style={{
                    wordBreak: "break-all",
                    overflowWrap: "break-word",
                    width: "100%",
                  }}>
                  {renderNotes()}
                  {notes.length > maxLength && (
                    <span
                      onClick={handleTogglenotes}
                      style={{ color: "#1565c0", cursor: "pointer" }}
                    >
                      {isExpandednotes ? "Read Less" : "Read More"}
                    </span>
                  )}
                </p>
              </div>
              <>
                {LocalStorageServices.getUserRole() !== "Runner" ? (
                  <div className="property_task_view_details_field_container_grid">
                    <p className="property_task_view_details_field_label">
                      Runner Note
                    </p>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={openModal}
                      style={{
                        textTransform: "none",
                        lineHeight: 2.0,
                        fontFamily: "Inter",
                        width: "160px",
                      }}
                    >
                      View Runner Notes
                    </Button>
                  </div>
                ) : null}
                <div  className="modal_notes"> 
                <CustomModal
                open={isModalOpen}
                fullWidth={true}
                handleClose={handleClose} 
                body={
                  <PropertyRunnerViewNotes
                  data={PropertyTaskData}
                  onClose={handleClose}
                  />
                }
                
                title={"Runner Notes"}
                PaperProps={{ style: { maxWidth: '100vw', width: '100vw' } }}/>
             </div>
              </>
            </div>
          </div>
          <Divider />
          {Privilages?.Tasks.some((item) => item === "Update") &&
          Privilages.url_role === "Runner" &&
          PropertyTaskData?.sl_status !== "Completed" &&
          !EditAttachmentRunner ? (
            <>
              <div
                className="propertyTaskAttachment_edit_details"
                onClick={() => SetEditAttachmentRunner(true)}
              >
                <RiFileEditFill />
                <p>Edit</p>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="property_task_attachment_container">
            {PropertyTaskData && PropertyTaskData.pta_attachment.length > 0 ? (
              <>
                <div>
                  <PropertyTaskAttachments
                    attachments={
                      PropertyTaskData && PropertyTaskData.pta_attachment
                    }
                  />
                </div>
              </>
            ) : !EditAttachmentRunner ? (
              <>
                <p className="property_Task_attachment">
                  No attachments are added
                </p>
              </>
            ) : (
              <></>
            )}
            {Privilages?.Tasks.some((item) => item === "Update") &&
            Privilages.url_role === "Runner" &&
            PropertyTaskData?.sl_status !== "Completed" &&
            EditAttachmentRunner ? (
              <>
                <div>
                  <CustomAttachmentContainerV1
                    attachments={attachment}
                    uploadAttachments={uploadAttachment}
                    deleteAttachments={deleteAttachmentAtIndex}
                  />
                  {attachment && attachment.length > 0 && (
                    <div className="property_task_action_attachment">
                      <FormButton
                        id="property_task_attachment"
                        type="submit"
                        form="property_task_attachment"
                        variant="contained"
                        text={"Save"}
                        loading={loading}
                        onClick={() => {
                          UpdateAttachments(
                            PropertyTaskData.pt_id,
                            PropertyTaskData.pt_property_id,
                            attachment
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
           
          <div >
          {Privilages?.Tasks.some((item) => item === "Update") &&
                Privilages.url_role === "Runner" ? (
                  <>
                 <PropertyNotes data={PropertyTaskData} readOnly={Privilages?.Tasks.some((item) => item === "Update") && Privilages.url_role === "Runner" && PropertyTaskData?.sl_status == "Completed"} />

                  </>
                ):(null)}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PropertyTaskView;
