import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import VehicleLoanApplication from './vehicle_loan/VehicleLoanApplication';
import PropertyLoanApplication from './property_loan/PropertyLoanApplication';
import { LocalStorageServices } from '../../../../../services/localStorageServices';

function LoanApplications() {
    const navigate = useNavigate()
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const selected_loan_type = queryParams.get("selected_loan_type");
    const [selectedLookup, setselectedLookup] = useState(null)
    useEffect(() => {
        if (selected_loan_type) {
            setselectedLookup(selected_loan_type)
        } else {
            navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/loan_applications?selected_loan_type=${1}`);
        }
    }, [selected_loan_type])
    const lookUpItems = [
        { id: 1, label: 'Vehicle' },
        { id: 2, label: 'Property' },
    ];

    const handleLookUpClick = (id) => {
        navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/loan_applications?selected_loan_type=${id}`);
    };

    function UpdateTabData(id) {
        switch (id) {
            case '1':
                return <VehicleLoanApplication/>;
            case '2':
                return <PropertyLoanApplication/>;
            default:
                return <VehicleLoanApplication/>;
        }
    }
    return (
        <div>
            <h3 className="page_title">Loan Application</h3>
            <div className='lookUp_list_tags'>
                {lookUpItems.map(item => (
                    <p className='lookUp_tag' key={item.id}
                        onClick={() => handleLookUpClick(item.id)}
                        style={{
                            color: selectedLookup === `${item.id}` ? 'white' : 'black',
                            backgroundColor: selectedLookup === `${item.id}` ? '#0F62FE' : 'white'
                        }}>{item.label}</p>
                ))}
            </div>
            {UpdateTabData(selectedLookup)}
        </div>
    )
}

export default LoanApplications