import React, { useEffect, useState } from "react";
import "./profileStyle.css";
import { useNavigate, useParams } from "react-router-dom";
import { IoCameraOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../../../../../components/customModal/CustomModal";
import ProfileForm from "./profile_form/ProfileForm";
import { Avatar, IconButton } from "@mui/material";
import { updateUserProfilePic } from "../../../../../api/apiServices/userApis";
import { getUserDetails } from "../../../../../api/apiServices/userApis";
import { useFormik } from "formik";
import { SuccessToast } from "../../../../../toasts/Toasts";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ChangePassword from "./changepassword/ChangePassword";
function Profile() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profilePic, setProfilePic] = useState("");
  const [open, setOpen] = React.useState(false);
  const [userDetails, setUserDetails] = useState(null);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [Loading, SetLoading] = useState(false);
  function openFileUploader() {
    document.getElementById("fileInput").click();
  }
  const [openModal2, setOpenModal2] = React.useState(false);

  const handleOpenModal2 = () => {
    setOpenModal2(true);
  };

  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("profilePic", file);
    SetLoading(true);
    // Call the updateUserProfilePic function with formData
    updateUserProfilePic(formData)
      .then((response) => {
        // Update the profile picture state
        setProfilePic(URL.createObjectURL(file));
        getAllUserDetails();
        SuccessToast("Image Uploaded Successsfully");
        // setUploadSuccess(true);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        SetLoading(false);
      });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (state) => {
    setOpen(state);
    getAllUserDetails();
  };

  function getAllUserDetails() {
    SetLoading(true);
    dispatch(getUserDetails())
      .then((response) => {
        setUserDetails(response);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        SetLoading(false);
      });
  }
  const formik = useFormik({
    initialValues: {
      profilePic: null,
    },
    onSubmit: (values, actions) => {
      handleFileUpload({ target: { files: [values.profilePic] } });
      actions.setSubmitting(false);
    },
  });
  useEffect(() => {
    getAllUserDetails();
  }, []);

  return (
    <div>
      <p>My Profile</p>
      {userDetails && userDetails.data && (
        <div className="SubContainer_details_container1">
          <div className="SubContainer_details_container2">
            <div className="SubContainer_details_container2_1">
              <input
                type="file"
                id="fileInput"
                accept="image/png, image/jpeg"
                style={{ display: "none" }}
                onChange={(e) => {
                  formik.setFieldValue("profilePic", e.currentTarget.files[0]);
                  formik.handleSubmit();
                }}
              />
              <div className="SubContainer_details_container_Image">
                <Avatar
                  //  className="SubContainer_details_container_Image"
                  sx={{
                    width: 130,
                    height: 130,
                    borderRadius: "100%",
                    cursor: "pointer",
                  }}
                  src={`${userDetails.data.ud_profile_picture}`}
                />
                <IconButton
                  component="label"
                  sx={{
                    position: "absolute",
                    zIndex: 2,
                    bottom: "10%",
                    right: "15%",
                    padding: 0.5,
                    background: "white",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",

                    "&:hover": {
                      background: "white",
                    },
                  }}
                  loading={Loading}
                >
                  <CameraAltIcon
                    sx={{
                      color: "black",
                      fontSize: 25,
                    }}
                    onClick={openFileUploader}
                  />
                </IconButton>
              </div>
              <div className="SubContainer_details_container_Image_mobile">
                <Avatar
                  //  className="SubContainer_details_container_Image"
                  sx={{
                    width: 80,
                    height: 80,
                    borderRadius: "100%",
                    cursor: "pointer",
                    marginLeft: "3px",
                  }}
                  src={`${userDetails.data.ud_profile_picture}`}
                />
                <IconButton
                  component="label"
                  sx={{
                    position: "absolute",
                    zIndex: 2,
                    bottom: "2%",
                    right: "15%",
                    padding: 0.5,
                    background: "white",
                    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",

                    "&:hover": {
                      background: "white",
                    },
                  }}
                >
                  <CameraAltIcon
                    sx={{
                      color: "black",
                      fontSize: 17,
                    }}
                    onClick={openFileUploader}
                  />
                </IconButton>
              </div>
              {/* <div
                className="SubContainer_details_container2_2"
                onClick={openFileUploader}
              >
                <IoCameraOutline style={{ fontSize: 25 }} />
              </div> */}
              {/* <div
                className="SubContainer_details_container2_mobile"
                onClick={openFileUploader}
              >
                <IoCameraOutline style={{ fontSize: 20 }} />
              </div> */}
            </div>
            <p className="userProfile_user_name">{userDetails.data.ud_name}</p>
            <p
              className="userProfile_status_tag"
              style={{
                backgroundColor:
                  userDetails &&
                  userDetails.data &&
                  userDetails.data.usl_status === "Active"
                    ? "rgb(80, 148, 80)"
                    : "rgba(237, 89, 103, 1)",
              }}
            >
              {userDetails && userDetails.data && userDetails.data.usl_status}
            </p>

            <p
              className="userProfile_Change_Password_tag"
              onClick={() => {
                handleOpenModal2();
              }}
            >
              Change Password
            </p>
            <p
              className="userProfile_Change_Password_tag"
              onClick={() => {
                handleClickOpen();
              }}
            >
              Edit Profile
            </p>
          </div>
          <div>
            <div className="userProfile_details">
              <p className="userProfile_details_placeholder">Name</p>
              <p className="userProfile_details_text">
                {userDetails.data.ud_name}
              </p>
            </div>
            <div className="userProfile_details">
              <p className="userProfile_details_placeholder">Email</p>
              <p className="userProfile_details_text">
                {userDetails.data.up_email}
              </p>
            </div>
            <div className="userProfile_details">
              <p className="userProfile_details_placeholder">Role</p>
              <p className="userProfile_details_text">
                {userDetails.data.url_role}
              </p>
            </div>
            <div className="userProfile_details">
              <p className="userProfile_details_placeholder">Phone Number</p>
              <p className="userProfile_details_text">
                {userDetails.data.ud_phone_no}
              </p>
            </div>
            <div className="userProfile_details">
              <p className="userProfile_details_placeholder">Address</p>
              <p className="userProfile_details_text">
                {userDetails.data.ud_address}
              </p>
            </div>
          </div>
        </div>
      )}
      <CustomModal
        open={open}
        handleClose={handleClose}
        body={<ProfileForm onclose={handleClose} userDetails={userDetails} />}
        title={"Update user details"}
      />
      <CustomModal
        open={openModal2}
        handleClose={handleCloseModal2}
        body={<ChangePassword onclose={handleCloseModal2} />}
        title={"Change Password"}
      />
    </div>
  );
}

export default Profile;
