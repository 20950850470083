import { ErrorMessage, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import CustomTextField from "../../../../components/customTextField/CustomTextField";
import CustomTextArea from "../../../../components/customTextArea/CustomTextArea";
import CustomDatePicker from "../../../../components/customDatePicker/CustomDatePicker";
import CustomAttachmentContainer from "../../../../components/customAttachmentContainer/CustomAttachmentContainer";
import TermsAndConditions from "./termsAndConditions/TermsAndConditions";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./ApplicationForm.css";
import FormButton from "../../../../components/formButton/FormButton";
import CustomAutoComplete from "../../../../components/customAutoComplete/CustomAutoComplete";
import { PaymentMethod, SourceOfKnow } from "./DropDownDatas";
import { getAllBanks } from "../../../../api/apiServices/financialSupportBankApi";
import { useDispatch } from "react-redux";
import ResponseHandler from "../../../../services/ResponseHandler";
import * as Yup from "yup";
import dayjs from "dayjs";
import { FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { LocalStorageServices } from "../../../../services/localStorageServices";
import {createNewPropertyApplication, getProperyBasicDetails} from "../../../../api/apiServices/propertyApplicationApi";
import { createNewVehicleApplication, getVehicleBasicDetails } from "../../../../api/apiServices/vehicleApplicationApi";
function ApplicationForm() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const moduleName = queryParams.get("module");
  const {vehicle_id,property_id}=useParams()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [attachment, SetAttachment] = useState([]);
  const [Banks, SetBanks] = useState([]);
  
  useEffect(() => {
    if(moduleName ==="vehicle" ){
      dispatch(getVehicleBasicDetails(vehicle_id)).then((response)=>{
        const data=response.data.data
        formik.setValues({
          ...formik.values,
          module_number: data.vbd_id,
          offer_amount: data.vad_amount,
          product_type: data.vtl_type 
        });
      }).catch((error)=>{
        ResponseHandler(error)
      })
    }else if(moduleName ==="property"){
      dispatch(getProperyBasicDetails(property_id)).then((response)=>{
        const data=response.data.data
        formik.setValues({
          ...formik.values,
          module_number: data.pbd_id,
          offer_amount: data.plr_appraisal_amount,
          product_type: data.ptl_type 
        });
      }).catch((error)=>{
        ResponseHandler(error)
      })
    }else{
      navigate(-1)
    }
    AllBanks();
  }, [vehicle_id,property_id,moduleName]);
  function uploadAttachment(data) {
    SetAttachment([...attachment, data]);
  }
  function deleteAttachmentAtIndex(index) {
    const files = attachment;
    files.splice(index, 1);
    SetAttachment([...files]);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (state) => {
    setOpen(state);
  };
  function AllBanks() {
    dispatch(getAllBanks())
      .then((response) => {
        const data = response.map((item) => {
          return {
            label: item.foel_name,
            value: item.foel_id,
          };
        });
        SetBanks(data);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }
  const initialValues = {
    module_number:"",
    payment_method: null,
    financing_of_expenses: '',
    offer_amount: "",
    product_type: "",
    observation: "",
    name: "",
    nationality_or_country: "",
    proxy_of_the_company: "",
    email: "",
    tel: "",
    identity_or_legal_card_number: "",
    date_of_birth: "",
    exact_direction: "",
    cell_phone: "",
    know_about: null,
    broker_name: "",
    id_number: "",
    agree: false,
  };
  const validationSchema = Yup.object({
    module_number: Yup.string().required("Module number is required"),
    payment_method: Yup.string().required("Payment method is required"),
    offer_amount: Yup.string().required("Offer amount is required"),
    product_type: Yup.string().required("Product type is required"),
    observation: Yup.string(),
    name: Yup.string().required("Name is required"),
    nationality_or_country: Yup.string(),
    proxy_of_the_company: Yup.string(),
    email: Yup.string().email("Invalid email").required("Email is required"),
    tel: Yup.string(),
    identity_or_legal_card_number: Yup.string().required(
      "Identity or legal card number is required"
    ),
    date_of_birth: Yup.date().required("Date of birth is required"),
    exact_direction: Yup.string(),
    cell_phone: Yup.string().required("Cell phone is required"),
    broker_name: Yup.string(),
    id_number: Yup.string(),
    agree: Yup.boolean()
      .oneOf(
        [true],
        "You need to agree to the terms and conditions to continue"
      )
      .required("You need to agree to the terms and conditions to continue"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append('applicationDetails',JSON.stringify(values))
      data.append('applicationAttachment',JSON.stringify(attachment))
      if(attachment && attachment.length>0){
        attachment.forEach((item) => {
          if(!(item.file.toString().startsWith('http://') || item.file.toString().startsWith('https://'))){
            if(moduleName ==="vehicle"){
              data.append(`vehicleApplicationAttachments`, item.file,`${'AVA_'+vehicle_id+'_'+new Date().toISOString().replace(/:/g, '_')+'-'+item.title+'.'+item.filetype}`);
            }else if(moduleName === "property"){
              data.append(`propertyApplicationAttachments`, item.file,`${'APA_'+property_id+'_'+new Date().toISOString().replace(/:/g, '_')+'-'+item.title+'.'+item.filetype}`);
            }
          }
        })
      }else{
        if(moduleName ==="vehicle"){
          data.append(`vehicleApplicationAttachments`,null);
        }
      }
      if(moduleName === "vehicle" ){
          dispatch(createNewVehicleApplication(vehicle_id,data)).then((response)=>{
            ResponseHandler(response)
            navigate(-1)
          }).catch((error)=>{
            ResponseHandler(error)
          })
      }else if(moduleName === "property"){
        dispatch(createNewPropertyApplication(property_id,data)).then((response)=>{
          ResponseHandler(response)
          navigate(-1)
        }).catch((error)=>{
          ResponseHandler(error)
        })
      }
    
    },
  });
  return (
    <div>
      <div className="applicationForm_header">
        <p className="page_title">Offer Details</p>
        <div
          className="back_button"
          onClick={() => {
            navigate(-1);
          }}
        >
          <KeyboardBackspaceIcon />
          <p>back</p>
        </div>
      </div>
      <div className="application_form_main_Container">
        <form id="applicationform" onSubmit={formik.handleSubmit}>
          <div className="application_formContainer">
            <p className="application_form_legend">Add Details</p>
            <div className="applicationform_fields">
              <div>
                <div className="applicationform_field_container">
                  <p className="applicationform_fields_labels">
                    {moduleName ==="vehicle"?"Vehicle Number":moduleName ==="property"?"Property Number":"null"}
                  </p>
                  <CustomTextField
                    disabled={true}
                    name="module_number"
                    placeholder={moduleName ==="vehicle"?"Vehicle Number":moduleName ==="property"?"Property Number":"null"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.module_number}
                    error={
                      formik.touched.module_number &&
                      Boolean(formik.errors.module_number)
                    }
                    helperText={
                      formik.touched.module_number &&
                      formik.errors.module_number
                    }
                  />
                </div>
                <div className="applicationform_field_container">
                  <p className="applicationform_fields_labels">
                    Payment Method
                  </p>
                  <CustomAutoComplete
                    name="payment_method"
                    placeholder="Payment method"
                    options={PaymentMethod}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "payment_method",
                        newValue ? newValue.label : ""
                      );
                      formik.setFieldValue(
                        "financing_of_expenses",
                        null
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      PaymentMethod.find(
                        (payment_method) =>
                          payment_method.value === formik.values.payment_method
                      ) || null
                    }
                    error={
                      formik.touched.payment_method &&
                      Boolean(formik.errors.payment_method)
                    }
                    helperText={
                      formik.touched.payment_method &&
                      formik.errors.payment_method
                    }
                  />
                </div>
                {formik.values.payment_method === "Loan" && (
                  <div className="applicationform_field_container">
                    <p className="applicationform_fields_labels">
                      Financing of expenses
                    </p>
                    <CustomAutoComplete
                      name="financing_of_expenses"
                      placeholder="Select banks"
                      options={Banks}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          "financing_of_expenses",
                          newValue ? newValue.value : ""
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        Banks.find(
                          (financing_of_expenses) =>
                            financing_of_expenses.value ===
                            formik.values.financing_of_expenses
                        ) || null
                      }
                      error={
                        formik.touched.financing_of_expenses &&
                        Boolean(formik.errors.financing_of_expenses)
                      }
                      helperText={
                        formik.touched.financing_of_expenses &&
                        formik.errors.financing_of_expenses
                      }
                    />
                  </div>
                )}
                <div className="applicationform_field_container">
                  <p className="applicationform_fields_labels">Observation</p>
                  <CustomTextArea
                    name="observation"
                    placeholder="Enter observation"
                    maxRows={5}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.observation}
                    error={
                      formik.touched.observation &&
                      Boolean(formik.errors.observation)
                    }
                    helperText={
                      formik.touched.observation && formik.errors.observation
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="applicationform_field_container">
                    <p className="applicationform_fields_labels">
                      Offer amount
                    </p>
                    <CustomTextField
                     disabled={true}
                      name="offer_amount"
                      placeholder="Enter offer amount"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.offer_amount}
                      error={
                        formik.touched.offer_amount &&
                        Boolean(formik.errors.offer_amount)
                      }
                      helperText={
                        formik.touched.offer_amount &&
                        formik.errors.offer_amount
                      }
                    />
                  </div>
                  <div className="applicationform_field_container">
                    <p className="applicationform_fields_labels">
                      Product Type
                    </p>
                    <CustomTextField
                     disabled={true}
                      name="product_type"
                      placeholder="Enter product type"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.product_type}
                      error={
                        formik.touched.product_type &&
                        Boolean(formik.errors.product_type)
                      }
                      helperText={
                        formik.touched.product_type &&
                        formik.errors.product_type
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="application_formContainer">
            <p className="application_form_legend">Offeror Details</p>
            <div className="applicationform_fields">
              <div>
                <div className="applicationform_field_container">
                  <p className="applicationform_fields_labels">Name</p>
                  <CustomTextField
                    name="name"
                    placeholder="Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </div>
                <div className="applicationform_field_container">
                  <p className="applicationform_fields_labels">
                    Nationality or Country of Registration (if legal entity)
                  </p>
                  <CustomTextField
                    name="nationality_or_country"
                    placeholder="Nationality or country of registration"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.nationality_or_country}
                    error={
                      formik.touched.nationality_or_country &&
                      Boolean(formik.errors.nationality_or_country)
                    }
                    helperText={
                      formik.touched.nationality_or_country &&
                      formik.errors.nationality_or_country
                    }
                  />
                </div>
                <div className="applicationform_field_container">
                  <p className="applicationform_fields_labels">
                    Proxy of The Company
                  </p>
                  <CustomTextField
                    name="proxy_of_the_company"
                    placeholder="Enter proxy of the company"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.proxy_of_the_company}
                    error={
                      formik.touched.proxy_of_the_company &&
                      Boolean(formik.errors.proxy_of_the_company)
                    }
                    helperText={
                      formik.touched.proxy_of_the_company &&
                      formik.errors.proxy_of_the_company
                    }
                  />
                </div>
                <div className="applicationform_field_container">
                  <p className="applicationform_fields_labels">Email</p>
                  <CustomTextField
                    name="email"
                    placeholder="Enter email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />
                </div>
                <div className="applicationform_field_container">
                  <p className="applicationform_fields_labels">Tel</p>
                  <CustomTextField
                    name="tel"
                    placeholder="Enter tel"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.tel}
                    error={formik.touched.tel && Boolean(formik.errors.tel)}
                    helperText={formik.touched.tel && formik.errors.tel}
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="applicationform_field_container">
                    <p className="applicationform_fields_labels">
                      Identity or Legal Card Number
                    </p>
                    <CustomTextField
                      name="identity_or_legal_card_number"
                      placeholder="Enter identity or legal card number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.identity_or_legal_card_number}
                      error={
                        formik.touched.identity_or_legal_card_number &&
                        Boolean(formik.errors.identity_or_legal_card_number)
                      }
                      helperText={
                        formik.touched.identity_or_legal_card_number &&
                        formik.errors.identity_or_legal_card_number
                      }
                    />
                  </div>
                  <div className="applicationform_field_container">
                    <p className="applicationform_fields_labels">
                      Date of Birth or Date of Incorporation (if it is a legal
                      entity)
                    </p>
                    <CustomDatePicker
                      name="date_of_birth"
                      onChange={(date) => {
                        formik.setFieldValue("date_of_birth", date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.date_of_birth, "MM/DD/YYYY")}
                      error={
                        formik.touched.date_of_birth &&
                        Boolean(formik.errors.date_of_birth)
                      }
                      helperText={
                        formik.touched.date_of_birth &&
                        formik.errors.date_of_birth
                      }
                    />
                  </div>
                  <div className="applicationform_field_container">
                    <p className="applicationform_fields_labels">
                      Exact Direction
                    </p>
                    <CustomTextField
                      name="exact_direction"
                      placeholder="Enter exact direction"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.exact_direction}
                      error={
                        formik.touched.exact_direction &&
                        Boolean(formik.errors.exact_direction)
                      }
                      helperText={
                        formik.touched.exact_direction &&
                        formik.errors.exact_direction
                      }
                    />
                  </div>
                  <div className="applicationform_field_container">
                    <p className="applicationform_fields_labels">Cell Phone</p>
                    <CustomTextField
                      name="cell_phone"
                      placeholder="Enter cell phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cell_phone}
                      error={
                        formik.touched.cell_phone &&
                        Boolean(formik.errors.cell_phone)
                      }
                      helperText={
                        formik.touched.cell_phone && formik.errors.cell_phone
                      }
                    />
                  </div>
                  <div className="applicationform_field_container">
                    <p className="applicationform_fields_labels">
                      How did you find out about the sale?
                    </p>
                    <CustomAutoComplete
                      name="know_about"
                      placeholder="Select social media "
                      options={SourceOfKnow}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          "know_about",
                          newValue ? newValue.label : ""
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        SourceOfKnow.find(
                          (know_about) =>
                            know_about.value === formik.values.know_about
                        ) || null
                      }
                      error={
                        formik.touched.know_about &&
                        Boolean(formik.errors.know_about)
                      }
                      helperText={
                        formik.touched.know_about && formik.errors.know_about
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="application_formContainer">
            <p className="application_form_legend">Add Attachments</p>
            <div>
              <p className="application_remark">
                a. Attach a photocopy of the identity card or the legal card and
                identity card of the general representative.
              </p>
              <p className="application_remark">
                b. The Bank is exempt from all liability if it cannot locate the
                offeror by the indicated means.
              </p>
              <p className="application_remark">
                c. See the Direct Purchase-Sale Offer Contract for the
                conditions that govern this sale.
              </p>
            </div>
            <CustomAttachmentContainer
              attachments={attachment}
              uploadAttachments={uploadAttachment}
              deleteAttachments={deleteAttachmentAtIndex}
            />
            <div></div>
          </div>
          <div className="application_formContainer">
            <p className="application_form_legend">Broker Details</p>
            <p className="application_remark">
              (Fill out only if the sale is through a broker real estate)
            </p>
            <div className="applicationform_fields">
              <div>
                <div className="applicationform_field_container">
                  <p className="applicationform_fields_labels">
                    Real estate broker's full name
                  </p>
                  <CustomTextField
                    name="broker_name"
                    placeholder="Real estate broker's full name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.broker_name}
                    error={
                      formik.touched.broker_name &&
                      Boolean(formik.errors.broker_name)
                    }
                    helperText={
                      formik.touched.broker_name && formik.errors.broker_name
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="applicationform_field_container">
                    <p className="applicationform_fields_labels">ID number</p>
                    <CustomTextField
                      name="id_number"
                      placeholder="Enter id number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.id_number}
                      error={
                        formik.touched.id_number &&
                        Boolean(formik.errors.id_number)
                      }
                      helperText={
                        formik.touched.id_number && formik.errors.id_number
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="terms_and_condition_container">
            <FormControl
              error={formik.touched.agree && Boolean(formik.errors.agree)}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    name="agree"
                    checked={formik.values.agree}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    color="primary"
                  />
                }
                label={
                  <span>
                    I agree with the{" "}
                    <span
                      className="terms_and_condition_link"
                      onClick={() => {
                        handleClickOpen(true);
                      }}
                    >
                      Terms & Conditions
                    </span>{" "}
                    for the Direct purchase of offer sale contract
                  </span>
                }
              />
              <FormHelperText>
                {formik.touched.agree && formik.errors.agree}
              </FormHelperText>
            </FormControl>
          </div>
          <div className="applicationform_action">
            <FormButton
              id="applicationformCreateCancel"
              variant="outlined"
              text="Cancel"
              onClick={() => {
                window.history.back();
              }}
            />
            <FormButton
              id="applicationformCreateSubmit"
              type="submit"
              form="applicationform"
              variant="contained"
              text="Save"
            />
          </div>
        </form>
      </div>
      <TermsAndConditions open={open} handleClose={handleClose} />
    </div>
  );
}

export default ApplicationForm;
