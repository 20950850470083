import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import "./VehicleTasks.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVehicleTasks,
  getAllVehicleTasks,
} from "../../../../../api/apiServices/vehicleApi";
import { setRefresh } from "../../../../../store/usersStore";
import SimpleTable from "../../../../../components/simpleTable/SimpleTable3";
import CustomChip from "../../../../../components/customChip/CustomChip";
import ResponseHandler from "../../../../../services/ResponseHandler";
import CustomModal from "../../../../../components/customModal/CustomModal";
import ReAssignVehicleTaskForm from "./reAssignVehicleTask/ReAssignVehicleTaskForm";
import { IoArrowBack } from "react-icons/io5";
import { LocalStorageServices } from "../../../../../services/localStorageServices";
import SearchFilter from "./SearchFilter/SearchFilter";

function VehicleTasks() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState({
    taskId: "",
    vehicleId: "",
  });
  const [deleteVehicleTask, setDeleteVehicleTask] = useState({
    id: "",
    loading: false,
  });
  const AllvehicleTasks = useSelector(
    (state) => state.vehicleStore.VehicleTasks
  );
  useEffect(() => {
    if (id) {
      dispatch(getAllVehicleTasks(id));
    }
  }, [id]);
  const UpdateTaskDeleteDetails = (id, loading) => {
    setDeleteVehicleTask({
      ...deleteVehicleTask,
      ["id"]: id,
      ["loading"]: loading,
    });
  };
  const handleClickOpen = () => {
    dispatch(setRefresh())
    setOpen(true);
  };

  const handleClose = (state) => {
    dispatch(setRefresh())
    setOpen(state);
  };
  const handleDelete = (vehicleId, taskId) => {
    UpdateTaskDeleteDetails(taskId, true);
    dispatch(deleteVehicleTasks(vehicleId, taskId))
      .then((response) => {
        ResponseHandler(response);
      })
      .catch((error) => {
        ResponseHandler(error);
      })
      .finally(() => {
        UpdateTaskDeleteDetails(taskId, false);
      });
  };
  const handleReassign = (taskId, vehicleId) => {
    setSelectedIds({
      ...selectedIds,
      ["taskId"]: taskId,
      ["vehicleId"]: vehicleId,
    });
    handleClickOpen();
  };
  const Privilages = useSelector((state) => state.userStore.Privilages);
  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Task Name",
      dataIndex: "taskName",
      key: "taskName",
    },
    {
      title: "Task Description",
      dataIndex: "taskDescription",
      key: "taskDescription",
    },
    {
      title: "Assign To",
      dataIndex: "assignTo",
      key: "assignTo",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      action: (row) => (
        <p
          style={{
            color: row.status === "Completed" ? "green" : "blue",
            minWidth: "100px",
          }}
        >
          {row.status}
        </p>
      ),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      action: (row) => (
        <div className="vechicle_tasks_chipset">
          {Privilages &&
          Privilages?.Tasks.some((item) => item === "Update") &&
          Privilages?.url_role !== "Runner" ? (
            <CustomChip
              disabled={row.status === "Completed"}
              text="Reassign"
              bgColor="#573CFA"
              color="white"
              onClick={() =>
                handleReassign(row.taskId, id ? row.vehicleId : null)
              }
            />
          ) : null}

          <CustomChip
            text="View"
            bgColor="#F8893C"
            color="white"
            onClick={() =>
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${
                  row.taskId
                }/view_and_edit_vehicle_task?edit=${false}`
              )
            }
          />
          {Privilages &&
          Privilages.Tasks &&
          Privilages.Tasks.some((item) => item === "Delete") ? (
            <CustomChip
              loading={
                deleteVehicleTask.id === row.taskId
                  ? deleteVehicleTask.loading
                  : false
              }
              text="Delete"
              bgColor="#ED5967"
              color="white"
              onClick={() => handleDelete(id, row.taskId)}
            />
          ) : null}
        </div>
      ),
    },
  ];
  const datas = () => {
    const result =
      AllvehicleTasks &&
      AllvehicleTasks.map((item, index) => {
        return {
          key: index + 1,
          taskId: item.vehicle_task_id,
          vehicleId: item.vt_vehicle_id,
          taskName: item.vt_task_name,
          taskDescription: item.vt_task_description,
          assignTo: item.up_email,
          startDate: item.vt_start_date,
          endDate: item.vt_end_date,
          duration: item.vt_duration
            ? item.vt_duration == 1
              ? item.vt_duration + " Day"
              : item.vt_duration + " Days"
            : item.vt_duration,
          status: item.sl_status,
          priority: item.pl_priority,
        };
      });
    return result;
  };

  return (
    <div>
      {id ? (
        <>
          <div className="vehicle_task_header">
            <p className="vehicle_name">Vehicle Tasks</p>
            {Privilages &&
            Privilages.Tasks &&
            Privilages.Tasks.some((item) => item === "Add") ? (
              <button
                className="add_vehicle_task"
                onClick={() => {
                  navigate(
                    `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${id}/create_task`
                  );
                }}
              >
                Add Task
              </button>
            ) : null}
          </div>

          <div className="vehicle_task_view_back_container">
            <div>
              <SearchFilter />
            </div>
            <div
              className="vehicle_task_view__back"
              onClick={() => {
                navigate(-1);
              }}
            >
              <IoArrowBack />
              <p>Back</p>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="vehicle_task_view_back_container">
            <div>
              <SearchFilter />
            </div>
          </div>
        </>
      )}
      <SimpleTable columns={Columns} rows={datas()} />
      <CustomModal
        open={open}
        handleClose={handleClose}
        body={
          <ReAssignVehicleTaskForm
            taskId={selectedIds.taskId}
            vehicleId={selectedIds.vehicleId}
            onclose={handleClose}
          />
        }
        title={"Change Assigne"}
      />
    </div>
  );
}

export default VehicleTasks;
