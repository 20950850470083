import React,{useState} from 'react'
import { MdInfoOutline } from "react-icons/md";
import { IoArrowBack } from "react-icons/io5";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import './forgetPasswordStyle.css'
import { UpdateforgetPassword, forgetPassword } from '../../api/apiServices/userApis';
import ResponseHandler from '../../services/ResponseHandler';
import CustomTextField from '../../components/customTextField/CustomTextField';
function ForgetPassword() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [passwordForm, SetPasswordForm] = useState(false);
    const formik1 = useFormik({
      initialValues: {
        email: "",
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .email("Invalid email address")
          .required("Email is required"),
      }),
      onSubmit: (values) => {
        dispatch(forgetPassword(values))
          .then((response) => {
            ResponseHandler(response);
            SetPasswordForm(true);
            formik2.setValues({
              ...formik2.values,
              ["email"]: values.email,
            });
          })
          .catch((error) => {
            ResponseHandler(error);
          });
      },
    });
    const formik2 = useFormik({
      initialValues: {
        email: "",
        otp: "",
        newPassword: "",
        confirmNewPassword: "",
      },
      validationSchema: Yup.object({
        email: Yup.string()
          .email("Invalid email address")
          .required("Email is required"),
        otp: Yup.string().required("Otp is required"),
        newPassword: Yup.string() .min(6, "Password must be at least 6 characters")
        .required("New password is required"),
        confirmNewPassword: Yup.string()
          .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
          .required("Confirm new password is required"),
      }),
      onSubmit: (values) => {
        dispatch(UpdateforgetPassword(values))
          .then((response) => {
            ResponseHandler(response);
            navigate('/admin_portal/login')
          })
          .catch((error) => {
            ResponseHandler(error);
          });
      },
    });
    return (
      <div className="forgot_password_main_container">
        <div className="forgot_password_sub_container">
          {!passwordForm ? (
            <>
              <div className="forgot_password_info_icon_container">
                <MdInfoOutline style={{ fontSize: 60, color: "#003756" }} />
              </div>
              <p className="forgot_password_text">Forgot password</p>
              <p className="forgot_password_info_text">
               Enter your email to receive an OTP for password reset.
              </p>
              <form
                onSubmit={formik1.handleSubmit}
                className="forgot_password_form"
              >
                <div>
                  <CustomTextField
                    placeholder={"Enter your email"}
                    name="email"
                    className="input_fields"
                    autoComplete="off"
                    onChange={formik1.handleChange}
                    onBlur={formik1.handleBlur}
                    value={formik1.values.email}
                  />
                  {formik1.touched.email && formik1.errors.email && (
                    <p className="forgot_password_error_message">
                      {formik1.errors.email}
                    </p>
                  )}
                </div>
  
                <button className="forgot_password_submit_button" type="submit">
                  Submit
                </button>
              </form>
              <div
                className="forgot_password_back"
                onClick={() => {
                  navigate("/admin_portal/login");
                }}
              >
                <IoArrowBack style={{ marginTop: "1px" }} />
                <p>Back to login</p>
              </div>
            </>
          ) : (
            <>
              <div>
                <p  className="forgot_password_text" >Reset password</p>
                <form
                  onSubmit={formik2.handleSubmit}
                  className="forgot_password_form"
                >
                  <div>
                    <div className="forgot_password_form_field">
                      <CustomTextField
                      disabled={true}
                        placeholder={"Enter your otp"}
                        name="email"
                        className="input_fields"
                        autoComplete="off"
                        onChange={formik2.handleChange}
                        onBlur={formik2.handleBlur}
                        value={formik2.values.email}
                      />
                      {formik2.touched.email && formik2.errors.email && (
                        <p className="forgot_password_error_message">
                          {formik2.errors.email}
                        </p>
                      )}
                    </div>
  
                    <div className="forgot_password_form_field">
                      <CustomTextField
                        placeholder={"Enter your otp"}
                        name="otp"
                        className="input_fields"
                        autoComplete="off"
                        onChange={formik2.handleChange}
                        onBlur={formik2.handleBlur}
                        value={formik2.values.otp}
                      />
                      {formik2.touched.otp && formik2.errors.otp && (
                        <p className="forgot_password_error_message">
                          {formik2.errors.otp}
                        </p>
                      )}
                    </div>
                    <div className="forgot_password_form_field">
                      <CustomTextField
                        placeholder={"Enter your new password"}
                        name="newPassword"
                        type={"password"}
                        className="input_fields"
                        autoComplete="off"
                        onChange={formik2.handleChange}
                        onBlur={formik2.handleBlur}
                        value={formik2.values.newPassword}
                      />
                      {formik2.touched.newPassword &&
                        formik2.errors.newPassword && (
                          <p className="forgot_password_error_message">
                            {formik2.errors.newPassword}
                          </p>
                        )}
                    </div>
                    <div className="forgot_password_form_field">
                      <CustomTextField
                        placeholder={"Confirm New Password"}
                        name="confirmNewPassword"
                        type={"password"}
                        className="input_fields"
                        autoComplete="off"
                        onChange={formik2.handleChange}
                        onBlur={formik2.handleBlur}
                        value={formik2.values.confirmNewPassword}
                      />
                      {formik2.touched.confirmNewPassword &&
                        formik2.errors.confirmNewPassword && (
                          <p className="forgot_password_error_message">
                            {formik2.errors.confirmNewPassword}
                          </p>
                        )}
                    </div>
                  </div>
  
                  <button className="forgot_password_submit_button" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            </>
          )}
        </div>
      </div>
  )
}

export default ForgetPassword