import { socketServices } from "./socketServices"

export class LocalStorageServices{

    static setLocalStorageData(data){
        localStorage.setItem("user_details", JSON.stringify(data))
    }

    static getLocalStorageData(){
        return localStorage.getItem("user_details")
    }
    static getLocalStorageDataAndParse(){
        const UserDetails= localStorage.getItem("user_details")
        let details=undefined
        if(UserDetails){
            details=JSON.parse(UserDetails)
        }
        return details

    }

    static getUserRole(){
        const UserDetails= localStorage.getItem("user_details")
        let role=undefined
        if(UserDetails){
            role=JSON.parse(UserDetails)?.up_role
        }
        return role

    }
    static deleteLocalStorageData(){
        socketServices.disconnectSocket()
        localStorage.removeItem('user_details');
    }

    static setLanguage(language) {
        localStorage.setItem('currentLanguage', language);
    }

    static getLanguage() {
        return localStorage.getItem('currentLanguage') || 'en'; // Default to 'en' if not set
    }
}