import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import VehicleTaskForm from "../vehicleTaskFormPage/VehicleTaskForm";
import VehicleTaskView from "./vehicleTaskView/VehicleTaskView";
import { useDispatch } from "react-redux";
import { getVehicleTaskByTaskId } from "../../../../../api/apiServices/vehicleApi";
import ResponseHandler from "../../../../../services/ResponseHandler";

function VehicleTaskViewAndEdit() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const isEdit = queryParams.get("edit");
  const [data, SetData] = useState(null);
  const [isedit, SetisEdit] = useState(false);
  const [DataUpdated, SetDataUpdated] = useState(false);
  useEffect(() => {
    if (id) {
      if (isEdit) {
        SetisEdit(JSON.parse(isEdit));
      }
      dispatch(getVehicleTaskByTaskId(id))
        .then((response) => {
          SetData(response.data.data);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
  }, [id, isEdit, DataUpdated]);

  function UpdateData() {
    SetDataUpdated(!DataUpdated);
  }
  return (
    <>
      {isedit ? (
        <VehicleTaskForm vehicleTaskData={data} />
      ) : (
        <VehicleTaskView data={data} UpdateData={UpdateData} />
      )}
    </>
  );
}

export default VehicleTaskViewAndEdit;
