import ResponseHandler from "../../services/ResponseHandler.js";
import { setAllUsers, setPrivilages, updateUserTableLoading } from "../../store/usersStore.js";
import { ErrorToast, SuccessToast } from "../../toasts/Toasts.js";
import {ApiConfig}  from "../apiConfig.js"



export const userLogin =(data)=>{
    return  async()=>{
    try {
        const response = await ApiConfig.post('user/login',data );
        return response.data
      } catch (error) {
        throw error
      }
    }
}



export const createNewUser =(data)=>{
    return  async()=>{
    try {
        const response = await ApiConfig.post('user/register',data );
        return response
      } catch (error) {
        throw error
      }
    }
}

export const getUserRoleWithPrivilage=(roleId)=>{
  return  async(dispatch)=>{
      try {
          const response = await ApiConfig.get(`user/${roleId}/privilage`);
          dispatch(setPrivilages(response.data.data))
        } catch (error) {
          ResponseHandler(error)
        }
    }
}

export const getAllUsers=()=>{
  return async(dispatch)=>{
    try {
      dispatch(updateUserTableLoading(true))
      const response = await ApiConfig.get('user/users');
      dispatch(setAllUsers(response.data.data))
      dispatch(updateUserTableLoading(false))
    } catch (error) {
      ResponseHandler(error)
      dispatch(updateUserTableLoading(false))
    }
  }
}

export const getUserById=(id)=>{
  return async()=>{
    try {
      const response = await ApiConfig.get(`user/${id}/get_user`);
      return response.data
    } catch (error) {
      throw error
    }
  }
}

export const getUserStatus=()=>{
  return async()=>{
    try {
      const response = await ApiConfig.get(`user_status`);
      return response.data
    } catch (error) {
      throw error
    }
  }
}

export const updateUser=(id,data)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.put(`user/${id}/update_user`,data );
        return response
      } catch (error) {
        throw error
      }
    }
}

export const updatePassword=(id,data)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.put(`user/${id}/update_password`,data );
        return response
      } catch (error) {
        throw error
      }
    }
}

export const deleteUser=(id)=>{
  return  async(dispatch)=>{
    try {
        const response = await ApiConfig.delete(`user/${id}/delete_user`);
        dispatch(getAllUsers());
        return response
      } catch (error) {
        throw error
      }
    }
}

export const getUserDetails=()=>{
  return async()=>{
    try {
      const response = await ApiConfig.get(`/user/user_details`);
      return response.data
    } catch (error) {
      throw error
    }
  }
}

export const updateUserProfilePic = (formData) => {
  return new Promise((resolve, reject) => {
    ApiConfig.put(`/user/update_user_profile_pic`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
    .then(response => resolve(response))
    .catch(error => reject(error));
  });
};

export const updateUserDetails_user = (data) => {
  return async () => {
    try {
      const response = await ApiConfig.put(
        `/user/update_user_details_by_user`,
        data
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
};

export const updateUserPassword_user = (data) => {
  return async () => {
    try {
      const response = await ApiConfig.put(
        `/user/update_user_password_by_user`,
        data
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
};

export const forgetPassword=(data)=>{
  return  async(dispatch)=>{
    try {
        const response = await ApiConfig.post(`/user/forgot_password`,data);
         return response
      } catch (error) {
         throw error
      }
}
}

export const UpdateforgetPassword=(data)=>{
  return  async(dispatch)=>{
    try {
        const response = await ApiConfig.post(`/user/update_password`,data);
         return response
      } catch (error) {
         throw error
      }
}
}