import { CircularProgress } from '@mui/material'
import React,{useState,useEffect} from 'react'
import './CustomSpin.css'
function CustomSpin({ loading, children }) {
    const [isLoadingWithDelay, setIsLoadingWithDelay] = useState(false);

  useEffect(() => {
    let timeoutId;
    if (loading) {
      timeoutId = setTimeout(() => {
        setIsLoadingWithDelay(true);
      }, 200);
    }else{
        setIsLoadingWithDelay(false);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [loading]);
  return (
    <div style={{ position: 'relative' }}>
      {isLoadingWithDelay ? (
        <div className='spin_container'>
          <CircularProgress size={30} />
          <p className="loading-text">Loading</p>
        </div>
      ) : null}
      {children}
    </div>
  )
}

export default CustomSpin