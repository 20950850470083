import ResponseHandler from "../../services/ResponseHandler"
import { setNotifications } from "../../store/homePageStore"
import { ApiConfig } from "../apiConfig"

export const getAllNotifications=()=>{
    return  async(dispatch)=>{
        try{
            const response=await ApiConfig.get('notifications')
            dispatch(setNotifications(response.data.data))
        }catch(error){
            ResponseHandler(error)
        }
    }
}

export const updateNotificationIsRead=()=>{
    
    return  async(dispatch)=>{
        try{
            await ApiConfig.put('update_notification')
            dispatch(getAllNotifications())
        }catch(error){
            ResponseHandler(error)
        }
    }
}