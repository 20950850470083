import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../../../../components/customTextField/CustomTextField";
import CustomTextArea from "../../../../../components/customTextArea/CustomTextArea";
import CustomAutoComplete from "../../../../../components/customAutoComplete/CustomAutoComplete";
import CustomDatePicker from "../../../../../components/customDatePicker/CustomDatePicker";
import dayjs from "dayjs";
import FormButton from "../../../../../components/formButton/FormButton";
import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../../../../api/apiServices/userApis";
import { getAllPriority } from "../../../../../api/apiServices/priorityApi";
import ResponseHandler from "../../../../../services/ResponseHandler";
import { useState } from "react";
import { getAllStatus } from "../../../../../api/apiServices/statusApi";
import "./VehicleTaskForm.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  createNewVehicleTask,
  updateVehicleTask,
} from "../../../../../api/apiServices/vehicleApi";
import VehicleAppraisalAttachment from "../vehicleFormPage/vehicleAppraisal/vehicleAppraisalAttachment.tsx/VehicleAppraisalAttachment";
function VehicleTaskForm({ vehicleTaskData }) {
  const dispatch = useDispatch();
  const [Isview, SetIsview] = useState(false);
  const [attachment, SetAttachment] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [priorityDropDown, SetPriorityDropDownData] = useState([]);
  const [statusDropDown, SetStatusDropDownData] = useState([]);
  const [DurationSetter, SetDurationSetter] = useState({
    StartDate: null,
    EndDate: null,
  });
  const [loading, Setloading] = useState(false);
  const users = useSelector((state) => state.userStore.WholeUsers);

  function uploadAttachment(data) {
    SetAttachment([...attachment, data]);
  }
  function deleteAttachmentAtIndex(index) {
    const files = attachment;
    files.splice(index, 1);
    SetAttachment([...files]);
  }
  function updateFormData(name, data) {}

  useEffect(() => {
    dispatch(getAllUsers());
    SetPriorityDropDown();
    SetStatusDropDown();
    if (vehicleTaskData) {
      formik.setValues({
        ...formik.values,
        taskName: vehicleTaskData.vt_task_name,
        taskDescription: vehicleTaskData.vt_task_description,
        assignTo: vehicleTaskData.vt_assignto,
        priority: vehicleTaskData.vt_priority,
        startDate: vehicleTaskData.vt_start_date,
        endDate: vehicleTaskData.vt_end_date,
        duration: vehicleTaskData.vt_duration,
        status: vehicleTaskData.vt_status,
        notes: vehicleTaskData.vt_notes,
      });
      SetDurationSetter({
        ...DurationSetter,
        ["StartDate"]: dayjs(vehicleTaskData.vt_start_date, "MM/DD/YYYY"),
        ["EndDate"]: dayjs(vehicleTaskData.vt_end_date, "MM/DD/YYYY"),
      });
      if (vehicleTaskData.vta_attachments) {
        SetAttachment(vehicleTaskData.vta_attachments);
      }
    }
  }, [vehicleTaskData]);

  function SetUsersDropdown(users) {
    if (users && users.length > 0) {
      const filterUsers = users.filter(
        (item) =>
          item.url_role !== "Admin" &&
          item.url_role !== "SuperAdmin" &&
          item.url_role !== "Agent"
      );
      const data = filterUsers.map((item) => {
        return {
          label: item.up_email,
          value: item.up_id,
        };
      });
      return data;
    } else {
      return [];
    }
  }
  function SetPriorityDropDown() {
    dispatch(getAllPriority())
      .then((response) => {
        const data = response.map((item) => {
          return {
            label: item.pl_priority,
            value: item.pl_id,
          };
        });
        SetPriorityDropDownData(data);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }
  function SetStatusDropDown() {
    dispatch(getAllStatus())
      .then((response) => {
        const data = response.map((item) => {
          return {
            label: item.sl_status,
            value: item.sl_id,
          };
        });
        SetStatusDropDownData(data);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }
  const initialValues = {
    taskName: "",
    taskDescription: "",
    assignTo: null,
    priority: null,
    startDate: null,
    endDate: null,
    duration: 0,
    status: null,
    notes: "",
  };
  const validationSchema = Yup.object({
    taskName: Yup.string().required("Task name is required"),
    status: Yup.string().nullable().required("Status is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append("fieldDetails", JSON.stringify(values));
      data.append("attachmentDetails", JSON.stringify(attachment));

      if (attachment && attachment.length > 0) {
        attachment.forEach((attachment) => {
          if (
            !(
              attachment.file.toString().startsWith("http://") ||
              attachment.file.toString().startsWith("https://")
            )
          ) {
            data.append(
              `vehicleAttachments`,
              attachment.file,
              `${
                "VTA_" +
                `${vehicleTaskData ? vehicleTaskData.vt_vehicle_id : id}` +
                "_" +
                new Date().toISOString().replace(/:/g, "_") +
                "-" +
                attachment.title +
                "." +
                attachment.filetype
              }`
            );
          }
        });
      } else {
        data.append(`vehicleAttachments`, null);
      }

      if (!vehicleTaskData) {
        Setloading(true);
        dispatch(createNewVehicleTask(id, data))
          .then((response) => {
            ResponseHandler(response);
            navigate(-1);
          })
          .catch((error) => {
            ResponseHandler(error);
          })
          .finally(() => {
            Setloading(false);
          });
      } else {
        Setloading(true);
        dispatch(updateVehicleTask(id, data))
          .then((response) => {
            ResponseHandler(response);
            navigate(-1);
          })
          .catch((error) => {
            ResponseHandler(error);
          })
          .finally(() => {
            Setloading(false);
          });
      }
    },
  });
  function durationGenerator(StartDate, EndDate) {
    SetDurationSetter({ ...DurationSetter, StartDate, EndDate });
    if (StartDate && EndDate) {
      const date1 = new Date(StartDate);
      const date2 = new Date(EndDate);
      const differenceMs = date2 - date1;
      const daysDifference = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
      if (daysDifference >= 0) {
        formik.setFieldValue("duration", daysDifference + 1);
      } else {
        formik.setFieldValue("duration", 0);
      }
    }
  }
  return (
    <div>
      <p className="vehicle_page_title">
        {vehicleTaskData ? "Update Tasks/Jobs" : "Add Tasks/Jobs"}
      </p>
      <div className="vehicle_task_form_main_Container">
        <div className="vehicle_task_formContainer">
          <p className="vehicle_task_form_legend">Add Details</p>
          <form id="vehicle_taskform" onSubmit={formik.handleSubmit}>
            <div className="vehicle_taskform_fields">
              <div id="vehicle_form_item1">
                <div className="vehicle_taskform_field_container">
                  <p className="vehicle_taskform_fields_labels">Task Name</p>
                  <CustomTextField
                    name="taskName"
                    placeholder="Enter task name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.taskName}
                    error={
                      formik.touched.taskName && Boolean(formik.errors.taskName)
                    }
                    helperText={
                      formik.touched.taskName && formik.errors.taskName
                    }
                  />
                </div>
                <div className="vehicle_taskform_field_container">
                  <p className="vehicle_taskform_fields_labels">Assigned To</p>
                  <CustomAutoComplete
                    disabled={statusDropDown.find(
                      (status) =>
                        status.value === vehicleTaskData?.vt_status &&
                        status.label === "Completed"
                    )}
                    name="assignTo"
                    placeholder="Select assigned to"
                    options={SetUsersDropdown(users)}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "assignTo",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      SetUsersDropdown(users).find(
                        (assignTo) => assignTo.value === formik.values.assignTo
                      ) || null
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.value.toString() === value.value.toString()
                    }
                    error={
                      formik.touched.assignTo && Boolean(formik.errors.assignTo)
                    }
                    helperText={
                      formik.touched.assignTo && formik.errors.assignTo
                    }
                  />
                </div>
                <div className="vehicle_taskform_field_container">
                  <p className="vehicle_taskform_fields_labels">Priority</p>
                  <CustomAutoComplete
                    name="priority"
                    placeholder="Select priority"
                    options={priorityDropDown}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "priority",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      priorityDropDown.find(
                        (priority) => priority.value === formik.values.priority
                      ) || null
                    }
                    error={
                      formik.touched.priority && Boolean(formik.errors.priority)
                    }
                    helperText={
                      formik.touched.priority && formik.errors.priority
                    }
                  />
                </div>
                <div className="vehicle_taskform_field_container">
                  <p className="vehicle_taskform_fields_labels">
                    Task Description
                  </p>
                  <CustomTextArea
                    maxRows={4}
                    name="taskDescription"
                    placeholder="Enter task description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.taskDescription}
                    error={
                      formik.touched.taskDescription &&
                      Boolean(formik.errors.taskDescription)
                    }
                    helperText={
                      formik.touched.taskDescription &&
                      formik.errors.taskDescription
                    }
                  />
                </div>
              </div>

              <div id="vehicle_form_item2">
                <div className="vehicle_taskform_field_container-date-main">
                  <div className="vehicle_taskform_field_container">
                    <p className="vehicle_taskform_fields_labels">Start Date</p>
                    <CustomDatePicker
                      name="startDate"
                      onChange={(date) => {
                        formik.setFieldValue("startDate", date);
                        durationGenerator(date, DurationSetter.EndDate);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.startDate, "MM/DD/YYYY")}
                      error={
                        formik.touched.startDate &&
                        Boolean(formik.errors.startDate)
                      }
                      helperText={
                        formik.touched.startDate && formik.errors.startDate
                      }
                    />
                  </div>
                  <div className="vehicle_taskform_field_container">
                    <p className="vehicle_taskform_fields_labels">End Date</p>
                    <CustomDatePicker
                      disabled={formik.values.startDate ? false : true}
                      minDate={dayjs(formik.values.startDate, "MM/DD/YYYY")}
                      name="endDate"
                      onChange={(date) => {
                        formik.setFieldValue("endDate", date);
                        durationGenerator(DurationSetter.StartDate, date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.endDate, "MM/DD/YYYY")}
                      error={
                        formik.touched.endDate && Boolean(formik.errors.endDate)
                      }
                      helperText={
                        formik.touched.endDate && formik.errors.endDate
                      }
                    />
                  </div>
                </div>
                <div className="vehicle_taskform_field_container">
                  <p className="vehicle_taskform_fields_labels">
                    Duration (days)
                  </p>
                  <CustomTextField
                    disabled={true}
                    name="duration"
                    placeholder="Enter durartion"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.duration}
                    error={
                      formik.touched.duration && Boolean(formik.errors.duration)
                    }
                    helperText={
                      formik.touched.duration && formik.errors.duration
                    }
                  />
                </div>
                <div className="vehicle_taskform_field_container">
                  <p className="vehicle_taskform_fields_labels">Status</p>
                  <CustomAutoComplete
                    name="status"
                    placeholder="Select Status"
                    options={statusDropDown}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "status",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      statusDropDown.find(
                        (status) => status.value === formik.values.status
                      ) || null
                    }
                    error={
                      formik.touched.status && Boolean(formik.errors.status)
                    }
                    helperText={formik.touched.status && formik.errors.status}
                  />
                </div>
                <div className="vehicle_taskform_field_container">
                  <p className="vehicle_taskform_fields_labels">Notes</p>
                  <CustomTextArea
                    maxRows={4}
                    name="notes"
                    placeholder="Enter task notes"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.notes}
                    error={formik.touched.notes && Boolean(formik.errors.notes)}
                    helperText={formik.touched.notes && formik.errors.notes}
                  />
                </div>
              </div>
            </div>
            <div id="attachment-div">
              <VehicleAppraisalAttachment
                Isview={Isview}
                attachments={attachment}
                uploadAttachments={uploadAttachment}
                deleteAttachments={deleteAttachmentAtIndex}
                updateFormDatas={updateFormData}
              />
            </div>
          </form>
        </div>
        <div className="vehicle_taskform_action">
          <FormButton
            id="vehicle_taskCreateCancel"
            variant="outlined"
            loading={false}
            text="Cancel"
            onClick={() => {
              navigate(-1);
            }}
          />
          <FormButton
            id="vehicle_taskCreateSubmit"
            type="submit"
            form="vehicle_taskform"
            variant="contained"
            text={vehicleTaskData ? "Update Task" : "Create Task"}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
}

export default VehicleTaskForm;
