import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import CustomTextArea from "../../../../../../components/customTextArea/CustomTextArea";
import FormButton from "../../../../../../components/formButton/FormButton";
import './profileFormStyle.css'
import { updateUserDetails_user } from "../../../../../../api/apiServices/userApis";
import { useDispatch } from "react-redux";
import ResponseHandler from "../../../../../../services/ResponseHandler";
function ProfileForm({onclose,userDetails}) {
  const dispatch = useDispatch()
  const initialValues = {
    name: userDetails.data.ud_name ? userDetails.data.ud_name : "",
    email_id: userDetails.data.up_email ? userDetails.data.up_email : "",
    role: userDetails.data.url_role ? userDetails.data.url_role : "",
    phone_no: userDetails.data.ud_phone_no ? userDetails.data.ud_phone_no: "",
    address: userDetails.data.ud_address ? userDetails.data.ud_address : "",
  };
  
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email_id: Yup.string()
      .email("Invalid email address")
      .required("Email address is required"),
    address: Yup.string().required("Address is required"),
    phone_no: Yup.string().required("Phone Number is required"),
  });
  let formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit:  (values) => {
      dispatch(updateUserDetails_user(values)).then((response)=>{
        ResponseHandler(response);
        onclose(false);
      }).catch((error)=>{
        ResponseHandler(error)
      })
      
    }
    })
  return (
  
     <div className="update_property_task_assigne_Form">
      <form id="userProfileform" onSubmit={formik.handleSubmit}>
        <div className="userProfile_form_row">
          <div>
            <p className="userProfile_form_label">Name</p>
            <CustomTextField
              name="name"
              placeholder={"Enter name"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.name}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
              FormHelperTextProps={{
                style: { marginLeft: 0 },
              }}
            />
          </div>
          <div>
            <label className="userProfile_form_label">Email Address</label>
            <CustomTextField
              disabled={true}
              name="email_id"
              placeholder={"Enter email address"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email_id}
              error={formik.touched.email_id && Boolean(formik.errors.email_id)}
              helperText={formik.touched.email_id && formik.errors.email_id}
              FormHelperTextProps={{
                style: { marginLeft: 0 },
              }}
            />
          </div>
        </div>
        <div className="userProfile_form_row">
          <div>
            <label className="userProfile_form_label">Phone Number</label>
            <CustomTextField
              name="phone_no"
              placeholder={"Enter phone number"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.phone_no}
              error={
                formik.touched.phone_no &&
                Boolean(formik.errors.phone_no)
              }
              helperText={
                formik.touched.phone_no && formik.errors.phone_no
              }
              FormHelperTextProps={{
                style: { marginLeft: 0 },
              }}
            />
          </div>
          <div>
            <label className="userProfile_form_label">Role</label>
            <CustomTextField
              disabled={true}
              name="role"
              placeholder={"Enter Role"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.role}
              error={formik.touched.role && Boolean(formik.errors.role)}
              helperText={formik.touched.role && formik.errors.role}
              FormHelperTextProps={{
                style: { marginLeft: 0 },
              }}
            />
          </div>
        </div>
        <div>
          <label className="userProfile_form_label">Address</label>
          <CustomTextArea
            name="address"
            fullWidth
            multiline
            maxRows={3}
            placeholder={"Enter address"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.address}
          />
        </div>
        <div className="userProfile_form_action_button">
              <FormButton
                id="userProfileCreateSubmit"
                type="submit"
                form="userProfileform"
                variant="contained"
                // loading={loading}
                text={'Update'}
              />
              <FormButton
                id="userProfileCreateCancel"
                variant="outlined"
                loading={false}
                text="Cancel"
                onClick={() =>{onclose(false)} }
              />
            </div>
      </form>
      </div>
    
  );
}

export default ProfileForm;
