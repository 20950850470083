import { ApiConfig } from "../apiConfig";
import ResponseHandler from "../../services/ResponseHandler";
import { setTestimonials } from "../../store/testimonialStore";

export const updateTestimonialPic = (formData,testimonial_id) => {
    return new Promise((resolve, reject) => {
      ApiConfig.put(`/customer/${testimonial_id}/update_testimonial_profile_pic`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      })
      .then(response => resolve(response))
      .catch(error => reject(error));
    });
  };

  export const CreateNewTestimonials=(formData)=>{
    return  async()=>{
        try{
          const response=await ApiConfig.post('/customer/create_new_testimonial',formData,{
            headers: {
              'Content-Type': 'multipart/form-data',
            } 
          })
           return response
        }catch(error){
            throw error
        }
    }
  }

  export const getAllTestimonials=()=>{
    return  async(dispatch)=>{
        try{
          const response=await ApiConfig.get('/customer/testimonials');
          dispatch(setTestimonials(response.data.data))         
        }
        catch(error){
            ResponseHandler(error)
            throw error
        }
    }
    
}

export const getAllTestimonialsById=(testimonial_id)=>{
    return  async()=>{
        try{
          const response=await ApiConfig.get(`/customer/${testimonial_id}/testimonial`)
           return response.data.data
        }catch(error){
            throw error
        }
    }
}


export const updateTestimonials=(testimonial_id,data)=>{
    return  async()=>{
        try{
          const response=await ApiConfig.put(`/customer/${testimonial_id}/update_testimonial`,data)
           return response
        }catch(error){
            throw error
        }
    }

    
}
export const deleteTestimonials=(testimonial_id)=>{
    return  async()=>{
        try{
          const response=await ApiConfig.delete(`/customer/${testimonial_id}/delete_testimonial`)
           return response
        }catch(error){
            throw error
        }
    }
}