import React, { useEffect, useState } from "react";
import "./VehicleTaskView.css";
import { Divider } from "@mui/material";
import VehicleTaskAttachments from "../vehicleTaskAttachment/VehicleTaskAttachments";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomAutoComplete from "../../../../../../components/customAutoComplete/CustomAutoComplete";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import { getAllStatus } from "../../../../../../api/apiServices/statusApi";
import { Button } from "@mui/material";
import {
  updateVehicleTaskAttachmentByRunner,
  updateVehicleTaskByRunner,
} from "../../../../../../api/apiServices/vehicleApi";
import CustomModal from "../../../../../../components/customModal/CustomModal";
import VehicleAppraisalAttachment from "../../vehicleFormPage/vehicleAppraisal/vehicleAppraisalAttachment.tsx/VehicleAppraisalAttachment";
import { RiFileEditFill } from "react-icons/ri";
import FormButton from "../../../../../../components/formButton/FormButton";
import CustomNotesContainer from "../../../../../../components/customNotes/CustomNotes";
import RunnerViewNotes from "../runnerviewnotes/RunnerViewNotes";
import CustomAttachmentContainerV1 from "../../../../../../components/customAttachmentContainer_v1/CustomAttachmentContainerV1";
import { ErrorToast } from "../../../../../../toasts/Toasts";
function VehicleTaskView({ data, UpdateData }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const Privilages = useSelector((state) => state.userStore.Privilages);
  const [statusDropDown, SetStatusDropDownData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [attachment, SetAttachment] = useState([]);
  const [EditAttachmentRunner, SetEditAttachmentRunner] = useState(false);
  const [loading, Setloading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isExpandednotes, setIsExpandednotes] = useState(false);
  const maxLength = 300;

  const description =
    data && data.vt_task_description ? data.vt_task_description.trim() : "--";

    const notes =
    data && data.vt_notes ? data.vt_notes.trim() : "--";

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  const renderDescription = () => {
    if (description === "--") {
      return "--";
    }

    if (isExpanded || description.length <= maxLength) {
      return description.match(/.{1,45}/g).map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    } else {
      return <>{description.substring(0, maxLength)}...</>;
    }
  };

  const handleTogglenotes = () => {
    setIsExpandednotes(!isExpandednotes);
  };

  const renderNotes = () => {
    if (notes === "--") {
      return "--";
    }

    if (isExpandednotes || notes.length <= maxLength) {
      return notes.match(/.{1,45}/g).map((line, index) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    } else {
      return <>{notes.substring(0, maxLength)}...</>;
    }
  };


  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false); // Close the modal
  };
  useEffect(() => {
    if (data) {
      SetStatusDropDown();
    }
  }, [data]);
  function SetStatusDropDown() {
    dispatch(getAllStatus())
      .then((response) => {
        const datas = response.map((item) => {
          return {
            label: item.sl_status,
            value: item.sl_id,
          };
        });
        if (data) {
          const initialStatus = datas.find(
            (status) => status.label === data.sl_status
          );
          setSelectedStatus(initialStatus?.value);
        }
        SetStatusDropDownData(datas);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }

  function updateStatus(task_id, task_status) {
    try {
      dispatch(updateVehicleTaskByRunner(task_id, task_status))
        .then((response) => {
          ResponseHandler(response);
          UpdateData();
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    } catch (error) {
      ResponseHandler(error);
    }
  }

  function uploadAttachment(data) {
    SetAttachment([...attachment, data]);
  }
  function deleteAttachmentAtIndex(index) {
    const files = attachment;
    files.splice(index, 1);
    SetAttachment([...files]);
  }

  function UpdateAttachments(task_id, vehicle_id, attachments) {
    Setloading(true);
    const data = new FormData();
    data.append("attachmentDetails", JSON.stringify(attachments));
    if (attachment && attachment.length > 0) {
      attachment.forEach((attachment) => {
        data.append(
          `vehicleAttachments`,
          attachment.file,
          `${
            "VTA_" +
            `${vehicle_id}` +
            "_" +
            new Date().toISOString().replace(/:/g, "_") +
            "-" +
            attachment.title +
            "." +
            attachment.filetype
          }`
        );
      });
    } else {
      data.append(`vehicleAttachments`, null);
    }
    dispatch(updateVehicleTaskAttachmentByRunner(task_id, data))
      .then((response) => {
        ResponseHandler(response);
        UpdateData();
        SetAttachment([]);
        SetEditAttachmentRunner(false);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        Setloading(false);
      });
  }
  return (
    <div>
      <div className="vehicle_task__view_header">
        <p className="page_title">View Tasks/Jobs</p>
        <div
          className="vehicle_task_view_back"
          onClick={() => {
            navigate(-1);
          }}
        >
          <IoArrowBack />
          <p>Back</p>
        </div>
      </div>
      <div className="vehicle_task_view_mainContainer">
        {Privilages &&
        Privilages?.Vehicle &&
        Privilages?.Vehicle.some((item) => item === "Update") ? (
          <div className="vehicle_task_view_edit_action">
            <RiFileEditFill
              onClick={() => {
                navigate(
                  `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${id}/view_and_edit_vehicle_task?edit=${true}`
                );
              }}
            />
          </div>
        ) : null}

        <div className="vehicle_task_view_details_mainContainer">
          <div className="vehicle_task_view_details_subContainer">
            <div
              className="vehicle_task_view_details_grid_Container"
              id="vtvdgc1"
            >
              <div className="vehicle_task_view_details_field_container_grid">
                <p className="vehicle_task_view_details_field_label">
                  Task Name
                </p>
                <p
                  className="vehicle_task_view_details_field_value"
                  style={{
                    wordBreak: "break-all",
                    overflowWrap: "break-word",
                    width: "100%",
                  }}
                >
                  {data
                    ? data.vt_task_name && data.vt_task_name.length > 0
                      ? data.vt_task_name
                          .trim()
                          .match(/.{1,45}/g)
                          .map((line, index) => (
                            <React.Fragment key={index}>
                              {line}
                              <br />
                            </React.Fragment>
                          ))
                      : "--"
                    : "--"}
                </p>
              </div>

              <div className="vehicle_task_view_details_field_container_grid">
                <p className="vehicle_task_view_details_field_label">
                  Assigned To
                </p>
                <p className="vehicle_task_view_details_field_value">
                  {data ? (data.up_email ? data.up_email : "--") : "--"}
                </p>
              </div>
              <div className="vehicle_task_view_details_field_container_grid">
                <p className="vehicle_task_view_details_field_label">Status</p>
                {Privilages?.Tasks.some((item) => item === "Update") &&
                Privilages.url_role === "Runner" &&
                data?.sl_status !== "Completed" ? (
                  <>
                    <div className="vehicle_task_view_details_custom_dropdown">
                      <CustomAutoComplete
                        name="status"
                        placeholder="Select Status"
                        options={statusDropDown}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          setSelectedStatus(newValue ? newValue?.value : "");
                          if(newValue){
                          updateStatus(data.vehicle_task_id, newValue?.value);
                          }else{
                            ErrorToast('Task status is required.')
                          }
                          
                        }}
                        value={
                          statusDropDown.find(
                            (status) => status.value === selectedStatus
                          ) || null
                        }
                      />
                    </div>
                  </>
                ) : (
                  <p
                    className="vehicle_task_view_details_field_value"
                    style={{
                      textAlign: "center",
                      maxWidth: "140px",
                      padding: "4px 0px",
                      borderRadius: "5px",
                      color:
                        data &&
                        (data.sl_status === "Completed"
                          ? "green"
                          : data.sl_status === "In Progress"
                          ? "rgba(251, 188, 4, 1)"
                          : data.sl_status === "New"
                          ? "blue"
                          : ""),
                      backgroundColor:
                        data &&
                        (data.sl_status === "Completed"
                          ? "rgba(50, 147, 111, 0.15)"
                          : data.sl_status === "In Progress"
                          ? "rgba(255, 248, 227, 1)"
                          : data.sl_status === "New"
                          ? "rgba(223, 234, 255, 1)"
                          : ""),
                      border:
                        data &&
                        (data.sl_status === "Completed"
                          ? "1px solid green"
                          : data.sl_status === "In Progress"
                          ? "1px solid rgb(251, 188, 4)"
                          : data.sl_status === "New"
                          ? "1px solid blue"
                          : ""),
                    }}
                  >
                    {data ? (data.sl_status ? data.sl_status : "--") : "--"}
                  </p>
                )}
              </div>
              <div className="vehicle_task_view_details_field_container_grid">
                <p className="vehicle_task_view_details_field_label">
                  Start Date
                </p>
                <p className="vehicle_task_view_details_field_value">
                  {data
                    ? data.vt_start_date
                      ? data.vt_start_date
                      : "--"
                    : "--"}
                </p>
              </div>
              <div className="vehicle_task_view_details_field_container_grid">
                <p className="vehicle_task_view_details_field_label">
                  Due Date
                </p>
                <p className="vehicle_task_view_details_field_value">
                  {data ? (data.vt_end_date ? data.vt_end_date : "--") : "--"}
                </p>
              </div>
              <div className="vehicle_task_view_details_field_container_grid">
                <p className="vehicle_task_view_details_field_label">
                  Priority
                </p>
                <p className="vehicle_task_view_details_field_value">
                  {data ? (data.pl_priority ? data.pl_priority : "--") : "--"}
                </p>
              </div>
            </div>

            <div
              className="vehicle_task_view_details_grid_Container"
              id="vtvdgc2"
            >
              <div className="vehicle_task_view_details_field_container_grid">
                <p className="vehicle_task_view_details_field_label">
                  Description
                </p>
                <p
                  className="vehicle_task_view_details_field_value"
                  style={{
                    wordBreak: "break-all",
                    overflowWrap: "break-word",
                    width: "100%",
                  }}
                >
                  {renderDescription()}
                  {description.length > maxLength && (
                    <span
                      onClick={handleToggle}
                      style={{ color: "#1565c0", cursor: "pointer" }}
                    >
                      {isExpanded ? "Read Less" : "Read More"}
                    </span>
                  )}
                </p>
              </div>

              <div className="vehicle_task_view_details_field_container_grid">
                <p className="vehicle_task_view_details_field_label">Notes</p>
                <p
                  className="vehicle_task_view_details_field_value"
                  style={{
                    wordBreak: "break-all",
                    overflowWrap: "break-word",
                    width: "100%",
                  }}
                >
                  {renderNotes()}
                  {notes.length > maxLength && (
                    <span
                      onClick={handleTogglenotes}
                      style={{ color: "#1565c0", cursor: "pointer" }}
                    >
                      {isExpandednotes ? "Read Less" : "Read More"}
                    </span>
                  )}
                </p>
              </div>
              <>
                {LocalStorageServices.getUserRole() !== "Runner" ? (
                  <div className="vehicle_task_view_details_field_container_grid">
                    <p className="vehicle_task_view_details_field_label">
                      Runner Note
                    </p>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={openModal}
                      style={{
                        textTransform: "none",
                        lineHeight: 2.0,
                        fontFamily: "Inter",
                        width: "160px",
                      }}
                    >
                      View Runner Notes
                    </Button>
                  </div>
                ) : null}
                <div className="modal_notes">
                  <CustomModal
                    open={isModalOpen}
                    fullWidth={true}
                    handleClose={handleClose}
                    body={<RunnerViewNotes data={data} onClose={handleClose} />}
                    title={"Runner Notes"}
                    PaperProps={{
                      style: { maxWidth: "100vw", width: "100vw" },
                    }}
                  />
                </div>
              </>
            </div>
          </div>
          <Divider />
          {Privilages?.Tasks.some((item) => item === "Update") &&
          Privilages.url_role === "Runner" &&
          data?.sl_status !== "Completed" &&
          !EditAttachmentRunner ? (
            <>
              <div
                className="VehicleTaskAttachment_edit_details"
                onClick={() => SetEditAttachmentRunner(true)}
              >
                <RiFileEditFill />
                <p>Edit</p>
              </div>
            </>
          ) : (
            <></>
          )}
          <div className="vehicle_task_attachment_container">
            {data && data.vta_attachments.length > 0 ? (
              <>
                <div>
                  <VehicleTaskAttachments
                    attachments={data && data.vta_attachments}
                  />
                </div>
              </>
            ) : !EditAttachmentRunner ? (
              <>
                <p className="vehicle_Task_attachment">
                  No attachments are added
                </p>
              </>
            ) : (
              <></>
            )}
            {Privilages?.Tasks.some((item) => item === "Update") &&
            Privilages.url_role === "Runner" &&
            data?.sl_status !== "Completed" &&
            EditAttachmentRunner ? (
              <>
                <div>
                  <CustomAttachmentContainerV1
                    attachments={attachment}
                    uploadAttachments={uploadAttachment}
                    deleteAttachments={deleteAttachmentAtIndex}
                  />
                  {attachment && attachment.length > 0 && (
                    <div className="vehicle_task_action_attachment">
                      <FormButton
                        id="Vehicle_task_attachment"
                        type="submit"
                        form="Vehicle_task_attachment"
                        variant="contained"
                        text={"Save"}
                        loading={loading}
                        onClick={() => {
                          UpdateAttachments(
                            data.vehicle_task_id,
                            data.vt_vehicle_id,
                            attachment
                          );
                        }}
                      />
                    </div>
                  )}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>

          <div>
            {Privilages?.Tasks.some((item) => item === "Update") &&
            Privilages.url_role === "Runner" ? (
              <>
                <CustomNotesContainer
                  data={data}
                  readOnly={
                    Privilages?.Tasks.some((item) => item === "Update") &&
                    Privilages.url_role === "Runner" &&
                    data?.sl_status == "Completed"
                  }
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default VehicleTaskView;
