import React, { useRef, useState } from 'react'
import { FaMicrophone } from "react-icons/fa";
import { BsRecordCircle } from "react-icons/bs";
import './voiceRecording.css'
function VoiceRecording({isVoiceEnabled,updateAudioUrl,updateAudioBlob}) {
    const [isRecording, setIsRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);
  
    const startRecording = async () => {
        try {
            updateAudioUrl(null)
            updateAudioBlob(null)
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaRecorderRef.current.ondataavailable = event => {
              audioChunksRef.current.push(event.data);
            };
            mediaRecorderRef.current.onstop = () => {
              const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/wav' });
              const audioURL = URL.createObjectURL(audioBlob);
              updateAudioUrl(audioURL)
              audioChunksRef.current = [];
              updateAudioBlob(audioBlob)
            };
            mediaRecorderRef.current.start();
            setIsRecording(true);
          } catch (error) {
            isVoiceEnabled(false);
            setIsRecording(false);
            console.error('Error accessing microphone:', error);
          }
     
    };
  
    const stopRecording = () => {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    };
  return (
    <div>
    <button className='voice_record_button' onClick={()=>{
        if(isRecording){
            stopRecording()
        }else{
            isVoiceEnabled(true)
            startRecording()
        } }}>
      {isRecording ? <BsRecordCircle className="voice_icon" color='red' /> : <FaMicrophone className="voice_icon" color='#5570F1'/>}
    </button>
  </div>
  )
}

export default VoiceRecording