import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  getVehiclesBasedOnInterestApplication,
  getVehiclesBasedOnInterestApplicationFilter,
} from '../../../../../../api/apiServices/customerPortalApis';
import ResponseHandler from '../../../../../../services/ResponseHandler';
import { Pagination, styled } from '@mui/material';
import CustomChip from '../../../../../../components/customChip/CustomChip';
import CustomTableWithOutPagination from '../../../../../../components/CustomTableWithOutPagination/CustomTableWithOutPagination';
import { useNavigate } from 'react-router-dom';
import { LocalStorageServices } from '../../../../../../services/localStorageServices';
import VehicleInterestFilter from './vehicle_interest_filter/VehicleInterestFilter';
import usePagination from '../../../../../../components/Pagination';
import { setRefresh } from '../../../../../../store/usersStore';
import { Button, Box } from "@mui/material";

function VehicleInterestApplication() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState({
    vehicle_id: '',
    title: '',
    vehicle_type: '',
    location: '',
    amount: '',
  });
  
  const [isFiltered, setFiltered] = useState(false);
  const tableId = 'VehicleInterestApplication'; // Unique identifier for the table
  const [PageNumber, SetPageNumber] = useState(1);
  // Use custom pagination hook
  const [currentPage, setCurrentPage, pageCount, setPageCount] = usePagination(1, tableId);
  const [vehicleLists, setVehicleLists] = useState([]);
  
  useEffect(()=>{
    setTimeout(()=>{
      dispatch(setRefresh())
    },500)
    dispatch(setRefresh())
  },[currentPage])
  const hasAnyValue = (obj) => {
    return Object.values(obj).some((value) => value !== '');
  };

  useEffect(() => {
    if (hasAnyValue(values)) {
      dispatch(getVehiclesBasedOnInterestApplicationFilter(currentPage, 10, values))
        .then((response) => {
          const { vehicleLists, count } = response;
          setPageCount(Math.ceil(count / 10));
          setVehicleLists(vehicleLists);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    } else {
      dispatch(getVehiclesBasedOnInterestApplication(currentPage, 10))
        .then((response) => {
          const { vehicleLists, count } = response;
          setPageCount(Math.ceil(count / 10));
          setVehicleLists(vehicleLists);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
    dispatch(setRefresh())
  }, [currentPage, isFiltered]);

  const CustomPagination = styled(Pagination)(({ theme }) => ({
    '& .MuiPaginationItem-root': {
      backgroundColor: '#EDEFF6',
      color: '#4A60A1',
      '&.Mui-selected': {
        backgroundColor: '#1976d2',
        color: 'white',
      },
      '&:hover': {
        backgroundColor: '#fff',
        color: '#000',
      },
    },
  }));

  const columns = [
    { title: '#', dataIndex: 'Key', key: 'key' },
    { title: 'Vehicle ID', dataIndex: 'vehicle_id', key: 'vehicle_id' },
    { title: 'Title', dataIndex: 'sale_title', key: 'sale_title' },
    { title: 'Vehicle Type', dataIndex: 'vehicle_type', key: 'vehicle_type' },
    { title: 'Location', dataIndex: 'location', key: 'location' },
    { title: 'Amount', dataIndex: 'amount', key: 'amount' },
    {
      title: 'Actions',
      dataIndex: 'Actions',
      key: 'Actions',
      action: (row) => (
        <div className="user_action_chipset">
          <CustomChip
            text="Applications"
            bgColor="#F8893C"
            color="white"
            onClick={() => {
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/${row.vehicle_id}/vehicle_interest_applications`
              );
            }}
          />
          <CustomChip
            text="Details"
            bgColor="#1976d2"
            color="white"
            onClick={() => {
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${row.vehicle_id}/update_vehicle?tk=0&view_edit=true`
              );
            }}
          />
        </div>
      ),
    },
  ];

  const datas = () => {
    return (
      vehicleLists &&
      vehicleLists.map((item, index) => {
        return {
          Key: currentPage * 10 - (10 - (index + 1)),
          vehicle_id: item.vbd_id,
          sale_title: item.vs_sale_title,
          vehicle_type: item.vtl_type,
          location: item.vad_map_location,
          amount: item.vad_amount,
        };
      })
    );
  };
// ------------------------translation code--------------------------------------
  const translateText = async (text) => {
    const currentLang = localStorage.getItem("currentLanguage") || "en";
    if (currentLang === "es") {
      try {
        const response = await fetch(
          `https://api.mymemory.translated.net/get?q=${encodeURIComponent(text)}&langpair=es|en`
        );
        const data = await response.json();
        if (data.responseStatus === 200) {
          return data.responseData.translatedText;
        } else {
          return text;
        }
      } catch (error) {
        console.error("Error translating text:", error);
        return text; // Fallback to original text if translation fails
      }
    }
    return text;
  };
  
  const onChangeValues = async (event) => {
    const { value, name } = event.target;
    const currentLang = localStorage.getItem("currentLanguage") || "en";
    const isSpanish = currentLang === "es";
  
    if (isSpanish) {
      const translatedValue = await translateText(value);
      setValues({ ...values, [name]: translatedValue });
      console.log(translatedValue,"translatedValue");
    } else {
      setValues({ ...values, [name]: value });
    }
  };
  

  const onResetValues = () => {
    setValues({
      vehicle_id: '',
      title: '',
      vehicle_type: '',
      location: '',
      amount: '',
    });
    setCurrentPage(1);
    setFiltered(!isFiltered);
  };

  const onSearch = () => {
    setCurrentPage(1);
    setFiltered(!isFiltered);
  };
  const renderCustomPagination = () => {
    const pageRange = 3;
    let startPage = Math.max(1, PageNumber - Math.floor(pageRange / 2));
    const endPage = Math.min(pageCount, startPage + pageRange - 1);
    if (endPage - startPage + 1 < pageRange) {
      startPage = Math.max(1, endPage - pageRange + 1);
    }

    const pages = [];

    if (PageNumber > 1) {
      pages.push(
        <Button
          key="prev"
          variant="outlined"
          onClick={() => SetPageNumber(PageNumber - 1)}
          sx={{
            minWidth: '30px',
            padding: '3px',
            margin: '0 5px',
            backgroundColor: '#EDEFF6',
            color: '#4A60A1',
            '&:hover': {
              backgroundColor: '#EDEFF6',
              color: '#4A60A1',
            },
          }}
        >
          {'<'}
        </Button>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Button
          key={i}
          variant={PageNumber === i ? 'contained' : 'outlined'}
          onClick={() => SetPageNumber(i)}
          sx={{
            minWidth: '30px',
            padding: '3px',
            margin: '0 5px',
            backgroundColor: PageNumber === i ? '#005EB8' : '#EDEFF6',
            color: PageNumber === i ? 'white' : '#4A60A1',
            '&:hover': {
              backgroundColor: PageNumber === i ? '#005EB8' : '#EDEFF6',
              color: PageNumber === i ? 'white' : '#4A60A1',
            },
          }}
        >
          {i}
        </Button>
      );
    }

    if (PageNumber < pageCount) {
      pages.push(
        <Button
          key="next"
          variant="outlined"
          onClick={() => SetPageNumber(PageNumber + 1)}
          sx={{
            minWidth: '30px',
            padding: '3px',
            margin: '0 5px',
            backgroundColor: '#EDEFF6',
            color: '#4A60A1',
            '&:hover': {
              backgroundColor: '#EDEFF6',
              color: '#4A60A1',
            },
          }}
        >
          {'>'}
        </Button>
      );
    }

    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0' }}>
        {pages}
      </Box>
    );
  };
  return (
    <div>
      <div style={{ margin: '30px 0px' }}>
        <VehicleInterestFilter
          values={values}
          onChange={onChangeValues}
          onReset={onResetValues}
          onSubmit={onSearch}
        />
      </div>

      <CustomTableWithOutPagination columns={columns} rows={datas()} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          margin: '30px 0px',
        }}
      >
        {renderCustomPagination()}
      </div>
    </div>
  );
}

export default VehicleInterestApplication;
