import { createSlice } from "@reduxjs/toolkit";

export const TasksOrJobsSlice=createSlice({
    name:"TasksOrJobs",
    initialState:{
        selectedToggleKey:0
    },
    reducers:{
        updateSelectedToggleKey:(state,actions)=>{
            state.selectedToggleKey=actions.payload
        },
        ResetTasksOrJobs:(state)=>{
            state.selectedToggleKey=0
        }
    }
})

export const {updateSelectedToggleKey,ResetTasksOrJobs} = TasksOrJobsSlice.actions
export default TasksOrJobsSlice.reducer