import React, { useEffect, useRef, useState } from "react";
import Map from "ol/Map.js";
import View from "ol/View.js";
import TileLayer from "ol/layer/Tile.js";
import OSM from "ol/source/OSM.js";
import Feature from "ol/Feature.js";
import Point from "ol/geom/Point.js";
import { Vector as VectorLayer } from "ol/layer.js";
import { Vector as VectorSource } from "ol/source.js";
import { Icon, Style } from "ol/style.js";
import Geolocation from "ol/Geolocation.js";
import "ol/ol.css"; 
import { fromLonLat ,toLonLat} from 'ol/proj';
import LocationMarker from "../../../../../../assets/LocationMarker.png"

function PropertyMap({ locationCoordinates }) {
//   
const mapTargetElement = useRef(null);
const mapRef = useRef(null);
const locationLayerRef = useRef(null);

const handleMapClick = (event) => {
  const coordinates = toLonLat(event.coordinate);
  const locationFeature = new Feature({
    geometry: new Point(fromLonLat(coordinates)),
  });

  locationLayerRef.current.getSource().clear();
  locationLayerRef.current.getSource().addFeature(locationFeature);
};

useEffect(() => {
  const map = new Map({
    target: mapTargetElement.current,
    layers: [new TileLayer({ source: new OSM() })],
    view: new View({
      center: fromLonLat(locationCoordinates),
      zoom: 10,
    }),
  });

  const locationLayer = new VectorLayer({
    source: new VectorSource(),
  });

  locationLayer.setStyle(
    new Style({
      image: new Icon({
        anchor: [0.5, 1],
        anchorXUnits: "fraction",
        anchorYUnits: "fraction",
        src: LocationMarker,
      }),
    })
  );

  map.addLayer(locationLayer);
  map.on("click", handleMapClick);

  if (locationCoordinates) {
    const locationFeature = new Feature({
      geometry: new Point(fromLonLat(locationCoordinates)),
    });
    locationLayer.getSource().clear();
    locationLayer.getSource().addFeature(locationFeature);
  }

  mapRef.current = map;
  locationLayerRef.current = locationLayer;

  return () => {
    if (map) {
      map.un("click", handleMapClick);
      map.setTarget(null);
    }
  };
}, [locationCoordinates]);

return <div ref={mapTargetElement} className="map" style={{ width: "100%", height: "350px" }} />;
}
export default PropertyMap;
