import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getContactUs } from '../../../../../api/apiServices/customerPortalApis'
import ResponseHandler from '../../../../../services/ResponseHandler'
import SimpleTable from '../../../../../components/simpleTable/SimpleTable'
import ContactUsFilter from './customer_filter/ContactUsFilter'



function ContactUs() {
    const dispatch=useDispatch()
    const contactUs=useSelector((state)=>state.customerStore.allContactUs)
    useEffect(()=>{
      dispatch(getContactUs())
    },[])
    
    const Columns = [
      {
        title: "#",
        dataIndex: "Key",
        key: "key",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Contact Number",
        dataIndex: "contact_number",
        key: "contact_number",
      },
      {
          title: "Message",
          dataIndex: "message",
          key: "message",
      }
    ]
    const datas = () => {
      const result =
      contactUs &&
      contactUs.map((item, index) => {
          return {
            Key:index+1 ,
            id:item.cu_id,
            name: item.cu_name	,
            email: item.cu_email,
            contact_number: item.cu_contact_number,
            message: item.cu_message,
          };
        });
      return result;
    };
    return (
   
         <div>
        <div>
            <p className="page_title">Contact US</p>
        </div>
        <div style={{margin:"30px 0px"}}>
            <ContactUsFilter/>
        </div>
        <div>
           <SimpleTable columns={Columns} rows={datas()} />
        </div>
         
    </div>
    )
}

export default ContactUs