export const vehiclePlotTags={
    1: "Vehicles",
    2: "Ready for sale",
    3: "Sold out",
    4: "Interest Enquiry",
    5: "Loan Enquiry"
   
};

export const propertyPlotTags={
    1: "Properties",
    2: "Ready for sale",
    3: "Sold out",
    4: "Interest Enquiry",
    5: "Loan Enquiry"
};