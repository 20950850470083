import React, { useEffect, useState } from "react";
import FormButton from "../../../../../../../../components/formButton/FormButton";
import CustomTextField from "../../../../../../../../components/customTextField/CustomTextField";
import CustomTextArea from "../../../../../../../../components/customTextArea/CustomTextArea";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useFormik } from "formik";
import { FormHelperText } from "@mui/material";
import * as Yup from "yup";
import "./bankFormStyle.css";
import { useDispatch } from "react-redux";
import { FaUpload } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";
import { Helpers } from "../../../../../../../../services/Helpers";
import {
  CreateNewBanks,
  getApplicationOwnerNameById,
  updateBankDetails,
} from "../../../../../../../../api/apiServices/applicationOwnerApi";
import ResponseHandler from "../../../../../../../../services/ResponseHandler";
import { useParams } from "react-router";
import { RiFileEditFill } from "react-icons/ri";
function BankForm() {
  const { Bank_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isView, SetIsView] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  useEffect(() => {
    if (Bank_id) {
      SetIsView(true);
      dispatch(getApplicationOwnerNameById(Bank_id))
        .then((response) => {
          setFormikData(response);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
  }, [Bank_id]);
  const initialValues = {
    name: "",
    branch_code: "",
    email_id: "",
    address: "",
    phone_number: "",
    location: "",
    bank_type: "",
    about: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Bank name is required"),
    branch_code: Yup.string().required("Branch Code is required"),
    bank_type: Yup.string().required("Bank type is required"),
    email_id: Yup.string()
      .email("Invalid email address")
      .required("Email address is required"),
    address: Yup.string().required("Address is required"),
    phone_number: Yup.string().required("Phone Number is required"),
  });
  let formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      Object.keys(values).forEach((key) => {
        formData.append(key, values[key]);
      });
      if (
        selectedImage &&
        (selectedImage.toString().startsWith("http://") ||
          selectedImage.toString().startsWith("https://"))
      ) {
        formData.append("logo", selectedImage);
      } else if (selectedImage) {
        formData.append("bankLogo", selectedImage);
      }
      if (Bank_id) {
        dispatch(updateBankDetails(Bank_id, formData))
          .then((response) => {
            ResponseHandler(response);
            navigate(-1);
          })
          .catch((error) => {
            ResponseHandler(error);
          });
      } else {
        dispatch(CreateNewBanks(formData))
          .then((response) => {
            ResponseHandler(response);
            navigate(-1);
          })
          .catch((error) => {
            ResponseHandler(error);
          });
      }
    },
  });
  function setFormikData(data) {
    const mappedData = Object.keys(initialValues).reduce((acc, key) => {
      const originalKey = `aonl_${key}`;
      acc[key] = data[originalKey] || initialValues[key];
      return acc;
    }, {});
    formik.setValues({
      ...formik.values,
      ...mappedData,
    });
    setSelectedImage(data.aonl_logo);
  }
  return (
    <div>
      <div className="bank_form_header">
        <p className="page_title">
          {Bank_id
            ? isView
              ? "View Bank details"
              : "Update Bank Details"
            : "Add Bank"}
        </p>
        <div
          className="bank_form_back_button"
          onClick={() => {
            navigate(-1);
          }}
        >
          <KeyboardBackspaceIcon />
          <p>Back</p>
        </div>
      </div>
      <div>
        <div className="bank_form_main_Container">
          {Bank_id && isView && (
            <div
              className="bank_form_edit_details"
              onClick={() => SetIsView(false)}
            >
              <RiFileEditFill />
              <p>Edit</p>
            </div>
          )}
          <div className="bank_formContainer">
            <p className="bank_form_legend">
              {Bank_id ? "Bank Details" : "Add bank details"}
            </p>

            <form id="bankform" onSubmit={formik.handleSubmit}>
              <div className="bank_form_row">
                <div>
                  <p className="bank_form_label">Bank Name</p>
                  <CustomTextField
                    disabled={isView}
                    name="name"
                    placeholder={"Enter bank name"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                    FormHelperTextProps={{
                      style: { marginLeft: 0 },
                    }}
                  />
                </div>
                <div>
                  <label className="bank_form_label">Branch Code</label>
                  <CustomTextField
                    disabled={isView}
                    name="branch_code"
                    placeholder={"Enter branch code"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.branch_code}
                    error={
                      formik.touched.branch_code &&
                      Boolean(formik.errors.branch_code)
                    }
                    helperText={
                      formik.touched.branch_code && formik.errors.branch_code
                    }
                    FormHelperTextProps={{
                      style: { marginLeft: 0 },
                    }}
                  />
                </div>
              </div>
              <div className="bank_form_row">
                <div>
                  <label className="bank_form_label">Email Address</label>
                  <CustomTextField
                    disabled={isView}
                    name="email_id"
                    placeholder={"Enter email address"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email_id}
                    error={
                      formik.touched.email_id && Boolean(formik.errors.email_id)
                    }
                    helperText={
                      formik.touched.email_id && formik.errors.email_id
                    }
                    FormHelperTextProps={{
                      style: { marginLeft: 0 },
                    }}
                  />
                </div>
                <div>
                  <label className="bank_form_label">Phone Number</label>
                  <CustomTextField
                    disabled={isView}
                    name="phone_number"
                    placeholder={"Enter phone number"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone_number}
                    error={
                      formik.touched.phone_number &&
                      Boolean(formik.errors.phone_number)
                    }
                    helperText={
                      formik.touched.phone_number && formik.errors.phone_number
                    }
                    FormHelperTextProps={{
                      style: { marginLeft: 0 },
                    }}
                  />
                </div>
              </div>
              <div className="bank_form_row">
                <div>
                  <label className="bank_form_label">Location</label>
                  <CustomTextField
                    disabled={isView}
                    name="location"
                    placeholder={"Enter location"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.location}
                  />
                </div>
                <div>
                  <div>
                    <label className="bank_form_label">Bank Type</label>
                    <CustomTextField
                      disabled={isView}
                      name="bank_type"
                      placeholder={"Enter bank type"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.bank_type}
                      error={
                        formik.touched.bank_type &&
                        Boolean(formik.errors.bank_type)
                      }
                      helperText={
                        formik.touched.bank_type && formik.errors.bank_type
                      }
                      FormHelperTextProps={{
                        style: { marginLeft: 0 },
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <label className="bank_form_label">Address</label>
                <CustomTextArea
                  disabled={isView}
                  name="address"
                  fullWidth
                  multiline
                  maxRows={6}
                  placeholder={"Enter address"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                />
              </div>
              <div>
                <label className="bank_form_label">About</label>
                <CustomTextArea
                  disabled={isView}
                  name="about"
                  fullWidth
                  multiline
                  maxRows={10}
                  placeholder={"Enter about"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.about}
                />
              </div>
              {/* {selectedImage && (
                <div>
                  <img
                    alt="not found"
                    width={"250px"}
                    src={
                      selectedImage.toString().startsWith("http://") ||
                      selectedImage.toString().startsWith("https://")
                        ? selectedImage
                        : URL.createObjectURL(selectedImage)
                    }
                  />
                  <br />
                  <button onClick={() => setSelectedImage(null)}>Remove</button>
                </div>
              )}
              {!selectedImage && (
                <input
                  type="file"
                  accept=".png, .jpg, .jpeg"
                  name="myImage"
                  onChange={(event) => {
                    console.log(event.target.files[0]);
                    
                    setSelectedImage(event.target.files[0]);
                  }}
                />
              )} */}
{/* ------------------------------------------------------------------- */}

<label className="bank_form_attachment_label">Attachment</label>
<div className="Attachment_formContainer" style={{marginTop:"12px"}}>
        <>
            <div
              className="file-upload-container-bank"
              style={{marginTop: "20px" }}
            >
              {/* {!selectedImage &&  ( */}
                <div>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    name="myImage"
                    onChange={(event) => {
                      const file = event.target.files[0];
                      setSelectedImage(file);
                      formik.setFieldValue("file", file);
                    }}
                    style={{ display: "none" }}
                    id="file-input"
                  />
                  <label
                    htmlFor="file-input"
                    style={{ cursor: "pointer", display: "inline-block" }}
                  >
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        backgroundColor: "#f0f0f0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        margin: "0 auto",
                        position: "relative",
                      }}
                    >
                      <FaUpload style={{ fontSize: "24px", color: "#666" }} />
                    </div>
                  </label>
                </div>
              {/* )} */}
            </div>
            {!selectedImage && (
              <div className="file_upload_text-bank">Upload Files</div>
            )}
          
            <FormHelperText
            style={{ marginLeft: 0, color: "#e74c3c", fontSize: 13 }}
          >
            {formik.touched.file && formik.errors.file}
          </FormHelperText>
        
          </>
          {selectedImage && (
        <div>
          {/* <img
            alt="not found"
            width={"250px"}
            src={selectedImage.toString().startsWith("http://") ||
            selectedImage.toString().startsWith("https://")
              ? selectedImage
              : URL.createObjectURL(selectedImage)}
          />
          <br />
          <button onClick={() => setSelectedImage(null)}>Remove</button> */}
            <div className="img-div-attachment">
                      <img
                       alt="not found"
                        
                        src={
                          selectedImage.toString().startsWith("http://") ||
                          selectedImage.toString().startsWith("https://")
                            ? selectedImage
                            : URL.createObjectURL(selectedImage)
                        }
                    
                        className="image_attachment"
                      />
                        <div
                          className="attachment_close_icon_type"
                          onClick={() => setSelectedImage(null)}
                        >
                          <CancelIcon className="attachemnt_cancelIcon" />
                        </div>
                    </div>
            </div>
          )} 
 </div>
            </form>
          </div>
          {!isView && (
            <div className="bank_form_action_button">
              <FormButton
                id="bankCreateSubmit"
                type="submit"
                form="bankform"
                variant="contained"
                // loading={loading}
                text={Bank_id ? "Update" : "Save"}
              />
              <FormButton
                id="bankCreateCancel"
                variant="outlined"
                loading={false}
                text="Cancel"
                onClick={() => navigate(-1)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BankForm;
