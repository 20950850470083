import { ApiConfig } from "../apiConfig";

export const getSuperAdminDashboardDetails=()=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get('super_admin/dashboard');
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getRunnerDashboardDetails=()=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get('runner/dashboard');
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getAgentDashboardDetails=()=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get('agent/dashboard');
           return response.data.data
        }catch(error){
            throw error
        }
    }
}