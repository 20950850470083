import { Autocomplete, Paper, TextField } from "@mui/material";
import React from "react";
import "./CustomAutoComplete.css";

function CustomAutoComplete({
  name,
  options,
  getOptionLabel,
  placeholder,
  onChange,
  onBlur,
  value,
  error,
  helperText,
  isOptionEqualToValue,
  disabled
}) {
  const inputStyles = {
    "& .MuiOutlinedInput-root": {
      padding: "6px 8px !important",
      fontFamily:"Inter",
      fontSize: 15,
    },
  };
const outlinedInputStyles = {
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#0F62FE !important",
      },
    },
  };
  return (
    <div style={{ position: "relative" }}>
      <Autocomplete
        disabled={disabled}
        name={name}
        options={options}
        getOptionLabel={getOptionLabel}
        fullWidth
        autoComplete={false}
        ListboxProps={{ style: { minHeight: 0 } }}
        menuProps={{ style: { minHeight: 0 } }}
        PaperComponent={(props) => (
          <Paper
            {...props}
            style={{
              ...props.style,
              fontFamily:"Inter",
              fontSize: "15px",
              margin: "0px",
            }}
          />
        )}
        size="small"
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        isOptionEqualToValue={isOptionEqualToValue}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            error={error}
            helperText={helperText}
            FormHelperTextProps={{
              style: { marginLeft: 0, position: "absolute", top: "36px"},
            }}
          />
        )}
        sx={{ ...inputStyles, ...outlinedInputStyles }}
      />
    </div>
  );
}

export default CustomAutoComplete;
