import React, { useEffect, useState } from "react";
import "./PropertyAppraisalForm.css";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import CustomTextArea from "../../../../../../components/customTextArea/CustomTextArea";
import CustomDatePicker from "../../../../../../components/customDatePicker/CustomDatePicker";
import FormButton from "../../../../../../components/formButton/FormButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import {
  getPropertyAppraisalDetails,
  updatePropertyAppraisal,
} from "../../../../../../api/apiServices/propertyApi";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import { RiFileEditFill } from "react-icons/ri";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
function PropertyAppraisalForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { property_id } = useParams();

  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iSViewAndEdit = queryParams.get("view_edit");
  const [loading, setLoading] = useState(false);
  const [Isview, SetIsview] = useState(true);

  useEffect(() => {
    if (property_id) {
      dispatch(getPropertyAppraisalDetails(property_id))
        .then((response) => {
          setFormikData(response.data.data);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
  }, [property_id]);
  const initialValues = {
    office: "",
    purpose_of_appraisal: "",
    appraisal_report_no: "",
    landvalue: "",
    value_of_construction: "",
    total_value_of_the_goods: "",
    value_in_letter: "",
    estimated_time: "",
    predominant_use_of_property: "",
    policy_amount: "",
    maximum_scope: "",
    specify: "",
    general_observation: "",
    name_of_the_expert: "",
    type_of_professional: "",
    siacc_expect_code: "",
    emp: "",
    license: "",
    telephone_number: "",
    email_or_web: "",
    company_name: "",
    company_code: "",
    inspected_date: "",
    reported_date: "",
    garbage_collection: "",
    public_or_communal_building: "",
    registartion_of_farm: "",
    cadastre_plane_no: "",
    property_identity: "",
    percentage: "",
    according_to_the_plane: "",
    measurement: "",
    too_much: "",
    conclusive_value: "",
    sections_factor: "",
    final_unit_value: "",
    total_value_of_the_land: "",
    adjusted_value: "",
    area: "",
    unit_value_land_improvement: "",
    north: "",
    south: "",
    east: "",
    west: "",
    project_coordinates: "",
    vertex: "",
  };

  const validationSchema = Yup.object({
    office: Yup.string(),
    purpose_of_appraisal: Yup.string(),
    appraisal_report_no: Yup.string(),
    landvalue: Yup.string(),
    value_of_construction: Yup.string(),
    total_value_of_the_goods: Yup.string(),
    value_in_letter: Yup.string(),
    estimated_time: Yup.string(),
    predominant_use_of_property: Yup.string(),
    policy_amount: Yup.string(),
    maximum_scope: Yup.string(),
    specify: Yup.string(),
    general_observation: Yup.string(),
    name_of_the_expert: Yup.string(),
    type_of_professional: Yup.string(),
    siacc_expect_code: Yup.string(),
    emp: Yup.string(),
    license: Yup.string(),
    telephone_number: Yup.string(),
    email_or_web: Yup.string(),
    company_name: Yup.string(),
    company_code: Yup.string(),
    // inspected_date: Yup.string(),
    // reported_date:Yup.string(),
    garbage_collection: Yup.string(),
    public_or_communal_building: Yup.string(),
    registartion_of_farm: Yup.string(),
    cadastre_plane_no: Yup.string(),
    property_identity: Yup.string(),
    percentage: Yup.string(),
    according_to_the_plane: Yup.string(),
    measurement: Yup.string(),
    too_much: Yup.string(),
    conclusive_value: Yup.string(),
    sections_factor: Yup.string(),
    final_unit_value: Yup.string(),
    total_value_of_the_land: Yup.string(),
    adjusted_value: Yup.string(),
    area: Yup.string(),
    unit_value_land_improvement: Yup.string(),
    north: Yup.string(),
    south: Yup.string(),
    east: Yup.string(),
    west: Yup.string(),
    project_coordinates: Yup.string(),
    vertex: Yup.string(),
  });
  function setFormikData(data) {
    const mappedData = Object.keys(initialValues).reduce((acc, key) => {
      const originalKey = `pa_${key}`;
      acc[key] = data[originalKey] || initialValues[key];
      return acc;
    }, {});
    formik.setValues({
      ...formik.values,
      ...mappedData,
    });
  }
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(updatePropertyAppraisal(property_id, values))
        .then((response) => {
          ResponseHandler(response);
          if (iSViewAndEdit) {
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${3}&view_edit=${true}`
            );
          } else {
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${3}`
            );
          }
        })
        .catch((error) => {
          ResponseHandler(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const Privilages = useSelector((state) => state.userStore.Privilages);
  return (
    <div>
      <div className="PropertyAppraisal_form_main_Container">
        {Privilages &&
        Privilages?.Property &&
        Privilages?.Property.some((item) => item === "Update")
          ? Isview && (
              <div
                className="PropertyAppraisalform_edit_details"
                onClick={() => SetIsview(false)}
              >
                <RiFileEditFill />
                <p>Edit</p>
              </div>
            )
          : null}
        <form id="PropertyAppraisalform" onSubmit={formik.handleSubmit}>
          <div className="PropertyAppraisal_formContainer">
            <p className="PropertyAppraisal_form_legend">Appraisal</p>
            <div className="PropertyAppraisalform_fields">
              <div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">Office</p>
                  <CustomTextField
                    name="office"
                    disabled={Isview}
                    placeholder="Enter office"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.office}
                    error={
                      formik.touched.office && Boolean(formik.errors.office)
                    }
                    helperText={formik.touched.office && formik.errors.office}
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Purpose of the Appraisal
                  </p>
                  <CustomTextField
                    name="purpose_of_appraisal"
                    disabled={Isview}
                    placeholder="Enter Purpose of the Appraisal"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.purpose_of_appraisal}
                    error={
                      formik.touched.purpose_of_appraisal &&
                      Boolean(formik.errors.purpose_of_appraisal)
                    }
                    helperText={
                      formik.touched.purpose_of_appraisal &&
                      formik.errors.purpose_of_appraisal
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Appraisal Report No
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="appraisal_report_no"
                    placeholder="Enter Appraisal Report No"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.appraisal_report_no}
                    error={
                      formik.touched.appraisal_report_no &&
                      Boolean(formik.errors.appraisal_report_no)
                    }
                    helperText={
                      formik.touched.appraisal_report_no &&
                      formik.errors.appraisal_report_no
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Land Value
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="landvalue"
                    placeholder="Enter Land Value"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.landvalue}
                    error={
                      formik.touched.landvalue &&
                      Boolean(formik.errors.landvalue)
                    }
                    helperText={
                      formik.touched.landvalue && formik.errors.landvalue
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Value of Constructions
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="value_of_construction"
                    placeholder="Enter Value of Constructions"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_of_construction}
                    error={
                      formik.touched.value_of_construction &&
                      Boolean(formik.errors.value_of_construction)
                    }
                    helperText={
                      formik.touched.value_of_construction &&
                      formik.errors.value_of_construction
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Total Value of The Good
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="total_value_of_the_goods"
                    placeholder="Enter Total Value of The Good"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.total_value_of_the_goods}
                    error={
                      formik.touched.total_value_of_the_goods &&
                      Boolean(formik.errors.total_value_of_the_goods)
                    }
                    helperText={
                      formik.touched.total_value_of_the_goods &&
                      formik.errors.total_value_of_the_goods
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Value In Letters
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="value_in_letter"
                    placeholder="Enter Value In Letters"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.value_in_letter}
                    error={
                      formik.touched.value_in_letter &&
                      Boolean(formik.errors.value_in_letter)
                    }
                    helperText={
                      formik.touched.value_in_letter &&
                      formik.errors.value_in_letter
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Estimated time for the sale of the property
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="estimated_time"
                    placeholder="Enter Estimated time for the sale of the property"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.estimated_time}
                    error={
                      formik.touched.estimated_time &&
                      Boolean(formik.errors.estimated_time)
                    }
                    helperText={
                      formik.touched.estimated_time &&
                      formik.errors.estimated_time
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Predominant use of the property
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="predominant_use_of_property"
                      placeholder="Enter Predominant use of the property"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.predominant_use_of_property}
                      error={
                        formik.touched.predominant_use_of_property &&
                        Boolean(formik.errors.predominant_use_of_property)
                      }
                      helperText={
                        formik.touched.predominant_use_of_property &&
                        formik.errors.predominant_use_of_property
                      }
                    />
                  </div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Policy Amount Not Less Than
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="policy_amount"
                      placeholder="Enter Policy Amount Not Less Than"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.policy_amount}
                      error={
                        formik.touched.policy_amount &&
                        Boolean(formik.errors.policy_amount)
                      }
                      helperText={
                        formik.touched.policy_amount &&
                        formik.errors.policy_amount
                      }
                    />
                  </div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Maximum scope of potential market
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="maximum_scope"
                      placeholder="Enter Maximum scope of potential market"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.maximum_scope}
                      error={
                        formik.touched.maximum_scope &&
                        Boolean(formik.errors.maximum_scope)
                      }
                      helperText={
                        formik.touched.maximum_scope &&
                        formik.errors.maximum_scope
                      }
                    />
                  </div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Specify
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="specify"
                      placeholder="Enter Specify"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.specify}
                      error={
                        formik.touched.specify && Boolean(formik.errors.specify)
                      }
                      helperText={
                        formik.touched.specify && formik.errors.specify
                      }
                    />
                  </div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      General Observations
                    </p>
                    <CustomTextArea
                      disabled={Isview}
                      maxRows={5}
                      name="general_observation"
                      placeholder="Enter General Observations"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.general_observation}
                      error={
                        formik.touched.general_observation &&
                        Boolean(formik.errors.general_observation)
                      }
                      helperText={
                        formik.touched.general_observation &&
                        formik.errors.general_observation
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="PropertyAppraisal_formContainer">
            <p className="PropertyAppraisal_form_legend">Company Details</p>
            <div className="PropertyAppraisalform_fields">
              <div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Name of the Expert
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="name_of_the_expert"
                    placeholder="Enter Name of the Expert"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_of_the_expert}
                    error={
                      formik.touched.name_of_the_expert &&
                      Boolean(formik.errors.name_of_the_expert)
                    }
                    helperText={
                      formik.touched.name_of_the_expert &&
                      formik.errors.name_of_the_expert
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Type of Professional
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="type_of_professional"
                    placeholder="Enter Type of Professional"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.type_of_professional}
                    error={
                      formik.touched.type_of_professional &&
                      Boolean(formik.errors.type_of_professional)
                    }
                    helperText={
                      formik.touched.type_of_professional &&
                      formik.errors.type_of_professional
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    SIACC Expert Code
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="siacc_expect_code"
                    placeholder="Enter SIACC Expert Code"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.siacc_expect_code}
                    error={
                      formik.touched.siacc_expect_code &&
                      Boolean(formik.errors.siacc_expect_code)
                    }
                    helperText={
                      formik.touched.siacc_expect_code &&
                      formik.errors.siacc_expect_code
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">Emp</p>
                  <CustomTextField
                    disabled={Isview}
                    name="emp"
                    placeholder="Enter Emp"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.emp}
                    error={formik.touched.emp && Boolean(formik.errors.emp)}
                    helperText={formik.touched.emp && formik.errors.emp}
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">License</p>
                  <CustomTextField
                    disabled={Isview}
                    name="license"
                    placeholder="Enter License"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.license}
                    error={
                      formik.touched.license && Boolean(formik.errors.license)
                    }
                    helperText={formik.touched.license && formik.errors.license}
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Telephone numbers for contact
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="telephone_number"
                    placeholder="Enter Telephone numbers for contact"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.telephone_number}
                    error={
                      formik.touched.telephone_number &&
                      Boolean(formik.errors.telephone_number)
                    }
                    helperText={
                      formik.touched.telephone_number &&
                      formik.errors.telephone_number
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Email / WEB Address
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="email_or_web"
                    placeholder="Enter Email / WEB Address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email_or_web}
                    error={
                      formik.touched.email_or_web &&
                      Boolean(formik.errors.email_or_web)
                    }
                    helperText={
                      formik.touched.email_or_web && formik.errors.email_or_web
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Company name
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="company_name"
                      placeholder="Enter Company name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.company_name}
                      error={
                        formik.touched.company_name &&
                        Boolean(formik.errors.company_name)
                      }
                      helperText={
                        formik.touched.company_name &&
                        formik.errors.company_name
                      }
                    />
                  </div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Code Company
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="company_code"
                      placeholder="Enter Code Company"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.company_code}
                      error={
                        formik.touched.company_code &&
                        Boolean(formik.errors.company_code)
                      }
                      helperText={
                        formik.touched.company_code &&
                        formik.errors.company_code
                      }
                    />
                  </div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Inspection date
                    </p>
                    <CustomDatePicker
                      disabled={Isview}
                      name="inspected_date"
                      placeholder="Enter Inspection date"
                      onChange={(date) => {
                        formik.setFieldValue("inspected_date", date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.inspected_date, "MM/DD/YYYY")}
                      error={
                        formik.touched.inspected_date &&
                        Boolean(formik.errors.inspected_date)
                      }
                      helperText={
                        formik.touched.inspected_date &&
                        formik.errors.inspected_date
                      }
                    />
                  </div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Report date
                    </p>
                    <CustomDatePicker
                      disabled={Isview}
                      name="reported_date"
                      placeholder="Enter Report date"
                      onChange={(date) => {
                        formik.setFieldValue("reported_date", date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.reported_date, "MM/DD/YYYY")}
                      error={
                        formik.touched.reported_date &&
                        Boolean(formik.errors.reported_date)
                      }
                      helperText={
                        formik.touched.reported_date &&
                        formik.errors.reported_date
                      }
                    />
                  </div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Garbage Collection
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="garbage_collection"
                      placeholder="Enter Garbage Collection"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.garbage_collection}
                      error={
                        formik.touched.garbage_collection &&
                        Boolean(formik.errors.garbage_collection)
                      }
                      helperText={
                        formik.touched.garbage_collection &&
                        formik.errors.garbage_collection
                      }
                    />
                  </div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Public / Communal Buildings
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="public_or_communal_building"
                      placeholder="Enter Public / Communal Buildings"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.public_or_communal_building}
                      error={
                        formik.touched.public_or_communal_building &&
                        Boolean(formik.errors.public_or_communal_building)
                      }
                      helperText={
                        formik.touched.public_or_communal_building &&
                        formik.errors.public_or_communal_building
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="PropertyAppraisal_formContainer">
            <p className="PropertyAppraisal_form_legend">
              Registration & Area of the Estate
            </p>
            <div className="PropertyAppraisalform_fields">
              <div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Registration of the farm
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="registartion_of_farm"
                    placeholder="Enter Registration of the farm"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.registartion_of_farm}
                    error={
                      formik.touched.registartion_of_farm &&
                      Boolean(formik.errors.registartion_of_farm)
                    }
                    helperText={
                      formik.touched.registartion_of_farm &&
                      formik.errors.registartion_of_farm
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Cadastre plan No.
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="cadastre_plane_no"
                    placeholder="Enter Cadastre plan No."
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cadastre_plane_no}
                    error={
                      formik.touched.cadastre_plane_no &&
                      Boolean(formik.errors.cadastre_plane_no)
                    }
                    helperText={
                      formik.touched.cadastre_plane_no &&
                      formik.errors.cadastre_plane_no
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Property Identifier
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="property_identity"
                    placeholder="Enter Property Identifier"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.property_identity}
                    error={
                      formik.touched.property_identity &&
                      Boolean(formik.errors.property_identity)
                    }
                    helperText={
                      formik.touched.property_identity &&
                      formik.errors.property_identity
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Percentage
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="percentage"
                    placeholder="Enter Percentage"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.percentage}
                    error={
                      formik.touched.percentage &&
                      Boolean(formik.errors.percentage)
                    }
                    helperText={
                      formik.touched.percentage && formik.errors.percentage
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      According to the plane
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="according_to_the_plane"
                      placeholder="Enter According to the plane"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.according_to_the_plane}
                      error={
                        formik.touched.according_to_the_plane &&
                        Boolean(formik.errors.according_to_the_plane)
                      }
                      helperText={
                        formik.touched.according_to_the_plane &&
                        formik.errors.according_to_the_plane
                      }
                    />
                  </div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Measurement
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="measurement"
                      placeholder="Enter Measurement"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.measurement}
                      error={
                        formik.touched.measurement &&
                        Boolean(formik.errors.measurement)
                      }
                      helperText={
                        formik.touched.measurement && formik.errors.measurement
                      }
                    />
                  </div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Too much
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="too_much"
                      placeholder="Enter Too much"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.too_much}
                      error={
                        formik.touched.too_much &&
                        Boolean(formik.errors.too_much)
                      }
                      helperText={
                        formik.touched.too_much && formik.errors.too_much
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="PropertyAppraisal_formContainer">
            <p className="PropertyAppraisal_form_legend">Land Value</p>
            <div className="PropertyAppraisalform_fields">
              <div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Conclusive value
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="conclusive_value"
                    placeholder="Enter Conclusive value"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.conclusive_value}
                    error={
                      formik.touched.conclusive_value &&
                      Boolean(formik.errors.conclusive_value)
                    }
                    helperText={
                      formik.touched.conclusive_value &&
                      formik.errors.conclusive_value
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Sections factor
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="sections_factor"
                    placeholder="Enter Sections factor"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sections_factor}
                    error={
                      formik.touched.sections_factor &&
                      Boolean(formik.errors.sections_factor)
                    }
                    helperText={
                      formik.touched.sections_factor &&
                      formik.errors.sections_factor
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Final Unit Value
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="final_unit_value"
                    placeholder="Enter Final Unit Value"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.final_unit_value}
                    error={
                      formik.touched.final_unit_value &&
                      Boolean(formik.errors.final_unit_value)
                    }
                    helperText={
                      formik.touched.final_unit_value &&
                      formik.errors.final_unit_value
                    }
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">
                    Total Value of The Land
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="total_value_of_the_land"
                    placeholder="Enter Total Value of The Land"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.total_value_of_the_land}
                    error={
                      formik.touched.total_value_of_the_land &&
                      Boolean(formik.errors.total_value_of_the_land)
                    }
                    helperText={
                      formik.touched.total_value_of_the_land &&
                      formik.errors.total_value_of_the_land
                    }
                  />
                </div>
              </div>
              <div>
                <div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Adjusted value
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="adjusted_value"
                      placeholder="Enter Adjusted value"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.adjusted_value}
                      error={
                        formik.touched.adjusted_value &&
                        Boolean(formik.errors.adjusted_value)
                      }
                      helperText={
                        formik.touched.adjusted_value &&
                        formik.errors.adjusted_value
                      }
                    />
                  </div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">Area</p>
                    <CustomTextField
                      disabled={Isview}
                      name="area"
                      placeholder="Enter Area"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.area}
                      error={formik.touched.area && Boolean(formik.errors.area)}
                      helperText={formik.touched.area && formik.errors.area}
                    />
                  </div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Unit Value Land Improvements
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="unit_value_land_improvement"
                      placeholder="Enter Unit Value Land Improvements"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.unit_value_land_improvement}
                      error={
                        formik.touched.unit_value_land_improvement &&
                        Boolean(formik.errors.unit_value_land_improvement)
                      }
                      helperText={
                        formik.touched.unit_value_land_improvement &&
                        formik.errors.unit_value_land_improvement
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="PropertyAppraisal_formContainer">
            <p className="PropertyAppraisal_form_legend">Current Boundaries</p>
            <div className="PropertyAppraisalform_fields">
              <div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">North</p>
                  <CustomTextField
                    disabled={Isview}
                    name="north"
                    placeholder="Enter North"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.north}
                    error={formik.touched.north && Boolean(formik.errors.north)}
                    helperText={formik.touched.north && formik.errors.north}
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">South</p>
                  <CustomTextField
                    disabled={Isview}
                    name="south"
                    placeholder="Enter South"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.south}
                    error={formik.touched.south && Boolean(formik.errors.south)}
                    helperText={formik.touched.south && formik.errors.south}
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">East</p>
                  <CustomTextField
                    disabled={Isview}
                    name="east"
                    placeholder="Enter East"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.east}
                    error={formik.touched.east && Boolean(formik.errors.east)}
                    helperText={formik.touched.east && formik.errors.east}
                  />
                </div>
                <div className="PropertyAppraisalform_field_container">
                  <p className="PropertyAppraisalform_fields_labels">West</p>
                  <CustomTextField
                    disabled={Isview}
                    name="west"
                    placeholder="Enter West"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.west}
                    error={formik.touched.west && Boolean(formik.errors.west)}
                    helperText={formik.touched.west && formik.errors.west}
                  />
                </div>
              </div>
              <div>
                <div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Projected Coordinates
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="project_coordinates"
                      placeholder="Enter Projected Coordinates"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.project_coordinates}
                      error={
                        formik.touched.project_coordinates &&
                        Boolean(formik.errors.project_coordinates)
                      }
                      helperText={
                        formik.touched.project_coordinates &&
                        formik.errors.project_coordinates
                      }
                    />
                  </div>
                  <div className="PropertyAppraisalform_field_container">
                    <p className="PropertyAppraisalform_fields_labels">
                      Vertex
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="vertex"
                      placeholder="Enter Vertex"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.vertex}
                      error={
                        formik.touched.vertex && Boolean(formik.errors.vertex)
                      }
                      helperText={formik.touched.vertex && formik.errors.vertex}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!Isview && (
            <div className="PropertyAppraisalform_action">
              <FormButton
                id="PropertyAppraisalformCreateCancel"
                variant="outlined"
                loading={false}
                text="Cancel"
                onClick={() => {
                  navigate(
                    `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`
                  );
                }}
              />
              <FormButton
                id="PropertyAppraisalformCreateSubmit"
                type="submit"
                form="PropertyAppraisalform"
                variant="contained"
                loading={loading}
                text="Save & Continue"
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default PropertyAppraisalForm;
