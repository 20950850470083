import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getPropertyEnquiries, getPropertyEnquiriesFilter } from '../../../../../../api/apiServices/customerPortalApis'
import ResponseHandler from '../../../../../../services/ResponseHandler'
import { Pagination, styled } from '@mui/material'
import CustomChip from '../../../../../../components/customChip/CustomChip'
import CustomTableWithOutPagination from '../../../../../../components/CustomTableWithOutPagination/CustomTableWithOutPagination'
import { useNavigate } from 'react-router-dom'
import { LocalStorageServices } from '../../../../../../services/localStorageServices'
import PropertyEnquireyFilter from './property_enquirey_filter/PropertyEnquireyFilter'
import { setRefresh } from '../../../../../../store/usersStore'

function PropertyEnquirey() {
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const [PageNumber,SetPageNumber]=useState(1)
    const [PageCount,SetPageCount]=useState(0)
    const [PropertyEnquireyList,SetPropertyEnquireyList]=useState([])
    const [isFiltered,setFiltered]=useState(false)
    const [values,setValues]=useState({
      "property_id":"",
      "name":"",
      "email":"",
      "contactNumber":"",
      "start_date":"",
      "end_date":""
    })
    const hasAnyValue = (obj) => {
      return Object.values(obj).some(value => value !== "");
    };
    useEffect(()=>{
      if(hasAnyValue(values)){
        dispatch(getPropertyEnquiriesFilter(PageNumber,10,values)).then((response)=>{
          const {enquiries, count} = response
          SetPageCount(Math.ceil(count/10))
          SetPropertyEnquireyList(enquiries)
         }).catch((error)=>{
           ResponseHandler(error)
         })
      }else{
        dispatch(getPropertyEnquiries(PageNumber,10)).then((response)=>{
          const {enquiries, count} = response
          SetPageCount(Math.ceil(count/10))
          SetPropertyEnquireyList(enquiries)
         }).catch((error)=>{
           ResponseHandler(error)
         })
      }
      dispatch(setRefresh())
    },[PageNumber,isFiltered])
    
    const CustomPagination = styled(Pagination)(({ theme }) => ({
      '& .MuiPaginationItem-root': {
        backgroundColor:"#EDEFF6",
        color: '#4A60A1', // Color for the pagination items
        '&.Mui-selected': {
          backgroundColor: '#1976d2', // Background color for selected item
          color: 'white', // Text color for selected item
        },
        '&:hover': {
          backgroundColor: '#fff', // Background color for selected item
          color: '#000', // Background color on hover
        },
      
      },
    }));
    const Columns = [
      {
        title: "#",
        dataIndex: "Key",
        key: "key",
      },
      {
        title: "Property ID",
        dataIndex: "property_id",
        key: "property_id",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Contact Number",
        dataIndex: "contact_number",
        key: "contact_number",
      },
      {
          title: "Message",
          dataIndex: "message",
          key: "message",
      },
      {
        title: "Enquirey Date",
        dataIndex: "enquiry_date_time",
        key: "enquiry_date_time",
    },
      {
        title: "Actions",
        dataIndex: "Actions",
        key: "Actions",
        action: (row) => (
          <div className="user_action_chipset">
            <CustomChip
              text="View "
              bgColor="#F8893C"
              color="white"
              onClick={()=>{
                navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${row.property_id}/update_properties?tk=0&view_edit=true`)
              }}
            />
          </div>
        ),
      },
    ]
    const datas = () => {
      const result =
      PropertyEnquireyList &&
      PropertyEnquireyList.map((item, index) => {
          return {
            Key:((PageNumber*10)-(10-(index + 1))) ,
            property_id: item.cpe_vehicle_property_id,
            name: item.cpe_name,
            email: item.cpe_email_id,
            contact_number: item.cpe_contact_number,
            message: item.cpe_message,
            enquiry_date_time: item.cpe_enquiry_date_time.split('T')[0],
          };
        });
      return result;
    };
     // ------------------------translation code--------------------------------------
     const translateText = async (text) => {
      const currentLang = localStorage.getItem("currentLanguage") || "en";
      if (currentLang === "es") {
        try {
          const response = await fetch(
            `https://api.mymemory.translated.net/get?q=${encodeURIComponent(text)}&langpair=es|en`
          );
          const data = await response.json();
          if (data.responseStatus === 200) {
            return data.responseData.translatedText;
          } else {
            return text;
          }
        } catch (error) {
          console.error("Error translating text:", error);
          return text; // Fallback to original text if translation fails
        }
      }
      return text;
    };
    
    const onchangeValues = async (event) => {
      const { value, name } = event.target;
      const currentLang = localStorage.getItem("currentLanguage") || "en";
      const isSpanish = currentLang === "es";
    
      if (isSpanish) {
        const translatedValue = await translateText(value);
        setValues({ ...values, [name]: translatedValue });
        console.log(translatedValue,"translatedValue");
      } else {
        setValues({ ...values, [name]: value });
      }
    };
    const onchangeDateRange=(Dates)=>{
      setValues({...values,["start_date"]:Dates[0],["end_date"]:Dates[1]})
    }
    const onResetValues=()=>{
      setValues({
        "property_id":"",
        "name":"",
        "email":"",
        "contactNumber":"",
        "start_date":"",
        "end_date":""
      })
     SetPageNumber(1)
     setFiltered(!isFiltered)
    }
  
    const onSearch=()=>{
      SetPageNumber(1)
      setFiltered(!isFiltered)
    }
    return (
      <div>
        <div style={{margin:'30px 0px'}}>
           <PropertyEnquireyFilter values={values} onChange={onchangeValues} onchangeDateRange={onchangeDateRange} onReset={onResetValues} onSubmit={onSearch}/>
        </div>
          <CustomTableWithOutPagination columns={Columns} rows={datas()}/>
          <div style={{display:'flex',justifyContent:'center',alignItems:'center',margin:'30px 0px'}}>
          <button
            className="table_pagination_button"
            style={{ color: PageNumber === 1 ? "#005EB8" : "" }}
            onClick={() => {
              SetPageNumber(1);
              // Save the current page for this specific table to localStorage
              // localStorage.setItem(`currentPage_${tableId}`, 1);
            }}
          >
            First
          </button>
        <CustomPagination count={PageCount} page={PageNumber}  variant="outlined" shape="rounded"  onChange={(e,page_number)=>{
        SetPageNumber(page_number)
      }}/>
       <button
          className="table_pagination_button"
          style={{
            color: PageNumber === PageCount ? "#005EB8" : "",
          }}
          onClick={() => {
            SetPageNumber(PageCount);
          }}
        >
          Last
        </button>
      </div>
      </div>
    )

}

export default PropertyEnquirey