import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import "./PropertyTypeForm.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormButton from "../../../../../../components/formButton/FormButton";
import { FormHelperText } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  createPropertyType,
  updatePropertyType,
} from "../../../../../../api/apiServices/propertyTypeApi";
import { FaUpload } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";
import ResponseHandler from "../../../../../../services/ResponseHandler";
function PropertyTypeForm({ EditDetails, onclose }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(0);
  useEffect(()=>{
    if(EditDetails?.attachment){
      setSelectedImage(EditDetails.attachment)
    }
},[EditDetails])
  const formik = useFormik({
    initialValues: {
      type: "",
      status: "active",
      attachment:EditDetails ? EditDetails.attachment :  null,
      file: null,
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Property type is required"),
      status: Yup.string().required("Status is required"),
      file: Yup.mixed().required("Attachment is required")
      // .test(
      //   "fileFormat",
      //   "Unsupported File Format",
      //   (value) =>
      //     !value || (value && ["image/jpg", "image/jpeg", "image/png"].includes(value.type))
      // ),
    }),
    onSubmit: (values) => {
 
      setLoading(true);
      const formData = new FormData();
      formData.append("type", values.type);
      formData.append("status", values.status);
      if (isImageSelected == 1) {
        formData.append("propertyTypeAttachment", selectedImage);
      } else {
        if (
          selectedImage?.startsWith("http") ||
          selectedImage?.startsWith("https")
        ) {

          setSelectedImage(null);
        } else {
          formData.append("propertyTypeAttachment", selectedImage);
        }
      }
      

      if (EditDetails) {

        dispatch(updatePropertyType(EditDetails.id, formData))
          .then((response) => {
            ResponseHandler(response);
            onclose(false);
          })
          .catch((error) => {
            console.error(
              "Update Error:",
              error.response ? error.response.data : error.message
            );
            ResponseHandler(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        dispatch(createPropertyType(formData))
          .then((response) => {
            ResponseHandler(response);
            onclose(false);
          })
          .catch((error) => {
            console.error(
              "Create Error:",
              error.response ? error.response.data : error.message
            );
            ResponseHandler(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
   
  });
  useEffect(() => {
    if (EditDetails) {
      formik.setValues({
        ...formik.values,
        type: EditDetails ? EditDetails.types : "",
        status: EditDetails ? EditDetails.status : "active",
      });
    }
  }, [EditDetails]);

  return (
    <div className="property_Form">
      <form onSubmit={formik.handleSubmit}>
        <p className="property_form_label">Property type</p>
        <TextField
          name="type"
          autoComplete="off"
          size="small"
          placeholder="Enter property type"
          className="property_type_form_input"
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.type}
          error={formik.touched.type && Boolean(formik.errors.type)}
          helperText={formik.touched.type && formik.errors.type}
          FormHelperTextProps={{
            style: { marginLeft: 0 },
          }}
        />
        <p className="property_form_label">Status</p>
        <Select
          className="property_type_form_input"
          name="status"
          size="small"
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.status}
          error={formik.touched.status && Boolean(formik.errors.status)}
        >
          <MenuItem value="active" style={{ fontSize: "14px" }}>
            Active
          </MenuItem>
          <MenuItem value="inactive" style={{ fontSize: "14px" }}>
            InActive
          </MenuItem>
        </Select>
        <FormHelperText
          {...formik.getFieldProps("status")}
          style={{ marginLeft: 0, color: "#e74c3c" }}
        >
          {formik.touched.status ? formik.errors.status : ""}
        </FormHelperText>
        {selectedImage && (
        <div>
          {/* <img
            alt="not found"
            width={"250px"}
            src={selectedImage.toString().startsWith("http://") ||
            selectedImage.toString().startsWith("https://")
              ? selectedImage
              : URL.createObjectURL(selectedImage)}
          />
          <br />
          <button onClick={() => setSelectedImage(null)}>Remove</button> */}
            <div className="img-div-attachment">
                      <img
                       alt="not found"
                        
                        src={
                          selectedImage.toString().startsWith("http://") ||
                          selectedImage.toString().startsWith("https://")
                            ? selectedImage
                            : URL.createObjectURL(selectedImage)
                        }
                    
                        className="image_attachment"
                      />
                        <div
                          className="attachment_close_icon_type"
                          onClick={() => {
                            setSelectedImage(null);
                            setIsImageSelected(0); // Reset the state to 0 when the image is removed
                          }}
                        >
                          <CancelIcon className="attachemnt_cancelIcon" />
                        </div>
                    </div>
            </div>
          )}
        
    
        <>
            <div
              className="file-upload-container"
              style={{ textAlign: "center", marginTop: "20px" }}
            >
              {!selectedImage && (
                <div>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    name="myImage"
                    onChange={(event) => {
                      const file = event.target.files[0];
                      setSelectedImage(file);
                      formik.setFieldValue("file", file);
                      setIsImageSelected(1);
                    }}
                    style={{ display: "none" }}
                    id="file-input"
                  />
                  <label
                    htmlFor="file-input"
                    style={{ cursor: "pointer", display: "inline-block" }}
                  >
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",
                        backgroundColor: "#f0f0f0",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        margin: "0 auto",
                        position: "relative",
                      }}
                    >
                      <FaUpload style={{ fontSize: "24px", color: "#666" }} />
                    </div>
                  </label>
                </div>
              )}
            </div>
            {!selectedImage && (
              <div className="file_upload_text">Upload Files</div>
            )}
            {!EditDetails?(
            <FormHelperText
            style={{ marginLeft: 0, color: "#e74c3c", fontSize: 13,display:"flex",justifyContent:"center" }}
          >
            {formik.touched.file && formik.errors.file}
          </FormHelperText>
           ):(null)}
          </>
         
        <div className="property_type_form_action">
          <FormButton
            id="propertyTypeForm"
            type="submit"
            variant="contained"
            loading={loading}
            text={EditDetails ? "Update" : "Add"}
          />
          <FormButton
            id="propertyTypeCancel"
            variant="outlined"
            loading={false}
            text="Cancel"
            onClick={() => onclose(false)}
          />
        </div>
      </form>
    </div>
  );
}

export default PropertyTypeForm;
