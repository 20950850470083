import React, { useEffect, useState } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import "./SimpleTable.css";
import { Button, Box } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { useTranslation } from "react-i18next";
import { Table } from "@mui/material";
import {useDispatch, useSelector } from "react-redux";
import { setRefresh } from "../../store/usersStore";
function SimpleTable({ columns, rows ,isPaginationNotNeeded }) {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowData, setRowData] = useState([]);
  useEffect(() => {
    if (rows) {
      slicedTableData(currentPage);
    }
  }, [rows]);
  const dispatch=useDispatch()
  useEffect(()=>{
    setTimeout(()=>{
      dispatch(setRefresh())
    },50)
  
  },[currentPage])
  
  function slicedTableData(currentPageNumber) {
    const firstPageIndex = (currentPageNumber - 1) * 8;
    const lastPageIndex = firstPageIndex + 8;
    const slicedData = rows.slice(firstPageIndex, lastPageIndex);
    setRowData([...slicedData]);
  }
  const handleChange = (_, value) => {
    slicedTableData(value);
    setCurrentPage(value);
  };
  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#E9F2FF",
      color: "black",
      borderBottom: "none",
      fontWeight: "600",
      fontFamily: "Inter",
      fontSize: "15px",
      padding: "15px 12px",
    },
    [`&.${tableCellClasses.body}`]: {
      maxWidth: "500px",
      overflowWrap: "break-word",
      fontSize: "15px",
      borderBottom: "none",
      fontFamily: "Inter",
      padding: "12px 15px",
      overflow: "auto",
      whiteSpace: "normal",
      wordWrap: "break-word",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
      "& > *:first-of-type": {
        backgroundColor: "white",
      },
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#E9F2FF",
      "& > *:first-of-type": {
        backgroundColor: "#E9F2FF",
      },
    },
  }));

  // responsive table start
  const IsCollapsed = useSelector((state) => state.mainStore.IsCollapsed);
  const [TableResp, setTableResp] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    if (window.innerWidth < 999) {
      setTableResp({});
    } else {
      setTableResp({
        maxWidth: IsCollapsed ? "calc(100vw-420px)" : "calc(100vw)",
      });
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth, IsCollapsed]);


  const CustomPagination = styled(Pagination)(({ theme }) => ({
    "& .MuiPaginationItem-root": {
      backgroundColor: "#EDEFF6",
      color: "#4A60A1",
      "&.Mui-selected": {
        backgroundColor: "#003087",
        color: "white",
      },
      "&:hover": {
        backgroundColor: "#003087",
        color: "white",
      },
    },
  }));
  const renderCustomPagination = () => {
    const totalPages = Math.ceil(rows.length / 8);
    const pageRange = 3;
    let startPage = Math.max(1, currentPage - Math.floor(pageRange / 2));
    const endPage = Math.min(totalPages, startPage + pageRange - 1);
    if (endPage - startPage + 1 < pageRange) {
      startPage = Math.max(1, endPage - pageRange + 1);
    }

    const pages = [];

    if (currentPage > 1) {
      pages.push(
        <Button
          key="first"
          variant="outlined"
          onClick={() => handleChange(null, 1)}
          sx={{
            minWidth: "30px",
            padding: "3px",
            margin: "0 5px",
            backgroundColor: "#EDEFF6",
            color: "#4A60A1",
            "&:hover": {
              backgroundColor: "#EDEFF6",
              color: "#4A60A1",
            },
          }}
        >
          {"<<"}
        </Button>
      );

      pages.push(
        <Button
          key="prev"
          variant="outlined"
          onClick={() => handleChange(null, currentPage - 1)}
          sx={{
            minWidth: "30px",
            padding: "3px",
            margin: "0 5px",
            backgroundColor: "#EDEFF6",
            color: "#4A60A1",
            "&:hover": {
              backgroundColor: "#EDEFF6",
              color: "#4A60A1",
            },
          }}
        >
          {"<"}
        </Button>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Button
          key={i}
          variant={currentPage === i ? "contained" : "outlined"}
          onClick={() => handleChange(null, i)}
          sx={{
            minWidth: "30px",
            padding: "3px",
            margin: "0 5px",
            backgroundColor: currentPage === i ? "#005EB8" : "#EDEFF6",
            color: currentPage === i ? "white" : "#4A60A1",
            "&:hover": {
              backgroundColor: currentPage === i ? "#005EB8" : "#EDEFF6",
              color: currentPage === i ? "white" : "#4A60A1",
            },
          }}
        >
          {i}
        </Button>
      );
    }

    if (currentPage < totalPages) {
      pages.push(
        <Button
          key="next"
          variant="outlined"
          onClick={() => handleChange(null, currentPage + 1)}
          sx={{
            minWidth: "30px",
            padding: "3px",
            margin: "0 5px",
            backgroundColor: "#EDEFF6",
            color: "#4A60A1",
            "&:hover": {
              backgroundColor: "#EDEFF6",
              color: "#4A60A1",
            },
          }}
        >
          {">"}
        </Button>
      );

      pages.push(
        <Button
        key="last"
        variant="outlined"
        onClick={() => handleChange(null, totalPages)}
        sx={{
          minWidth: "30px",
            padding: "3px",
          margin: '0 5px',
          backgroundColor: '#EDEFF6',
          color: '#4A60A1',
          '&:hover': {
            backgroundColor: '#EDEFF6',
            color: '#4A60A1',
          },
        }}
      >
        {'>>'}
      </Button>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px 0",
        }}
      >
        {pages}
      </Box>
    );
  };


  return (
    <div style={TableResp}>
      <div className="customTable">
        <TableContainer className="simpleTable_mainContainer">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      align={column.dataIndex === "Actions" ? "right" : "left"}
                      style={{
                        width: column.dataIndex === "key" ? "20px" : "",
                        height: column.dataIndex === "key" ? "40px" : "40px",
                        paddingRight:
                          column.dataIndex === "Actions" ? "45px" : "",
                      }}
                    >
                      {t(column.title)}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData &&
                rowData.map((row) => (
                  <StyledTableRow key={row.key}>
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.key}
                        align={
                          column.dataIndex === "Actions" ? "center" : "left"
                        }
                        style={{
                          width: column.dataIndex === "key" ? "20px" : "",
                        
                        }}
                      >
                        {column.action ? (
                          column.action(row)
                        ) : (
                          <div
                            style={{
                              width:
                                column.dataIndex === "taskDescription"
                                  ? "400px"
                                  : "auto",
                            }}
                            className={
                              column.key !== "key" ? "textcontainer" : ""
                            }
                          >
                            <p>
                              {row[column.dataIndex]
                                ? row[column.dataIndex].length >= 40
                                  ? row[column.dataIndex].slice(0, 40) + "..."
                                  : row[column.dataIndex]
                                : " - "}
                            </p>
                          </div>
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          {rowData && rowData.length === 0 && (
            <div className="noData_Container">
              <p className="noData_text">{t("No Data")}</p>
            </div>
          )}
        </TableContainer>
      </div>
      <div>
        {rowData && rowData.length > 0 && (
          <div className="table_pagination_container">
            {renderCustomPagination()}
          </div>
        )}
      </div>
       
       
     
    </div>
  );
}

export default SimpleTable;
