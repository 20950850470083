import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUsersSpecificDetails } from '../../../../../api/apiServices/messageApis'
import ResponseHandler from '../../../../../services/ResponseHandler'
import { useLocation } from 'react-router-dom';
import dummy_avatar from '../../../../../assets/dummy_avatar.png'
import './profieDetails.css'
import { Avatar, Badge, Chip, styled } from '@mui/material'
function ProfileDetails() {
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const user_id = queryParams.get("user");
    const onlineUsers = useSelector((state) => state.messageStore.onlineUsers)
    const dispatch = useDispatch()
    const [userDetails, setUserDetails] = useState(null)
    useEffect(() => {
        if (user_id) {
            dispatch(getUsersSpecificDetails(user_id)).then((response) => {
                setUserDetails(response)
            }).catch((error) => {
                ResponseHandler(error)
            })
        }
    }, [user_id, onlineUsers])
    const CustomBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-dot': {
            top: 8,
            left: 28,
            width: 15,  // Change the width of the dot
            height: 15, // Change the height of the dot
            borderRadius: '50%', // Ensure the dot is circular
        },
    }));
    function OnlineUsersId(id) {
        return onlineUsers.some(item => item.userId === id);
    }
    return (
        <div className='message_user_profile_main_container'>
            <div className='message_user_sub_container'>
                {userDetails?.ud_profile_picture ?
                    <CustomBadge variant="dot" color="success" invisible={!OnlineUsersId(userDetails?.up_id)}>
                        <Avatar src={userDetails?.ud_profile_picture?userDetails?.ud_profile_picture:dummy_avatar} sx={{ width: 50, height: 50 }} />
                    </CustomBadge>
                    :
                    <CustomBadge variant="dot" color="success" invisible={!OnlineUsersId(userDetails?.up_id)}>
                        <Avatar
                            sx={{ width: 50, height: 50 }}>{userDetails?.ud_name.charAt(0)}</Avatar>
                    </CustomBadge>
                }
                <div>
                    <p className='message_user_name'>{userDetails?.ud_name}</p>
                    <p className='message_user_email'>{userDetails?.up_email}</p>
                </div>
            </div>
        </div>
    )
}

export default ProfileDetails