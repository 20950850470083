import { createSlice } from "@reduxjs/toolkit";

export const vehicleStoreSlice = createSlice({
  name: "vehicleStore",
  initialState: {
    vehicleTableLoading: false,
    selectedTab: 0,
    defaultChecked: [
      "plate_or_board",
      "owner_name",
      "issue_date",
      "brand",
      "price",
      "type",
      "status",
      "sale_status"
    ],
    WholeVehicles: [],
    Vehicles: [],
    VehicleTaskTableLoading: false,
    WholeVehicleTasks: [],
    VehicleTasks: [],
    FilterDropDownList:{
      type:[],
      bank:[],
      status:[]
    },
    VehicleFilterData:{
      type: null,
      status: null,
      bank: null,
      issue_start_date: null,
      issue_end_date: null,
      sale_status:null
    },
    VehicleTaskFilterData:{
      taskName:"",
      assign_to:null,
      start_date:null,
      status:null,
      end_date:null,
      priority:null
    },
    VehicleTaskFilterDropDownList:{
      assign_to:[],
      status:[],
      priority:[]
    },
  
  },
  reducers: {
    updateDefaultChecked: (state, action) => {
      const { isChecked, name } = action.payload;
      if (isChecked) {
        state.defaultChecked = [...state.defaultChecked, name];
      } else {
        let filterUncheckedData = state.defaultChecked;
        filterUncheckedData = filterUncheckedData.filter(
          (item) => item !== name
        );
        state.defaultChecked = filterUncheckedData;
      }
    },
    updateSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },

    setAllVehicles: (state, actions) => {
      state.Vehicles = actions.payload;
      state.WholeVehicles = actions.payload;
    },
    updateVehicleTableLoading: (state, actions) => {
      state.vehicleTableLoading = actions.payload;
    },
    SetAllVehicleTasks: (state, actions) => {
      state.VehicleTasks = actions.payload;
      state.WholeVehicleTasks = actions.payload;
    },

    updateFilterDropdownData:(state)=>{
      const datas=JSON.parse(JSON.stringify(state.WholeVehicles))
      const UniqueType=[...new Set(datas.map(item => item.vtl_type))].map(type => ({label: type,value: type}))
      const UniqueBank=[...new Set(datas.filter((item)=>item.vbd_owner_name!==null).map(item => item.vbd_owner_name))].map(type => ({label: type,value: type}))
      const UniqueStatus=[...new Set(datas.map(item => item.sl_status))].map(type => ({label: type,value: type}))
      state.FilterDropDownList={ type:UniqueType,bank:UniqueBank,status:UniqueStatus}
    },
    updateTaskFilterDropdown:(state)=>{
      const stringifyData=JSON.stringify(state.WholeVehicleTasks)
      const parseData=JSON.parse(stringifyData)
      const UniqueAssignTo=[...new Set(parseData.filter(item => item.up_email !== null).map(item => item.up_email))].map(type => ({label: type,value: type}))
      const UniqueStatus=[...new Set(parseData.filter(item => item.sl_status !== null).map(item => item.sl_status))].map(type => ({label: type,value: type}))
      const UniquePriority=[...new Set(parseData.filter(item => item.pl_priority !== null).map(item => item.pl_priority))].map(type => ({label: type,value: type}))
      state.VehicleTaskFilterDropDownList={assign_to:UniqueAssignTo,status:UniqueStatus,priority:UniquePriority}
    },
    filterVehicleData:(state,actions)=>{
      const WholeVehicle = JSON.stringify(state.WholeVehicles);
      const FilterData= JSON.stringify(state.VehicleFilterData);
      const ParseWholeVehicle=JSON.parse(WholeVehicle)
      const ParseFilterData=JSON.parse(FilterData)
      let FilterdData=[]
      if(ParseFilterData.type){
        FilterdData= ParseWholeVehicle.filter((item)=>item.vtl_type===ParseFilterData.type)
      }else{
        FilterdData= ParseWholeVehicle
      }
      
      if(ParseFilterData.bank){
        FilterdData = FilterdData.filter((item)=>item.vbd_owner_name==ParseFilterData.bank)
      }
      
      if(ParseFilterData.status){
        FilterdData = FilterdData.filter((item)=>item.sl_status===ParseFilterData.status)
      }
     
      if(ParseFilterData.issue_start_date && ParseFilterData.issue_end_date){
        FilterdData = FilterdData.filter((item)=>new Date(item.vbd_issue_date) >= new Date(ParseFilterData.issue_start_date) && new Date(item.vbd_issue_date) <= new Date(ParseFilterData.issue_end_date))
      }
      if(ParseFilterData.sale_status!==null && ParseFilterData.sale_status!=='Incompleted'){
        FilterdData = FilterdData.filter((item)=>item.vs_sale_status===ParseFilterData.sale_status)
      }
      if(ParseFilterData.sale_status==='Incompleted'){
        FilterdData = FilterdData.filter((item)=>item.vs_sale_status == null)
      }
      state.Vehicles=FilterdData
    },
    setVehicleFilterDatas:(state,actions)=>{
      state.VehicleFilterData=actions.payload
    },
    setVehicleTaskFilterDatas:(state,actions)=>{
      state.VehicleTaskFilterData=actions.payload
      console.log(state.VehicleTaskFilterData,"state.VehicleTaskFilterData");
    },
    searchFilterVehicleTaskData:(state,action)=>{
      const WholeVehicleTask = JSON.stringify(state.WholeVehicleTasks);
      const FilterTaskData= JSON.stringify(state.VehicleTaskFilterData);
      const ParseWholeVehicleTasks=JSON.parse(WholeVehicleTask)
      const ParseFilterVehicleTaskData=JSON.parse(FilterTaskData)
      let filterData=[]
      if(ParseFilterVehicleTaskData.taskName.trim()){
        filterData = ParseWholeVehicleTasks.filter((item) => {
          const vtTaskName = item.vt_task_name || '';
          return vtTaskName.toLowerCase().includes(ParseFilterVehicleTaskData.taskName.trim().toLowerCase());
        });
      }else{
        filterData= ParseWholeVehicleTasks
      }
      if(ParseFilterVehicleTaskData.assign_to){
        filterData = filterData.filter((item) => item.up_email === ParseFilterVehicleTaskData.assign_to);
      }
      if(ParseFilterVehicleTaskData.start_date && ParseFilterVehicleTaskData.end_date){
        filterData = filterData.filter((item)=>new Date(item.vt_start_date) >= new Date(ParseFilterVehicleTaskData.start_date) && new Date(item.vt_end_date) <= new Date(ParseFilterVehicleTaskData.end_date))
      }
      if(ParseFilterVehicleTaskData.priority){
        filterData = filterData.filter((item)=> item.pl_priority === ParseFilterVehicleTaskData.priority)
      }
      if(ParseFilterVehicleTaskData.status){
        filterData = filterData.filter((item)=> item.sl_status=== ParseFilterVehicleTaskData.status)
      }
      state.VehicleTasks=filterData
    },
    setResetFilter:(state,action)=>{
      const WholeVehicle = JSON.stringify(state.WholeVehicles);
      const ParseWholeVehicle=JSON.parse(WholeVehicle)
      state.VehicleFilterData={
        type: null,
        status: null,
        bank: null,
        issue_start_date: null,
        issue_end_date: null,
        sale_status:null
      }
      state.Vehicles= ParseWholeVehicle
    },
    setVehicleTaskResetFilter:(state,action)=>{
      const WholeVehicle_Tasks = JSON.stringify(state.WholeVehicleTasks);
      const ParseWholeVehicle_Task=JSON.parse(WholeVehicle_Tasks);
      state.VehicleTaskFilterData={
          taskName:"",
          assign_to:null,
          start_date:null,
          status:null,
          end_date:null,
          priority:null
        }
      state.VehicleTasks= ParseWholeVehicle_Task
    },
    
  
  },
});

export const {
  updateDefaultChecked,
  updateSelectedTab,
  setAllVehicles,
  updateVehicleTableLoading,
  SetAllVehicleTasks,
  updateFilterDropdownData,
  filterVehicleData,
  setVehicleFilterDatas,
  setVehicleTaskFilterDatas,
  searchFilterVehicleTaskData,
  setResetFilter,
  updateTaskFilterDropdown,
  setVehicleTaskResetFilter,
  
} = vehicleStoreSlice.actions;
export default vehicleStoreSlice.reducer;
