import { ApiConfig } from "../apiConfig";

export const getProperyBasicDetails=(property_id)=>{
    return  async()=>{
      try {
          const response = await ApiConfig.get(`/property/${property_id}/get_property_basic_details`);
          return response
        } catch (error) {
          throw error
        }
  }
}

export const createNewPropertyApplication=(property_id,data)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.post(`/property/${property_id}/property_application`,data,{
              headers: {
                'Content-Type': 'multipart/form-data',
              }});
            return response
          } catch (error) {
            throw error
          }
    }
}