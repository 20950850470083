import { TextField } from '@mui/material';
import React from 'react'

function CustomTextArea({
    name,
    placeholder,
    onChange,
    onBlur,
    value,
    error,
    helperText,
    FormHelperTextProps,
    maxRows,
    disabled
  }) {
    const inputStyles = {
        '& .MuiInputBase-input.MuiOutlinedInput-input': {
          padding: '0px!important',
          fontSize: '15px!important',
         
        },
      };
  
      const outlinedInputStyles = {
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0F62FE !important',
          },
        },
      };
    return (
      <div style={{position:'relative'}}>
         <TextField
          id="filled-multiline-static"
          disabled={disabled}
          name={name}
          multiline
          fullWidth
          rows={maxRows}
          variant="outlined"
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          error={error}
          helperText={helperText}
          FormHelperTextProps={{
            style: { marginLeft: 0,position:'absolute',top:`${((33 * maxRows))}px`},
        ...FormHelperTextProps,
        }}
          sx={{
            ...inputStyles,
            ...outlinedInputStyles,
        }}
        />
      </div>
    )
}

export default CustomTextArea