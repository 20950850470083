import { createSlice } from "@reduxjs/toolkit";

export const VehicleTypeSlice = createSlice({
  name: "vehicle_type",
  initialState:{
    VehicleTypeTableLoading:false,
    WholeVehicleTypes:[],
    VehicleTypes:[],
    filterTypeDetails:{
        type:"",
        status:"all",
    }
},
reducers:{
    setAllVehicleTypes:(state,action)=>{
        state.WholeVehicleTypes=action.payload
        state.VehicleTypes=action.payload
    },
    updateVehicleTableLoading:(state,actions)=>{
        state.VehicleTypeTableLoading=actions.payload
    },
    setVehicleFilterDatas:(state,actions)=>{
        const {name,value}=actions.payload
        state.filterTypeDetails[name]=value
    },
    filterVehicleTypeByData:(state)=>{
        let FilterType=[]
        if(state.filterTypeDetails.type.trim()){
            FilterType=state.WholeVehicleTypes.filter((item)=>item.vtl_type.toLowerCase().includes(state.filterTypeDetails.type.toLowerCase()))
        }else{
            FilterType=state.WholeVehicleTypes
        }

        if(state.filterTypeDetails.status!=='all'){
            FilterType=FilterType.filter((item)=>item.vtl_status.toLowerCase()===state.filterTypeDetails.status.toLowerCase())
        }
        state.VehicleTypes=FilterType
    },
    filterVehicleTypeByDataReset:(state)=>{
        state.VehicleTypes=state.WholeVehicleTypes
        state.filterTypeDetails={type:"",status:"all",}
    },
    ResetVehicleTypeData:(state)=>{
        state.VehicleTypeTableLoading=false
        state.WholeVehicleTypes=[]
        state.VehicleTypes=[]
        state.filterTypeDetails={
            type:"",
            status:"all"
        }
    }
}
});
export const { setAllVehicleTypes,updateVehicleTableLoading,setVehicleFilterDatas,filterVehicleTypeByData,filterVehicleTypeByDataReset,ResetVehicleTypeData}=VehicleTypeSlice.actions

export default VehicleTypeSlice.reducer