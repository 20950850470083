import React from 'react'
import TextField from '@mui/material/TextField';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import './CustomDatePicker.css'
import dayjs from 'dayjs';
function CustomDatePicker({
    name,
    onChange,
    onBlur,
    value,
    error,
    helperText,
    FormHelperTextProps,
    disabled,
    minDate
  }) {
    const inputStyles = {
        '& .MuiInputBase-input.MuiOutlinedInput-input': {
          padding: '8px 14px!important',
          fontSize: '15px!important',
          height: '25px!important',
        },
      };
    
      const outlinedInputStyles = {
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#0F62FE !important',
          },
        },
      };

      return (
        <div style={{position:'relative'}}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
          minDate={minDate}
          disabled={disabled}
          onChange={(date) => {
            onChange(date.format("MM/DD/YYYY"))
          }}
          format="MM/DD/YYYY"
           className= 'custom-datepicker-input-class'
            fullWidth
              name={name}
              autoComplete="off"
              size="small"
              onBlur={onBlur}
              value={value}
           slotProps={{
            textField: {
                helperText:helperText?helperText:'',
                error:error?error:false,
                FormHelperTextProps: {
                    style: { marginLeft: 0,position:'absolute',top:'36px' },
                  },
            },
          }}
        //    textField={
        //     <TextField
        //     fullWidth
        //       name={name}
        //       autoComplete="off"
        //       size="small"
        //       onChange={onChange}
        //       onBlur={onBlur}
        //       value={value}
        //       variant="outlined"
        //     />
        //   }
            sx={{
                ...inputStyles,
                ...outlinedInputStyles,
              }}
          />
        </LocalizationProvider>
        </div>
        
      );
}

export default CustomDatePicker