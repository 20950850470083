import React, { useEffect, useState } from "react";
import { useDispatch,useSelector } from "react-redux";
import { deleteBankDetails, getAllApplicationOwnerNames } from "../../../../../../../api/apiServices/applicationOwnerApi";
import CustomChip from "../../../../../../../components/customChip/CustomChip";
import SimpleTable from "../../../../../../../components/simpleTable/SimpleTable";
import './bankLookUpStyle.css'
import { useNavigate } from "react-router-dom";
import { LocalStorageServices } from "../../../../../../../services/localStorageServices";
import ResponseHandler from "../../../../../../../services/ResponseHandler";
import BankLookUpFilter from "./bank_form/BankLookUpFilter";
function BankLookUp() {
  const navigate=useNavigate()
  const dispatch = useDispatch();
  const [isUpdated,SetIsUpdated]=useState(false)
  const BankInfo =useSelector((state)=>state.lookUpStore.BankLookUpDetails)
  useEffect(() => {
    dispatch(getAllApplicationOwnerNames())
     
  }, [isUpdated]);
  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Bank Name",
      dataIndex: "bank_name",
      key: "bank_name",
    },
    {
      title: "Branch Code",
      dataIndex: "branch_code",
      key: "branch_code",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      action: (row) => (
        <div className="bank_lookup_action_chipset">
          <CustomChip text="View" bgColor="#F8893C" color="white"  onClick={()=>{
          navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/settings/lookup/${row.id}/Bank_form`)
          }}/>
          <CustomChip text="Delete" bgColor="#ED5967" color="white" onClick={()=>{
            dispatch(deleteBankDetails(row.id)).then((response)=>{
              SetIsUpdated(!isUpdated)
              ResponseHandler(response)
            }).catch((error)=>{
              ResponseHandler(error)
            })
          }} />
        </div>
      ),
    },
  ];
 
  const Datas=()=>{
    const result= BankInfo &&  BankInfo.map((item,index)=>{
      return{
        key:index+1,
        id:item.aonl_id,
        bank_name:item.aonl_name,
        branch_code:item.aonl_branch_code,
        email:item.aonl_email_id,
        phoneNumber:item.aonl_phone_number,
        address:item.aonl_address,
        location:item.aonl_location,

      }
    })
    return result
  }
  return (
    <div>
      <div className="bank_lookup_header">
         <div>
           <BankLookUpFilter/>
         </div>
         <div>
         <button
            className="add_bank_button"
            onClick={()=>{
              navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/settings/lookup/Bank_form`)
            }}
          >

            Add Bank
          </button>
         </div>
      </div>
       <SimpleTable columns={Columns} rows={Datas()}/>
    </div>
  );
}

export default BankLookUp;
