import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc);
dayjs.extend(timezone);

export  class DateTimeConvertionService{

    static DateTimeConverter(timeStamp){

        const utcDateTime = timeStamp;
        const utcDate = dayjs.utc(utcDateTime);
        const ActualTime = utcDate.tz("Etc/GMT0");
        const userTimezoneOffset = (new Date()).getTimezoneOffset();
        const adjustedActualTime = ActualTime.add(-userTimezoneOffset, 'minute');
        const formattedDateTime = adjustedActualTime.utc().format('YYYY-MM-DDTHH:mm:ss[Z]');
        return formattedDateTime.replace('T',' ').replace('Z',' ')
    }

    static TimeConverter(timeStamp) {
        const utcDateTime = timeStamp;
        const utcDate = dayjs.utc(utcDateTime);
        const ActualTime = utcDate.tz("Etc/GMT0");
        const userTimezoneOffset = (new Date()).getTimezoneOffset();
        const adjustedActualTime = ActualTime.add(-userTimezoneOffset, 'minute');
        const formattedDateTime = adjustedActualTime.utc().format('YYYY-MM-DD h:mm A'); // 12-hour format with AM/PM
        return formattedDateTime;
    }

}