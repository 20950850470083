import { createSlice } from "@reduxjs/toolkit";
import Roles from "../pages/homePage/subPages/user_staff/roles/Roles";

export const RolesSlice=createSlice({
    name:"Roles",
    initialState:{
        roleTableLoading:false,
        WholeRoles:[],
        Roles:[],
        FilterByRoleName:''
    },
    reducers:{
        setAllRoles:(state,actions)=>{
            state.Roles=actions.payload
            state.WholeRoles=actions.payload
        },
        updateRoleTableLoading:(state,actions)=>{
            state.roleTableLoading=actions.payload
        },
        setFilterRoleName:(state,actions)=>{
            state.FilterByRoleName=actions.payload
        },
        filterByRoleName:(state)=>{
            let FilterRoles=[]
            console.log(state.FilterByRoleName.trim(),'--------1')
            if(state.FilterByRoleName.trim()){
                FilterRoles=state.WholeRoles.filter((item) => item.url_role.toLowerCase().includes(state.FilterByRoleName.toLowerCase()));
            }else{
                FilterRoles=state.WholeRoles
            }
            state.Roles=FilterRoles
        },
        resetFilterData:(state)=>{
            state.Roles=state.WholeRoles
            state.FilterByRoleName=""
        },
        restRoleData:(state)=>{
            state.Roles=[]
            state.WholeRoles=[]
        },

        ResetRoleDatas:(state)=>{
            state.Roles=[]
            state.WholeRoles=[]
            state.roleTableLoading=false
            state.WholeRoles=[]
            state.Roles=[]
            state.FilterByRoleName=''
        }

    }
})

export const {setAllRoles,updateRoleTableLoading,setFilterRoleName,filterByRoleName,resetFilterData,restRoleData,ResetRoleDatas}=RolesSlice.actions

export default RolesSlice.reducer