import React, { useEffect, useState } from "react";
import SimpleTable from "../../../../../components/simpleTable/SimpleTable";
import Button from "@mui/material/Button";
import "./VehicleType.css";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import CustomChip from "../../../../../components/customChip/CustomChip";
import CustomModal from "../../../../../components/customModal/CustomModal";
import TextField from "@mui/material/TextField";

import AddIcon from "@mui/icons-material/Add";
import VechicleTypeForm from "./subComponents/VechicleTypeForm";
import { useTranslation } from "react-i18next";
import SimpleFilter from "../../../../../components/simpleFilter/SimpleFilter";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteVehicleType,
  getAllVehicleType,
} from "../../../../../api/apiServices/vehicleTypeApi";
import CustomSpin from "../../../../../components/customSpin/CustomSpin";
import ResponseHandler from "../../../../../services/ResponseHandler";

function VehicleType() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const vehicleTypeData = useSelector(
    (state) => state.vehicleTypeStore.VehicleTypes
  );
  const TableLoading = useSelector(
    (state) => state.vehicleTypeStore.VehicleTypeTableLoading
  );
  const [deleteRowDetails, setDeleteRowDetails] = useState({
    id: "",
    loading: false,
  });
  const FilterData = useSelector(
    (state) => state.vehicleTypeStore.filterTypeDetails
  );
  useEffect(() => {
    dispatch(getAllVehicleType());
  }, []);

  const [open, setOpen] = React.useState(false);
  const [EditTypeDetails, SetEditTypeDetails] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (state) => {
    setOpen(state);
  };

  const handleEdit = (row) => {
    SetEditTypeDetails(row);
    handleClickOpen();
  };

  const UpdateDeleteDetails = (id, loading) => {
    setDeleteRowDetails({
      ...deleteRowDetails,
      ["id"]: id,
      ["loading"]: loading,
    });
  };
  const handleDelete = (rowKey) => {
    UpdateDeleteDetails(rowKey, true);
    dispatch(deleteVehicleType(rowKey))
      .then((response) => {
        ResponseHandler(response);
        getAllVehicleType();
      })
      .catch((error) => {
        ResponseHandler(error);
      })
      .finally(() => {
        UpdateDeleteDetails(rowKey, false);
      });
  };

  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Types",
      dataIndex: "types",
      key: "types",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      action: (row) => (
        <p style={{ color: row.status === "active" ? "green" : "red" }}>
          {row.status}
        </p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      action: (row) => (
        <div className="vechicle_type_chipset">
          <CustomChip
            text="Edit"
            bgColor="#F8893C"
            color="white"
            onClick={() => handleEdit(row)}
          />
          <CustomChip
            loading={
              deleteRowDetails.id === row.id ? deleteRowDetails.loading : false
            }
            text="Delete"
            bgColor="#ED5967"
            color="white"
            onClick={() => handleDelete(row.id)}
          />
        </div>
      ),
    },
  ];
  const datas = () => {
    const result =
      vehicleTypeData &&
      vehicleTypeData.map((item, index) => {
        return {
          key: index + 1,
          id: item.vtl_id,
          types: item.vtl_type,
          status: item.vtl_status,
          attachment:item.vtl_attachment
        };
      });
    return result;
  };

  const Privilages = useSelector((state) => state.userStore.Privilages);

  return (
    <>
      <p className="page_title">{t("Vehicle Type")}</p>
      <CustomSpin loading={TableLoading}>
        <div className="vehicleType_add_sort_container">
          <SimpleFilter id="VehicleTypeFilter" filterData={FilterData} />
          {Privilages?.Vehicle_Type.some((item) => item === "Add") ? (
            <div className="vehicleType_sort_select_container">
              <Button
                onClick={() => {
                  SetEditTypeDetails(null);
                  handleClickOpen();
                }}
                variant="contained"
                size="small"
                style={{
                  textTransform: "none",
                  fontFamily: "Inter",
                  fontSize: "15px",
                  padding: " 4px 25px",
                  backgroundColor: "#0F62FE",
                }}
              >
                {t("Add ")}
              </Button>
            </div>
          ) : null}
        </div>
        <SimpleTable columns={Columns} rows={datas()} />
        <CustomModal
          open={open}
          handleClose={handleClose}
          body={
            <VechicleTypeForm
              EditDetails={EditTypeDetails}
              onclose={handleClose}
            />
          }
          title={
            EditTypeDetails ? "Update vehicle type" : "Create new vehicle type"
          }
        />
      </CustomSpin>
    </>
  );
}

export default VehicleType;
