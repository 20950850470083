import { Button, TextField } from "@mui/material";
import './CustomerFilterStyle.css'
import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from "react";
import { setRefresh } from '../../../../../../store/usersStore';
import { filterCustomerData, resetFilterCustomerData, setFilterCustomerDatas } from '../../../../../../store/customerStore';

function CustomerFilter() {
  const dispatch = useDispatch();
  const customerFilterData = useSelector((state) => state.customerStore.filterCustomerDetails);
// ------------------------translation code--------------------------------------
  const translateText = async (text) => {
    const currentLang = localStorage.getItem("currentLanguage") || "en";
    if (currentLang === "es") {
      try {
        const response = await fetch(
          `https://api.mymemory.translated.net/get?q=${encodeURIComponent(text)}&langpair=es|en`
        );
        const data = await response.json();
        if(data.responseStatus==200){
            return data.responseData.translatedText;
        }else{
            return text; 
        }
       
      } catch (error) {
        console.error("Error translating text:", error);
        return text; // Fallback to original text if translation fails
      }
    }
    return text;
  };

  const UpdateFieldData = (event) => {
    const { name, value } = event.target;
    dispatch(setFilterCustomerDatas({ name, value }));
  };

  const SearchData = async () => {
    const currentLang = localStorage.getItem("currentLanguage") || "en";
    const isSpanish = currentLang === "es";

    if (isSpanish) {
      const translationPromises = Object.entries(customerFilterData).map(([key, value]) => {
        return translateText(value).then((text) => {
          dispatch(setFilterCustomerDatas({ name: key, value: text }));
        });
      });
  
      await Promise.all(translationPromises);
    }

    dispatch(filterCustomerData());
    setTimeout(() => {
      dispatch(setRefresh());
    }, 500);
  };

  const ResetFilter = () => {
    dispatch(resetFilterCustomerData());
    setTimeout(() => {
      dispatch(setRefresh());
    }, 500);
  };

  const inputStyles = {
    "& .MuiOutlinedInput-root": {
      fontSize: "14px !important",
      fontFamily: "Inter",
      "& fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color
      },
      "&:hover fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color when focused
      },
    },
  };

  return (
    <div>
      <div className="CustomerFilter_input_action_container">
        <TextField
          name="name"
          value={customerFilterData.name}
          fullWidth
          className="CustomerFilter_input"
          autoComplete="off"
          style={{ fontSize: "15px" }}
          size="small"
          placeholder={"Enter name"}
          onChange={UpdateFieldData}
          variant="outlined"
          inputProps={{
            style: {
              padding: "3.5px 7px", // Add padding to input
              fontSize: "15px",
              fontWeight: 400,
              fontFamily: "Inter",
              borderRadius: "4px",
            },
          }}
          sx={{
            "& fieldset": { border: "none" },
          }}
        />
        <TextField
          name="email"
          value={customerFilterData.email}
          fullWidth
          className="CustomerFilter_input"
          autoComplete="off"
          style={{ fontSize: "15px" }}
          size="small"
          placeholder={"Enter email"}
          onChange={UpdateFieldData}
          inputProps={{
            style: {
              padding: "3.5px 7px", // Add padding to input
              fontSize: "15px",
              fontWeight: 400,
              fontFamily: "Inter",
              borderRadius: "4px",
            },
          }}
          sx={{
            "& fieldset": { border: "none" },
          }}
        />
        <TextField
          name="role"
          value={customerFilterData.role}
          fullWidth
          className="CustomerFilter_input"
          autoComplete="off"
          style={{ fontSize: "15px" }}
          size="small"
          placeholder={"Enter Role"}
          onChange={UpdateFieldData}
          inputProps={{
            style: {
              padding: "3.5px 7px", // Add padding to input
              fontSize: "15px",
              fontWeight: 400,
              fontFamily: "Inter",
              borderRadius: "4px",
            },
          }}
          sx={{
            "& fieldset": { border: "none" },
          }}
        />
        <div className="CustomerFilter_action">
          <Button
            className="users_Search_button"
            onClick={SearchData}
            variant="contained"
            size="small"
            style={{
              textTransform: "none",
              fontFamily: "Inter",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              padding: "8px 15px",
              backgroundColor: "#0f62fe",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            {"Search"}
          </Button>
          <Button
            onClick={ResetFilter}
            className="users_Reset_button"
            variant="outlined"
            size="small"
            style={{
              textTransform: "none",
              cursor: 'pointer',
              fontFamily: 'inter',
              border: '1px solid #005eb8',
              backgroundColor: 'white',
              borderRadius: '5px',
              padding: '7px 25px',
              color: '#0f62fe',
              fontSize: '14px'
            }}
          >
            {"Reset"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default CustomerFilter;
