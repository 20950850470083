import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deleteTestimonials } from "../../../../../api/apiServices/TestimonialApi";
import CustomChip from "../../../../../components/customChip/CustomChip";
import SimpleTable from "../../../../../components/simpleTable/SimpleTable";
import { useNavigate } from "react-router-dom";
import { LocalStorageServices } from "../../../../../services/localStorageServices";
import { getAllTestimonials } from "../../../../../api/apiServices/TestimonialApi";
import ResponseHandler from "../../../../../services/ResponseHandler";
import TestimonialFilter from "./TestimonialsFilter";
import './testimonialsStyles.css'
function Testimonials() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const testimonials=useSelector((state)=>state.TestimonialStore.allTestimonials)
  // const [testimonialData, setTestimonialData] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    dispatch(getAllTestimonials())
  }, []);
 

  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: " Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "content",
      dataIndex: "content",
      key: "content",
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
    },

    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      action: (row) => (
        <div className="bank_lookup_action_chipset">
          <CustomChip
            text="View"
            bgColor="#F8893C"
            color="white"
            onClick={() => {
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/testimonials/${
                  row.id
                }/testimonial_form`
              );
            }}
          />
          <CustomChip text="Delete" bgColor="#ED5967" color="white" onClick={()=>{
            dispatch(deleteTestimonials(row.id)).then((response)=>{
              setIsUpdated(!isUpdated)
              ResponseHandler(response)
            }).catch((error)=>{
              ResponseHandler(error)
            })
          }} />
        </div>
      ),
    },
  ];

  const Datas = () => {
    const result = testimonials.map((item, index) => {
      return {
        key: index + 1,
        id: item.t_id,
        name: item.t_name, // Adjust these properties based on your actual data structure
        designation: item.t_designation,
        content: item.t_content,
        rating: item.t_rating,
      };
    });
    return result;
  };
  return (
    <div>
      <h3 className="page_title">Testimonials</h3>
      <div className="testimonial_header_main_container">
        <div>
          <TestimonialFilter />
        </div>
        <div>
          <button
            className="add_bank_button"
            onClick={() => {
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/testimonials/testimonial_form`
              );
            }}
          >
            Add Testimonials
          </button>
        </div>
      </div>
      <SimpleTable columns={Columns} rows={Datas()} />
    </div>
  );
}

export default Testimonials;
