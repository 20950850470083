import React from 'react'
import UserLists from './userList/UserLists'
import MessageList from './messageList/MessageList'
import './MessagePage.css'
function MessagePage() {
  return (
    <div className='message_main_container'>
        <div className='message_users_sub_container'>
          <UserLists/>
        </div>
        <div className='message_list_sub_container'>
          <MessageList/>
        </div>
        
    </div>
  )
}

export default MessagePage