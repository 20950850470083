import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

function CustomLineChart({plotData}) {
    const [datas,SetDatas]=useState([])
    useEffect(()=>{
        SetDatas(plotData)
    },[plotData])
   
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend
      );
      
      const options = {
        responsive: true,
        plugins: {
          legend: {
            display:false,
            position: 'top',
          },
        },
        scales: {
          y: {
            min: 0,
            max: 30,
            stepSize: 1,
            grid: {
              display: false, // Remove x-axis grid lines
            },
          },
          x: {
            display: true,
            grid: {
              display: false, // Remove x-axis grid lines
            },
          },
        },
        indexAxis: 'x', // Set the index axis to 'x'
      };
      
      const labels = [
        'Jan', 'Feb', 'March', 'Apr', 'May', 'Jun', 'Jul',
        'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ];
    
      const data = {
        labels,
        datasets: [
          {
            fill: true,
            label: 'Values',
            data: datas,
            borderColor: '#5051f9',
            backgroundColor: 'rgba(82, 81, 249, 0.2)', // Transparent blue overlay
          },
        ],
      };
  return (
    <div>
         <Line options={options} data={data} />
    </div>
  )
}

export default CustomLineChart