import React, { useEffect, useRef, useState } from "react";

import { Grid, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./VehicleSlideView.css";
import { FaChevronRight } from "react-icons/fa";
import { useTheme } from "@mui/material/styles";
import { FaChevronLeft } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { ApiConfig } from "../../../../../../api/apiConfig";
import { Helpers } from "../../../../../../services/Helpers";
import dummyvedio from "../../../../../../assets/vehicle dummy.png"

const VehicleImageSlide = () => {
  const { id } = useParams();
  const theme = useTheme();
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [isVideo, setIsVideo] = useState(false);
  const [startIndex, setStartIndex] = React.useState(0);
 
  const [vehiclesdetails, setvehiclesdetails] = useState([]);
  const cardListRef = useRef(null);
  const filteredVehicles = vehiclesdetails.filter(
    (item) => item.filetype !== "pdf"
  );
  const visibleItems = filteredVehicles.slice(startIndex, startIndex + 4);
  const getvehicledetails = async () => {
    try {
      if (id) {
        const result = await ApiConfig.get(
          `/customer/${id}/get_vehicle_details`
        );
        const { data } = result.data;
        setvehiclesdetails(data.Vehicle_attachment);
        // setSelectedMedia(data.Vehicle_attachment[0]?.file ?? "");
        const initialMedia = data.Vehicle_attachment[0]?.file ?? "";
        setSelectedMedia(initialMedia);
        setIsVideo(Helpers.getUrlExtensions(initialMedia));
      }
    } catch (error) {
      console.log(error);
    }
  };

useEffect(() => {
    getvehicledetails();
}, [id]);


  const scrollLeft = () => {
   
    if (cardListRef.current) {
      cardListRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (cardListRef.current) {
      cardListRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  const isMd = useMediaQuery(theme.breakpoints.only("sm"));

  const handleNext = () => {
    setStartIndex((prev) => Math.min(prev + 4, vehiclesdetails.length - 4));
  };

  const handleNextphone = () => {
    setStartIndex((prev) => Math.min(prev + 1, vehiclesdetails.length - 1));
  };
 
  const handleBackphone = () => {
    setStartIndex((prev) => Math.max(prev - 1, 0));
  };

  const handleBack = () => {
    setStartIndex((prev) => Math.max(prev - 4, 0));
  };

  const handleImageClick = (file) => {
    setSelectedMedia(file);
    setIsVideo(Helpers.getUrlExtensions(file));
  };

  return (
    <>
      <div className="image_flex">
        {selectedMedia && isVideo ? (
          <video
            src={selectedMedia}
            controls
            style={{ width: "100%", height: "110%" }}
            autoPlay
            onError={(e) => console.error("Video failed to load", e)}
          />
        ) : (
          <img
            src={selectedMedia}
            alt="Selected media"
            style={{ width: "100%", height: "110%" }}
          />
        )}
      </div>
      <div className="image_mobile_flex">
        {isVideo ? (
          <video
            src={selectedMedia}
            controls
            style={{ width: "100%", height: "220px" }}
            autoPlay
          />
        ) : (
          <img
            src={selectedMedia}
            alt="Vehicle"
            style={{ width: "100%", height: "90%" }}
          />
        )}
      </div>
      <div className="image_flex_ipad">
        {selectedMedia && isVideo ? (
          <video
            src={selectedMedia}
            controls
            style={{ width: "100%", height: "90%" }}
            autoPlay
          />
        ) : (
          <img
            src={selectedMedia}
            alt="Selected media"
            style={{ width: "100%", height: "90%" }}
          />
        )}
      </div>

      <Grid item xs={12} style={{ display: isXs || isMd ? "none" : "flex" }}>
        <div
          style={{
            height: "15%",
            width: "100%",
            display: "flex",
            justifyContent: "center", // Centering the content
            alignItems: "flex-start",
            flexDirection: "row",
            marginTop: "30px",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            style={{ width: "100%", justifyContent: "space-between" }}
          >
            <div>
              <div
                onClick={handleBack}
                className="vehicleslider_backbuttons"
                disabled={startIndex === 0}
                style={{
                  backgroundColor: "#F1F1F1",
                  color: "#000000",
                  cursor: startIndex === 0 ? "not-allowed" : "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80%",
                  width: "30px",
                }}
              >
                  <FaChevronLeft />
             
              </div>
            </div>
            <Grid
              container
              spacing={2}
              style={{ width: visibleItems.length * 25 + "%", marginLeft: "0" }}
            >
              {visibleItems.map((item, index) => (
                <Grid item xs={12 / visibleItems.length} key={index} mt={3}>
                  {Helpers.getUrlExtensions(item.file) ? (
                    <img
                      onClick={() => handleImageClick(item.file)}
                      src={dummyvedio} // Assuming it's a preview image or a thumbnail
                      style={{
                        height: 150,
                        width: "100%",
                        display: "block",
                        overflow: "hidden",
                        cursor: "pointer",
                        marginTop: 14,
                      }}
                      alt={item.title}
                    />
                  ) : (
                    <img
                      onClick={() => handleImageClick(item.file)}
                      src={item.file}
                      style={{
                        height: 130,
                        width: "100%",
                        display: "block",
                        overflow: "hidden",
                        cursor: "pointer",
                        marginTop: 22,
                      }}
                      alt={item.title}
                    />
                  )}
                </Grid>
              ))}
            </Grid>
            <div>
              <div
                onClick={handleNext}
                className="vehicleslider_buttons"
                disabled={startIndex >= filteredVehicles.length - 4}
                style={{
                  backgroundColor: "#F1F1F1",
                  color: "#000000",
                  cursor:
                    startIndex >= filteredVehicles.length - 4
                      ? "not-allowed"
                      : "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "80%",
                  width: "30px",
                }}
              >
               
                  <FaChevronRight style={{ marginTop: "23px" }} />
                
              </div>
            </div>
          </Stack>
        </div>
      </Grid>
      {/* <Grid item xs={12} style={{ display: isXs ? "flex" : "flex" }}>
     
      </Grid> */}
      <div style={{  display: isXs || isMd ? "flex" : "none", }} className="slider_container">
               <div
                onClick={scrollLeft}
                className="vehicleslider_backbuttonsss"
                style={{
                  backgroundColor: "#F1F1F1",
                  color: "#000000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaChevronLeft style={{ marginTop: "5px" }} />
              </div>
              <div style={{display:'flex',justifyContent:'start',gap:'30px',overflowX:'hidden','scrollBehavior':'smooth'}} ref={cardListRef}>
              {visibleItems
                .map((item, index) => (
                   <>
                    {Helpers.getUrlExtensions(item.file) ? (
                      <img
                        onClick={() => handleImageClick(item.file)}
                        src={dummyvedio} // Replace with a placeholder or default image for videos
                        style={{
                          height: "80px", // Ensure a consistent image size
                          width: "180px",
                          cursor: "pointer",
                          margin: "auto", // Center the image itself within its container
                        }}
                        alt={item.title}
                      />
                    ) : (
                      <img
                        onClick={() => handleImageClick(item.file)}
                        src={item.file}
                        style={{
                          height: "80px", // Ensure a consistent image size
                          width: "180px",
                          cursor: "pointer",
                          margin: "auto", // Center the image itself within its container
                        }}
                        alt={item.title}
                      />
                    )}
                  </>
                ))}
            </div>

              <div
                onClick={scrollRight}
                className="vehicleslider_buttonsss"
                style={{
                  backgroundColor: "#F1F1F1",
                  color: "#000000",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <FaChevronRight style={{ marginTop: "5px" }} />
              </div>
      </div>
     
      
    </>
  );
};

export default VehicleImageSlide;
