import { configureStore } from '@reduxjs/toolkit'
import mainStoreReducer from './homePageStore'
import userStoreReducer from './usersStore'
import roleStoreReducer from './rolesStore'
import propertyTypeStoreReducer from './propertyTypeStore'
import vehicleTypeStoreReducer from './vehicleTypeStore'
import propertiesReducers from './propertiesStore'
import vehicleStoreReducer from './vehicleStore'
import taskOrJobsReducer from './tasksOrJobsStore'
import agentStoreReducer from './agentStore'
import LookUpStoreReducer from './LookUpStore'
import customerStoreReducer  from './customerStore'
import TestimonialStoreReducer from './testimonialStore'
import {thunk} from 'redux-thunk';
import messageStoreReducer from './messageStore'


const middleware = (getDefaultMiddleware) => {
  return getDefaultMiddleware().concat(thunk);
};

export const Store = configureStore({
  reducer: {
    mainStore:mainStoreReducer,
    userStore:userStoreReducer,
    roleStore:roleStoreReducer,
    propertyTypeStore:propertyTypeStoreReducer,
    vehicleTypeStore:vehicleTypeStoreReducer,
    vehicleStore:vehicleStoreReducer,
    propertiesStore:propertiesReducers,
    taskOrJobsStores:taskOrJobsReducer,
    agentStore:agentStoreReducer,
    lookUpStore:LookUpStoreReducer,
    customerStore:customerStoreReducer,
    TestimonialStore:TestimonialStoreReducer,
    messageStore:messageStoreReducer
  },
  middleware
})