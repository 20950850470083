import React from "react";
import "./CustomChip.css";
import { CircularProgress } from "@mui/material";
function CustomChip({ text, bgColor, color, loading, onClick ,disabled}) {
  const handleClick = () => {
    if (!loading && onClick) {
      onClick();
    }
  };

  return (
    <div
      className="Custom_Chip_main_container"
      style={{ backgroundColor: disabled?'#EBEBEB':bgColor, color:disabled?'#858585':color,cursor:disabled?'not-allowed':'pointer' ,border:disabled?'1px solid #D9D9D9':'none'}}
      onClick={()=>{
        if(!disabled){
          handleClick()
        }
      }}
    >
      {loading ? <CircularProgress size={10} color="inherit" /> : <p>{text}</p>}
    </div>
  );
}

export default CustomChip;
