import { Button, CircularProgress, useTheme } from "@mui/material";
import React from "react";

function FormButton({ id,form, type, variant, loading, text, onClick }) {
  const theme = useTheme();
  const primaryColor = theme.palette.primary.main;
  return (
    <Button
      disableElevation
      id={id}
      form={form}
      variant={variant}
      size="small"
      style={{ textTransform: "none", lineHeight: 1.5 ,fontSize:14,fontFamily:"Inter",p:2}}
      type={type}
      disabled={loading}
      sx={{
        "&.Mui-disabled": {
          background: primaryColor,
          color: theme.palette.getContrastText(primaryColor),
        },
      }}
      onClick={() => {
        onClick && onClick();
      }}
    >
      {loading ? <CircularProgress size={20} color="inherit" /> : text}
    </Button>
  );
}

export default FormButton;
