import ResponseHandler from "../../services/ResponseHandler"
import { setAllPropertyTypes, updatePropertyTableLoading } from "../../store/propertyTypeStore"
import { ApiConfig } from "../apiConfig"

export const getPropertyType=()=>{
    return  async(dispatch)=>{
        try{
            dispatch(updatePropertyTableLoading(true))
            const response=await ApiConfig.get('property_type/property_types')
            dispatch(setAllPropertyTypes(response.data.data))
            dispatch(updatePropertyTableLoading(false))
        }catch(error){
            ResponseHandler(error)
        }
    }
}
export const createPropertyType=(data)=>{
    return  async(dispatch)=>{
        try{
            const response=await ApiConfig.post('property_type/create_new_type',data,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                }
              })
            dispatch(getPropertyType())
            return response
        }catch(error){
            throw error
        }
    }
}

export const updatePropertyType=(id,data)=>{
    return async(dispatch)=>{
        try {
            const response=await ApiConfig.put(`property_type/${id}/update_type`,data)
            dispatch(getPropertyType())
            return response
        } catch (error) {
            throw error
        }
    }
}

export const deletePropertyType=(id,data)=>{
    return async(dispatch)=>{
        try {
            const response=await ApiConfig.delete(`property_type/${id}/delete_type`,data)
            dispatch(getPropertyType())
            return response
        } catch (error) {
            throw error
        }
    }
}