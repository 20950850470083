import ResponseHandler from "../../services/ResponseHandler"
import { ApiConfig } from "../apiConfig"

export const getAllStatus=()=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get('status')
           return response.data.data
        }catch(error){
            throw error
        }
    }
}