import { ResetPropertyTypeData } from "../store/propertyTypeStore"
import { ResetRoleDatas } from "../store/rolesStore"
import { ResetTasksOrJobs } from "../store/tasksOrJobsStore"
import { ResetUserData } from "../store/usersStore"
import { ResetVehicleTypeData } from "../store/vehicleTypeStore"

export const ResetStoreData=(dispatch,key)=>{
    switch(key){
        case 'Roles':
            dispatch(ResetRoleDatas())
            break
        case 'Users':
            dispatch(ResetUserData())
            break
        case 'Property Type':
            dispatch(ResetPropertyTypeData())
            break
        case 'Vehicle Type':
            dispatch(ResetVehicleTypeData())
            break
        case 'Tasks/Jobs':
            dispatch(ResetTasksOrJobs())
            break
        default:
            break
    }
   
}