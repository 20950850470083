import { ApiConfig } from "../apiConfig"

export const getAllPrivilages=()=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get('privilages')
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getPrivilageByRoleId=(roleId)=>{
    return  async()=>{
        try{
           const response=await ApiConfig.get(`/${roleId}/privilage`)
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const updateRolePrivilage=(roleId,data)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.put(`privilage/${roleId}/update_privilage`,{privilages:data})
           return response
        }catch(error){
            throw error
        }
    }
}