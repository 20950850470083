export const PaymentMethod=[
    { label: 'Cash', value:"Cash" },
    { label: 'Net Banking', value: "Net Banking" },
    { label: 'Loan', value: "Loan"},
  ]

  export const SourceOfKnow = [
    { label: 'Facebook', value: 'Facebook' },
    { label: 'Twitter', value: 'Twitter' },
    { label: 'Instagram', value: 'Instagram' },
    { label: 'LinkedIn', value: 'LinkedIn' },
    { label: 'Other', value: 'Other' },
];