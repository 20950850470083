import React, { useEffect, useRef, useState } from "react";
import { LocalStorageServices } from "../../services/localStorageServices";
import { useSelector } from "react-redux";

const Language = () => {
  const [updateRefresh,setUpdateRefresh]=useState(null)
  const refresh = useSelector((state) => state.userStore.Refresh);
  const scriptRef = useRef(null);

  useEffect(() => {
    // Initialize Google Translate settings
    window.gtranslateSettings = {
      default_language: "en",
      languages: ["en", "es"],
      wrapper_selector: ".gtranslate_wrapper",
      switcher_horizontal_position: "right",
      switcher_vertical_position: "top",
      float_switcher_open_direction: "bottom",
    };

    // Create and append script element
    const script = document.createElement("script");
    script.src = "https://cdn.gtranslate.net/widgets/latest/float.js";
    script.defer = true;
    scriptRef.current = script;
    document.body.appendChild(script);

    script.onload = () => {
      const applyStyles = () => {
        const wrapper = document.getElementById("gt_float_wrapper");
        if (wrapper) {
          wrapper.style.position = "fixed";
          wrapper.style.top = "10px";
          wrapper.style.right = "20px";
          wrapper.style.zIndex = "999999";
        }
      };

      // Apply custom CSS to replace text with flags
      const styleElement = document.createElement("style");

      styleElement.textContent = `
        .gtranslate_wrapper {
          position: fixed;
          top: 10px;
          right: 20px;
          z-index: 999999;
        }
        .gtranslate_wrapper .gtranslate_switcher span::before {
          content: url('https://flagcdn.com/es.svg'); /* URL of Spain flag */
          display: inline-block;
          width: 16px;
          height: 11px;
          margin-right: 5px;
        }
        @media only screen and (max-width:600px) {
          .gt_float_switcher .gt-selected .gt-current-lang {
            padding: 7px 5px;
            color: #333;
            font-weight: bold;
          }
          .gt_float_switcher img {
            vertical-align: middle;
            display: inline-block;
            width: 28px;
            height: auto;
            margin: 0 5px 0 0;
            border-radius: 3px;
          }
          .gt_float_switcher {
            font-family: Arial;
            font-size: 15px;
            color: #555;
            line-height: 20px;
          }
          .gt_float_switcher .gt_options a {
            display: block;
            text-decoration: none;
            padding: 6px 5px;
            color: #444;
            transition: color .4s linear;
          }
        }
      `;
      document.head.appendChild(styleElement);

      const replaceEsTextWithFlag = () => {
        const elements = document.querySelectorAll(".gtranslate_switcher span");
        if (elements && elements.length > 0) {
          elements.forEach((el) => {
            if (el.textContent.trim() === "es") {
              el.innerHTML =
                `<img src="https://flagcdn.com/es.svg" alt="Spain" style="width: 16px; height: 11px; margin-right: 5px;">` +
                el.textContent;
            }
          });
        }
      };

      const logCurrentLanguage = () => {
        const combo = document.querySelector(".goog-te-combo");
        if (combo) {
          const currentLang = combo.value;
          LocalStorageServices.setLanguage(currentLang);
          return currentLang;
        }
        return null;
      };
      replaceEsTextWithFlag();
      logCurrentLanguage();
      // // Periodically check and apply changes
      // const intervalId = setInterval(() => {
      //   try {
         
      //   } catch (error) {
      //     console.log(error);
      //   }
      // }, 1000); // Check every second

      // Apply styles immediately if the wrapper is already present
      applyStyles();

      // Cleanup function to clear interval on unmount
      // return () => {
      //   clearInterval(intervalId);
      // };
    };

    // Cleanup function to remove script element on unmount
    return () => {
      if (scriptRef.current) {
        document.body.removeChild(scriptRef.current);
      }
    };
  }, []);

  const switchLanguage = (lang) => {
    const combo = document.querySelector(".goog-te-combo");
    if (combo) {
      combo.value = lang;
      combo.dispatchEvent(new Event('change'));
    }
  };

  const logCurrentLanguage = () => {
    const combo = document.querySelector(".goog-te-combo");
    if (combo) {
      const currentLang = combo.value;
      console.log(`Current language: ${currentLang}`);
      LocalStorageServices.setLanguage(currentLang);
      return currentLang;
    }
    return null;
  };

  useEffect(() => {
    if(updateRefresh !=refresh){
      const currentLang = logCurrentLanguage();
      if (currentLang === 'en') {
        // Switch to English first, then to Spanish with a delay
        // switchLanguage("es");
      } if (currentLang === 'es')  {
        // setTimeout(()=>{
          switchLanguage("es");
        // },50)
       
      }
      setUpdateRefresh(refresh)
    }
  }, [refresh]);

  return <div className="gtranslate_wrapper"></div>;
};

export default Language;
