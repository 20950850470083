import React, { useEffect, useState } from "react";
import { Button, CircularProgress, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import pdf_log from "../../../../../../../assets/Pdf_logo.png";
import { updatePropertyTaskAttachmentIsPublish } from "../../../../../../../api/apiServices/propertyApi";
import ResponseHandler from "../../../../../../../services/ResponseHandler";
import "./PropertyTaskAttachments.css";
function PropertyTaskAttachments({ attachments }) {
  const theme = useTheme();
  const { property_task_id } = useParams();
  const dispatch = useDispatch();
  const primaryColor = theme.palette.primary.main;
  const [Attachment, SetAttachments] = useState([]);
  const [isPublishedChecked, SetisPublishedChecked] = useState(false);
  const [loading, Setloading] = useState(false);
  useEffect(() => {
    SetAttachments(attachments);
  }, [attachments]);

  function updatePublishedState(index) {
    let referenceAttachment = Attachment.slice(); // Make a shallow copy of the Attachment array
    referenceAttachment[index] = {
      ...referenceAttachment[index], // Copy the existing properties of the attachment
      isPublish: !referenceAttachment[index].isPublish, // Toggle the isPublish property
    };

    SetAttachments(referenceAttachment);
  }
  const Privilages = useSelector((state) => state.userStore.Privilages);
  return (
    <div className="property_task_attachment_main_base_container">
      <p className="property_task_attachment_title">Attachment</p>
      <div className="property_task_attachment_main_container">
        <div className="property_task_attachment_sub_container">
          {Attachment &&
            Attachment.map((item, index) => {
              return (
                !["pdf", "doc", "docx", "txt"].includes(item.filetype) && (
                  <div key={index}>
                    <div className="property_task_image_attachment_container">
                      {Privilages &&
                      Privilages?.Property &&
                      Privilages?.Property.some((item) => item === "Update") ? (
                        <input
                          type="checkbox"
                          checked={item.isPublish}
                          onChange={() => updatePublishedState(index)}
                        />
                      ) : null}

                      <img
                        className="property_task_image_attachment"
                        src={item.file}
                      />
                    </div>
                    <p className="property_task_image_title">{item.title}</p>
                  </div>
                )
              );
            })}
        </div>
      </div>
      {Privilages &&
      Privilages?.Property &&
      Privilages?.Property.some((item) => item === "Update") ? (
        <div className="property_task_publish_action_footer">
          <>
            <div className="property_task_publish_action_checkbox">
              <input
                type="checkbox"
                value={isPublishedChecked}
                onChange={(e) => SetisPublishedChecked(e.target.checked)}
              />
              <p>Publish files</p>
            </div>
            <Button
              disableElevation
              variant={"contained"}
              size="small"
              style={{ textTransform: "none", lineHeight: 1.5 }}
              disabled={!isPublishedChecked || loading}
              sx={{
                "&.Mui-disabled": {
                  background: primaryColor,
                  color: theme.palette.getContrastText(primaryColor),
                },
              }}
              onClick={() => {
                Setloading(true);
                dispatch(
                  updatePropertyTaskAttachmentIsPublish(
                    property_task_id,
                    Attachment
                  )
                )
                  .then((response) => {
                    ResponseHandler(response);
                  })
                  .catch((error) => {
                    ResponseHandler(error);
                  })
                  .finally(() => {
                    Setloading(false);
                  });
              }}
            >
              {loading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                "Submit"
              )}
            </Button>
          </>
        </div>
      ) : null}
    </div>
  );
}

export default PropertyTaskAttachments;
