import React, { useEffect, useState } from "react";
import FormButton from "../../../../../components/formButton/FormButton";
import CustomTextArea from "../../../../../components/customTextArea/CustomTextArea";
import CustomTextField from "../../../../../components/customTextField/CustomTextField";
import { Avatar, IconButton } from "@mui/material";
import { ApiConfig } from "../../../../../api/apiConfig";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { useNavigate } from "react-router-dom";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CreateNewTestimonials,
  updateTestimonials,
  getAllTestimonialsById,
} from "../../../../../api/apiServices/TestimonialApi";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { RiFileEditFill } from "react-icons/ri";
import "./TestimonialsForm.css";
import ResponseHandler from "../../../../../services/ResponseHandler";

function TestimonialForm() {
  const { testimonial_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isView, setIsView] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [Image, setImage] = useState(null);

  useEffect(() => {
    if (testimonial_id) {
      setIsView(true);
      dispatch(getAllTestimonialsById(testimonial_id))
        .then((response) => {
          setFormikData(response);
          setImage(response.t_profile_pic);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
  }, [testimonial_id]);

  const initialValues = {
    name: "",
    designation: "",
    content: "",
    rating: "",
    // testimonialProfilePic: null,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    designation: Yup.string().required("Designation is required"),
    content: Yup.string().required("Content is required"),
    rating: Yup.string().required("Rating is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (testimonial_id) {
        dispatch(updateTestimonials(testimonial_id, values))
          .then((response) => {
            ResponseHandler(response);
            navigate(-1);
          })
          .catch((error) => {
            ResponseHandler(error);
          });
      } else {
        dispatch(CreateNewTestimonials(values))
          .then((response) => {
            ResponseHandler(response);
            navigate(-1);
          })
          .catch((error) => {
            ResponseHandler(error);
          });
      }
    },
  });

  function setFormikData(data) {
    const mappedData = Object.keys(initialValues).reduce((acc, key) => {
      const originalKey = `t_${key}`;
      acc[key] = data[originalKey] || initialValues[key];
      return acc;
    }, {});

    formik.setValues({
      ...formik.values,
      ...mappedData,
    });

    if (data.t_testimonialProfilePic) {
      setSelectedImage(data.t_testimonialProfilePic);
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedImage(file);
    formik.setFieldValue("testimonialProfilePic", file);
  };
  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      console.error("No file selected");
      return;
    }
    setSelectedImage(file); // Update state with the selected image

    const formData = new FormData();
    formData.append("testimonialProfilePic", file);
    
    try {
      const result = await ApiConfig.put(
        `/customer/${testimonial_id}/update_testimonial_profile_pic`,
        formData
      );
      ResponseHandler(result);
    } catch (error) {
      ResponseHandler(error);
    }
  };

  const openFileUploader = () => {
    document.getElementById("fileInput").click();
  };

  return (
    <div>
      <div className="bank_form_header">
        <p className="page_title">
          {testimonial_id
            ? isView
              ? "View Testimonial details"
              : "Update Testimonial Details"
            : "Add Testimonial"}
        </p>
        <div
          className="bank_form_back_button"
          onClick={() => {
            navigate(-1);
          }}
        >
          <KeyboardBackspaceIcon />
          <p>Back</p>
        </div>
      </div>
      <div>
        <div className="bank_form_main_Container">
          {testimonial_id && isView && (
            <div
              className="bank_form_edit_details"
              onClick={() => setIsView(false)}
            >
              <RiFileEditFill />
              <p>Edit</p>
            </div>
          )}
          <div className="bank_formContainer">
            <p className="bank_form_legend">
              {testimonial_id
                ? "Testimonials "
                : "Add Testimonials details"}
            </p>
            <form id="bankform" onSubmit={formik.handleSubmit}>
            <p className="bank_form_label">Profile Picture</p>
            <div
                className="Attachment_formContainer"
                style={{ marginTop: "12px" }}
              >
                <div className="SubContainer_details_container2_1">
                  <input
                    type="file"
                    id="fileInput"
                    accept="image/png, image/jpeg"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      if (testimonial_id && isView) {
                        handleFileUpload(e); 
                      } else {
                        handleFileChange(e);  
                      }
                    }}
                  />
                  
                  <div className="SubContainer_details_main_container_Image">
                
                    <div className="SubContainer_details_container_Image">
                   
                      <Avatar
                        sx={{
                          width: 130,
                          height: 130,
                          borderRadius: "100%",
                          cursor: "pointer",
                        }}
                        src={
                          selectedImage
                            ? typeof selectedImage === "string"
                              ? selectedImage
                              : URL.createObjectURL(selectedImage)
                            : Image
                        }
                      />
                      <IconButton
                        component="label"
                        sx={{
                          position: "absolute",
                          zIndex: 2,
                          bottom: 20,
                          left: 90,
                          padding: 0.5,
                          background: "white",
                          boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
                          "&:hover": {
                            background: "white",
                          },
                        }}
                        onClick={openFileUploader}
                      >
                        <CameraAltIcon
                          sx={{
                            color: "black",
                            fontSize: 25,
                          }}
                        />
                        
                      </IconButton>
                      
                    </div>
                  </div>
                </div>
              </div>

              <div className="Testimonial_form_row">
                <div>
                  <p className="bank_form_label">Name</p>
                  <CustomTextField
                    disabled={isView}
                    name="name"
                    placeholder={"Enter name"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                </div>
                <div>
                  <label className="bank_form_label">Designation</label>
                  <CustomTextField
                    disabled={isView}
                    name="designation"
                    placeholder={"Enter designation"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.designation}
                    error={
                      formik.touched.designation &&
                      Boolean(formik.errors.designation)
                    }
                    helperText={
                      formik.touched.designation && formik.errors.designation
                    }
                  />
                </div>
              </div>
              <div className="Testimonial_form_Textarea">
              <div>
                <label className="bank_form_label">Content</label>
                <CustomTextArea
                  disabled={isView}
                  name="content"
                  fullWidth
                  multiline
                  maxRows={6}
                  placeholder={"Enter content"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.content}
                />
              </div>
              </div>
              <div>
                <label className="bank_form_label">Rating</label>
                <CustomTextField
                  disabled={isView}
                  name="rating"
                  fullWidth
                  multiline
                  maxRows={10}
                  placeholder={"Enter rating"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.rating}
                />
              </div>

              
            </form>
          </div>
          {!isView && (
            <div className="bank_form_action_button">
              <FormButton
                id="bankCreateSubmit"
                type="submit"
                form="bankform"
                variant="contained"
                text={testimonial_id ? "Update" : "Save"}
              />
              <FormButton
                id="bankCreateCancel"
                variant="outlined"
                text="Cancel"
                onClick={() => navigate(-1)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TestimonialForm;
