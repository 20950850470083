import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  OutlinedInput,
  InputAdornment,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import HttpsOutlinedIcon from "@mui/icons-material/HttpsOutlined";
import NoEncryptionOutlinedIcon from "@mui/icons-material/NoEncryptionOutlined";
import "./PasswordUpdateForm.css";
import FormButton from "../../../../../../../components/formButton/FormButton";
import { useDispatch } from "react-redux";
import { updatePassword } from "../../../../../../../api/apiServices/userApis";
import { SuccessToast } from "../../../../../../../toasts/Toasts";
import ResponseHandler from "../../../../../../../services/ResponseHandler";
function PasswordUpdateForm({id,onclose}) {
  const dispatch=useDispatch()
  const [loading,setLoading]=useState(false)
  const handleMouseDownPassword = (event, fieldID, icon1Id, icon2Id) => {
    event.preventDefault();
    const passwordField = document.getElementById(fieldID);
    const passwordInVisiIcon = document.getElementById(icon2Id);
    const passwordVisiIcon = document.getElementById(icon1Id);
    if (passwordField && passwordInVisiIcon && passwordVisiIcon) {
      if (passwordField.type === "password") {
        passwordField.type = "text";
        passwordInVisiIcon.style.display = "none";
        passwordVisiIcon.style.display = "block";
      } else {
        passwordField.type = "password";
        passwordInVisiIcon.style.display = "block";
        passwordVisiIcon.style.display = "none";
      }
    }
  };
  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .required("New Password is required")
        .min(6, "Password must be at least 6 characters"),
      confirmNewPassword: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("newPassword"), null], "New password must match"),
    }),
    onSubmit: (values) => {
      setLoading(true)
      dispatch(updatePassword(id,values)).then((response)=>{
        ResponseHandler(response)
        onclose(false)
      }).catch((error)=>{
        ResponseHandler(error)
      }).finally(()=>{
        setLoading(false)
      })
    },
  });
  return (
    <div className="updatePassword_Form">
      <form onSubmit={formik.handleSubmit}>
        <p className="updatePassword_form_label">New Password</p>
        <TextField
          className="updatePassword_form_input"
          id="newPassword"
          name="newPassword"
          placeholder={"Enter your new password"}
          variant="outlined"
          type={"password"}
          fullWidth
          size="small"
          autoComplete="off"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.newPassword}
          error={
            formik.touched.newPassword && Boolean(formik.errors.newPassword)
          }
          helperText={formik.touched.newPassword && formik.errors.newPassword}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" >
                <IconButton
                  onMouseDown={(e) => {
                    handleMouseDownPassword(
                      e,
                      "newPassword",
                      "np_visible",
                      "np_not_visible"
                    );
                  }}
                >
                  <NoEncryptionOutlinedIcon
                    id="np_visible"
                    style={{ display: "none" }}
                  />
                  <HttpsOutlinedIcon id="np_not_visible" />
                </IconButton>
              </InputAdornment>
            ),
          }}
          FormHelperTextProps={{
            style: { marginLeft: 0 },
          }}
        />
        <p className="updatePassword_form_label">Confirm New Password</p>
        <TextField
          className="updatePassword_form_input"
          id="confirmNewPassword"
          name="confirmNewPassword"
          placeholder={"Enter your confirm new password"}
          variant="outlined"
          type={"password"}
          fullWidth
          size="small"
          autoComplete="off"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.confirmNewPassword}
          error={
            formik.touched.confirmNewPassword &&
            Boolean(formik.errors.confirmNewPassword)
          }
          helperText={
            formik.touched.confirmNewPassword &&
            formik.errors.confirmNewPassword
          }
          FormHelperTextProps={{
            style: { marginLeft: 0 },
          }}
        />
        <div className="changePassword_form_action">
          <FormButton 
            id = "changePasswordCreateForm_update"
            type= "submit"
            variant="contained"
            loading={loading}
            text= {"Update"}
            />
            <FormButton 
            id = "changePasswordCreateForm_Cancel"
            variant="outlined"
            loading={false}
            text= "Cancel"
            onClick={() => onclose(false)}
            />
        </div>
      </form>
    </div>
  );
}

export default PasswordUpdateForm;
