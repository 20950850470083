import React from 'react'
import { Document, Page, Text, View, Link, Image, PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';
function VehicleDetailsExport() {
  return (
    <div>
        <PDFViewer style={{ width: '100%', height: '80vh' }}>
          <Document>
            <Page size="A4">
              <View>
                <Text>Page 1: Hello, this is a custom PDF content!</Text>
                <Image src={"http://localhost:5000/public/attachments/vehicles/VA_19_2024-02-08T09_18_18.706Z-Test1.jpeg"} style={{ width: 50, height: 50 }} />
                <Link src="https://openai.com">Click here to visit OpenAI website</Link>
              </View>
            </Page>
            <Page size="A4">
              <View>
                <Text>Page 2: This is page 2 content.</Text>
              </View>
            </Page>
          </Document>
        </PDFViewer>
    </div>
  )
}

export default VehicleDetailsExport