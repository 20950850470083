import React, { useState } from "react";
import { Button } from "@mui/material";
import { FiUpload } from "react-icons/fi";
import CancelIcon from "@mui/icons-material/Cancel";
import "./CustomAttachmentContainer.css";
import CustomTextField from "../customTextField/CustomTextField";
import pdf_logo from "../../assets/Pdf_logo.png";
function CustomAttachmentContainer({
  Isview,
  attachments,
  uploadAttachments,
  deleteAttachments,
}) {
  const [seletedAttachment, SetSelectedAttachment] = useState({
    filetype: "",
    title: "",
    file: "",
    uploadedDateTime: "",
    isPublish: false,
  });
  const [ValidText, SetValidText] = useState({
    IsNotValid: false,
    message: "",
  });
  const [validFile, SetValidFile] = useState({
    IsNotValid: false,
    message: "File is required",
  });
  function handleFileSelect(event) {
    const fileInput = event.target;
    const fileInfo = document.getElementById("fileInfo");
    if (fileInput.files.length > 0) {
      const fileName = fileInput.files[0].name;
      SetSelectedAttachment({
        ...seletedAttachment,
        ["file"]: fileInput.files[0],
        uploadedDateTime: new Date().toISOString(),
        filetype: fileInput.files[0].type.split("/")[1],
      });
      fileInfo.textContent = `Selected file: ${
        fileName.length > 5 ? fileName.slice(0, 5) + "..." : fileName
      }`;
    } else {
      fileInfo.textContent = "Choose file";
    }
    if (validFile.IsNotValid) {
      SetValidFile({ ...validFile, ["IsNotValid"]: false });
    }
  }
  function setAttachment(name, value) {
    SetSelectedAttachment({ ...seletedAttachment, [name]: value });
    if (ValidText.IsNotValid) {
      SetValidText({
        ...ValidText,
        ["IsNotValid"]: false,
        message: "Name is required",
      });
    }
  }
  function uploadFile() {
    const getExistingName = attachments.filter((item) =>
      seletedAttachment.title
        .trim()
        .toLowerCase()
        .includes(item.title.trim().toLowerCase())
    );
    // if(getExistingName.length>0){
    //   SetValidText({...ValidText,['IsNotValid']:true,message:"Name alreday exists"})
    // } else
    if (
      !seletedAttachment.title.trim() &&
      seletedAttachment.file.toString().length <= 0
    ) {
      SetValidText({
        ...ValidText,
        ["IsNotValid"]: true,
        message: "Name is required",
      });
      SetValidFile({ ...validFile, ["IsNotValid"]: true });
    } else if (!seletedAttachment.title.trim()) {
      SetValidText({
        ...ValidText,
        ["IsNotValid"]: true,
        message: "Name is required",
      });
    } else if (seletedAttachment.file.toString().length <= 0) {
      SetValidFile({ ...validFile, ["IsNotValid"]: true });
    } else {
      uploadAttachments(seletedAttachment);
      SetSelectedAttachment({
        filetype: "",
        title: "",
        file: "",
        isPublish: false,
        uploadedDateTime: "",
      });
      const fileInfo = document.getElementById("fileInfo");
      fileInfo.textContent = "Choose file";
    }
  }
  function calculateBase64FileSize(base64String) {
    const base64WithoutPrefix = base64String.replace(
      /^(data:[^;]+;base64,)/,
      ""
    );
    const sizeInBytes =
      Math.ceil((base64WithoutPrefix.length * 3) / 4) -
      base64WithoutPrefix.split("=")[1].length;

    if (sizeInBytes < 1024) {
      return sizeInBytes + "Bytes";
    } else if (sizeInBytes < 1024 * 1024) {
      return (sizeInBytes / 1024).toFixed(2) + "KB";
    } else {
      return (sizeInBytes / (1024 * 1024)).toFixed(2) + "MB";
    }
  }
  function DeleteAttachment(index) {
    return deleteAttachments(index);
  }
  return (
    <div className="Custom_attachment_main_container">
      <p className="attachment_title">Attachment</p>
      {!Isview && (
        <div className="input_file_container">
          <div style={{ position: "relative" }}>
            <label htmlFor="files" className="choose_file_field">
              <FiUpload className="file_upload_icon" />
              <span id="fileInfo"> Choose file</span>
            </label>
            <input
              id="files"
              style={{ display: "none" }}
              type="file"
              onChange={handleFileSelect}
            />
            {validFile.IsNotValid ? (
              <p style={{ position: "absolute", color: "red" }}>
                {validFile.message}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div style={{ position: "relative", width: "170px" }}>
            <CustomTextField
              value={seletedAttachment.title}
              placeholder={"Title"}
              onChange={(e) => setAttachment("title", e.target.value)}
            />
            {ValidText.IsNotValid ? (
              <p style={{ position: "absolute", color: "red" }}>
                {ValidText.message}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              size="small"
              variant="contained"
              onClick={uploadFile}
              style={{
                textTransform: "none",
                lineHeight: 2.0,
                fontFamily: "Inter",
              }}
            >
              Upload
            </Button>
          </div>
        </div>
      )}

      <div
        // style={{display:!attachments.images.length===0?'none':'flex'}}
        className="attachment_main_container"
      >
        {attachments &&
          attachments.map((item, index) => {
            return (
              <>
                {item.filetype !== "pdf" && (
                  <div className="attachment_container">
                    <img
                      key={index}
                      src={
                        item.file.toString().startsWith("http://") ||
                        item.file.toString().startsWith("https://")
                          ? item.file
                          : URL.createObjectURL(item.file)
                      }
                      alt={`Uploaded Image ${index + 1}`}
                      className="image_attachment"
                    />
                    <p style={{ textAlign: "center" }}>{item.title}</p>
                    {!Isview && (
                      <div
                        className="attachment_close_icon"
                        onClick={() => {
                          DeleteAttachment(index);
                        }}
                      >
                        <CancelIcon className="attachemnt_cancelIcon" />
                      </div>
                    )}
                  </div>
                )}
              </>
            );
          })}
      </div>
      <div
        className="attachment_main_container"
        // style={{display:!attachments.files.length===0?'none':'flex'}}
      >
        {attachments &&
          attachments.map((item, index) => {
            return (
              <>
                {item.filetype === "pdf" && (
                  <div className="file_attachment_container">
                    <div className="attachment_sub_container">
                      <img
                        key={index}
                        src={pdf_logo}
                        alt={`Uploaded Image ${index + 1}`}
                        className="file_attachment"
                      />
                      <div>
                        <p className="file_title">{item.title + ".pdf"}</p>
                        {/* <p className="file_size">{calculateBase64FileSize(item.file)}</p> */}
                      </div>
                    </div>
                    {!Isview && (
                      <div
                        className="attachment_close_icon"
                        onClick={() => {
                          DeleteAttachment(index);
                        }}
                      >
                        <CancelIcon className="attachemnt_cancelIcon" />
                      </div>
                    )}
                  </div>
                )}
              </>
            );
          })}
      </div>
    </div>
  );
}

export default CustomAttachmentContainer;
