import { ApiConfig } from "../apiConfig"
import { setAllFuels } from "../../store/LookUpStore"
import ResponseHandler from "../../services/ResponseHandler"

export const getAllFuels=(data)=>{
    return  async(dispatch)=>{
        try{
            const response=await ApiConfig.get('fuels',data)
            dispatch(setAllFuels(response.data.data))
           return response.data.data
        }catch(error){
            ResponseHandler(error)
            throw error
        }
    }
}
export const CreateFuel=(value)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.post('fuel/create_new_fuel',value)
           return response
        }catch(error){
            throw error
        }
    }
}
export const UpdateFuel=(id,value)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.put(`fuel/${id}/update_fuel`,value)
           return response
        }catch(error){
            throw error
        }
    }
}
export const DeleteFuel=(id)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.delete(`fuel/${id}/delete_fuel`)
           return response
        }catch(error){
            throw error
        }
    }
}
