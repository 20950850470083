import { createSlice } from "@reduxjs/toolkit";
export const LookUpStoreSlice = createSlice({
  name: "lookUpStore",
  initialState: {
    vehicleTableLoading: false,
    selectedTab: 0,
    defaultChecked: [""],
    WholeApplicationOwnerNames: [],
    BankLookUpDetails: [],
    wholeCurrency: [],
    currencies: [],
    WholeFuels: [],
    Fuels: [],
    WholeLiquidity: [],
    Liquidity: [],
    WholeFinancialSupport: [],
    FinancialSupport: [],
    BankLookUpFilterData: {
      Bank_Name: null,
      Branch_Code: null,
      Email: null,
    },
    LookUpFilterDropDownList: {
      Bank_Name: [],
      Branch_Code: [],
      Email: [],
    },

    CurrencyFilterData: {
      CurrencyName: null,
    },
    CurrencyFilterDropdownList: {
      CurrencyName: [],
    },

    FuelFilterData: {
      FuelName: null,
    },
    FuelFilterDropdownList: {
      FuelName: [],
    },

    LiquidityFilterData: {
      LiquidityName: null,
    },
    LiquidityFilterDropdownList: {
      LiquidityName: [],
    },
    FinancialSupportFilterData: {
      FinancialSupportName: null,
    },
    FinancialSupportFilterDropdownList: {
      FinancialSupportName: [],
    },
  },
  reducers: {
    SetAllApplicationOwnerNames: (state, actions) => {
      state.WholeApplicationOwnerNames = actions.payload;
      state.BankLookUpDetails = actions.payload;
    },

    updateLookUpFilterDropdown: (state) => {
      const stringifyData = JSON.stringify(state.WholeApplicationOwnerNames);
      const parseData = JSON.parse(stringifyData);
      const UniqueBankName = [
        ...new Set(
          parseData
            .filter((item) => item.aonl_name !== null)
            .map((item) => item.aonl_name)
        ),
      ].map((type) => ({ label: type, value: type }));
      const UniqueBranchCode = [
        ...new Set(
          parseData
            .filter((item) => item.aonl_branch_code !== null)
            .map((item) => item.aonl_branch_code)
        ),
      ].map((type) => ({ label: type, value: type }));
      const UniqueEmail = [
        ...new Set(
          parseData
            .filter((item) => item.aonl_email_id !== null)
            .map((item) => item.aonl_email_id)
        ),
      ].map((type) => ({ label: type, value: type }));
      state.LookUpFilterDropDownList = {
        Bank_Name: UniqueBankName,
        Branch_Code: UniqueBranchCode,
        Email: UniqueEmail,
      };
    },
    setLookUpFilterDatas: (state, actions) => {
      state.BankLookUpFilterData = actions.payload;
    },
    searchLookUpData: (state, action) => {
      const WholeApplicationOwnerNames = JSON.stringify(
        state.WholeApplicationOwnerNames
      );
      const FilterBankData = JSON.stringify(state.BankLookUpFilterData);
      const ParseWholeApplicationOwnerNames = JSON.parse(
        WholeApplicationOwnerNames
      );
      const ParseFilterBankData = JSON.parse(FilterBankData);
      let filterData = [];
      if (ParseFilterBankData.Bank_Name.trim()) {
        filterData = ParseWholeApplicationOwnerNames.filter(
          (item) => item.aonl_name === ParseFilterBankData.Bank_Name
        );
      } else {
        filterData = ParseWholeApplicationOwnerNames;
      }
      // if(ParseFilterBankData.Bank_Name){
      //   filterData = filterData.filter((item) => item.aonl_name === ParseFilterBankData.Bank_Name);
      // }

      if (ParseFilterBankData.Branch_Code) {
        filterData = filterData.filter(
          (item) => item.aonl_branch_code === ParseFilterBankData.Branch_Code
        );
      }
      if (ParseFilterBankData.Email) {
        filterData = filterData.filter(
          (item) => item.aonl_email_id === ParseFilterBankData.Email
        );
      }
      state.BankLookUpDetails = filterData;
    },

    setAllCurrency: (state, actions) => {
      state.wholeCurrency = actions.payload;
      state.currencies = actions.payload;
    },

    updateCurrencyFilterDropdown: (state) => {
      const stringifyData = JSON.stringify(state.wholeCurrency);
      const parseData = JSON.parse(stringifyData);
      const UniqueCurrencyName = [
        ...new Set(
          parseData
            .filter((item) => item.cl_currency !== null)
            .map((item) => item.cl_currency)
        ),
      ].map((type) => ({ label: type, value: type }));

      state.CurrencyFilterDropdownList = { CurrencyName: UniqueCurrencyName };
    },
    setCurrencyUpFilterDatas: (state, actions) => {
      state.CurrencyFilterData = actions.payload;
    },
    searchCurrencyData: (state, action) => {
      const wholeCurrency = JSON.stringify(state.wholeCurrency);
      const FilterCurrencyData = JSON.stringify(state.CurrencyFilterData);
      const ParsewholeCurrency = JSON.parse(wholeCurrency);
      const ParseFilterCurrencykData = JSON.parse(FilterCurrencyData);
      let filterData = [];
      if (ParseFilterCurrencykData.CurrencyName.trim()) {
        filterData = ParsewholeCurrency.filter(
          (item) => item.cl_currency === ParseFilterCurrencykData.CurrencyName
        );
      } else {
        filterData = ParsewholeCurrency;
      }
      state.currencies = filterData;
    },

    setAllFuels: (state, actions) => {
      state.WholeFuels = actions.payload;
      state.Fuels = actions.payload;
    },

    updateFuelFilterDropdown: (state) => {
      const stringifyData = JSON.stringify(state.WholeFuels);
      const parseData = JSON.parse(stringifyData);
      const UniqueFuelName = [
        ...new Set(
          parseData
            .filter((item) => item.fl_fuel !== null)
            .map((item) => item.fl_fuel)
        ),
      ].map((type) => ({ label: type, value: type }));

      state.FuelFilterDropdownList = { FuelName: UniqueFuelName };
    },
    setFuelFilterDatas: (state, actions) => {
      state.FuelFilterData = actions.payload;
    },
    searchFuelData: (state, action) => {
      const WholeFuel = JSON.stringify(state.WholeFuels);
      const FilterFuelData = JSON.stringify(state.FuelFilterData);
      const ParseWholeFuels = JSON.parse(WholeFuel);
      const ParseFilterFuelData = JSON.parse(FilterFuelData);
      let FilterdData = [];
      if (ParseFilterFuelData.FuelName.trim()) {
        FilterdData = ParseWholeFuels.filter(
          (item) => item.fl_fuel === ParseFilterFuelData.FuelName
        );
      } else {
        FilterdData = ParseWholeFuels;
      }
      state.Fuels = FilterdData;
    },

    setAllLiquidity: (state, actions) => {
      state.WholeLiquidity = actions.payload;
      state.Liquidity = actions.payload;
    },

    updateLiquidityFilterDropdown: (state) => {
      const stringifyData = JSON.stringify(state.WholeLiquidity);
      const parseData = JSON.parse(stringifyData);
      const UniqueLiquidityName = [
        ...new Set(
          parseData
            .filter((item) => item.ll_liquid !== null)
            .map((item) => item.ll_liquid)
        ),
      ].map((type) => ({ label: type, value: type }));

      state.LiquidityFilterDropdownList = {
        LiquidityName: UniqueLiquidityName,
      };
    },
    setLiquidityFilterDatas: (state, actions) => {
      state.LiquidityFilterData = actions.payload;
    },
    searchLiquidityData: (state, action) => {
      const WholeLiquidity = JSON.stringify(state.WholeLiquidity);
      const FilterLiquidityData = JSON.stringify(state.LiquidityFilterData);
      const ParseWholeLiquidity = JSON.parse(WholeLiquidity);
      const ParseFilterLiquidityData = JSON.parse(FilterLiquidityData);
      let FilterdData = [];
      if (ParseFilterLiquidityData.LiquidityName.trim()) {
        FilterdData = ParseWholeLiquidity.filter(
          (item) => item.ll_liquid === ParseFilterLiquidityData.LiquidityName
        );
      } else {
        FilterdData = ParseWholeLiquidity;
      }
      state.Liquidity = FilterdData;
    },
    setAllFinancialSupport: (state, actions) => {
      state.WholeFinancialSupport = actions.payload;
      state.FinancialSupport = actions.payload;
    },

    updateFinancialSupportFilterDropdown: (state) => {
      const stringifyData = JSON.stringify(state.WholeFinancialSupport);
      const parseData = JSON.parse(stringifyData);
      const UniqueFinancialSupportName = [
        ...new Set(
          parseData
            .filter((item) => item.foel_name !== null)
            .map((item) => item.foel_name)
        ),
      ].map((type) => ({ label: type, value: type }));

      state.FinancialSupportFilterDropdownList = {
        FinancialSupportName: UniqueFinancialSupportName,
      };
    },
    setFinancialSupportFilterDatas: (state, actions) => {
      state.FinancialSupportFilterData = actions.payload;
    },
    searchFinancialSupportData: (state, action) => {
      const WholeFinancialSupport = JSON.stringify(state.WholeFinancialSupport);
      const FilterFinancialSupportData = JSON.stringify(state.FinancialSupportFilterData);
      const ParseWholeFinancialSupport = JSON.parse(WholeFinancialSupport);
      const ParseFilterFinancialSupportData = JSON.parse(FilterFinancialSupportData);
      let FilterdData = [];
      if (ParseFilterFinancialSupportData.FinancialSupportName.trim()) {
        FilterdData = ParseWholeFinancialSupport.filter(
          (item) => item.foel_name === ParseFilterFinancialSupportData.FinancialSupportName
        );
      } else {
        FilterdData = ParseWholeFinancialSupport;
      }
      state.FinancialSupport = FilterdData;
    },
    resetBankLookupData:(state,actions)=>{
      const WholeBankLookupData = JSON.parse(JSON.stringify(state.WholeApplicationOwnerNames));
      state.BankLookUpDetails=WholeBankLookupData
      state.BankLookUpFilterData = { Bank_Name: null,Branch_Code: null,Email: null,}
    },
    resetCurrencyLookupData:(state,actions)=>{
      const WholeCurrencyLookupData = JSON.parse(JSON.stringify(state.wholeCurrency));
      state.currencies=WholeCurrencyLookupData
      state.CurrencyFilterData={CurrencyName: null}
    },
    resetFuelLookupData:(state,actions)=>{
      const WholeFuelLookupData = JSON.parse(JSON.stringify(state.WholeFuels));
      state.Fuels=WholeFuelLookupData
      state.FuelFilterData={FuelName: null}
    },
    resetLiquidityLookupData:(state,actions)=>{
      const WholeLiquidityLookupData = JSON.parse(JSON.stringify(state.WholeLiquidity));
      state.Liquidity=WholeLiquidityLookupData
      state.LiquidityFilterData={LiquidityName: null}
    },
    resetFinancialSupportLookupData:(state,actions)=>{
      const WholeFinancialLookupData = JSON.parse(JSON.stringify(state.WholeFinancialSupport));
      state.FinancialSupport=WholeFinancialLookupData
      state.FinancialSupportFilterData={FinancialSupportName: null}
    }
  },
});
export const {
  SetAllApplicationOwnerNames,
  updateLookUpFilterDropdown,
  setLookUpFilterDatas,
  searchLookUpData,
  setAllCurrency,
  updateCurrencyFilterDropdown,
  setCurrencyUpFilterDatas,
  searchCurrencyData,
  setAllFuels,
  updateFuelFilterDropdown,
  setFuelFilterDatas,
  searchFuelData,
  setAllLiquidity,
  updateLiquidityFilterDropdown,
  setLiquidityFilterDatas,
  searchLiquidityData,
  setAllFinancialSupport,
  updateFinancialSupportFilterDropdown,
  setFinancialSupportFilterDatas,
  searchFinancialSupportData,
  resetBankLookupData,
  resetCurrencyLookupData,
  resetFuelLookupData,
  resetLiquidityLookupData,
  resetFinancialSupportLookupData
} = LookUpStoreSlice.actions;
export default LookUpStoreSlice.reducer;
