import React, { useEffect, useState } from "react";
import "./VehicleFormPage.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BankDetailsForm from "./bankDetailsForm/BankDetailsForm";
import GeneralVehicleCharacteristics from "./generalVehicleCharacteristics/GeneralVehicleCharacteristics";
import EngineCharacteristicsForm from "./engineCharacteristicsForm/EngineCharacteristicsForm";
import VehicleAppraisalForm from "./vehicleAppraisal/VehicleAppraisalForm";
import ConservationStatusAndLiquidityForm from "./conservationStatusAndLiquidityForm/ConservationStatusAndLiquidityForm";
import { useDispatch, useSelector } from "react-redux";
import { updateSelectedTab } from "../../../../../store/vehicleStore";
import AgentVehicleView from "./vehicleAppraisal/AgentVehicleView";
import { LocalStorageServices } from "../../../../../services/localStorageServices";


function VehicleFormPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const { id } = useParams();
  const tabKey = queryParams.get("tk");
  const iSViewAndEdit = queryParams.get("view_edit");
  const selectedTab = useSelector((state) => state.vehicleStore.selectedTab);
  const Privilages = useSelector((state) => state.userStore.Privilages);
  const [isEditable, SetIsEditable] = useState(false);
  function updateIsEditable(isEdit) {
    SetIsEditable(isEdit);
  }
  useEffect(() => {
    if (tabKey) {
      if (selectedTab != Number(tabKey)) {
        dispatch(updateSelectedTab(Number(tabKey)));
      }
    }
  }, [tabKey]);

  const handleChange = (_, newValue) => {
    if (id) {
      if (iSViewAndEdit) {
        navigate(
          `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${id}/update_vehicle?tk=${newValue}&view_edit=${true}`
        );
      } else {
        navigate(
          `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${id}/update_vehicle?tk=${newValue}`
        );
      }
      updateIsEditable(false);
    }
    dispatch(updateSelectedTab(newValue));
  };
  const renderTabContent = (value) => {
    if (isEditable && Number(tabKey) !== selectedTab) {
      updateIsEditable(false);
    }
    switch (value) {
      case 0:
        return <BankDetailsForm updateIsEditable={updateIsEditable} />;
      case 1:
        return (
          <GeneralVehicleCharacteristics updateIsEditable={updateIsEditable} />
        );
      case 2:
        return (
          <EngineCharacteristicsForm updateIsEditable={updateIsEditable} />
        );
      case 3:
        return <VehicleAppraisalForm updateIsEditable={updateIsEditable} />;
      case 4:
        return (
          <ConservationStatusAndLiquidityForm
            updateIsEditable={updateIsEditable}
          />
        );
      default:
        return null;
    }
  };

  // responsive table start
  const IsCollapsed = useSelector((state) => state.mainStore.IsCollapsed);
  const [TableResp, setTableResp] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    if (windowWidth < 999) {
      setTableResp({});
    } else {
      setTableResp({
        overflowX: "auto",
        maxWidth: IsCollapsed ? "calc(100vw - 120px)" : "calc(100vw - 320px)",
      });
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth, IsCollapsed]);

  // responsive table end

  return (
    <div>
    {/* {Privilages && Privilages?.url_role === "Agent" ? (
      <div><AgentVehicleView/></div>
    ) : ( */}
    
    <div style={TableResp}>
      <div className="VehicleForm_header">
        <p className="page_title">
          {id && isEditable
            ? "Update Vehicle"
            : id
            ? "View Vehicle"
            : "Add Vehicle"}
        </p>
        <div
          className="back_button"
          onClick={() => {
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/vehicles`
            );
          }}
        >
          <KeyboardBackspaceIcon />
          <p>Back</p>
        </div>
      </div>
      <div className="vehicle_tabs">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#0F62FE",
              height: 3,
            },
            ".Mui-selected": {
              color: "#0F62FE !important",
            },
            "& .MuiTab-root": {
              marginRight: "50px", // Add margin between tabs
            },
          }}
        >
          <Tab className="mui_vehicle_style" label="Bank Details" value={0} />
          <Tab
            className="mui_vehicle_style"
            label="General Vehicle Characteristics"
            value={1}
            disabled={id ? false : true}
          />
          <Tab
            className="mui_vehicle_style"
            label="Engine Characteristics"
            value={2}
            disabled={id ? false : true}
          />
          <Tab
            className="mui_vehicle_style"
            label="Vehicle Appraisal"
            value={3}
            disabled={id ? false : true}
          />
          <Tab
            className="mui_vehicle_style"
            label="Conservation Status & Liquidity"
            value={4}
            disabled={id ? false : true}
          />
        </Tabs>
      </div>
      {renderTabContent(selectedTab)}
      </div>
      {/* )} */}
    </div>
  );
}

export default VehicleFormPage;
