
import { ApiConfig } from "../apiConfig";
import { SetAllVehicleTasks, setAllVehicles, updateVehicleTableLoading } from "../../store/vehicleStore";
import ResponseHandler from "../../services/ResponseHandler";

export const getAllVehicles =(data)=>{
  return  async(dispatch)=>{
  try {
      dispatch(updateVehicleTableLoading(true))
      const response = await ApiConfig.get('vehicles',data );
      dispatch(setAllVehicles(response.data.data))
      dispatch(updateVehicleTableLoading(false))
    } catch (error) {
      ResponseHandler(error)
      dispatch(updateVehicleTableLoading(false))
    }
  }
}

export const createVehicleBankDetails =(data)=>{
    return  async()=>{
    try {
        const response = await ApiConfig.post('vehicle/create_new_vehicle',data );
        return response
      } catch (error) {
        throw error
      }
    }
}

export const getVehicleBankDetails=(id)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.get(`vehicle/${id}/get_vehicle_bankDetails`);
            return response
          } catch (error) {
            throw error
          }
        }
}

export const updateVehicleBankDetails=(id,data)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.put(`vehicle/${id}/update_vehicle_bankDetails`,data);
            return response
          } catch (error) {
            throw error
          }
    }
}

export const getVehicleDetailsId=(id)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.get(`/vehicle/${id}/get_vehicle_details`);
            return response
          } catch (error) {
            throw error
          }
        }
}

export const updateVehicleDetailsId=(id,data)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.put(`/vehicle/${id}/update_vehicle_details`,data);
            return response
          } catch (error) {
            throw error
          }
        }
}

export const getVehicleEngineDetailsId=(id)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.get(`/vehicle/${id}/get_vehicle_engine_details`);
            return response
          } catch (error) {
            throw error
          }
    }
}

export const updateVehicleEngineDetailsId=(id,data)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.put(`/vehicle/${id}/update_engine_details`,data);
            return response
          } catch (error) {
            throw error
          }
        }
}

export const getVehicleOtherDetailsId=(id)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.get(`/vehicle/${id}/get_vehicle_other_details`);
            return response
          } catch (error) {
            throw error
          }
    }
}

export const updateVehicleOtherDetails=(id,data)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.put(`/vehicle/${id}/update_vehicle_other_details`,data);
            return response
          } catch (error) {
            throw error
          }
    }
}

export const getVehicleAppraisalAndAttachment=(id)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.get(`/vehicle/${id}/get_vehicle_appraisal_attachment_details`);
        return response
      } catch (error) {
        throw error
      }
}
}

export const updateVehicleAppraisalAndAttachment=(id,data)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.put(`/vehicle/${id}/update_vehicle_appraisal_attachment`,data,{
              headers: {
                'Content-Type': 'multipart/form-data',
              }});
            return response
          } catch (error) {
            throw error
          }
    }
}

export const deleteVehicle=(id)=>{
  return  async(dispatch)=>{
    try {
        const response = await ApiConfig.delete(`/vehicle/${id}/delete_vehicle`);
        return response
      } catch (error) {
        throw error
      }
}
}

export const createNewVehicleTask=(id,data)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.post(`/vehicle/${id}/create_new_vehicle_task`,data,{
          headers: {
            'Content-Type': 'multipart/form-data',
          }});
        return response
      } catch (error) {
        throw error
      }
}
}

export const getAllVehicleTasks=(vehicleId)=>{
    return  async(dispatch)=>{
      try {
          const response = await ApiConfig.get(`/vehicle/${vehicleId}/get_vehicle_tasks`);
          dispatch(SetAllVehicleTasks(response.data.data))
          return response
        } catch (error) {
          throw error
        }
  }
}

export const getVehicleTaskByTaskId=(taskId)=>{
  return  async(dispatch)=>{
    try {
        const response = await ApiConfig.get(`/vehicle/${taskId}/get_vehicle_task`);
        return response
      } catch (error) {
        throw error
      }
}
}

export const updateVehicleTaskAssigne=(taskId,data,vehicleId)=>{
  return  async(dispatch)=>{
    try {
        const response = await ApiConfig.put(`/vehicle/${taskId}/update_vehicle_task_assigne`,data);
        if(vehicleId){
          dispatch(getAllVehicleTasks(vehicleId))
        }else{
          dispatch(getvehicleTasksWithoutVehicleId())
        }
        return response
      } catch (error) {
        throw error
      }
  }
}

export const updateVehicleTaskAttachmentIsPublish=(taskId,data)=>{
  return  async(dispatch)=>{
    try {
        const response = await ApiConfig.put(`/vehicle/${taskId}/update_vehicle_task_attachment_isPublish`,{"attachment":data});
        return response
      } catch (error) {
        throw error
      }
  }
}

export const updateVehicleTask=(taskId,data)=>{
  return  async(dispatch)=>{
    try {
        const response = await ApiConfig.put(`/vehicle/${taskId}/update_vehicle_task`,data,{
          headers: {
            'Content-Type': 'multipart/form-data',
          }});
        return response
      } catch (error) {
        throw error
      }
  }
}

export const deleteVehicleTasks=(vehicleId,taskId)=>{
    return  async(dispatch)=>{
      try {
          const response = await ApiConfig.delete(`/vehicle/${taskId}/delete_vehicle_task`);
          if(vehicleId){
            dispatch(getAllVehicleTasks(vehicleId))
          }else{
            dispatch(getvehicleTasksWithoutVehicleId())
          }
          return response
        } catch (error) {
          throw error
        }
    }
}

export const getvehicleTasksWithoutVehicleId=()=>{
  return  async(dispatch)=>{
    try {
        const response = await ApiConfig.get(`/vehicle/get_vehicle_tasks`);
        dispatch(SetAllVehicleTasks(response.data.data))
      } catch (error) {
        ResponseHandler(error)
      }
  }
}

export const updateVehicleTaskByRunner=(taskId,task_status)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.put(`/vehicle/${taskId}/runner/update_vehicle_tasks`,{task_status});
        return response
      } catch (error) {
        throw error
      }
  }
}

export const updateVehicleTaskAttachmentByRunner=(taskId,data)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.put(`/vehicle/${taskId}/runner/update_vehicle_task_attachments`,data,{
          headers: {
            'Content-Type': 'multipart/form-data',
          }});;
        return response
      } catch (error) {
        throw error
      }
  }
}
export const getVehicleTaskNote=(vehicleId,taskId,data)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.get(`/vehicle/${vehicleId}/${taskId}/get_vehicle_task_notes`,data);
        return response.data.data
      } catch (error) {
        throw error
      }
  }
}
export const createVehicleTaskNotesByRunner=(vehicleId,taskId,data)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.post(`/vehicle/${vehicleId}/${taskId}/runner/create_vehicle_task_note`,data);
        return response
      } catch (error) {
        throw error
      }
  }
}
export const updateVehicleTaskNotesByRunner=(note_id,data)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.put(`/vehicle/${note_id}/runner/update_vehicle_task_note`,data);
        return response
      } catch (error) {
        throw error
      }
  }
}
export const deleteVehicleTaskNotesByRunner=(note_id)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.delete(`/vehicle/${note_id}/runner/delete_vehicle_task_note`);
        return response
      } catch (error) {
        throw error
      }
  }
}
export const getAgentVehicleView=(id,data)=>{
  return  async()=>{
      try {
          const response = await ApiConfig.get(`/vehicle/${id}/vehicle_agent_view`,data);
          return response
        } catch (error) {
          throw error
        }
      }
}


////////////////SALE////////////////


export const getVehicleSaleDetailsById = (id) => {
  return ApiConfig.get(`/vehicle/${id}/vehicle_sale_details`)
      .then(response => response.data.data)
      .catch(error => {
          throw error;
      });
};
export const createVehicleSale = (id,data) => {
  return ApiConfig.post(`/vehicle/${id}/create_vehicle_sale`,data)
      .then(response => response)
      .catch(error => {
          throw error;
      });
};

export const updateVehicleSale = (id,data) => {
  return ApiConfig.put(`/vehicle/${id}/update_vehicle_sale`,data)
      .then(response => response)
      .catch(error => {
          throw error;
      });
};