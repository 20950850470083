
import {Routes,Route,Navigate} from 'react-router-dom'
import LoginPage from '../pages/loginPage/LoginPage'
import React from 'react'
import HomePage from '../pages/homePage/HomePage'
import Dashboard from '../pages/homePage/subPages/Dashboard'
import VehicleType from '../pages/homePage/subPages/vehicle/vechicleTypepage/VehicleType'
import PropertysPage from '../pages/homePage/subPages/property/PropertysPage'
import PropertyType from '../pages/homePage/subPages/property/propertyTypePage/PropertyType'
import Users from '../pages/homePage/subPages/user_staff/users/users/Users'
import UserForm from '../pages/homePage/subPages/user_staff/users/userForm/UserForm'
import Roles from '../pages/homePage/subPages/user_staff/roles/Roles'
import Privilages from '../pages/homePage/subPages/user_staff/privilages/Privilages'
import { LocalStorageServices } from '../services/localStorageServices'
import Vehicles from '../pages/homePage/subPages/vehicle/vechiclePage/Vehicles'
import VehicleFormPage from '../pages/homePage/subPages/vehicle/vehicleFormPage/VehicleFormPage'
import VehicleTasks from '../pages/homePage/subPages/vehicle/vehicleTaskPage/VehicleTasks'
import VehicleTaskForm from '../pages/homePage/subPages/vehicle/vehicleTaskFormPage/VehicleTaskForm'
import VehicleTaskViewAndEdit from '../pages/homePage/subPages/vehicle/vehicleTaskViewAndEdit/VehicleTaskViewAndEdit'
import VehicleDetailsExport from '../pages/homePage/subPages/vehicle/vehicleDetailsExportPage/VehicleDetailsExport'
import PropertiesPage from '../pages/homePage/subPages/property/propertiesPage/PropertiesPage'
import PropertyFormPage from '../pages/homePage/subPages/property/propertiesFormPage/PropertyFormPage'
import PropertyTaskPage from '../pages/homePage/subPages/property/propertiesTaskPage/PropertyTaskPage'
import PropertyTaskFormPage from '../pages/homePage/subPages/property/propertiesTaskPage/PropertiesTaskFormPage/PropertyTaskFormPage'
import ProperyTaskViewAndEditPage from '../pages/homePage/subPages/property/propertiesTaskPage/propertyTaskViewAndEditPage/ProperyTaskViewAndEditPage'
import TasksOrJobsPage from '../pages/homePage/subPages/task_or_jobs/TasksOrJobsPage'
import PrivilagesPage from '../pages/homePage/subPages/user_staff/privilages/PrivilagesPage'
import ApplicationForm from '../pages/homePage/subPages/property_or_vehicle_applyForm/ApplicationForm'
import NotificationPage from '../pages/homePage/subPages/notification/NotificationPage'
import MessagePage from '../pages/homePage/subPages/message/MessagePage'
import NotFoundPage from '../pages/notFoundPage/NotFoundPage'
import AgentsPage from '../pages/homePage/subPages/agent/AgentsPage'
import AgentForm from '../pages/homePage/subPages/agent/AgentForm/AgentForm'
import Lookup from '../pages/homePage/subPages/settings/lookup_page/Lookup'
import Profile from '../pages/homePage/subPages/settings/profile_page/Profile'
import BankForm from '../pages/homePage/subPages/settings/lookup_page/sub_components/bankLookUp/bank_form/BankForm'
import RegisteredBidder from "../pages/homePage/subPages/Registered_Bidder/Registeredbidder";
import BidManagement from "../pages/homePage/subPages/Bid_management/BidManagement";
import Reports from "../pages/homePage/subPages/Reports/Reports";
import AgentVehicleView from '../pages/homePage/subPages/vehicle/vehicleFormPage/vehicleAppraisal/AgentVehicleView'
import AgentPropertyView from '../pages/homePage/subPages/property/propertiesFormPage/AgentPropertyView/AgentPropertyView'
import Customers from '../pages/homePage/subPages/customer_portal/customers/Customers'
import InterestApplications from '../pages/homePage/subPages/customer_portal/interest_applications/InterestApplications'
import VehicleInterestsApplicationDetails from '../pages/homePage/subPages/customer_portal/interest_applications/vehicle_interests/VehicleInterestsApplicationDetails'
import PropertyInterestsApplicationDetails from '../pages/homePage/subPages/customer_portal/interest_applications/property_interests/PropertyInterestsApplicationDetails'
import LoanApplications from '../pages/homePage/subPages/customer_portal/loan_applications/LoanApplications'
import VehicleLoanApplicationDetails from '../pages/homePage/subPages/customer_portal/loan_applications/vehicle_loan/VehicleLoanApplicationDetails'
import PropertyLoanApplicationDetails from '../pages/homePage/subPages/customer_portal/loan_applications/property_loan/PropertyLoanApplicationDetails'
import Enquiries from '../pages/homePage/subPages/customer_portal/enquiries/Enquiries'
import ContactUs from '../pages/homePage/subPages/customer_portal/contact_us/ContactUs'
import Testimonials from '../pages/homePage/subPages/customer_portal/Testimonials/Testimonials'
import TestimonialForm from '../pages/homePage/subPages/customer_portal/Testimonials/TestimonialsForm'
import ForgetPassword from '../pages/forgetPasswordPage/ForgetPassword'
function UiRoutes() {
  return (
    <Routes>
        <Route path="/" element={<Navigate to={LocalStorageServices.getLocalStorageData()?`/admin_portal/${LocalStorageServices.getUserRole()}/home`:`/admin_portal/login`}/>}/>
        <Route path="/admin_portal/login" replace element={<LoginPage/>}/>
        <Route path="/admin_portal/not_found" replace element={<NotFoundPage/>}/>
        <Route path="/admin_portal/forget_password" replace element={<ForgetPassword/>}/>
        <Route path='/admin_portal/:role/home' element={<HomePage/>}>
           <Route index element={<Navigate to={`dashboard`}/>}/>
           <Route path='dashboard' element={<Dashboard/>}/>
           <Route path='vehicle'>
              <Route index element={<Navigate to={`vehicles`}/>}/>
              <Route path ='vehicles' element={<Vehicles/>}/>
              <Route path ='add_vehicle' element={<VehicleFormPage/>}/>
              <Route path =':id/update_vehicle' element={<VehicleFormPage/>}/>
              <Route path ='vehicle_type' element={<VehicleType/>}/>
              <Route path=':id/vehicle_tasks' element={<VehicleTasks />} />
              <Route path=':id/create_task' element={<VehicleTaskForm/>} />
              <Route path=':id/view_and_edit_vehicle_task' element={<VehicleTaskViewAndEdit/>} />
              <Route path=':vehicle_id/apply' element={<ApplicationForm/>} />
              <Route path=':id/vehicle_details_export' element={<VehicleDetailsExport/>} />
              <Route path=':id/AgentVehicle_View' element={<AgentVehicleView/>} />
           </Route>
           <Route path='property'>
              <Route index element={<Navigate to={`properties`}/>}/>
              <Route path ='properties' element={<PropertiesPage/>}/>
              <Route path ='property_type' element={<PropertyType/>}/>
              <Route path ='add_properties' element={<PropertyFormPage/>}/>
              <Route path =':property_id/update_properties' element={<PropertyFormPage/>}/>
              <Route path =':property_id/property_task' element={<PropertyTaskPage />}/>
              <Route path =':property_id/create_property_task' element={<PropertyTaskFormPage/>}/>
              <Route path =':property_task_id/view_and_edit_property_task' element={<ProperyTaskViewAndEditPage/>}/>
              <Route path=':property_id/apply' element={<ApplicationForm/>} />
              <Route path=':id/AgentProperty_View' element={<AgentPropertyView/>} />
           </Route>
           <Route path="tasks_or_jobs"  element={<TasksOrJobsPage/>}/>
           <Route path='user_staff'>
              <Route path ='users' element={<Users/>}/>
              <Route path =':id/update_user' element={<UserForm/>}/>
              <Route path ='create_user' element={<UserForm/>}/>
              <Route path ='roles' element={<Roles/>}/>
              <Route path ='privilages' element={<PrivilagesPage/>}/>
           </Route>
           <Route path='agent'>
              <Route path ='agents' element={<AgentsPage/>}/>
              <Route path ='create_agent' element={<AgentForm/>}/>
              <Route path =':agent_id/agent_details' element={<AgentForm/>}/>
           </Route>
           <Route path='customer_portal'>
              <Route path ='customers' element={<Customers/>}/>
              <Route path ='interest_applications' element={<InterestApplications/>}/>
              <Route path =':vehicle_id/vehicle_interest_applications' element={<VehicleInterestsApplicationDetails/>}/>
              <Route path =':property_id/property_interest_applications' element={<PropertyInterestsApplicationDetails/>}/>
              <Route path ='loan_applications' element={<LoanApplications/>}/>
              <Route path =':vehicle_id/vehicle_loan_applications' element={<VehicleLoanApplicationDetails/>}/>
              <Route path =':property_id/property_loan_applications' element={<PropertyLoanApplicationDetails/>}/>
              <Route path ='enquires' element={<Enquiries/>}/>
              <Route path ='contact_us' element={<ContactUs/>}/>
              <Route path ='testimonials' element={<Testimonials/>}/>
              <Route path ='testimonials/testimonial_form' element={<TestimonialForm/>}/>
              <Route path ='testimonials/:testimonial_id/testimonial_form' element={<TestimonialForm/>}/>
           </Route>
           <Route path='settings'>
              <Route path ='lookup' element={<Lookup/>}/>
              <Route path ='lookup/Bank_form' element={<BankForm/>}/>
              <Route path ='lookup/:Bank_id/Bank_form' element={<BankForm/>}/>
              <Route path ='profile' element={<Profile/>}/>
           </Route>
           <Route path='notification' element={<NotificationPage/>}/>
           <Route path='message' element={<MessagePage/>}/>
        <Route path="Registeredbidder" element={<RegisteredBidder/>} />
        <Route path="Bidmanagement" element={<BidManagement />} />
        <Route path="reports" element={<Reports />} />
        <Route path="notification" element={<NotificationPage />} />
        <Route path="message" element={<MessagePage />} />
        </Route>
      <Route path="/*" element={<Navigate to={"/admin_portal/not_found"} />} />
    </Routes>
  );
}

export default UiRoutes;
