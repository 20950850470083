import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import VehicleInterestApplication from './vehicle_interests/VehicleInterestApplication';
import PropertyInterestApplication from './property_interests/PropertyInterestApplication';
import { LocalStorageServices } from '../../../../../services/localStorageServices';
import { useDispatch } from 'react-redux';
import { setRefresh } from '../../../../../store/usersStore';

function InterestApplications() {
    const navigate = useNavigate()
    const dispatch=useDispatch()
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const selected_interest_type = queryParams.get("selected_intrerest_type");
    const [selectedLookup, setselectedLookup] = useState(null)
    useEffect(() => {
       
        if (selected_interest_type) {
            setselectedLookup(selected_interest_type)
        } else {
            navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/interest_applications?selected_intrerest_type=${1}`);
        }
    }, [selected_interest_type])
    const lookUpItems = [
        { id: 1, label: 'Vehicle' },
        { id: 2, label: 'Property' },
    ];

    const handleLookUpClick = (id) => {
        navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/interest_applications?selected_intrerest_type=${id}`);
    };

    function UpdateTabData(id) {
        switch (id) {
            case '1':
                return <VehicleInterestApplication />;
            case '2':
                return <PropertyInterestApplication />;
            default:
                return <VehicleInterestApplication />;
        }
    }
    return (
        <div>
            <h3 className="page_title">Interest Application</h3>
            <div className='lookUp_list_tags'>
                {lookUpItems.map(item => (
                    <p className='lookUp_tag' key={item.id}
                        onClick={() => handleLookUpClick(item.id)}
                        style={{
                            color: selectedLookup === `${item.id}` ? 'white' : 'black',
                            backgroundColor: selectedLookup === `${item.id}` ? '#0F62FE' : 'white'
                        }}>{item.label}</p>
                ))}

            </div>
            {UpdateTabData(selectedLookup)}
        </div>
    )
}

export default InterestApplications