import {toast} from 'react-toastify'

const ErrorToast=(message)=>{
    return toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: 0,
        theme: "light"
        });
}
const SuccessToast=(message)=>{
    return toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: 0,
        theme: "light"
        });
}

const InfoToast=(message)=>{
    return toast.info(message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: 0,
        theme: "light"
        });
}

export {ErrorToast,SuccessToast,InfoToast}