import i18n from "i18next";
import { initReactI18next } from "react-i18next";
 import esTranslation from './es.json'
  i18n
      .use(initReactI18next) // passes i18n down to react-i18next
      .init({
          resources: {
              es: {
                 translation:esTranslation
              }
          },
          lng: "en", // if you're using a language detector, do not define the lng option
          fallbackLng: "en",
          interpolation: {
              escapeValue: false // react already safes from xss
          }
});

export default i18n