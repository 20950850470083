import { useDispatch } from "react-redux"
import { ErrorToast, SuccessToast } from "../../toasts/Toasts"
import { ApiConfig } from "../apiConfig"
import { filterByRoleName, setAllRoles, updateRoleTableLoading } from "../../store/rolesStore"
import NavigateUnauthorized from "../NavigateUnauthorized"
import ResponseHandler from "../../services/ResponseHandler"


export const GetAllRoles =()=>{
    return  async(dispatch)=>{
    try {
        dispatch(updateRoleTableLoading(true));
        const response = await ApiConfig.get('/role/roles');
        dispatch(setAllRoles(response.data.data));
        dispatch(filterByRoleName())
        dispatch(updateRoleTableLoading(false))
        return response.data.data
      } catch (error) {
        dispatch(updateRoleTableLoading(false))
        ResponseHandler(error)
      }
    }
} 

export const CreateNewRole = (data)=>{
    return  async(dispatch)=>{
        try {
            const response = await ApiConfig.post('role/create_new_role',data );
            dispatch(GetAllRoles());
            return response
          } catch (error) {
            throw error; 
          }
        }
}

export const UpdateRole=(id,data)=>{
    return  async(dispatch)=>{
        try {
            const response = await ApiConfig.put(`/role/${id}/update_role`,data);
            dispatch(GetAllRoles());
            return response
          } catch (error) {
            throw error; 
          }
        }
}

export const DeleteRole=(id)=>{
    return  async(dispatch)=>{
        try {
            const response = await ApiConfig.delete(`/role/${id}/delete_role`);
            dispatch(GetAllRoles());
            return response
          } catch (error) {
            throw error
          }
    }
}