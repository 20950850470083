import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import { getPropertyTaskById } from '../../../../../../api/apiServices/propertyApi'
import ResponseHandler from '../../../../../../services/ResponseHandler'
import PropertyTaskFormPage from '../PropertiesTaskFormPage/PropertyTaskFormPage'
import PropertyTaskView from './propertyTaskView/PropertyTaskView'

function ProperyTaskViewAndEditPage() {
  const {property_task_id}=useParams()
  const dispatch=useDispatch()
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const isEdit = queryParams.get('edit');
  const [isedit,SetisEdit]=useState(false)
  const [ResponseData,SetResponseData]=useState(null)
  const [DataUpdated,SetDataUpdated]=useState(false)
  useEffect(()=>{
    if(property_task_id){
      if(isEdit){
        SetisEdit(JSON.parse(isEdit))
      }
      dispatch(getPropertyTaskById(property_task_id)).then((response)=>{
        SetResponseData(response.data.data)
      }).catch((error)=>{
        ResponseHandler(error)
      })
    }
  },[property_task_id,isEdit,DataUpdated])
  function UpdateData(){
    SetDataUpdated(!DataUpdated)
  }
  return (
    <>
    {isedit?<PropertyTaskFormPage PropertyTaskData={ResponseData}/>:  <PropertyTaskView PropertyTaskData={ResponseData} UpdateData={UpdateData}/>}
    </>
  )
}

export default ProperyTaskViewAndEditPage