import React, { useEffect, useState } from 'react';
import { FaChevronDown } from 'react-icons/fa6';
import './customYearsDropDownStyle.css';

function CustomYearsDropDown({ selected, list, setSelectedYear }) {
    const [dropdownVisibility, setDropdownVisibility] = useState(false);

    useEffect(() => {
        setDropdownVisibility(false);
    }, [selected]);

    // Check if the list is empty
    const isListEmpty = list.length === 0;

    return (
        <div className='custom_years_dropdown_main_container'>
            <div 
                className='custom_years_dropdown_label_container' 
                onClick={() => !isListEmpty && setDropdownVisibility(!dropdownVisibility)}
            >
                <p>{isListEmpty ? 'No Data' : selected}</p>
                <FaChevronDown 
                    style={{ color: 'rgb(189, 189, 189)', visibility: isListEmpty ? 'hidden' : 'visible' }} 
                />
            </div>
            {!isListEmpty && dropdownVisibility && (
                <div className='custom_years_dropdown_item_container'>
                    {list.map((item) => (
                        <p 
                            key={item}
                            onClick={() => {
                                setSelectedYear(item);
                                setDropdownVisibility(false);
                            }}
                        >
                            {item}
                        </p>
                    ))}
                </div>
            )}
        </div>
    );
}

export default CustomYearsDropDown;
