import { TextField, Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./messageListStyle.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getMessageBasedOnUser } from "../../../../../api/apiServices/messageApis";
import ResponseHandler from "../../../../../services/ResponseHandler";
import { socketServices } from "../../../../../services/socketServices";
import { LocalStorageServices } from "../../../../../services/localStorageServices";
import Picker from "emoji-picker-react";
import { MdOutlineEmojiEmotions } from "react-icons/md";
import { IoSendSharp } from "react-icons/io5";
import { DateTimeConvertionService } from "../../../../../services/DateTimeConvertionService";
import ProfileDetails from "../profileDetails/ProfileDetails";
import VoiceRecording from "../subModules/voiceRecording/VoiceRecording";
import { MdOutlineDelete } from "react-icons/md";
import CustomBounceLoader from "../../../../../components/customBounceLoader/CustomBounceLoader";
function MessageList() {
  const dispath = useDispatch();
  const { search } = useLocation();
  const messageSended = useSelector(
    (state) => state.messageStore.messageSended
  );
  const messageReceived = useSelector(
    (state) => state.messageStore.messageReceived
  );
  const queryParams = new URLSearchParams(search);
  const user_id = queryParams.get("user");
  const [isVoiceMessageEnabled, setIsVoiceMessageEnabled] = useState(false);
  const [audioUrl, setAudioUrl] = useState(null);
  const [audioBlob, setAudioBlob] = useState(null);
  const [messages, SetMessages] = useState([]);
  const [messageStructure, setMessageStructure] = useState(null);
  const [isUpdated, setIsUpdated] = useState(false);
  const [emojiVisibility, SetEmojiVisibility] = useState(false);
  const messageContainerRef = useRef(null);
  const [errorMessage, setErrorMessage] = React.useState("");
  useEffect(() => {
    if (user_id) {
      dispath(getMessageBasedOnUser(user_id))
        .then((response) => {
          SetMessages(response);
          let CurrentUserDetails =
            LocalStorageServices.getLocalStorageDataAndParse();
          setMessageStructure({
            from: CurrentUserDetails?.up_id,
            to: user_id,
            message: "",
            message_type: null,
          });
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
  }, [user_id, messageSended, messageReceived]);
  useEffect(() => {
    if (messageContainerRef.current) {
      messageContainerRef.current.scrollTop =
        messageContainerRef.current.scrollHeight;
    }
  }, [messages, messageSended, messageReceived]);
  const onEmojiClick = (emojiObject, value) => {
    setMessageStructure({
      ...messageStructure,
      ["message"]: messageStructure.message + emojiObject.emoji,
      ["message_type"]: 0,
    });
    SetEmojiVisibility(!emojiVisibility);
  };

  const onUpdateRecording = (isRecording) => {
    setMessageStructure({
      ...messageStructure,
      ["message"]: "",
      ["message_type"]: null,
    });
    setIsVoiceMessageEnabled(isRecording);
    onUpdateAudioUrl(null);
  };

  const onUpdateAudioUrl = (audioUrl) => {
    setAudioUrl(audioUrl);
  };

  const onUpdateAudioBlob = (audioBlob) => {
    setAudioBlob(audioBlob);
  };
  const onResetVoiceMessageSection = () => {
    setIsVoiceMessageEnabled(false);
    setAudioUrl(null);
    setAudioBlob(null);
  };
  const sendRecording = (audioBlob) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      const base64AudioMessage = reader.result.split(",")[1];
      socketServices.sendAudioMessage({
        ...messageStructure,
        ["message"]: base64AudioMessage,
        ["message_type"]: 1,
      });
      setMessageStructure({
        ...messageStructure,
        ["message"]: base64AudioMessage,
        ["message_type"]: 1,
      });
      onResetVoiceMessageSection();
    };
    reader.readAsDataURL(audioBlob);
  };

  const handleSendClick = () => {
    if (
      messageStructure?.message?.trim().length === 0 &&
      !isVoiceMessageEnabled
    ) {
      setErrorMessage("Type something");
    } else if (isVoiceMessageEnabled && audioUrl && audioBlob) {
      sendRecording(audioBlob);
      setErrorMessage(""); // Clear any previous error message
    } else {
      if (messageStructure?.message?.trim().length !== 0) {
        socketServices.sendMessage(messageStructure);
        setIsUpdated(!isUpdated);
        setErrorMessage(""); // Clear any previous error message
      }
    }
  };
  // Function to send the message
  const sendMessage = () => {
    // Clear any existing error message
    setErrorMessage("");

    // Your logic to send the message
    // For example, making an API call or updating state to include the new message
    console.log("Message sent:", messageStructure.message);

    // Check if message is not empty before sending
    if (messageStructure.message.trim() !== "") {
      // Simulate sending the message
      // You can replace this with your actual message sending logic, e.g., API call
      // Example: sendMessageToServer(messageStructure.message);

      // Clear the message field after sending
      setMessageStructure({
        ...messageStructure,
        message: "",
      });
    } else {
      // If the message is empty, set an appropriate error message
      setErrorMessage("Message cannot be empty");
    }
  };

  return (
    <div className="message_listing_super_container">
      {user_id ? (
        <>
          <div className="message_profile_super_container">
            <ProfileDetails />
          </div>
          <div
            id="message_listing_main_container"
            ref={messageContainerRef}
            className="message_listing_main_container"
          >
            {messages &&
              messages.map((item) => (
                <div
                  className="message_text_container"
                  style={{
                    justifyContent:
                      item.m_to_id == user_id ? "flex-end" : "flex-start",
                  }}
                >
                  <div>
                    {item.m_message_type === 1 ? (
                      <audio
                        className="message_audio_controller"
                        controls
                        src={item.m_message}
                      ></audio>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          justifyContent:
                            item.m_to_id == user_id ? "end" : "start",
                        }}
                      >
                        <p
                          className="message_text"
                          style={{
                            backgroundColor:
                              item.m_to_id == user_id ? "#5570F1" : "#E9F2FF",
                            color: item.m_to_id == user_id ? "white" : "black",
                            // textAlign: item.m_to_id == user_id ? "right" : "left",
                          }}
                        >
                          {item.m_message.replace(/\s+/g, " ").trim()}
                        </p>
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent:
                          item.m_to_id == user_id ? "end" : "start",
                      }}
                    >
                      <p
                        className="message_date_time"
                        style={{
                          textAlign: item.m_to_id == user_id ? "right" : "left",
                        }}
                      >
                        {DateTimeConvertionService.TimeConverter(
                          item.m_date_time
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div>
            <div className="message_listing_main_input_send_container">
              {!isVoiceMessageEnabled ? (
                <>
                  <div className="message_emoji_main_container">
                    {emojiVisibility && (
                      <div className="message_emoji_sub_container">
                        <Picker onEmojiClick={onEmojiClick} />
                      </div>
                    )}
                    <div
                      onClick={() => {
                        SetEmojiVisibility(!emojiVisibility);
                      }}
                    >
                      <MdOutlineEmojiEmotions className="message_emoji" />
                    </div>
                  </div>
                  <div className="message_input_container">
                    {errorMessage && (
                      <div className="error_text_msg visible">
                        {errorMessage}
                      </div>
                    )}
                    {!errorMessage && <div className="error_text_msg"></div>}
                    <textarea
                      className="message_text_field"
                      rows={1}
                      placeholder="Enter the message"
                      value={messageStructure?.message}
                      onChange={(e) => {
                        setErrorMessage(""); // Clear the error message
                        setMessageStructure({
                          ...messageStructure,
                          message: e.target.value,
                          message_type: 0,
                        });
                      }}
                      onKeyDown={(e) => {
                        if (e.key === "Enter" && !e.shiftKey) {
                          // Check if Enter key is pressed without Shift
                          e.preventDefault(); // Prevent default Enter key behavior (e.g., new line)
                          handleSendClick(); // Call the function to send the message
                        }
                      }}
                    />
                  </div>
                </>
              ) : audioUrl ? (
                <>
                  <div className="message_audio_controller_main_container">
                    <audio
                      className="message_audio_controller"
                      controls
                      src={audioUrl}
                    ></audio>
                    <MdOutlineDelete
                      className="message_audio_delete"
                      onClick={() => {
                        onUpdateRecording(false);
                      }}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="dummy_recording_container">
                    <CustomBounceLoader />
                  </div>
                </>
              )}
              {/* <div
                style={{ display: "flex", alignItems: "center", gap: 5 }}
                onClick={() => {
                  if (messageStructure.message.length > 0) {
                    socketServices.sendMessage(messageStructure);
                    setIsUpdated(!isUpdated);
                  } else if (isVoiceMessageEnabled && audioUrl && audioBlob) {
                    sendRecording(audioBlob);
                  }
                }}
              > */}
              <div
                style={{ display: "flex", alignItems: "center", gap: 5 }}
                onClick={handleSendClick}
              >
                <VoiceRecording
                  isVoiceEnabled={onUpdateRecording}
                  updateAudioUrl={onUpdateAudioUrl}
                  updateAudioBlob={onUpdateAudioBlob}
                />
                {isVoiceMessageEnabled && !audioUrl ? (
                  <></>
                ) : (
                  <IoSendSharp className="message_send" />
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="message_helper_text">
            <p>Please select a user to send a message or view messages.</p>
          </div>
        </>
      )}
    </div>
  );
}

export default MessageList;
