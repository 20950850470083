import { createSlice } from "@reduxjs/toolkit";

export const homePageSlices=createSlice({
    name:'homeStore',
    initialState:{
        IsCollapsed:false,
        user_details:{},
        notifications:[]
    },
    reducers:{
        updateCollapsedState:(state)=>{
            state.IsCollapsed=!state.IsCollapsed
        },
        setUserDetails:(state,action)=>{
            state.user_details=action.payload
        },
        setNotifications:(state,action)=>{
            state.notifications=action.payload
        }
    }
})

export const {updateCollapsedState,setUserDetails,setNotifications}=homePageSlices.actions

export default homePageSlices.reducer