import ResponseHandler from "../../services/ResponseHandler";
import { SetAllPropertyTasks, setAllProperty } from "../../store/propertiesStore";
import { ApiConfig } from "../apiConfig"

export const getAllproperties =()=>{
    return  async(dispatch)=>{
    try {
        const response = await ApiConfig.get('properties');
        dispatch(setAllProperty(response.data.data))
      } catch (error) {
        ResponseHandler(error)
      }
    }
}

export const getPropertyBankDetails=(property_id)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.get(`property/${property_id}/get_property_bank_details`);
            return response
          } catch (error) {
            throw error
          }
    }
}

export const getPropertyDetails=(property_id)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.get(`property/${property_id}/get_property_details`);
            return response
          } catch (error) {
            throw error
          }
    }
}

export const getPropertyAppraisalDetails=(property_id)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.get(`property/${property_id}/get_property_appraisal`);
            return response
          } catch (error) {
            throw error
          }
    }
}

export const getPropertyConstructionDetails=(property_id)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.get(`property/${property_id}/get_property_construction_details`);
            return response
          } catch (error) {
            throw error
          }
    }
}

export const getPropertyAttachment=(property_id)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.get(`property/${property_id}/get_property_attachment`);
            return response
          } catch (error) {
            throw error
          }
    }
}

export const getPropertyLiquidityRating=(property_id)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.get(`property/${property_id}/get_property_liquidity_rating`);
            return response
          } catch (error) {
            throw error
          }
    }
}

export const createNewPropertyAndOthers=(data)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.post('property/create_new_property',data)
            return response
        }catch(error){
            throw error
        }
    }
}

export const updatePropertyBankDetails=(property_id,data)=>{
    return async()=>{
        try {
            const response =await ApiConfig.put(`property/${property_id}/update_property_bank_details`,data)
            return response
        } catch (error) {
            throw error
        }
    }
}

export const updatePropertyDetails=(property_id,data)=>{
    return async()=>{
        try {
            const response =await ApiConfig.put(`property/${property_id}/update_property_details`,data)
            return response
        } catch (error) {
            throw error
        }
    }
}

export const updatePropertyAppraisal=(property_id,data)=>{
    return async()=>{
        try {
            const response =await ApiConfig.put(`property/${property_id}/update_property_appraisal`,data)
            return response
        } catch (error) {
            throw error
        }
    }
}

export const updatePropertyConstructionDetails=(property_id,data)=>{
    return async()=>{
        try {
            const response =await ApiConfig.put(`property/${property_id}/update_property_construction_details`,data)
            return response
        } catch (error) {
            throw error
        }
    }

}

export const updatePropertyLiquidityRating=(property_id,data)=>{
    return async()=>{
        try {
            const response =await ApiConfig.put(`property/${property_id}/update_property_liquidity_rating`,data)
            return response
        } catch (error) {
            throw error
        }
    }

}

export const updatePropertyAttachment=(property_id,data)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.put(`property/${property_id}/update_property_attachment`,data,{
              headers: {
                'Content-Type': 'multipart/form-data',
              }});
            return response
          } catch (error) {
            throw error
          }
    }
}

export const createNewPropertyTask=(property_id,data)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.post(`property/${property_id}/create_new_property_task`,data,{
              headers: {
                'Content-Type': 'multipart/form-data',
              }});
            return response
          } catch (error) {
            throw error
          }
    }
}

export const getPropertyTasks=(property_id)=>{
    return  async(dispatch)=>{
        try {
            const response = await ApiConfig.get(`property/${property_id}/get_property_tasks`);
            dispatch(SetAllPropertyTasks(response.data.data))
          } catch (error) {
            ResponseHandler(error)
          }
    }
}

export const getPropertyTaskById=(task_id)=>{
    return  async(dispatch)=>{
        try {
            const response = await ApiConfig.get(`property/${task_id}/get_property_task`);
            return response
          } catch (error) {
            throw error
          }
    }
}

export const updatePropertyTask=(task_id,data)=>{
    return  async(dispatch)=>{
        try {
            const response = await ApiConfig.put(`property/${task_id}/update_property_task`,data);
            return response
          } catch (error) {
            throw error
          }
    }
}

export const updatePropertyTaskAssigne=(property_id,task_id,data)=>{
    return  async(dispatch)=>{
        try {
            const response = await ApiConfig.put(`property/${task_id}/update_property_task_assigne`,data);
            if(property_id){
              dispatch(getPropertyTasks(property_id))
            }else{
              dispatch(getpropertyTasksWithoutPropertyId())
            }
            return response
          } catch (error) {
            throw error
          }
    }
}

export const updatePropertyTaskAttachmentIsPublish=(task_id,data)=>{
    return  async(dispatch)=>{
        try {
            const response = await ApiConfig.put(`property/${task_id}/update_property_task_attachment_isPublish`,{"attachments":data});
            return response
          } catch (error) {
            throw error
          }
    }

}

export const deletePropertyTaskById=(property_id,task_id)=>{
    return  async(dispatch)=>{
        try {
            const response = await ApiConfig.delete(`property/${task_id}/delete_property_task`);
            if(property_id){
              dispatch(getPropertyTasks(property_id))
            }else{
              dispatch(getpropertyTasksWithoutPropertyId())
            }
            return response
          } catch (error) {
            throw error
          }
    }
}

export const deletePropery=(property_id)=>{
    return  async(dispatch)=>{
        try {
            const response = await ApiConfig.delete(`property/${property_id}/delete_property`);
            dispatch(getAllproperties())
            return response
          } catch (error) {
            throw error
          }
    }
}

export const getpropertyTasksWithoutPropertyId=()=>{
  return  async(dispatch)=>{
    try {
        const response = await ApiConfig.get(`property/get_property_tasks`);
        dispatch(SetAllPropertyTasks(response.data.data))
      } catch (error) {
        ResponseHandler(error)
        throw error
      }
}
}

export const updatePropertyTaskStatusByRunner=(taskId,task_status)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.put(`/property/${taskId}/runner/update_property_task`,{task_status});
        return response
      } catch (error) {
        throw error
      }
  }
}

export const updatePropertyTaskAttachmentByRunner=(taskId,data)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.put(`/propery/${taskId}/runner/update_property_task_attachments`,data,{
          headers: {
            'Content-Type': 'multipart/form-data',
          }});;
        return response
      } catch (error) {
        throw error
      }
  }
}
export const createProperyTaskNotesByRunner =(property_id,taskId,data)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.post(`/property/${property_id}/${taskId}/runner/create_property_task_note`,data);
        return response
      } catch (error) {
        throw error
      }
  }
}
export const updatePropertyTaskNotesByRunner=(note_id,data)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.put(`/property/${note_id}/runner/update_property_task_note`,data);
        return response
      } catch (error) {
        throw error
      }
  }
}
export const deletePropertyTaskNotesByRunner=(note_id,data)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.delete(`/property/${note_id}/runner/delete_property_task_note`,data);
        return response
      } catch (error) {
        throw error
      }
  }
}
export const getPropertyTaskNote=(property_id,taskId,data)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.get(`/property/${property_id}/${taskId}/get_property_task_notes`,data);
        return response.data.data
      } catch (error) {
        throw error
      }
  }
}

////////////////////////////////////////

export const getPropertySaleDetailsById = (id) => {
  return ApiConfig.get(`/property/${id}/property_sale_details`)
      .then(response => response.data.data)
      .catch(error => {
          throw error;
      });
};
export const createPropertySale = (id,data) => {
  return ApiConfig.post(`/property/${id}/create_property_sale`,data)
      .then(response => response)
      .catch(error => {
          throw error;
      });
};

export const updatePropertySale = (id,data) => {
  return ApiConfig.put(`/property/${id}/update_property_sale`,data)
      .then(response => response)
      .catch(error => {
          throw error;
      });
};