import { TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import './RoleForm.css'
import { useFormik } from "formik";
import * as Yup from "yup";
import FormButton from '../../../../../../../components/formButton/FormButton';
import { useDispatch } from 'react-redux';
import { CreateNewRole, UpdateRole } from '../../../../../../../api/apiServices/roleApis';
import ResponseHandler from '../../../../../../../services/ResponseHandler';
function RoleForm({EditDetails,onclose}) {
  const [loading,setLoading]=useState(false)
  const dispatch=useDispatch()
  useEffect(() => {
    if (EditDetails) {
      formik.setValues({
        ...formik.values,
        role: EditDetails?EditDetails.data.role:''
      });
    }
  }, [EditDetails]);
  const formik = useFormik({
    initialValues: {
      role: ""
    },
    validationSchema: Yup.object({
      role: Yup.string().required("Role is required")
    }),
    onSubmit: (values) => {
      setLoading(true)
      if(!EditDetails){
      dispatch(CreateNewRole(values)).then((response)=>{
        ResponseHandler(response)
        onclose(false)
      }).catch((error) => {
        ResponseHandler(error)
      }).finally(() => {
        setLoading(false);
      })}else{
        dispatch(UpdateRole(EditDetails.data.id,values)).then((response)=>{
          ResponseHandler(response)
          onclose(false)
        }).catch((error) => {
          ResponseHandler(error)
        }).finally(() => {
          setLoading(false);
        })
      }
    },
  });
  return (
    <div className="role_Form">
      <form onSubmit={formik.handleSubmit}>
        <p className="role_form_label">Role</p>
        <TextField
          name="role"
          className='role_form_input'
          autoComplete='off'
          value={formik.values.role}
          size="small"
          placeholder="Enter Role"
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.role && Boolean(formik.errors.role)}
          helperText={formik.touched.role && formik.errors.role}
          FormHelperTextProps={{
            style: { marginLeft: 0 },
          }}
        />
        <div className="role_form_action">
          <FormButton 
            id = "roleCreateForm"
            type= "submit"
            variant="contained"
            loading={loading}
            text= {EditDetails ? "Update" : "Add"}
            />
            <FormButton 
            id = "roleCreateForm"
            variant="outlined"
            loading={false}
            text= "Cancel"
            onClick={() => onclose(false)}
            />
        </div>
      </form>
      
    </div>
  )
}

export default RoleForm