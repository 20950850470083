import React, { useEffect, useState } from "react";
import "./VehicleAppraisalForm.css";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import FormButton from "../../../../../../components/formButton/FormButton";
import CustomDatePicker from "../../../../../../components/customDatePicker/CustomDatePicker";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getAllCurrency } from "../../../../../../api/apiServices/currencyApi";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import CustomAutoComplete from "../../../../../../components/customAutoComplete/CustomAutoComplete";
import VehicleAppraisalAttachment from "./vehicleAppraisalAttachment.tsx/VehicleAppraisalAttachment";
import map_icon from "../../../../../../assets/map_icon.png";
import CustomMap from "../map/CustomMap";
import { transform } from "ol/proj";
import {
  getVehicleAppraisalAndAttachment,
  updateVehicleAppraisalAndAttachment,
} from "../../../../../../api/apiServices/vehicleApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "@mui/material";
import dayjs from "dayjs";
import { RiFileEditFill } from "react-icons/ri";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
function VehicleAppraisalForm({ updateIsEditable }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [currency, SetCurrency] = useState([]);
  const [attachment, SetAttachment] = useState([]);
  const [latitudeLongitude, SetlatitudeLongitude] = useState("");
  const [Loading, SetLoading] = useState(false);
  const { id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iSViewAndEdit = queryParams.get("view_edit");
  const [Isview, SetIsview] = useState(false);
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        SetlatitudeLongitude([longitude, latitude]);
      });
    }
    getCurrency();
    if (id) {
      dispatch(getVehicleAppraisalAndAttachment(id))
        .then((response) => {
          setFormikData(response.data.data);
          SetAttachment(
            response.data.data.attchment ? response.data.data.attchment : []
          );
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
    if (iSViewAndEdit) {
      SetIsview(true);
    }
  }, [id]);
  const initialValues = {
    requested_by: "",
    official: "",
    customer: "",
    address: "",
    investment_plan: "",
    type_of_appraisal: "",
    date: null,
    order: "",
    year: "",
    phone: "",
    currency: "",
    amount: "",
    contract_value: "",
    map_location: "",
    longitude: "",
    latiitude: "",
  };
  function setFormikData(data) {
    const mappedData = Object.keys(initialValues).reduce((acc, key) => {
      const originalKey = `vad_${key}`;
      acc[key] = data[originalKey] || initialValues[key];
      return acc;
    }, {});
    formik.setValues({
      ...formik.values,
      ...mappedData,
    });
    if (data.vad_longitude && data.vad_latitude) {
      SetlatitudeLongitude([data.vad_longitude, data.vad_latitude]);
    }
  }
  function getCurrency() {
    dispatch(getAllCurrency())
      .then((response) => {
        const data = response.map((item) => {
          return {
            label: item.cl_currency,
            value: item.cl_id,
          };
        });
        SetCurrency(data);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }
  function uploadAttachment(data) {
    SetAttachment([...attachment, data]);
  }
  function deleteAttachmentAtIndex(index) {
    const files = attachment;
    files.splice(index, 1);
    SetAttachment([...files]);
  }
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (state) => {
    setOpen(state);
  };
  const UpdateLocationCordinates = (data) => {
    SetlatitudeLongitude(data);
  };
  function updateFormData(name, data) {}

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      requested_by: Yup.string(), //
      official: Yup.string(), //
      customer: Yup.string(), //
      address: Yup.string(),
      investment_plan: Yup.string(), //
      type_of_appraisal: Yup.string(), //
      // date: Yup.string(), //
      order: Yup.string(), //
      year: Yup.string(), //
      phone: Yup.string(),
      currency: Yup.string().required("Currency is required"),
      amount: Yup.string().required("Appraisal amount is required"),
      contract_value: Yup.string(),
      map_location: Yup.string(),
      longitude: Yup.string(),
      latiitude: Yup.string(),
    }),
    onSubmit: (values) => {
      values["longitude"] = latitudeLongitude[0];
      values["latiitude"] = latitudeLongitude[1];
      const data = new FormData();
      data.append("AprraisalDetails", JSON.stringify(values));
      data.append("AprraisalAttachment", JSON.stringify(attachment));
      if (attachment && attachment.length > 0) {
        attachment.forEach((attachment) => {
          if (
            !(
              attachment.file.toString().startsWith("http://") ||
              attachment.file.toString().startsWith("https://")
            )
          ) {
            data.append(
              `vehicleAttachments`,
              attachment.file,
              `${
                "VA_" +
                id +
                "_" +
                new Date().toISOString().replace(/:/g, "_") +
                "-" +
                attachment.title +
                "." +
                attachment.filetype
              }`
            );
          }
        });
      } else {
        data.append(`vehicleAttachments`, null);
      }
      SetLoading(true);
      dispatch(updateVehicleAppraisalAndAttachment(id, data))
        .then((response) => {
          ResponseHandler(response);
          if (iSViewAndEdit) {
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${id}/update_vehicle?tk=${4}&view_edit=${true}`
            );
          } else {
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${id}/update_vehicle?tk=${4}`
            );
          }
        })
        .catch((error) => {
          ResponseHandler(error);
        })
        .finally(() => {
          SetLoading(false);
        });
    },
  });

  const Privilages = useSelector((state) => state.userStore.Privilages);
  return (
    <div>
      <div className="VehicleAppraisal_form_main_Container">
        {Privilages &&
        Privilages?.Vehicle &&
        Privilages?.Vehicle.some((item) => item === "Update")
          ? Isview && (
              <div
                className="VehicleAppraisal_edit_details"
                onClick={() => {
                  SetIsview(false);
                  updateIsEditable(true);
                }}
              >
                <RiFileEditFill />
                <p>Edit</p>
              </div>
            )
          : null}
        <div className="VehicleAppraisal_formContainer">
          <p className="VehicleAppraisal_form_legend">Add Appraisal Details</p>
          <form id="VehicleAppraisalform" onSubmit={formik.handleSubmit}>
            <div className="VehicleAppraisalform_fields">
              <div>
                <div className="VehicleAppraisalform_field_container">
                  <p className="VehicleAppraisalform_fields_labels">
                    Requested By
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="requested_by"
                    placeholder="Enter Requested By"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.requested_by}
                    error={
                      formik.touched.requested_by &&
                      Boolean(formik.errors.requested_by)
                    }
                    helperText={
                      formik.touched.requested_by && formik.errors.requested_by
                    }
                  />
                </div>
                <div className="VehicleAppraisalform_field_container">
                  <p className="VehicleAppraisalform_fields_labels">Official</p>
                  <CustomTextField
                    disabled={Isview}
                    name="official"
                    placeholder="Enter Official"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.official}
                    error={
                      formik.touched.official && Boolean(formik.errors.official)
                    }
                    helperText={
                      formik.touched.official && formik.errors.official
                    }
                  />
                </div>
                <div className="VehicleAppraisalform_field_container">
                  <p className="VehicleAppraisalform_fields_labels">Customer</p>
                  <CustomTextField
                    disabled={Isview}
                    name="customer"
                    placeholder="Enter Customer"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customer}
                    error={
                      formik.touched.customer && Boolean(formik.errors.customer)
                    }
                    helperText={
                      formik.touched.customer && formik.errors.customer
                    }
                  />
                </div>
                <div className="VehicleAppraisalform_field_container">
                  <p className="VehicleAppraisalform_fields_labels">Address</p>
                  <CustomTextField
                    disabled={Isview}
                    name="address"
                    placeholder="Address"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.address}
                    error={
                      formik.touched.address && Boolean(formik.errors.address)
                    }
                    helperText={formik.touched.address && formik.errors.address}
                  />
                </div>
                <div className="VehicleAppraisalform_field_container">
                  <p className="VehicleAppraisalform_fields_labels">
                    Investment plan
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="investment_plan"
                    placeholder="Enter Investment plan"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.investment_plan}
                    error={
                      formik.touched.investment_plan &&
                      Boolean(formik.errors.investment_plan)
                    }
                    helperText={
                      formik.touched.investment_plan &&
                      formik.errors.investment_plan
                    }
                  />
                </div>
                <div className="VehicleAppraisalform_field_container">
                  <p className="VehicleAppraisalform_fields_labels">
                    Type of Appraisal
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="type_of_appraisal"
                    placeholder="Enter Type of Appraisal"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.type_of_appraisal}
                    error={
                      formik.touched.type_of_appraisal &&
                      Boolean(formik.errors.type_of_appraisal)
                    }
                    helperText={
                      formik.touched.type_of_appraisal &&
                      formik.errors.type_of_appraisal
                    }
                  />
                </div>
                <div className="VehicleAppraisalform_field_container">
                  <p className="VehicleAppraisalform_fields_labels">
                    Issue Date
                  </p>
                  <CustomDatePicker
                    disabled={Isview}
                    name="date"
                    onChange={(date) => formik.setFieldValue("date", date)}
                    value={dayjs(formik.values.date, "MM/DD/YYYY")}
                    error={formik.touched.date && Boolean(formik.errors.date)}
                    helperText={formik.touched.date && formik.errors.date}
                  />
                </div>
                <div className="VehicleAppraisalform_field_container">
                  <p className="VehicleAppraisalform_fields_labels">
                    Map Location ({latitudeLongitude.toString()})
                  </p>
                  <div className="VehicleAppraisalform_map_field_container">
                    <div>
                      <CustomTextField
                        disabled={Isview}
                        name="map_location"
                        placeholder="Enter Map Location"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.map_location}
                        error={
                          formik.touched.map_location &&
                          Boolean(formik.errors.map_location)
                        }
                        helperText={
                          formik.touched.map_location &&
                          formik.errors.map_location
                        }
                      />
                    </div>

                    <div
                      onClick={() => {
                        if (!Isview) {
                          handleClickOpen();
                        }
                      }}
                    >
                      <img src={map_icon} />
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="VehicleAppraisalform_field_container">
                  <p className="VehicleAppraisalform_fields_labels">Order</p>
                  <CustomTextField
                    disabled={Isview}
                    name="order"
                    placeholder="Enter Order"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.order}
                    error={formik.touched.order && Boolean(formik.errors.order)}
                    helperText={formik.touched.order && formik.errors.order}
                  />
                </div>
                <div className="VehicleAppraisalform_field_container">
                  <p className="VehicleAppraisalform_fields_labels">Year</p>
                  <CustomTextField
                    disabled={Isview}
                    name="year"
                    placeholder="Enter Year"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.year}
                    error={formik.touched.year && Boolean(formik.errors.year)}
                    helperText={formik.touched.year && formik.errors.year}
                  />
                </div>
                <div className="VehicleAppraisalform_field_container">
                  <p className="VehicleAppraisalform_fields_labels">Phone</p>
                  <CustomTextField
                    disabled={Isview}
                    name="phone"
                    placeholder="Enter Phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </div>
                <div className="VehicleAppraisalform_field_container">
                  <p className="VehicleAppraisalform_fields_labels">Currency</p>
                  <CustomAutoComplete
                    disabled={Isview}
                    name="currency"
                    placeholder="Enter Currency"
                    options={currency}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "currency",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      currency.find(
                        (currency) => currency.value === formik.values.currency
                      ) || null
                    }
                    error={
                      formik.touched.currency && Boolean(formik.errors.currency)
                    }
                    helperText={
                      formik.touched.currency && formik.errors.currency
                    }
                  />
                </div>
                <div className="VehicleAppraisalform_field_container">
                  <p className="VehicleAppraisalform_fields_labels">Amount</p>
                  <CustomTextField
                    disabled={Isview}
                    name="amount"
                    placeholder="Enter Amount"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.amount}
                    error={
                      formik.touched.amount && Boolean(formik.errors.amount)
                    }
                    helperText={formik.touched.amount && formik.errors.amount}
                  />
                </div>
                {/* <div className="VehicleAppraisalform_field_container">
                  <p className="VehicleAppraisalform_fields_labels">
                    Vehicle type
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="contract_value"
                    placeholder="Vehicle type"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contract_value}
                    error={
                      formik.touched.contract_value &&
                      Boolean(formik.errors.contract_value)
                    }
                    helperText={
                      formik.touched.contract_value &&
                      formik.errors.contract_value
                    }
                  />
                </div> */}
                <div className="VehicleAppraisalform_field_container">
                  <p className="VehicleAppraisalform_fields_labels">
                    Contract Value
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="contract_value"
                    placeholder="Enter Contract Value"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.contract_value}
                    error={
                      formik.touched.contract_value &&
                      Boolean(formik.errors.contract_value)
                    }
                    helperText={
                      formik.touched.contract_value &&
                      formik.errors.contract_value
                    }
                  />
                </div>
              </div>
            </div>

            <div id="attachment-div">
              <VehicleAppraisalAttachment
                Isview={Isview}
                attachments={attachment}
                uploadAttachments={uploadAttachment}
                deleteAttachments={deleteAttachmentAtIndex}
                updateFormDatas={updateFormData}
              />
            </div>
          </form>
        </div>

        {!Isview && (
          <div className="VehicleAppraisalform_action">
            <FormButton
              id="VehicleAppraisalCreateCancel"
              variant="outlined"
              loading={false}
              text="Cancel"
              onClick={() => {
                navigate(
                  `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/vehicles`
                );
              }}
            />
            <FormButton
              id="VehicleAppraisalCreateSubmit"
              type="submit"
              form="VehicleAppraisalform"
              variant="contained"
              text="Save & Continue"
              loading={Loading}
            />
          </div>
        )}
      </div>
      <CustomMap
        locationCordinates={latitudeLongitude}
        open={open}
        handleClose={handleClose}
        UpdateLocationCordinates={UpdateLocationCordinates}
      />
    </div>
  );
}

export default VehicleAppraisalForm;
