import React from 'react'
import not_found_image from '../../assets/not_found.png'
import './NotFoundPage.css'
import { useNavigate } from 'react-router-dom'
function NotFoundPage() {
    const navigate=useNavigate()
  return (
    <div className='main_not_found_container'>
        <div className='custom_circle_1' />
        <div className='custom_circle_2' />
        <div className='not_found_image_with_container'>
            <div>
                <div className='not_found_centralize_content'>
                  <img src={not_found_image} className='not_found_image'/>
                </div>
                
                <p className='main_text'>Something went wrong.</p>
                <p className='sub_text'>Sorry, We can’t find the page you’re looking for.</p>
                <div className='not_found_centralize_content'>
                   <button className='not_found_go_back_button' onClick={()=>{
                    navigate(-2)
                   }}>Go Back</button>
                </div>
                
            </div>
           
        </div>
    </div>
  )
}

export default NotFoundPage