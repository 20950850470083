import React, { useEffect, useState } from "react";
import SimpleTable from "../../../../../../components/simpleTable/SimpleTable3";
import CustomChip from "../../../../../../components/customChip/CustomChip";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "./Users.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import UsersFilter from "../subComponents/usersFilter/UsersFilter";
import { deleteUser, getAllUsers } from "../../../../../../api/apiServices/userApis";
import PasswordUpdateForm from "../subComponents/passwordUpdateForm/PasswordUpdateForm";
import CustomModal from "../../../../../../components/customModal/CustomModal";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import CustomSpin from "../../../../../../components/customSpin/CustomSpin";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
import Language from "../../../../../../components/languageswitch/LanguageSwitch";
import { useTranslation } from "react-i18next";

function Users() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const Privilages = useSelector((state) => state.userStore.Privilages);
  const UsersData = useSelector((state) => state.userStore.Users);
  const tableloading = useSelector((state) => state.userStore.userTableLoading);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [deleteRowDetails, setDeleteRowDetails] = useState({
    id: "",
    loading: false,
  });
  const [translatedData, setTranslatedData] = useState([]);
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language); // Initialize with current language

  useEffect(() => {
    dispatch(getAllUsers());
  }, [dispatch]);

  useEffect(() => {
    // Handle language changes
    const handleLanguageChange = () => {
      setLanguage(i18n.language); // Update state when language changes
    };

    handleLanguageChange(); // Initialize language on mount

    i18n.on("languageChanged", handleLanguageChange); // Listen for language changes

    return () => {
      i18n.off("languageChanged", handleLanguageChange); // Clean up listener on unmount
    };
  }, [i18n]);

  useEffect(() => {
    const transformDataForLanguage = async (data) => {
      const translateText = async (text, lang) => {
        // Use translation API or library
        // For example, using Google Translate API
        try {
          if (lang === "es") {
            const response = await fetch(
              `https://translation.googleapis.com/language/translate/v2?key=YOUR_VALID_API_KEY`,
              {
                method: "POST",
                body: JSON.stringify({
                  q: text,
                  target: "es",
                }),
                headers: {
                  "Content-Type": "application/json",
                },
              }
            );
            
            if (!response.ok) {
              const errorData = await response.json();
              throw new Error(`HTTP error! status: ${response.status}, Response: ${JSON.stringify(errorData)}`);
            }
      
            const result = await response.json();
      
            if (result && result.data && result.data.translations && result.data.translations[0]) {
              return result.data.translations[0].translatedText;
            } else {
              console.error("Unexpected response format:", result);
              return text;
            }
          }
          return text;
        } catch (error) {
          console.error("Translation error:", error);
          return text;
        }
      };

      const translatedData = await Promise.all(
        data.map(async (item) => {
          const translatedItem = { ...item };
          translatedItem.name = await translateText(item.name, language);
          translatedItem.email = await translateText(item.email, language);
          translatedItem.phoneNumber = await translateText(item.phoneNumber, language);
          translatedItem.role = await translateText(item.role, language);
          return translatedItem;
        })
      );

      return translatedData;
    };

    const updateData = async () => {
      const userData = datas();
      const transformedData = await transformDataForLanguage(userData);
      setTranslatedData(transformedData);
    };

    updateData();
  }, [UsersData, language]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (state) => {
    setOpen(state);
  };

  const Columns = [
    {
      title: "#",
      dataIndex: "Key",
      key: "key",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      action: (row) => (
        <div className="user_action_chipset">
          <CustomChip
            text="Edit"
            bgColor="#F8893C"
            color="white"
            onClick={() => handleEdit(row)}
          />
          <CustomChip
            text="Change Password"
            bgColor="#1976d2"
            color="white"
            onClick={() => handleChangePassword(row)}
          />
          <CustomChip
            loading={deleteRowDetails.id === row.id ? deleteRowDetails.loading : false}
            text="Delete"
            bgColor="#ED5967"
            color="white"
            onClick={() => handleDeleteUser(row)}
          />
        </div>
      ),
    },
  ];

  const handleEdit = (row) => {
    navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/user_staff/${row.id}/update_user`);
  };

  const handleChangePassword = (row) => {
    setSelectedId(row.id);
    handleClickOpen();
  };

  const UpdateDeleteDetails = (id, loading) => {
    setDeleteRowDetails({
      id: id,
      loading: loading,
    });
  };

  const handleDeleteUser = (row) => {
    UpdateDeleteDetails(row.id, true);
    dispatch(deleteUser(row.id))
      .then((response) => {
        ResponseHandler(response);
      })
      .catch((error) => {
        ResponseHandler(error);
      })
      .finally(() => {
        UpdateDeleteDetails(row.id, false);
      });
  };

  const datas = () => {
    let userDatas = UsersData;
    if (Privilages?.url_role && Privilages?.url_role !== "SuperAdmin") {
      userDatas = userDatas.filter((item) => item.url_role !== "SuperAdmin");
    }
    return userDatas.map((item, index) => ({
      Key: index + 1,
      id: item.up_id,
      name: item.ud_name,
      email: item.up_email,
      phoneNumber: item.ud_phone_no,
      address: item.ud_address,
      role: item.url_role,
    }));
  };

  return (
    <div>
      {/* <Language /> */}
      <p className="page_title">Users</p>
      <CustomSpin loading={tableloading}>
        <div className="user_filter_container">
          <UsersFilter />
          <div>
            <Button
              variant="contained"
              size="small"
              style={{
                textTransform: "none",
                fontFamily: "Inter",
                fontSize: "15px",
                fontWeight: 600,
                padding: "8px 15px",
                backgroundColor: "#0f62fe",
                color: "white",
                borderRadius: "5px",
                cursor: "pointer",
              }}
              onClick={() =>
                navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/user_staff/create_user`)
              }
            >
              Add User
            </Button>
          </div>
        </div>
        <SimpleTable columns={Columns} rows={translatedData} />
        <CustomModal
          open={open}
          handleClose={handleClose}
          body={<PasswordUpdateForm id={selectedId} onclose={handleClose} />}
          title="Change Password"
        />
      </CustomSpin>
    </div>
  );
}

export default Users;
