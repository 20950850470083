import React, { useState,useContext, useEffect } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { IconButton, Pagination, Table } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import "./CustomAdvanceTable.css";
import { motion } from "framer-motion";
import { Button, Box } from "@mui/material";
import {useDispatch, useSelector } from "react-redux";
import Language from "../languageswitch/LanguageSwitch";
import { LanguageContext } from "../../services/LanguageContext";
import { setRefresh } from "../../store/usersStore";
function CustomAdvanceTable({
  toolbar,
  columns,
  rows,
  defaultCheck,
  onChangeChecked,
  rowChecked,
  onchangeRowchecked,
  tableId, // Unique identifier for each table
}) {
  const { language } = useContext(LanguageContext);
  const [currentPage, setCurrentPage] = useState(() => {
    // Retrieve the saved page number for this specific table from localStorage or default to 1
    return parseInt(localStorage.getItem(`currentPage_${tableId}`)) || 1;
  });
  const dispatch=useDispatch()
  useEffect(()=>{
    
      dispatch(setRefresh())
  
    
  },[currentPage])
  const [rowData, setRowData] = useState([]);
  const IsCollapsed = useSelector((state) => state.mainStore.IsCollapsed);
  const [TableResp, setTableResp] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [allChecked, setAllChecked] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    if (windowWidth < 999) {
      setTableResp({});
    } else {
      setTableResp({
        maxWidth: IsCollapsed ? "calc(100vw - 120px)" : "calc(100vw - 320px)",
      });
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth, IsCollapsed]);
  useEffect(() => {
    // Update table content based on the selected language
    // You can fetch or load the translated data here if necessary
    console.log(`Current language in table component: ${language}`);
  }, [language]);
  useEffect(() => {
    const handleClickOutside = (e) => {
      try {
        if (
          !document
            .getElementById("advance_table_toolbar_setting")
            ?.contains(e.target) &&
          !document
            .getElementById("advance_table_toolbar_setting_dropdown")
            ?.contains(e.target)
        ) {
          const element = document.getElementById(
            "advance_table_toolbar_setting_dropdown"
          );
          if (element && element.style.display === "block") {
            element.style.display = "";
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (rows) {
      // Ensure the current page is within bounds for the new data set
      const totalPages = Math.ceil(rows.length / 8);
      if (currentPage > totalPages) {
        setCurrentPage(1);
        localStorage.setItem(`currentPage_${tableId}`, 1);
      }
      slicedTableData(currentPage);
    }
  }, [rows, currentPage]);

  const slicedTableData = (currentPageNumber) => {
    const firstPageIndex = (currentPageNumber - 1) * 8;
    const lastPageIndex = firstPageIndex + 8;
    const slicedData = rows.slice(firstPageIndex, lastPageIndex);
    setRowData([...slicedData]);
  };

  const handleChange = (_, value) => {
    slicedTableData(value);
    setCurrentPage(value);
    // Save the current page for this specific table to localStorage
    localStorage.setItem(`currentPage_${tableId}`, value);
  };

  const updateVisibility = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.style.display =
        element.style.display === "none" || element.style.display === ""
          ? "block"
          : "";
    }
  };

  const updateFilterColumns = (isChecked, name) => {
    onChangeChecked({ isChecked, name });
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#E9F2FF",
      color: "black",
      fontSize: 15,
      borderBottom: "none",
      fontWeight: "600",
      fontFamily: "Inter",
      padding: "15px 12px",
    },
    [`&.${tableCellClasses.body}`]: {
      maxWidth: "500px",
      overflowWrap: "break-word",
      fontSize: 15,
      fontFamily: "Inter",
      borderBottom: "none",
      fontWeight: "400",
      padding: "12px",
      overflow: "auto",
      whiteSpace: "normal",
      wordWrap: "break-word",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
      "& > *:first-of-type": {
        backgroundColor: "white",
      },
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#E9F2FF",
      "& > *:first-of-type": {
        backgroundColor: "#E9F2FF",
      },
    },
  }));

  const checkToggle = () => {
    const newState = !allChecked;
    setAllChecked(newState);
    columns.forEach((item) => {
      updateFilterColumns(newState, item.dataIndex);
    });
  };

  const ReturnTableAction = (columns, row) => {
    const actions = columns.filter((item) => item.dataIndex === "actions");
    return actions[0]?.action(row);
  };
  const CustomPagination = styled(Pagination)(({ theme }) => ({
    "& .MuiPaginationItem-root": {
      backgroundColor: "#EDEFF6",
      color: "#4A60A1",
      "&.Mui-selected": {
        backgroundColor: "#003087",
        color: "white",
      },
      "&:hover": {
        backgroundColor: "#003087",
        color: "white",
      },
    },
  }));
  const renderCustomPagination = () => {
    const totalPages = Math.ceil(rows.length / 8);
    const pageRange = 3;
    let startPage = Math.max(1, currentPage - Math.floor(pageRange / 2));
    const endPage = Math.min(totalPages, startPage + pageRange - 1);
    if (endPage - startPage + 1 < pageRange) {
      startPage = Math.max(1, endPage - pageRange + 1);
    }

    const pages = [];

    if (currentPage > 1) {
      pages.push(
        <Button
          key="first"
          variant="outlined"
          onClick={() => handleChange(null, 1)}
          sx={{
            minWidth: "30px",
            padding: "3px",
            margin: "0 5px",
            backgroundColor: "#EDEFF6",
            color: "#4A60A1",
            "&:hover": {
              backgroundColor: "#EDEFF6",
              color: "#4A60A1",
            },
          }}
        >
          {"<<"}
        </Button>
      );

      pages.push(
        <Button
          key="prev"
          variant="outlined"
          onClick={() => handleChange(null, currentPage - 1)}
          sx={{
            minWidth: "30px",
            padding: "3px",
            margin: "0 5px",
            backgroundColor: "#EDEFF6",
            color: "#4A60A1",
            "&:hover": {
              backgroundColor: "#EDEFF6",
              color: "#4A60A1",
            },
          }}
        >
          {"<"}
        </Button>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Button
          key={i}
          variant={currentPage === i ? "contained" : "outlined"}
          onClick={() => handleChange(null, i)}
          sx={{
            minWidth: "30px",
            padding: "3px",
            margin: "0 5px",
            backgroundColor: currentPage === i ? "#005EB8" : "#EDEFF6",
            color: currentPage === i ? "white" : "#4A60A1",
            "&:hover": {
              backgroundColor: currentPage === i ? "#005EB8" : "#EDEFF6",
              color: currentPage === i ? "white" : "#4A60A1",
            },
          }}
        >
          {i}
        </Button>
      );
    }

    if (currentPage < totalPages) {
      pages.push(
        <Button
          key="next"
          variant="outlined"
          onClick={() => handleChange(null, currentPage + 1)}
          sx={{
            minWidth: "30px",
            padding: "3px",
            margin: "0 5px",
            backgroundColor: "#EDEFF6",
            color: "#4A60A1",
            "&:hover": {
              backgroundColor: "#EDEFF6",
              color: "#4A60A1",
            },
          }}
        >
          {">"}
        </Button>
      );

      pages.push(
        <Button
        key="last"
        variant="outlined"
        onClick={() => handleChange(null, totalPages)}
        sx={{
          minWidth: "30px",
            padding: "3px",
          margin: '0 5px',
          backgroundColor: '#EDEFF6',
          color: '#4A60A1',
          '&:hover': {
            backgroundColor: '#EDEFF6',
            color: '#4A60A1',
          },
        }}
      >
        {'>>'}
      </Button>
      );
    }

    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px 0",
        }}
      >
        {pages}
      </Box>
    );
  };

  return (
    <div style={TableResp}>
      <div className="advance_table_toolbar">
        <div>{toolbar}</div>
        <div>
          <div
            id="advance_table_toolbar_setting"
            className="advance_table_toolbar_setting"
          >
            <IconButton
              aria-label="setting"
              onClick={() => {
                updateVisibility("advance_table_toolbar_setting_dropdown");
              }}
            >
              <SettingsIcon style={{ color: "#0F62FE" }} />
            </IconButton>
          </div>
          <motion.div
            className="advance_table_toolbar_setting_dropdown"
            transition={{ duration: 0.1 }}
            id="advance_table_toolbar_setting_dropdown"
          >
            <div className="setting_dropdown_toolbar">
              <p className="setting_list_view">List View</p>
              <p className="checkList_view" onClick={checkToggle}>
                {allChecked ? "UnCheck all" : "Check all"}
              </p>
            </div>
            <div className="advance_table_column_list">
              {columns.map((item) => {
                return (
                  <>
                    {item.dataIndex !== "key" &&
                    item.dataIndex !== "actions" ? (
                      <div className="setting_dropdown_items" key={item.key}>
                        <input
                          type="checkbox"
                          id={item.title}
                          name={item.title}
                          value={item.title}
                          defaultChecked={defaultCheck.includes(item.dataIndex)}
                          checked={defaultCheck.includes(item.dataIndex)}
                          onChange={(e) => {
                            updateFilterColumns(
                              e.target.checked,
                              item.dataIndex
                            );
                          }}
                        />
                        <p className="list_item">{item.title}</p>
                      </div>
                    ) : null}
                  </>
                );
              })}
            </div>
          </motion.div>
        </div>
      </div>
      <div>
        <TableContainer className="advance_table_mainContainer">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {defaultCheck.length === 0 ? (
                  <>
                    <StyledTableCell key={"key"} align={"left"}>
                      <p>#</p>
                    </StyledTableCell>
                    <StyledTableCell key={"no_data"} align={"left"}>
                      <p>{null}</p>
                    </StyledTableCell>
                    <StyledTableCell
                      key={"actions"}
                      align={"center"}
                      className="sticky-actions1"
                    >
                      <p>Actions</p>
                    </StyledTableCell>
                  </>
                ) : (
                  columns.map((column) => {
                    if (
                      column.dataIndex === "key" ||
                      column.dataIndex === "actions" ||
                      defaultCheck.includes(column.dataIndex)
                    ) {
                      return (
                        <StyledTableCell
                          key={column.dataIndex}
                          align={
                            column.dataIndex === "actions" ? "center" : "left"
                          }
                        >
                          <p>{column.title}</p>
                        </StyledTableCell>
                      );
                    }
                    return null;
                  })
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {defaultCheck.length === 0
                ? rowData.map((row) => (
                    <StyledTableRow key={row.key}>
                      <StyledTableCell key={"key"} align="left">
                        <p>{row.key}</p>
                      </StyledTableCell>
                      <StyledTableCell key={"no_data"} align="left">
                        <p> {null}</p>
                      </StyledTableCell>
                      <StyledTableCell
                        key={"actions"}
                        align="center"
                        className="sticky-actions1"
                      >
                        {ReturnTableAction(columns, row)}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
                : rowData.map((row, index) => (
                    <StyledTableRow key={row.key}>
                      {columns.map(
                        (column) =>
                          (column.dataIndex === "key" ||
                            column.dataIndex === "actions" ||
                            defaultCheck.includes(column.dataIndex)) && (
                            <StyledTableCell
                              key={column.key}
                              align={
                                column.dataIndex === "actions"
                                  ? "right"
                                  : "left"
                              }
                              style={{
                                width: column.dataIndex === "key" ? "20px" : "",
                                backgroundColor:
                                  column.dataIndex === "actions"
                                    ? index % 2 === 0
                                      ? "#ffffff"
                                      : "#E9F2FF"
                                    : "",
                              }}
                              id="sticky-actions1"
                              className={
                                column.dataIndex === "actions"
                                  ? "sticky-actions1"
                                  : ""
                              }
                            >
                              {column.action
                                ? column.action(row)
                                : row[column.dataIndex] || "-"}
                            </StyledTableCell>
                          )
                      )}
                    </StyledTableRow>
                  ))}
            </TableBody>
          </Table>
          {rows && rows.length === 0 && (
            <div className="noData_Container">
              <p className="noData_text">{"No Data"}</p>
            </div>
          )}
        </TableContainer>
      </div>
      {/* <div>
      {rowData && rowData.length > 0 && (
        <div className="table_pagination_container">
          <button
            className="table_pagination_button"
            style={{ color: currentPage === 1 ? "#005EB8" : "" }}
            onClick={() => {
              slicedTableData(1);
              setCurrentPage(1);
              // Save the current page for this specific table to localStorage
              localStorage.setItem(`currentPage_${tableId}`, 1);
            }}
          >
            First
          </button>
          <div>
          <Pagination
            className="advance_table_pagination"
            page={currentPage}
            count={rows && Math.ceil(rows.length / 8)}
            variant="outlined"
            shape="rounded"
            color="primary"
            onChange={handleChange}
          />
          </div>
          
          <button
            className="table_pagination_button"
            style={{
              color:
                currentPage === (rows && Math.ceil(rows.length / 8))
                  ? "#005EB8"
                  : "",
            }}
            onClick={() => {
              const lastPage = Math.ceil(rows.length / 8);
              slicedTableData(lastPage);
              setCurrentPage(lastPage);
              // Save the current page for this specific table to localStorage
              localStorage.setItem(`currentPage_${tableId}`, lastPage);
            }}
          >
            Last
          </button>
        </div>
      )}
      </div> */}
      <div>
        {rowData && rowData.length > 0 && (
          <div className="table_pagination_container">
            {renderCustomPagination()}
          </div>
        )}
      </div>
    </div>
  );
}

export default CustomAdvanceTable;
