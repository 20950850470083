import { createSlice } from "@reduxjs/toolkit";
export const TestimonialStoreSlice = createSlice({
  name: "TestimonialStore",
  initialState: {
    allTestimonialsBackUp: [],
    allTestimonials: [],
    filterTestimonialsDetails: {
      name: "",
      designation: "",
      rating: "",
    },
  },
  reducers: {
    setTestimonials: (state, action) => {
      state.allTestimonialsBackUp = action.payload;
      state.allTestimonials = action.payload;
    },

    setFilterTestimonialsDatas: (state, actions) => {
      const { name, value } = actions.payload;
      state.filterTestimonialsDetails[name] = value;
    },

    filterTestimonialsData: (state) => {
      let FilterTestimonialsDatas = [];
      if (state.filterTestimonialsDetails.name.trim()) {
        FilterTestimonialsDatas = state.allTestimonialsBackUp.filter((item) =>
          item.t_name
            .toLowerCase()
            .includes(state.filterTestimonialsDetails.name.toLocaleLowerCase())
        );
      } else {
        FilterTestimonialsDatas = state.allTestimonialsBackUp;
      }
      if (state.filterTestimonialsDetails.designation.trim()) {
        if (FilterTestimonialsDatas.length > 0) {
          FilterTestimonialsDatas = FilterTestimonialsDatas.filter((item) =>
            item.t_designation
              .toLowerCase()
              .includes(
                state.filterTestimonialsDetails.designation.toLocaleLowerCase()
              )
          );
        } else {
          FilterTestimonialsDatas = state.allTestimonialsBackUp.filter((item) =>
            item.t_designation
              .toLowerCase()
              .includes(
                state.filterTestimonialsDetails.designation.toLocaleLowerCase()
              )
          );
        }
      } else {
        if (FilterTestimonialsDatas.length === 0) {
          FilterTestimonialsDatas = state.allTestimonialsBackUp;
        }
      }

      const filterRatingString = state.filterTestimonialsDetails.rating.trim();
    
      if (filterRatingString) {
        FilterTestimonialsDatas = FilterTestimonialsDatas.filter(item =>
          item.t_rating == filterRatingString
        );
      } 
    
      state.allTestimonials = FilterTestimonialsDatas;
    },
    resetFilterTestimonialsData: (state) => {
      state.allTestimonials = state.allTestimonialsBackUp;
      state.filterTestimonialsDetails = {
        name: "",
        designation: "",
        rating: "",
      };
    },
  },
});
export const {
  setTestimonials,
  setFilterTestimonialsDatas,
  filterTestimonialsData,
  resetFilterTestimonialsData,
} = TestimonialStoreSlice.actions;
export default TestimonialStoreSlice.reducer;
