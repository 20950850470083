import React, { useState, useRef, useEffect } from "react";
import "./ToolbarSearch.css";
import { Autocomplete, TextField } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import IconButton from "@mui/material/IconButton";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import {
  filterVehicleData,
  setResetFilter,
  setVehicleFilterDatas,
  updateFilterDropdownData,
} from "../../../../../../store/vehicleStore";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { saleitemList } from "../../../../../../constant/SaleStatusList";
import { setRefresh } from "../../../../../../store/usersStore";

function ToolbarSearch() {
  const dispatch = useDispatch();
  const wholeVehicleData = useSelector(
    (state) => state.vehicleStore.WholeVehicles
  );
  const FilterDropDownData = useSelector(
    (state) => state.vehicleStore.FilterDropDownList
  );
  const FilterData = useSelector(
    (state) => state.vehicleStore.VehicleFilterData
  );
  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    dispatch(updateFilterDropdownData());
  }, [wholeVehicleData]);

  const inputStyles = {
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      fontSize: "15px !important",
      fontFamily: "Inter",
      width: 200,
      "& .MuiAutocomplete-input": {
        padding: "7.5px 4px 7.5px 9px !important",
        fontFamily: "Inter",
      },
      "& fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color
      },
      "&:hover fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color when focused
      },
    },
  };

  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const handleFieldClick = () => {
    togglePickerView("custom_date_picker_container");
  };

  const togglePickerView = (id) => {
    const element = document.getElementById(id);
    if (element) {
      if (element.style.display === "none" || element.style.display === "") {
        element.style.display = "block";
      } else {
        element.style.display = "";
      }
    }
  };

  window.addEventListener("click", function (e) {
    try {
      if (
        !document
          .getElementById("custom_date_picker_field")
          ?.contains(e.target) &&
        !document
          .getElementById("custom_date_picker_container")
          ?.contains(e.target)
      ) {
        const element = document.getElementById("custom_date_picker_container");
        if (element) {
          if ((element.style.display = "block")) {
            element.style.display = "";
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  });

  const initialValues = {
    type: FilterData.type,
    status: FilterData.status,
    bank: FilterData.bank,
    issue_start_date: FilterData.issue_start_date,
    issue_end_date: FilterData.issue_end_date,
    sale_status: FilterData.sale_status,
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      dispatch(setVehicleFilterDatas(values));
      dispatch(filterVehicleData());
      dispatch(setRefresh());
      console.log(values, "values");
    },
  });

  const handleSelect = (ranges) => {
    const issue_start_date = ranges.selection.startDate.toLocaleDateString();
    const issue_end_date = ranges.selection.endDate.toLocaleDateString();
    formik.setValues({ ...formik.values, issue_start_date, issue_end_date });
    setSelectionRange(ranges.selection);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      const icon = document.querySelector(".filter_icon_mobile");
      const container = document.querySelector(".filter_field_container");
      const datePicker = document.querySelector(
        ".custom-date-picker.rdrDateRangePickerWrapper"
      );

      const isDatePickerOpen = datePicker && datePicker.style.display == "flex";

      if (
        !isDatePickerOpen &&
        !icon.contains(event.target) &&
        !container.contains(event.target)
      ) {
        const datePickerContainer = document.getElementById(
          "custom_date_picker_container"
        );
        if (datePickerContainer) {
          if (datePickerContainer.style.display !== "block") {
            setFilterOpen(false);
          }
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterOpen]);

  function UpdateFieldVisibility(id) {
    const element = document.getElementById(id);
    if (element) {
      if (element.style.display === "none" || element.style.display === "") {
        element.style.display = "block";
      } else {
        element.style.display = "";
      }
    }
  }
  return (
    <div>
      <div className="filter_icon_mobile">
        <IconButton
          color="primary"
          onClick={() => {
            UpdateFieldVisibility("vehicle_filter_field_container");
            setFilterOpen(!filterOpen);
          }}
        >
          <FilterAltOutlinedIcon />
        </IconButton>
      </div>

      <div
        id="vehicle_filter_field_container"
        style={{ display: filterOpen ? "block" : "" }}
      >
        <div id="filter_field_container" className="filter_field_container">
          <form className="filter_tool_bar" onSubmit={formik.handleSubmit}>
            <div id="vehicle_filter" className="filter_tool_field_width">
              <Autocomplete
                name="type"
                componentsProps={{ paper: { sx: { wordBreak: "break-word" } } }}
                sx={{ ...inputStyles }}
                disablePortal
                id="combo-box-demo"
                options={FilterDropDownData.type}
                getOptionLabel={(option) => option.label}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  formik.setFieldValue("type", newValue ? newValue.label : "");
                  dispatch(setRefresh());
                }}
                value={
                  FilterDropDownData.type.find(
                    (type) => type.value === formik.values.type
                  ) || null
                }
                ListboxProps={{
                  style: { minHeight: 0, fontSize: 15, fontFamily: "Inter" },
                }}
                menuProps={{ style: { minHeight: 0, fontFamily: "Inter" } }}
                renderInput={(params) => (
                  <TextField {...params} label="" placeholder="Select type" />
                )}
              />
            </div>
            <div className="filter_tool_field_width">
              <Autocomplete
                sx={{ ...inputStyles }}
                disablePortal
                id="combo-box-demo"
                options={FilterDropDownData.bank}
                getOptionLabel={(option) => option.label}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  formik.setFieldValue("bank", newValue ? newValue.label : "");
                }}
                value={
                  FilterDropDownData.bank.find(
                    (bank) => bank.value === formik.values.bank
                  ) || null
                }
                ListboxProps={{
                  style: {
                    minHeight: 0,
                    fontSize: 15,
                    fontFamily: "Inter",
                    border: "0.5px solid rgba(119, 182, 243, 1) !important",
                  },
                }}
                menuProps={{ style: { minHeight: 0, fontFamily: "Inter" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    placeholder="Select bank"
                    style={{ fontFamily: "Inter" }}
                  />
                )}
              />
            </div>
            <div>
              <div
                id="custom_date_picker_field"
                className="custom_vehicle_date_picker_field"
                onClick={handleFieldClick}
              >
                <p style={{display:"flex",flexDirection:"row"}}>
                  <div>
                    
                    {formik.values.issue_start_date ? (
                      formik.values.issue_start_date
                    ) : (
                      <span
                        className="customPlaceholder"
                        style={{ fontFamily: "Inter", fontSize: "15px" }}
                      >
                        Start date  
                      </span>
                    )}
                  </div>
                  {"-"}
                  <div>
                    {formik.values.issue_end_date ? (
                      formik.values.issue_end_date
                    ) : (
                      <span
                        className="customPlaceholder"
                        style={{
                          fontFamily: "Inter",
                          fontSize: "15px",
                          textOverflow: "ellipsis",
                        }}
                      >
                        &nbsp;End date
                      </span>
                    )}
                  </div>
                </p>
                <div>
  {formik.values.issue_start_date && formik.values.issue_end_date && (
    <div>
      <div
        style={{
          fontSize: "20px",
          color: "grey",
          cursor: "pointer",
          display: "inline-block"
        }}
        onClick={() => {
          formik.setValues({
            ...formik.values,
            issue_start_date: null,
            issue_end_date: null,
          });
          setSelectionRange({
            startDate: new Date(),
            endDate: new Date(),
            key: "selection",
          });
        }}
      >
       <p><CloseIcon /></p> 
      </div>
    </div>
  )}
</div>

              </div>
              <div
                id={"custom_date_picker_container"}
                className="custom_date_picker_container"
              >
                <DateRangePicker
                  ranges={[selectionRange]}
                  onChange={handleSelect}
                  className="custom-date-picker"
                />
              </div>
            </div>
            <div className="filter_tool_field_width">
              <Autocomplete
                sx={{ ...inputStyles }}
                disablePortal
                id="combo-box-demo"
                options={FilterDropDownData.status}
                getOptionLabel={(option) => option.label}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "status",
                    newValue ? newValue.label : null
                  );
                }}
                value={
                  FilterDropDownData.status.find(
                    (status) => status.value === formik.values.status
                  ) || null
                }
                ListboxProps={{
                  style: { minHeight: 0, fontSize: 15, fontFamily: "Inter" },
                }}
                menuProps={{ style: { minHeight: 0, fontFamily: "Inter" } }}
                renderInput={(params) => (
                  <TextField {...params} label="" placeholder="Select status" />
                )}
              />
            </div>
            <div className="filter_tool_field_width">
              <Autocomplete
                sx={{ ...inputStyles }}
                disablePortal
                id="combo-box-demo"
                options={saleitemList}
                getOptionLabel={(option) => option.label}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  formik.setFieldValue(
                    "sale_status",
                    newValue ? newValue.value : null
                  );
                }}
                value={
                  saleitemList.find(
                    (saleStatus) =>
                      saleStatus.value == formik.values.sale_status
                  ) || null
                }
                ListboxProps={{
                  style: { minHeight: 0, fontSize: 15, fontFamily: "Inter" },
                }}
                menuProps={{ style: { minHeight: 0, fontFamily: "Inter" } }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label=""
                    placeholder="Select sale status"
                  />
                )}
              />
            </div>
            <div className="toolbar_Search">
              <button className="toolbar_Search_button" type="submit">
                Search
              </button>
              <button
                type="button"
                className="toolbar_Reset_button"
                onClick={() => {
                  dispatch(setResetFilter());
                  setTimeout(() => {
                    dispatch(setRefresh());
                  }, 500);
                  setSelectionRange({
                    startDate: new Date(),
                    endDate: new Date(),
                    key: "selection",
                  });
                  formik.setValues({
                    ...formik.values,
                    type: null,
                    bank: null,
                    status: null,
                    issue_start_date: null,
                    issue_end_date: null,
                    sale_status: null,
                  });
                }}
              >
                Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ToolbarSearch;
