import { ApiConfig } from "../apiConfig";

export const getVehicleBasicDetails=(vehicle_id)=>{
  return  async()=>{
    try {
        const response = await ApiConfig.get(`/vehicle/${vehicle_id}/get_vehicle_basic_details`);
        return response
      } catch (error) {
        throw error
      }
}
}


export const createNewVehicleApplication=(vehicle_id,data)=>{
    return  async()=>{
        try {
            const response = await ApiConfig.post(`/vehicle/${vehicle_id}/vehicle_application`,data,{
              headers: {
                'Content-Type': 'multipart/form-data',
              }});
            return response
          } catch (error) {
            throw error
          }
    }
}