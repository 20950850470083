import React, { useEffect, useState } from "react";
import "./runnerDashboard.css";
import { useDispatch } from "react-redux";
import dummyIcon from "../../../../../assets/dummy_icon_for_dashboard.png";
import CustomDashboardSmallCard from "../../../../../components/customDashboardSmallCard/CustomDashboardSmallCard";
import { getRunnerDashboardDetails } from "../../../../../api/apiServices/dashboardApis";
import { propertyPlotTags, vehiclePlotTags } from "./runnerDashboardHelper";
import CustomYearsDropDown from "../../../../../components/customYearsDropDown/CustomYearsDropDown";
import CustomLineChart from "../../../../../components/customLineChart/CustomLineChart";
function RunnerDashboard() {
  const [dashboardDetails, setDashboardDetails] = useState(null);
  const [selectedVehicleTag, setSelectedVehicleTag] = useState(null);
  const [selectedPropertyTag, setSelectedPropertyTag] = useState(null);
  const [selectedVehicleYear, setselectedVehicleYear] = useState(null);
  const [selectedPropertyYear, setselectedPropertyYear] = useState(null);

  const dispach = useDispatch();
  useEffect(() => {
    dispach(getRunnerDashboardDetails())
      .then((response) => {
        setDashboardDetails(response);
        setSelectedVehicleTag("1");
        setSelectedPropertyTag("1");
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    vehiclePlotSelected(selectedVehicleTag);
    propertyPlotSelected(selectedPropertyTag);
    setselectedVehicleYear(vehiclePlotSelectedYear(selectedVehicleTag));
    setselectedPropertyYear(propertyPlotSelectedYear(selectedPropertyTag));
  }, [selectedVehicleTag, selectedPropertyTag]);
  
  const transformData = (data, year) => {
    const monthsOrder = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (!Array.isArray(data) || data.length === 0) return [];

    const filteredData = data.find((item) => item.year == year);
    if (!filteredData) return [];
    // console.log( monthsOrder.map(month => filteredData[month] || 0))
    return monthsOrder.map((month) => filteredData[month] || 0);
  };

  const vehiclePlotSelected = (selectedVehicleTag) => {
    if (!dashboardDetails) return [];
    switch (selectedVehicleTag) {
      case "1":
        return dashboardDetails?.vehicle_task_plot.map((item) => item.year);
      case "2":
        return dashboardDetails?.vehicle_task_completed_plot.map(
          (item) => item.year
        );
      default:
        return [];
    }
  };
  const vehiclePlotSelectedYear = (selectedVehicleTag) => {
    if (!dashboardDetails) return "";
    switch (selectedVehicleTag) {
      case "1":
        return (
          dashboardDetails?.vehicle_task_plot
            .map((item) => item.year)[0]
            ?.toString() || ""
        );
      case "2":
        return (
          dashboardDetails?.vehicle_task_completed_plot
            .map((item) => item.year)[0]
            ?.toString() || ""
        );
      default:
        return "";
    }
  };

  const vehiclePlotData = (selectedVehicleTag, year) => {
    if (!dashboardDetails) return [];
    switch (selectedVehicleTag) {
      case "1":
        return transformData(dashboardDetails?.vehicle_task_plot, year);
      case "2":
        return transformData(
          dashboardDetails?.vehicle_task_completed_plot,
          year
        );
      default:
        return [];
    }
  };
  const propertyPlotSelected = (selectedPropertyTag) => {
    if (!dashboardDetails) return [];
    switch (selectedPropertyTag) {
      case "1":
        return dashboardDetails?.property_task_plot.map((item) => item.year);
      case "2":
        return dashboardDetails?.property_task_completed_plot.map(
          (item) => item.year
        );
      default:
        return [];
    }
  };
  const propertyPlotSelectedYear = (selectedPropertyTag) => {
    if (!dashboardDetails) return "";
    switch (selectedPropertyTag) {
      case "1":
        return (
          dashboardDetails?.property_task_plot
            .map((item) => item.year)[0]
            ?.toString() || ""
        );
      case "2":
        return (
          dashboardDetails?.property_task_completed_plot
            .map((item) => item.year)[0]
            ?.toString() || ""
        );
      default:
        return "";
    }
  };
  const propertyPlotData = (selectedPropertyTag, year) => {
    if (!dashboardDetails) return "";
    switch (selectedPropertyTag) {
      case "1":
        return transformData(dashboardDetails?.property_task_plot, year);
      case "2":
        return transformData(
          dashboardDetails?.property_task_completed_plot,
          year
        );
      default:
        return "";
    }
  };
  const setVehicleYear = (year) => {
    setselectedVehicleYear(year);
  };
  const setPropertyYear = (year) => {
    setselectedPropertyYear(year);
  };
  return (
    <div className="runner_dashboard_main_container">
      <div className="runner_dashboard_sub_listing_container_1">
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"Vehicle Tasks"}
          count={dashboardDetails?.vehicle_tasks?.total_vehicle_tasks}
        />
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"Completed"}
          count={
            dashboardDetails?.vehicle_completed_tasks
              ?.total_completed_vehicle_tasks
          }
        />
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"In Progress"}
          count={
            dashboardDetails?.vehicle_in_progress_tasks
              ?.total_in_progress_vehicle_tasks
          }
        />
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"New"}
          count={dashboardDetails?.vehicle_new_tasks?.total_new_vehicle_tasks}
        />
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"Completed On Time"}
          count={
            dashboardDetails?.vehicle_task_completed_before_end_date
              ?.total_vehicle_task_completed_before_enddate
          }
        />
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"Completed Late"}
          count={
            dashboardDetails?.vehicle_task_completed_after_end_date
              ?.total_vehicle_task_completed_after_enddate
          }
        />
      </div>
      <div className="runner_dashboard_sub_listing_container_1">
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"Property Tasks"}
          count={dashboardDetails?.property_tasks?.total_property_tasks}
        />
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"Completed"}
          count={
            dashboardDetails?.property_completed_tasks
              ?.total_completed_property_tasks
          }
        />
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"In Progress"}
          count={
            dashboardDetails?.property_in_progress_tasks
              ?.total_in_progress_property_tasks
          }
        />
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"New"}
          count={dashboardDetails?.property_new_tasks?.total_new_property_tasks}
        />
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"Completed On Time"}
          count={
            dashboardDetails?.property_task_completed_before_end_date
              ?.total_property_task_completed_before_enddate
          }
        />
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"Completed Late"}
          count={
            dashboardDetails?.property_task_completed_after_end_date
              ?.total_property_task_completed_after_enddate
          }
        />
      </div>
      <div className="runner_dashboard_sub_listing_container_3">
        <div className="runner_dashboard_sub_item_container_3_1 ">
          <div className="runner_dashboard_sub_item_container_3_2">
            <p className="runner_dashboard_sub_item_container_overview_title">
              Vehicle Task Overview
            </p>
            <div>
              <CustomYearsDropDown
                selected={selectedVehicleYear}
                list={vehiclePlotSelected(selectedVehicleTag)}
                setSelectedYear={setVehicleYear}
              />
            </div>
          </div>
          <div className="runner_dashboard_sub_item_container_3_3">
            {Object.entries(vehiclePlotTags).map(([key, value]) => (
              <p
                key={key}
                className="runner_dashboard_sub_item_tag_3_3"
                style={{
                  color: selectedVehicleTag == key ? "white" : "black",
                  backgroundColor:
                    selectedVehicleTag == key ? "#1976d2" : "white",
                }}
                onClick={() => {
                  setSelectedVehicleTag(key);
                }}
              >
                {value}
              </p>
            ))}
          </div>
          <div className=" graph-container">
            <CustomLineChart
              plotData={vehiclePlotData(
                selectedVehicleTag,
                selectedVehicleYear
              )}
            />
            {!selectedVehicleYear && (
              <div className="no-data-overlay">No Data</div>
            )}
          </div>
        </div>
        <div className="runner_dashboard_sub_item_container_3_1 ">
          <div className="runner_dashboard_sub_item_container_3_2">
            <p className="runner_dashboard_sub_item_container_overview_title">
              Property Task Overview
            </p>
            <div>
              <CustomYearsDropDown
                selected={selectedPropertyYear}
                list={propertyPlotSelected(selectedPropertyTag)}
                setSelectedYear={setPropertyYear}
              />
            </div>
          </div>
          <div className="runner_dashboard_sub_item_container_3_3">
            {Object.entries(propertyPlotTags).map(([key, value]) => (
              <p
                key={key}
                className="runner_dashboard_sub_item_tag_3_3"
                style={{
                  color: selectedPropertyTag == key ? "white" : "black",
                  backgroundColor:
                    selectedPropertyTag == key ? "#1976d2" : "white",
                }}
                onClick={() => {
                  setSelectedPropertyTag(key);
                }}
              >
                {value}
              </p>
            ))}
          </div>
          <div className=" graph-container">
            <CustomLineChart
              plotData={propertyPlotData(
                selectedPropertyTag,
                selectedPropertyYear
              )}
            />
            {!selectedPropertyYear && (
              <div className="no-data-overlay">No Data</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default RunnerDashboard;
