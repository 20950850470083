import React, { useEffect, useState } from 'react'
import './LanguageToggle.css'
import SpainFlag from '../../../../assets/SpainFlag.png'
import UkFlag from '../../../../assets/unitedkingdomFlag.png'
import { useTranslation } from "react-i18next";
function LanguageToggle({style}) {
    const { i18n } = useTranslation();
    const [languageCode,SetLanguageCode]=useState()
    useEffect(()=>{
       const lgCode= localStorage.getItem('currentLanguage')
       SetLanguageCode(lgCode?lgCode:'en')
       if(!lgCode){
        localStorage.setItem('lgCode','en');
       }
    },[])
    const toggle=()=>{
        i18n.changeLanguage(languageCode==='en'?'es':'en')
        localStorage.setItem('currentLanguage', languageCode==='en'?'es':'en');
        SetLanguageCode(languageCode==='en'?'es':'en')
    }
  return (
    <div className='toggle_main_container' style={style} onClick={toggle}>
        {languageCode && (
            <>
             <img className='flagImage' src={languageCode==='en'?UkFlag:SpainFlag} alt={languageCode}/>
             <p className='languagecode'>{languageCode.toUpperCase()}</p> 
             </>
        )}
      
    </div>
  )
}

export default LanguageToggle