import React, { useEffect, useState } from "react";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import "./PropertyDetailsForm.css";
import CustomDatePicker from "../../../../../../components/customDatePicker/CustomDatePicker";
import CustomTextArea from "../../../../../../components/customTextArea/CustomTextArea";
import FormButton from "../../../../../../components/formButton/FormButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertyDetails,
  updatePropertyDetails,
} from "../../../../../../api/apiServices/propertyApi";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RiFileEditFill } from "react-icons/ri";
import map_icon from "../../../../../../assets/map_icon.png";
import CustomMap from "../../../vehicle/vehicleFormPage/map/CustomMap";
import { transform } from "ol/proj";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
function PropertyDetailsForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { property_id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iSViewAndEdit = queryParams.get("view_edit");
  const [loading, setLoading] = useState(false);
  const [Isview, SetIsview] = useState(false);
  const [open, setOpen] = React.useState(false);
  const Privilages = useSelector((state) => state.userStore.Privilages);
  const [latitudeLongitude, SetlatitudeLongitude] = useState("");
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;
        SetlatitudeLongitude([longitude, latitude]);
      });
    }
    if (property_id) {
      dispatch(getPropertyDetails(property_id))
        .then((response) => {
          setFormikData(response.data.data);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
    if (iSViewAndEdit) {
      SetIsview(true);
    }
  }, [property_id]);

  const initialValues = {
    province: "",
    canton: "",
    district: "",
    exact_direction: "",
    location: "",
    longitude: "",
    latiitude: "",
    route_type: "",
    material: "",
    track_width: "",
    payment: "",
    spout: "",
    ditch: "",
    cord: "",
    sanitary_system: "",
    storm_tank: "",
    access_to_estate: "",
    phone: "",
    lighting: "",
    electricity: "",
    drinking_water: "",
    cellular_signal: "",
    cable_tv: "",
    meters_Installed: "",
    cable_Installed: "",
    garden_and_parks: "",
    public_transport: "",
    commercial_Building: "",
    garbage_collection: "",
    public_or_communal_building: "",
    middle_class: "",
    average_population_density: "",
    socio_class_near_by_area: "",
    site_activity: "",
    residential: "",
    agriculture: "",
    main_front: "",
    background: "",
    relationship: "",
    services_s1: "",
    services_s2: "",
    level_above_street: "",
    earning: "",
    type_of_road: "",
    large_trees_or_antennas: "",
    physical_condition: "",
    body_of_water_near_distance: "",
    slip_risk: "",
    typography: "",
    damage_chance: "",
    urabanization_of_area: "",
    study_date: "",
    transfer_easement_appointment: "",
    dominant_easement_appointment: "",
    observation: "",
  };

  const validationSchema = Yup.object({
    province: Yup.string(),
    canton: Yup.string(),
    district: Yup.string(),
    exact_direction: Yup.string(),
    location: Yup.string(),
    route_type: Yup.string(),
    material: Yup.string(),
    track_width: Yup.string(),
    payment: Yup.string(),
    spout: Yup.string(),
    ditch: Yup.string(),
    cord: Yup.string(),
    sanitary_system: Yup.string(),
    storm_tank: Yup.string(),
    access_to_estate: Yup.string(),
    phone: Yup.string(),
    lighting: Yup.string(),
    electricity: Yup.string(),
    drinking_water: Yup.string(),
    cellular_signal: Yup.string(),
    cable_tv: Yup.string(),
    meters_Installed: Yup.string(),
    cable_Installed: Yup.string(),
    garden_and_parks: Yup.string(),
    public_transport: Yup.string(),
    commercial_Building: Yup.string(),
    garbage_collection: Yup.string(),
    public_or_communal_building: Yup.string(),
    middle_class: Yup.string(),
    average_population_density: Yup.string(),
    socio_class_near_by_area: Yup.string(),
    site_activity: Yup.string(),
    residential: Yup.string(),
    agriculture: Yup.string(),
    main_front: Yup.string(),
    background: Yup.string(),
    relationship: Yup.string(),
    services_s1: Yup.string(),
    services_s2: Yup.string(),
    level_above_street: Yup.string(),
    earning: Yup.string(),
    type_of_road: Yup.string(),
    large_trees_or_antennas: Yup.string(),
    physical_condition: Yup.string(),
    slip_risk: Yup.string(),
    typography: Yup.string(),
    damage_chance: Yup.string(),
    urabanization_of_area: Yup.string(),
    study_date: Yup.string(),
    transfer_easement_appointment: Yup.string(),
    dominant_easement_appointment: Yup.string(),
    observation: Yup.string(),
    body_of_water_near_distance: Yup.string(),
  });
  function setFormikData(data) {
    const mappedData = Object.keys(initialValues).reduce((acc, key) => {
      const originalKey = `pd_${key}`;
      acc[key] = data[originalKey] || initialValues[key];
      return acc;
    }, {});
    formik.setValues({
      ...formik.values,
      ...mappedData,
    });
    if (data.pd_longitude && data.pd_latitude) {
      SetlatitudeLongitude([data.pd_longitude, data.pd_latitude]);
    }
  }
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values["longitude"] = latitudeLongitude[0];
      values["latitude"] = latitudeLongitude[1];
      setLoading(true);
      dispatch(updatePropertyDetails(property_id, values))
        .then((response) => {
          ResponseHandler(response);
          if (iSViewAndEdit) {
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${2}&view_edit=${true}`
            );
          } else {
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${2}`
            );
          }
        })
        .catch((error) => {
          ResponseHandler(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (state) => {
    setOpen(state);
  };
  const UpdateLocationCordinates = (data) => {
    SetlatitudeLongitude(data);
  };

  return (
    <div>
      <div className="PropertyDetails_form_main_Container">
        {Privilages &&
        Privilages?.Property &&
        Privilages?.Property.some((item) => item === "Update")
          ? Isview && (
              <div
                className="Property_detailsfrom_edit_details"
                onClick={() => SetIsview(false)}
              >
                <RiFileEditFill />
                <p>Edit</p>
              </div>
            )
          : null}
        <form id="PropertyDetailsform" onSubmit={formik.handleSubmit}>
          <div className="PropertyDetails_formContainer">
            <p className="PropertyDetails_form_legend">
              Location Of The Property
            </p>
            <div className="PropertyDetailsform_fields">
              <div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">Province</p>
                  <CustomTextField
                    disabled={Isview}
                    name="province"
                    placeholder="Enter province"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.province}
                    error={
                      formik.touched.province && Boolean(formik.errors.province)
                    }
                    helperText={
                      formik.touched.province && formik.errors.province
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">Canton</p>
                  <CustomTextField
                    disabled={Isview}
                    name="canton"
                    placeholder="Enter canton"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.canton}
                    error={
                      formik.touched.canton && Boolean(formik.errors.canton)
                    }
                    helperText={formik.touched.canton && formik.errors.canton}
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Location ({latitudeLongitude.toString()})
                  </p>
                  <div className="PropertyDetailsform_with_extra_actions">
                    <CustomTextField
                      name="location"
                      disabled={Isview}
                      placeholder="Enter location"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.location}
                      error={
                        formik.touched.location &&
                        Boolean(formik.errors.location)
                      }
                      helperText={
                        formik.touched.location && formik.errors.location
                      }
                    />
                    <div
                      onClick={() => {
                        if (!Isview) {
                          handleClickOpen();
                        }
                      }}
                    >
                      <img src={map_icon} />
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      District
                    </p>
                    <CustomTextField
                      name="district"
                      disabled={Isview}
                      placeholder="Enter district"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.district}
                      error={
                        formik.touched.district &&
                        Boolean(formik.errors.district)
                      }
                      helperText={
                        formik.touched.district && formik.errors.district
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Exact Direction
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="exact_direction"
                      placeholder="Enter direction"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.exact_direction}
                      error={
                        formik.touched.exact_direction &&
                        Boolean(formik.errors.exact_direction)
                      }
                      helperText={
                        formik.touched.exact_direction &&
                        formik.errors.exact_direction
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="PropertyDetails_formContainer">
            <p className="PropertyDetails_form_legend">Communication Ways</p>
            <div className="PropertyDetailsform_fields">
              <div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Route Type
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="route_type"
                    placeholder="Enter Route Type"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.route_type}
                    error={
                      formik.touched.route_type &&
                      Boolean(formik.errors.route_type)
                    }
                    helperText={
                      formik.touched.route_type && formik.errors.route_type
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">Material</p>
                  <CustomTextField
                    name="material"
                    disabled={Isview}
                    placeholder="Enter Material"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.material}
                    error={
                      formik.touched.material && Boolean(formik.errors.material)
                    }
                    helperText={
                      formik.touched.material && formik.errors.material
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Track Width
                    </p>
                    <CustomTextField
                      name="track_width"
                      disabled={Isview}
                      placeholder="Enter Track Width"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.track_width}
                      error={
                        formik.touched.track_width &&
                        Boolean(formik.errors.track_width)
                      }
                      helperText={
                        formik.touched.track_width && formik.errors.track_width
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">Payment</p>
                    <CustomTextField
                      name="payment"
                      disabled={Isview}
                      placeholder="Enter Payment"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.payment}
                      error={
                        formik.touched.payment && Boolean(formik.errors.payment)
                      }
                      helperText={
                        formik.touched.payment && formik.errors.payment
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="PropertyDetails_formContainer">
            <p className="PropertyDetails_form_legend">Aqueducts & Sewers</p>
            <div className="PropertyDetailsform_fields">
              <div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">Spout</p>
                  <CustomTextField
                    disabled={Isview}
                    name="spout"
                    placeholder="Enter spout"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.spout}
                    error={formik.touched.spout && Boolean(formik.errors.spout)}
                    helperText={formik.touched.spout && formik.errors.spout}
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">Ditch</p>
                  <CustomTextField
                    name="ditch"
                    disabled={Isview}
                    placeholder="Enter ditch"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.ditch}
                    error={formik.touched.ditch && Boolean(formik.errors.ditch)}
                    helperText={formik.touched.ditch && formik.errors.ditch}
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">Cord</p>
                  <CustomTextField
                    disabled={Isview}
                    name="cord"
                    placeholder="Enter cord"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cord}
                    error={formik.touched.cord && Boolean(formik.errors.cord)}
                    helperText={formik.touched.cord && formik.errors.cord}
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Sanitary System
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="sanitary_system"
                      placeholder="Enter Sanitary System"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.sanitary_system}
                      error={
                        formik.touched.sanitary_system &&
                        Boolean(formik.errors.sanitary_system)
                      }
                      helperText={
                        formik.touched.sanitary_system &&
                        formik.errors.sanitary_system
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Storm Tank
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="storm_tank"
                      placeholder="Storm Tank"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.storm_tank}
                      error={
                        formik.touched.storm_tank &&
                        Boolean(formik.errors.storm_tank)
                      }
                      helperText={
                        formik.touched.storm_tank && formik.errors.storm_tank
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Access To the Estate
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="access_to_estate"
                      placeholder="Access To the Estate"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.access_to_estate}
                      error={
                        formik.touched.access_to_estate &&
                        Boolean(formik.errors.access_to_estate)
                      }
                      helperText={
                        formik.touched.access_to_estate &&
                        formik.errors.access_to_estate
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="PropertyDetails_formContainer">
            <p className="PropertyDetails_form_legend">
              Availibiilty Of Services
            </p>
            <div className="PropertyDetailsform_fields">
              <div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">Phone</p>
                  <CustomTextField
                    disabled={Isview}
                    name="phone"
                    placeholder="Enter Phone"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">Lighting</p>
                  <CustomTextField
                    name="lighting"
                    disabled={Isview}
                    placeholder="Enter Lighting"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lighting}
                    error={
                      formik.touched.lighting && Boolean(formik.errors.lighting)
                    }
                    helperText={
                      formik.touched.lighting && formik.errors.lighting
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Electricity
                  </p>
                  <CustomTextField
                    name="electricity"
                    disabled={Isview}
                    placeholder="Enter Electricity"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.electricity}
                    error={
                      formik.touched.electricity &&
                      Boolean(formik.errors.electricity)
                    }
                    helperText={
                      formik.touched.electricity && formik.errors.electricity
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Drinking Water
                  </p>
                  <CustomTextField
                    name="drinking_water"
                    disabled={Isview}
                    placeholder="Enter Drinking Water"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.drinking_water}
                    error={
                      formik.touched.drinking_water &&
                      Boolean(formik.errors.drinking_water)
                    }
                    helperText={
                      formik.touched.drinking_water &&
                      formik.errors.drinking_water
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Cellular Signal
                  </p>
                  <CustomTextField
                    name="cellular_signal"
                    disabled={Isview}
                    placeholder="Enter Cellular Signal"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cellular_signal}
                    error={
                      formik.touched.cellular_signal &&
                      Boolean(formik.errors.cellular_signal)
                    }
                    helperText={
                      formik.touched.cellular_signal &&
                      formik.errors.cellular_signal
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">Cable TV</p>
                  <CustomTextField
                    name="cable_tv"
                    placeholder="Enter Cable TV"
                    disabled={Isview}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cable_tv}
                    error={
                      formik.touched.cable_tv && Boolean(formik.errors.cable_tv)
                    }
                    helperText={
                      formik.touched.cable_tv && formik.errors.cable_tv
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Meters Installed
                  </p>
                  <CustomTextField
                    name="meters_Installed"
                    disabled={Isview}
                    placeholder="Enter Meters Installed"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.meters_Installed}
                    error={
                      formik.touched.meters_Installed &&
                      Boolean(formik.errors.meters_Installed)
                    }
                    helperText={
                      formik.touched.meters_Installed &&
                      formik.errors.meters_Installed
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      cable_Installed
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="cable_Installed"
                      placeholder="Enter cable_Installed"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cable_Installed}
                      error={
                        formik.touched.cable_Installed &&
                        Boolean(formik.errors.cable_Installed)
                      }
                      helperText={
                        formik.touched.cable_Installed &&
                        formik.errors.cable_Installed
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Gardens and Parks
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="garden_and_parks"
                      placeholder="Enter Gardens and Parks"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.garden_and_parks}
                      error={
                        formik.touched.garden_and_parks &&
                        Boolean(formik.errors.garden_and_parks)
                      }
                      helperText={
                        formik.touched.garden_and_parks &&
                        formik.errors.garden_and_parks
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Public Transport
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="public_transport"
                      placeholder="Public Transport"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.public_transport}
                      error={
                        formik.touched.public_transport &&
                        Boolean(formik.errors.public_transport)
                      }
                      helperText={
                        formik.touched.public_transport &&
                        formik.errors.public_transport
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Commercial Buildings
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="commercial_Building"
                      placeholder="Commercial Buildings"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.commercial_Building}
                      error={
                        formik.touched.commercial_Building &&
                        Boolean(formik.errors.commercial_Building)
                      }
                      helperText={
                        formik.touched.commercial_Building &&
                        formik.errors.commercial_Building
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Garbage Collection
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="garbage_collection"
                      placeholder="Garbage Collection"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.garbage_collection}
                      error={
                        formik.touched.garbage_collection &&
                        Boolean(formik.errors.garbage_collection)
                      }
                      helperText={
                        formik.touched.garbage_collection &&
                        formik.errors.garbage_collection
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Public / Communal Buildings
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="public_or_communal_building"
                      placeholder="Public / Communal Buildings"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.public_or_communal_building}
                      error={
                        formik.touched.public_or_communal_building &&
                        Boolean(formik.errors.public_or_communal_building)
                      }
                      helperText={
                        formik.touched.public_or_communal_building &&
                        formik.errors.public_or_communal_building
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="PropertyDetails_formContainer">
            <p className="PropertyDetails_form_legend">Socio Economic Data</p>
            <div className="PropertyDetailsform_fields">
              <div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Middle Class
                  </p>
                  <CustomTextField
                    name="middle_class"
                    disabled={Isview}
                    placeholder="Enter Middle Class"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.middle_class}
                    error={
                      formik.touched.middle_class &&
                      Boolean(formik.errors.middle_class)
                    }
                    helperText={
                      formik.touched.middle_class && formik.errors.middle_class
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Socio Class Nearby Areas
                  </p>
                  <CustomTextField
                    name="socio_class_near_by_area"
                    disabled={Isview}
                    placeholder="Enter Socio Class Nearby Areas"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.socio_class_near_by_area}
                    error={
                      formik.touched.socio_class_near_by_area &&
                      Boolean(formik.errors.socio_class_near_by_area)
                    }
                    helperText={
                      formik.touched.socio_class_near_by_area &&
                      formik.errors.socio_class_near_by_area
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Residential
                  </p>
                  <CustomTextField
                    name="residential"
                    disabled={Isview}
                    placeholder="Enter Residential"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.residential}
                    error={
                      formik.touched.residential &&
                      Boolean(formik.errors.residential)
                    }
                    helperText={
                      formik.touched.residential && formik.errors.residential
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Average Population Density
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="average_population_density"
                      placeholder="Enter Average Population Density"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.average_population_density}
                      error={
                        formik.touched.average_population_density &&
                        Boolean(formik.errors.average_population_density)
                      }
                      helperText={
                        formik.touched.average_population_density &&
                        formik.errors.average_population_density
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Site Activity
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="site_activity"
                      placeholder="Site Activity"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.site_activity}
                      error={
                        formik.touched.site_activity &&
                        Boolean(formik.errors.site_activity)
                      }
                      helperText={
                        formik.touched.site_activity &&
                        formik.errors.site_activity
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Agricultural
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="agriculture"
                      placeholder="Agricultural"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.agriculture}
                      error={
                        formik.touched.agriculture &&
                        Boolean(formik.errors.agriculture)
                      }
                      helperText={
                        formik.touched.agriculture && formik.errors.agriculture
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="PropertyDetails_formContainer">
            <p className="PropertyDetails_form_legend">
              Physical Characteristics & Category Of Services
            </p>
            <div className="PropertyDetailsform_fields">
              <div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Main Front
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="main_front"
                    placeholder="Enter Main Front"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.main_front}
                    error={
                      formik.touched.main_front &&
                      Boolean(formik.errors.main_front)
                    }
                    helperText={
                      formik.touched.main_front && formik.errors.main_front
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Background
                  </p>
                  <CustomTextField
                    name="background"
                    disabled={Isview}
                    placeholder="Enter Background"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.background}
                    error={
                      formik.touched.background &&
                      Boolean(formik.errors.background)
                    }
                    helperText={
                      formik.touched.background && formik.errors.background
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Relationship
                  </p>
                  <CustomTextField
                    name="relationship"
                    disabled={Isview}
                    placeholder="Enter Relationship"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.relationship}
                    error={
                      formik.touched.relationship &&
                      Boolean(formik.errors.relationship)
                    }
                    helperText={
                      formik.touched.relationship && formik.errors.relationship
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Services S1
                  </p>
                  <CustomTextField
                    name="services_s1"
                    disabled={Isview}
                    placeholder="Enter Services S1"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.services_s1}
                    error={
                      formik.touched.services_s1 &&
                      Boolean(formik.errors.services_s1)
                    }
                    helperText={
                      formik.touched.services_s1 && formik.errors.services_s1
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Level Above Street
                    </p>
                    <CustomTextField
                      name="level_above_street"
                      disabled={Isview}
                      placeholder="Enter Level Above Street"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.level_above_street}
                      error={
                        formik.touched.level_above_street &&
                        Boolean(formik.errors.level_above_street)
                      }
                      helperText={
                        formik.touched.level_above_street &&
                        formik.errors.level_above_street
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">Earning</p>
                    <CustomTextField
                      name="earning"
                      disabled={Isview}
                      placeholder="Earning"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.earning}
                      error={
                        formik.touched.earning && Boolean(formik.errors.earning)
                      }
                      helperText={
                        formik.touched.earning && formik.errors.earning
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Type Of Road
                    </p>
                    <CustomTextField
                      name="type_of_road"
                      disabled={Isview}
                      placeholder="Type Of Road"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.type_of_road}
                      error={
                        formik.touched.type_of_road &&
                        Boolean(formik.errors.type_of_road)
                      }
                      helperText={
                        formik.touched.type_of_road &&
                        formik.errors.type_of_road
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Services S2
                    </p>
                    <CustomTextField
                      name="services_s2"
                      disabled={Isview}
                      placeholder="Enter Services S2"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.services_s2}
                      error={
                        formik.touched.services_s2 &&
                        Boolean(formik.errors.services_s2)
                      }
                      helperText={
                        formik.touched.services_s2 && formik.errors.services_s2
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="PropertyDetails_formContainer">
            <p className="PropertyDetails_form_legend">Potential Risks</p>
            <div className="PropertyDetailsform_fields">
              <div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Large Trees Or Antennas Within The Insured Properties
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="large_trees_or_antennas"
                    placeholder="Enter large_trees_or_antennas"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.large_trees_or_antennas}
                    error={
                      formik.touched.large_trees_or_antennas &&
                      Boolean(formik.errors.large_trees_or_antennas)
                    }
                    helperText={
                      formik.touched.large_trees_or_antennas &&
                      formik.errors.large_trees_or_antennas
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Physical Condition (Tree, Maintenance, Anchors, Winds,
                    Antenna / Towers)
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="physical_condition"
                    placeholder="Enter physical_condition"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.physical_condition}
                    error={
                      formik.touched.physical_condition &&
                      Boolean(formik.errors.physical_condition)
                    }
                    helperText={
                      formik.touched.physical_condition &&
                      formik.errors.physical_condition
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Body Of Water Near Distance
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="body_of_water_near_distance"
                    placeholder="Enter Body Of Water Near Distance"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.body_of_water_near_distance}
                    error={
                      formik.touched.body_of_water_near_distance &&
                      Boolean(formik.errors.body_of_water_near_distance)
                    }
                    helperText={
                      formik.touched.body_of_water_near_distance &&
                      formik.errors.body_of_water_near_distance
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Topography
                    </p>
                    <CustomTextField
                      name="typography"
                      disabled={Isview}
                      placeholder="Enter typography"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.typography}
                      error={
                        formik.touched.typography &&
                        Boolean(formik.errors.typography)
                      }
                      helperText={
                        formik.touched.typography && formik.errors.typography
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Damage Chance
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="damage_chance"
                      placeholder="Enter Damage Chance"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.damage_chance}
                      error={
                        formik.touched.damage_chance &&
                        Boolean(formik.errors.damage_chance)
                      }
                      helperText={
                        formik.touched.damage_chance &&
                        formik.errors.damage_chance
                      }
                    />
                  </div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Slip Risk
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="slip_risk"
                      placeholder="Enter Slip Risk"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.slip_risk}
                      error={
                        formik.touched.slip_risk &&
                        Boolean(formik.errors.slip_risk)
                      }
                      helperText={
                        formik.touched.slip_risk && formik.errors.slip_risk
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="PropertyDetails_formContainer">
            <p className="PropertyDetails_form_legend">
              Degree of Urbanization of The Area
            </p>
            <div className="PropertyDetailsform_fields">
              <div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    The Area Has An Estimated Urbanization of
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="urabanization_of_area"
                    placeholder="Enter urabanization_of_area"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.urabanization_of_area}
                    error={
                      formik.touched.urabanization_of_area &&
                      Boolean(formik.errors.urabanization_of_area)
                    }
                    helperText={
                      formik.touched.urabanization_of_area &&
                      formik.errors.urabanization_of_area
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="PropertyDetails_formContainer">
            <p className="PropertyDetails_form_legend">
              Impact of the Property
            </p>
            <div className="PropertyDetailsform_fields">
              <div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Study Date
                  </p>
                  <CustomDatePicker
                    name={"study_date"}
                    disabled={Isview}
                    onChange={(date) => {
                      formik.setFieldValue("study_date", date);
                    }}
                    onBlur={formik.handleBlur}
                    value={dayjs(formik.values.study_date, "MM/DD/YYYY")}
                    error={
                      formik.touched.study_date &&
                      Boolean(formik.errors.study_date)
                    }
                    helperText={
                      formik.touched.study_date && formik.errors.study_date
                    }
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="PropertyDetails_formContainer">
            <p className="PropertyDetails_form_legend">
              Liens, Warnings & Notations
            </p>
            <div className="PropertyDetailsform_fields">
              <div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Transferred Easement Appointments
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="transfer_easement_appointment"
                    placeholder="Enter transfer_easement_appointment"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.transfer_easement_appointment}
                    error={
                      formik.touched.transfer_easement_appointment &&
                      Boolean(formik.errors.transfer_easement_appointment)
                    }
                    helperText={
                      formik.touched.transfer_easement_appointment &&
                      formik.errors.transfer_easement_appointment
                    }
                  />
                </div>
                <div className="PropertyDetailsform_field_container">
                  <p className="PropertyDetailsform_fields_labels">
                    Observations
                  </p>
                  <CustomTextArea
                    disabled={Isview}
                    maxRows={5}
                    name="observation"
                    placeholder="Enter observation"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.observation}
                    error={
                      formik.touched.observation &&
                      Boolean(formik.errors.observation)
                    }
                    helperText={
                      formik.touched.observation && formik.errors.observation
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="PropertyDetailsform_field_container">
                    <p className="PropertyDetailsform_fields_labels">
                      Dominant Easement Appointments
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="dominant_easement_appointment"
                      placeholder="Enter Dominant Easement Appointments"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.dominant_easement_appointment}
                      error={
                        formik.touched.dominant_easement_appointment &&
                        Boolean(formik.errors.dominant_easement_appointment)
                      }
                      helperText={
                        formik.touched.dominant_easement_appointment &&
                        formik.errors.dominant_easement_appointment
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!Isview && (
            <div className="PropertyDetailsform_action">
              <FormButton
                id="PropertyDetailsformCreateCancel"
                variant="outlined"
                loading={false}
                text="Cancel"
                onClick={() => {
                  navigate(
                    `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`
                  );
                }}
              />
              <FormButton
                id="PropertyDetailsformCreateSubmit"
                type="submit"
                form="PropertyDetailsform"
                variant="contained"
                loading={loading}
                text="Save & Continue"
              />
            </div>
          )}
        </form>
        <CustomMap
          locationCordinates={latitudeLongitude}
          open={open}
          handleClose={handleClose}
          UpdateLocationCordinates={UpdateLocationCordinates}
        />
      </div>
    </div>
  );
}

export default PropertyDetailsForm;
