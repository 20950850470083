import React, { useEffect, useState } from "react";
import "./PropertyTaskPage.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropertyTasks,
  deletePropertyTaskById,
} from "../../../../../api/apiServices/propertyApi";
import { setRefresh } from "../../../../../store/usersStore";
import CustomChip from "../../../../../components/customChip/CustomChip";
import SimpleTable from "../../../../../components/simpleTable/SimpleTable3";
import CustomModal from "../../../../../components/customModal/CustomModal";
import PropertyReassign from "./propertyReassign/PropertyReassign";
import ResponseHandler from "../../../../../services/ResponseHandler";
import { IoArrowBack } from "react-icons/io5";
import { LocalStorageServices } from "../../../../../services/localStorageServices";
import PropertyTaskSearchFilter from "./propertyTaskSearchFilter/PropertyTaskSearchFilter";
function PropertyTaskPage() {
  const { property_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const propertyTasks = useSelector(
    (state) => state.propertiesStore.PropertyTasks
  );
  const [open, setOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState({
    taskId: "",
    propertyId: "",
  });
  const [deletePropertyTask, setDeletePropertyTask] = useState({
    taskId: "",
    loading: false,
  });
  useEffect(() => {
    if (property_id) {
      dispatch(getPropertyTasks(property_id));
    }
  }, [property_id]);
  const UpdateTaskDeleteDetails = (task_Id, loading) => {
    setDeletePropertyTask({
      ...deletePropertyTask,
      ["id"]: task_Id,
      ["loading"]: loading,
    });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (state) => {
    dispatch(setRefresh())
    setOpen(state);
  };
  const handleReassign = (taskId, propertyId) => {
    setSelectedIds({
      ...selectedIds,
      ["taskId"]: taskId,
      ["propertyId"]: propertyId,
    });
    handleClickOpen();
  };
  const handleDelete = (property_Id, task_Id) => {
    UpdateTaskDeleteDetails(task_Id, true);
    dispatch(deletePropertyTaskById(property_Id, task_Id))
      .then((response) => {
        ResponseHandler(response);
      })
      .catch((error) => {
        ResponseHandler(error);
      })
      .finally(() => {
        UpdateTaskDeleteDetails(task_Id, false);
      });
  };
  const Privilages = useSelector((state) => state.userStore.Privilages);

  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Task Name",
      dataIndex: "taskName",
      key: "taskName",
    },
    {
      title: "Task Description",
      dataIndex: "taskDescription",
      key: "taskDescription",
    },
    {
      title: "Assign To",
      dataIndex: "assignTo",
      key: "assignTo",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Duration",
      dataIndex: "duration",
      key: "duration",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      action: (row) => (
        <p
          style={{
            color: row.status === "Completed" ? "green" : "blue",
            minWidth: "100px",
          }}
        >
          {row.status}
        </p>
      ),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      action: (row) => (
        <div className="property_task_chipset">
          {Privilages &&
          Privilages?.Tasks.some((item) => item === "Update") &&
          Privilages?.url_role !== "Runner" ? (
            <CustomChip
              disabled={row.status === "Completed"}
              text="Reassign"
              bgColor="#573CFA"
              color="white"
              onClick={() => {
                handleReassign(row.taskId, property_id ? row.propertyId : null);
              }}
            />
          ) : null}

          <CustomChip
            text="View"
            bgColor="#F8893C"
            color="white"
            onClick={() =>
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${
                  row.taskId
                }/view_and_edit_property_task?edit=${false}`
              )
            }
          />
          {Privilages &&
          Privilages.Tasks &&
          Privilages.Tasks.some((item) => item === "Delete") ? (
            <CustomChip
              loading={
                deletePropertyTask.taskId === row.taskId
                  ? deletePropertyTask.loading
                  : false
              }
              text="Delete"
              bgColor="#ED5967"
              color="white"
              onClick={() => handleDelete(property_id, row.taskId)}
            />
          ) : null}
        </div>
      ),
    },
  ];
  const datas = () => {
    const result =
      propertyTasks &&
      propertyTasks.map((item, index) => {
        return {
          key: index + 1,
          taskId: item.pt_id,
          propertyId: item.pt_property_id,
          taskName: item.pt_taskname,
          taskDescription: item.pt_taskdescription,
          assignTo: item.pt_assignto,
          startDate: item.pt_startdate,
          endDate: item.pt_enddate,
          duration: item.pt_duration
            ? item.pt_duration == 1
              ? item.pt_duration + " Day"
              : item.pt_duration + " Days"
            : item.pt_duration,
          status: item.sl_status,
          priority: item.pl_priority,
        };
      });
    return result;
  };
  return (
    <div>
      {property_id ? (
        <>
          <div className="property_task_header">
            <p className="property_name">Property Task</p>
            {Privilages &&
            Privilages.Tasks &&
            Privilages.Tasks.some((item) => item === "Add") ? (
              <button
                className="add_property_task"
                onClick={() => {
                  navigate(
                    `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/create_property_task`
                  );
                }}
              >
                Add Task
              </button>
            ) : null}
          </div>
          <div className="property_task_view_back_container">
            <div>
              <PropertyTaskSearchFilter />
            </div>
            <div
              className="property_task_view__back"
              onClick={() => {
                navigate(-1);
              }}
            >
              <IoArrowBack />
              <p>Back</p>
            </div>
          </div>
        </>
      ) : (
        <div className="property_task_view_back_container">
          <div>
            <PropertyTaskSearchFilter />
          </div>
        </div>
      )}
      <div>
        <SimpleTable columns={Columns} rows={datas()} />
        <CustomModal
          open={open}
          handleClose={handleClose}
          title={"Change Assigne"}
          body={
            <PropertyReassign
              taskId={selectedIds.taskId}
              propertyId={selectedIds.propertyId}
              onclose={handleClose}
            />
          }
        />
      </div>
    </div>
  );
}

export default PropertyTaskPage;
