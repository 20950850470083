export const VehicleSubMenuEndPoints=[
    "vehicles",
    "add_vehicle",
    "update_vehicle",
    "vehicle_type",
    "vehicle_tasks",
    "create_task",
    "view_and_edit_vehicle_task",
]
export const PropertySubMenuEndPoints=[
    "properties",
    'property_type',
    "add_properties",
    "update_properties",
    "property_task",
    "create_property_task",
    "view_and_edit_property_task"
]
export const UsersOrStaffSubMenuEndPoints=[
    'users',

    'roles',
    'privilages'
]
 
export const VehiclesSubMenuEndPoints=[
    "vehicles",
    "add_vehicle",
    "update_vehicle",
    "vehicle_tasks",
    "create_task",
    "view_and_edit_vehicle_task",
    "AgentVehicle_View",
    "apply",
   
]
 
export const PropertiesSubMenuEndPoints=[
    "properties",
    "add_properties",
    "update_properties",
    "property_task",
    "create_property_task",
    "view_and_edit_property_task",
    "AgentProperty_View",
    "apply",
]
 
export const UserSubPagesEndEndPoints=[
    "create_user",
    'update_user'
  
]

export const AgentSubPagesEndPoints=[
    "create_agent"
]
export const SettingsSubPagesEndPoints=[
    "lookup",
    "Bank_form",
]
export const CustomerportaInterestSubPagesEndPoints=[
    "vehicle_interest_applications",
    "property_interest_applications",
]
export const CustomerportalLoanSubPagesEndPoints=[
    "vehicle_loan_applications",
    "property_loan_applications",
    
]

export const CustomerportalTestimonialsSubPagesEndPoints=[
    "testimonial_form"
]



