import React, { useEffect } from 'react'
import { getAllCustomers } from '../../../../../api/apiServices/customerPortalApis'
import { useDispatch, useSelector } from 'react-redux'
import SimpleTable from '../../../../../components/simpleTable/SimpleTable'
import { setCustomers } from '../../../../../store/customerStore'
import ResponseHandler from '../../../../../services/ResponseHandler'
import CustomerFilter from './customerFilter/CustomerFilter'
import './CustomersStyle.css'
function Customers() {
    const dispatch=useDispatch()
    const Customers=useSelector((state)=>state.customerStore.allCustomers)
    useEffect(()=>{
        dispatch(getAllCustomers()).then((response)=>{
            dispatch(setCustomers(response))
        }).catch((error)=>{
            ResponseHandler(error)
        })
    },[])
    const Columns = [
        {
          title: "#",
          dataIndex: "Key",
          key: "key",
        },
        {
          title: "Name",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "Email",
          dataIndex: "email",
          key: "email",
        },
        {
          title: "Phone Number",
          dataIndex: "phoneNumber",
          key: "phoneNumber",
        },
        {
            title: "Address",
            dataIndex: "address",
            key: "address",
          },
        {
          title: "Role",
          dataIndex: "role",
          key: "role",
        }
      ];
      const datas = () => {
        const result =
          Customers &&
          Customers.map((item, index) => {
            return {
              Key: index + 1,
              id: item.up_id,
              name: item.ud_name,
              email: item.up_email,
              phoneNumber: item.ud_phone_no,
              address: item.ud_address,
              role: item.url_role,
            };
          });
        return result;
      };
  return (
    <div>
        <div>
            <p className="page_title">Customers</p>
        </div>
        <div className='customers_filter_container'>
            <CustomerFilter/>
        </div>
        <div>
           <SimpleTable columns={Columns} rows={datas()} />
        </div>
         
    </div>
  )
}

export default Customers