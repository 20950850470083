import ResponseHandler from "../../services/ResponseHandler"
import { ApiConfig } from "../apiConfig"
import { setAllLiquidity } from "../../store/LookUpStore"


export const getAllLiquidity=(data)=>{
    return  async(dispatch)=>{
        try{
            const response=await ApiConfig.get('liquidity',data)
            dispatch(setAllLiquidity(response.data.data))
            return response.data.data
        }catch(error){
            ResponseHandler(error)
        }
    }
}

export const CreateLiquidity=(value)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.post('liquidity/create_new_liquidity',value)
           return response
        }catch(error){
            throw error
        }
    }
}
export const UpdateLiquidity=(id,value)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.put(`liquidity/${id}/update_liquidity`,value)
           return response
        }catch(error){
            throw error
        }
    }
}
export const DeleteLiquidity=(id)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.delete(`liquidity/${id}/delete_liquidity`)
           return response
        }catch(error){
            throw error
        }
    }
}
//   //Liquidity lookup - permission and validation check is not added
//   router.get('/liquidity',getAllLiquidity)
//   router.post('/liquidity/create_new_liquidity',createLiquidity)
//   router.put('/liquidity/:id/update_liquidity',updateLiquidity)
//   router.delete('/liquidity/:id/delete_liquidity',deleteLiquidity)
//   ////