import { updateMessageContainerIsUpdated } from "../../store/messageStore"
import { ApiConfig } from "../apiConfig"

export const getUsersWithLastMessage=()=>{
    return async(dispatch)=>{
        try {
            const response=await ApiConfig.get(`user_message`)
            return response.data.data
        } catch (error) {
            throw error
        }
    }
}
export const getUsersSpecificDetails=(user_id)=>{
    return async(dispatch)=>{
        try {
            const response=await ApiConfig.get(`/user/${user_id}/user_specific_details`)
            return response.data.data
        } catch (error) {
            throw error
        }
    }
}

export const getMessageBasedOnUser=(user_id)=>{
    return async(dispatch)=>{
        try {
            const response=await ApiConfig.get(`${user_id}/messages_based_on_user`)
            dispatch(updateMessageFlagBasedOnUser(user_id));
            return response.data.data
           
        } catch (error) {
            throw error
        }
    }
}

// Action to update message read flag based on user
export const updateMessageFlagBasedOnUser = (user_id) => {
    return async (dispatch) => {
      try {
        await ApiConfig.post(`/update_message_flag_based_on_user`, { user_id });
        dispatch(updateMessageContainerIsUpdated())
      } catch (error) {
        console.error('Error updating message read flag');
      }
    };
  };