import React, { useEffect, useState } from "react";
import FormButton from "../../../../../../components/formButton/FormButton";
import "./AttachmentForm.css";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import { FiUpload } from "react-icons/fi";
import { Button } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Pdf_Logo from "../../../../../../assets/Pdf_logo.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Helpers } from "../../../../../../services/Helpers";
import {
  getPropertyAttachment,
  updatePropertyAttachment,
} from "../../../../../../api/apiServices/propertyApi";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import { useDispatch, useSelector } from "react-redux";
import { RiFileEditFill } from "react-icons/ri";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
function AttachmentForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { property_id } = useParams();
  const [attachments, SetAttachments] = useState([]);
  const [seletedAttachment, SetSelectedAttachment] = useState({
    filetype: "",
    title: "",
    file: "",
    uploadedDateTime: "",
  });
  const [ValidText, SetValidText] = useState({
    IsNotValid: false,
    message: "",
  });
  const [validFile, SetValidFile] = useState({
    IsNotValid: false,
    message: "File is required",
  });
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iSViewAndEdit = queryParams.get("view_edit");
  const [loading, setLoading] = useState(false);
  const [Isview, SetIsview] = useState(true);

  useEffect(() => {
    if (iSViewAndEdit) {
      SetIsview(true);
    }
    if (property_id) {
      dispatch(getPropertyAttachment(property_id))
        .then((response) => {
          const data = response.data.data;
          SetAttachments(
            data && data.pat_attachments ? data.pat_attachments : []
          );
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
  }, [property_id]);
  function handleFileSelect(event) {
    const fileInput = event.target;
    const fileInfo = document.getElementById("fileInfo");
    if (fileInput.files.length > 0) {
      const fileName = fileInput.files[0].name;
      SetSelectedAttachment({
        ...seletedAttachment,
        ["file"]: fileInput.files[0],
        uploadedDateTime: new Date().toISOString(),
        filetype: fileInput.files[0].type.split("/")[1],
      });
      fileInfo.textContent = `Selected file: ${
        fileName.length > 5 ? fileName.slice(0, 5) + "..." : fileName
      }`;
    } else {
      fileInfo.textContent = "Choose file";
    }
    if (validFile.IsNotValid) {
      SetValidFile({ ...validFile, ["IsNotValid"]: false });
    }
  }
  function setAttachment(name, value) {
    SetSelectedAttachment({ ...seletedAttachment, [name]: value });
    if (ValidText.IsNotValid) {
      SetValidText({
        ...ValidText,
        ["IsNotValid"]: false,
        message: "Name is required",
      });
    }
  }
  function uploadFile() {
    const getExistingName = attachments.filter((item) =>
      seletedAttachment.title
        .trim()
        .toLowerCase()
        .includes(item.title.trim().toLowerCase())
    );
    if (getExistingName.length > 0) {
      SetValidText({
        ...ValidText,
        ["IsNotValid"]: true,
        message: "Name alreday exists",
      });
    } else if (
      !seletedAttachment.title.trim() &&
      seletedAttachment.file.toString().length <= 0
    ) {
      SetValidText({
        ...ValidText,
        ["IsNotValid"]: true,
        message: "Name is required",
      });
      SetValidFile({ ...validFile, ["IsNotValid"]: true });
    } else if (!seletedAttachment.title.trim()) {
      SetValidText({
        ...ValidText,
        ["IsNotValid"]: true,
        message: "Name is required",
      });
    } else if (seletedAttachment.file.toString().length <= 0) {
      SetValidFile({ ...validFile, ["IsNotValid"]: true });
    } else {
      SetAttachments([...attachments, seletedAttachment]);
      SetSelectedAttachment({
        filetype: "",
        title: "",
        file: "",
        uploadedDateTime: "",
      });
      const fileInfo = document.getElementById("fileInfo");
      fileInfo.textContent = "Choose file";
    }
  }
  function DeleteFile(SelectedIndex) {
    let Attachments = attachments.filter((_, index) => index !== SelectedIndex);
    SetAttachments(Attachments);
  }
  function setAttachment(name, value) {
    SetSelectedAttachment({ ...seletedAttachment, [name]: value });
    if (ValidText.IsNotValid) {
      SetValidText({
        ...ValidText,
        ["IsNotValid"]: false,
        message: "Name is required",
      });
    }
  }
  function onSubmit() {
    const data = new FormData();
    data.append("PropertyAttachmentDetails", JSON.stringify(attachments));
    if (attachments && attachments.length > 0) {
      attachments.forEach((attachment) => {
        if (
          !(
            attachment.file.toString().startsWith("http://") ||
            attachment.file.toString().startsWith("https://")
          )
        ) {
          data.append(
            `propertyAttachments`,
            attachment.file,
            `${
              "PA_" +
              property_id +
              "_" +
              new Date().toISOString().replace(/:/g, "_") +
              "-" +
              attachment.title +
              "." +
              attachment.filetype
            }`
          );
        }
      });
    } else {
      data.append(`propertyAttachments`, null);
    }
    setLoading(true);
    dispatch(updatePropertyAttachment(property_id, data))
      .then((response) => {
        ResponseHandler(response);
        if (iSViewAndEdit) {
          navigate(
            `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${5}&view_edit=${true}`
          );
        } else {
          navigate(
            `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${5}`
          );
        }
      })
      .catch((error) => {
        ResponseHandler(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }
  function isFileURL(file) {
    return typeof file === "string" && (file.startsWith("http://") || file.startsWith("https://"));
  }
  const Privilages = useSelector((state) => state.userStore.Privilages);
  return (
    <div>
      <div className="Attachment_form_main_Container">
        {Privilages &&
        Privilages?.Property &&
        Privilages?.Property.some((item) => item === "Update")
          ? Isview && (
              <div
                className="Property_BankDetails_edit_details"
                onClick={() => SetIsview(false)}
              >
                <RiFileEditFill />
                <p>Edit</p>
              </div>
            )
          : null}
        <div className="Attachment_formContainer">
          <div>
            <p className="Attachment_title">Upload Files</p>
            <div className="Attachment_sub_Container">
              <div style={{ position: "relative" }}>
                <label
                  htmlFor="files"
                  className="choose_file_field_attach_properties"
                >
                  <FiUpload className="file_upload_icon" />
                  <span id="fileInfo"> Choose file</span>
                </label>
                <input
                  disabled={Isview}
                  id="files"
                  style={{ display: "none" }}
                  type="file"
                  onChange={handleFileSelect}
                />
                {validFile.IsNotValid ? (
                  <p
                    style={{
                      position: "absolute",
                      color: "red",
                      visibility: validFile.IsNotValid ? "visible" : "hidden",
                    }}
                  >
                    {validFile.message}
                  </p>
                ) : null}
              </div>
              <div className="customTextField" style={{ position: "relative" }}>
                <CustomTextField
                  className="customTextField"
                  style={{ height: "18px" }}
                  disabled={Isview}
                  placeholder={"Title"}
                  value={seletedAttachment.title}
                  onChange={(e) => {
                    setAttachment("title", e.target.value);
                  }}
                />
                {ValidText.IsNotValid ? (
                  <p
                    style={{
                      position: "absolute",
                      color: "red",
                      visibility: ValidText.IsNotValid ? "visible" : "hidden",
                    }}
                  >
                    {ValidText.message}
                  </p>
                ) : null}
              </div>

              <div>
                <Button
                  size="small"
                  variant="contained"
                  style={{
                    textTransform: "none",
                    lineHeight: 2.3,
                    fontFamily: "Inter",
                  }}
                  onClick={() => {
                    uploadFile();
                  }}
                  disabled={Isview}
                >
                  Upload
                </Button>
              </div>
            </div>
          </div>

          <div>
            <div className="attachment_main_container">
              {attachments &&
                attachments.map((item, index) => {
                  return (
                    <>
                      {item.filetype !== "pdf" && !Helpers.getUrlExtensions(item.file) && (
                        <div className="attachment_container">
                          <img
                            key={index}
                            src={
                              typeof item.file === 'string' && (item.file.startsWith('http://') || item.file.startsWith('https://'))
                                ? item.file
                                : URL.createObjectURL(item.file)
                            }
                            alt={`Uploaded Image ${index + 1}`}
                            className="image_attachment"
                          />
                          <p style={{ textAlign: "center" }}>{item.title}</p>
                          <div
                            className="attachment_close_icon"
                            onClick={() => {
                              DeleteFile(index);
                            }}
                          >
                            <CancelIcon
                              className="attachemnt_cancelIcon"
                              style={{ display: Isview ? "none" : "block" }}
                            />
                          </div>
                        </div>
                      )}
                      {Helpers.isVideoFile(item.filetype)&& (
                        <div className="attachment_container" key={index}>
                          <video controls className="image_attachment">
                          <source
                     src={Helpers.isFileURL(item.file) ? item.file : URL.createObjectURL(item.file)}
                     type={`video/${item.filetype}`}
                  />
                            Your browser does not support the video tag.
                          </video>
                          <p style={{ textAlign: "center" }}>{item.title}</p>
                          <div
                            className="attachment_close_icon"
                            onClick={() => {
                              DeleteFile(index);
                            }}
                          >
                            <CancelIcon
                              className="attachemnt_cancelIcon"
                              style={{ display: Isview ? "none" : "block" }}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
            <div className="attachment_main_container">
              {attachments &&
                attachments.map((item, index) => {
                  return (
                    <>
                      {item.filetype === "pdf" && (
                        <div className="file_attachment_container">
                          <div className="attachment_sub_container">
                            <img
                              key={index}
                              src={Pdf_Logo}
                              alt={`Uploaded Image ${index + 1}`}
                              className="file_attachment"
                            />
                            <div>
                              <p className="file_title">
                                {item.title + ".pdf"}
                              </p>
                            </div>
                          </div>
                          <div
                            className="attachment_close_icon"
                            onClick={() => {
                              DeleteFile(index);
                            }}
                          >
                            <CancelIcon
                              className="attachemnt_cancelIcon"
                              style={{ display: Isview ? "none" : "block" }}
                            />
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
            </div>
          </div>
        </div>
        {!Isview && (
          <div className="Attachmentform_action">
            <FormButton
              id="AttachmentCreateCancel"
              variant="outlined"
              loading={false}
              text="Cancel"
              onClick={() => {
                navigate(
                  `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`
                );
              }}
            />
            <FormButton
              id="AttachmentCreateSubmit"
              type="submit"
              form="Attachmentform"
              variant="contained"
              onClick={onSubmit}
              loading={loading}
              text="Save & Continue"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AttachmentForm;
