import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./BankDetailsForm.css";
import CustomAutoComplete from "../../../../../../components/customAutoComplete/CustomAutoComplete";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import CustomDatePicker from "../../../../../../components/customDatePicker/CustomDatePicker";
import FormButton from "../../../../../../components/formButton/FormButton";
import { getAllApplicationOwnerNames } from "../../../../../../api/apiServices/applicationOwnerApi";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import { getAllStatus } from "../../../../../../api/apiServices/statusApi";
import { getAllVehicleType } from "../../../../../../api/apiServices/vehicleTypeApi";
import Maps from "../map/Maps";
import CustomMap from "../map/CustomMap";
import {
  createVehicleBankDetails,
  getVehicleBankDetails,
  updateVehicleBankDetails,
} from "../../../../../../api/apiServices/vehicleApi";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import { RiFileEditFill } from "react-icons/ri";
import CustomTextArea from "../../../../../../components/customTextArea/CustomTextArea";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
import { useDispatch, useSelector } from "react-redux";
import CustomSpin from "../../../../../../components/customSpin/CustomSpin";



function BankDetailsForm({updateIsEditable}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iSViewAndEdit = queryParams.get("view_edit");
  const [ApplicationOwnerNames, SetApplicationOwnerNames] = useState([]);
  const [Status, SetStatus] = useState([]);
  const [Loading, SetLoading] = useState(false);
  const [Isview, SetIsview] = useState(false);
  const vehicleTypeData = useSelector(
    (state) => state.vehicleTypeStore.VehicleTypes
  );
  
  const { id } = useParams();
  useEffect(() => {
    AllApplicationOwnerNames();
    AllStatus();
    dispatch(getAllVehicleType());
    if (id) {
      dispatch(getVehicleBankDetails(id))
        .then((response) => {
          setFormikData(response.data.data);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
    if (iSViewAndEdit) {
      SetIsview(true);
    }
  }, [id]);

  function AllApplicationOwnerNames() {
    dispatch(getAllApplicationOwnerNames())
      .then((response) => {
        const data = response.map((item) => {
          return {
            label: item.aonl_name,
            value: item.aonl_id,
          };
        });
        SetApplicationOwnerNames(data);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }
  function AllStatus() {
    dispatch(getAllStatus())
      .then((response) => {
        const data = response.map((item) => {
          return {
            label: item.sl_status,
            value: item.sl_id,
          };
        });
        SetStatus(data);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }
  function AllVehicles(vehicleData) {
    if (vehicleData && vehicleData.length > 0) {
      const data = vehicleData
        .filter((item) => item.vtl_status === "active")
        .map((item) => {
          return {
            label: item.vtl_type,
            value: item.vtl_id,
          };
        });
      return data;
    } else {
      return [];
    }
  }
  //status
  const initialValues = {
    application_name: null,
    owner_name: null,
    identification_number: "",
    issue_date: null,
    treasure_value: "",
    actual_state: "",
    tax_status: "",
    status: null,
    type: null,
  };
  const validationSchema = Yup.object({
    // application_name: Yup.string().required("Applicant Name is required"),
    // owner_name: Yup.string().required("Owner Name is required"),
    identification_number: Yup.string().required(
      "Identification Number is required"
    ),
    issue_date: Yup.string().required("Issue Date is required"),
    treasure_value: Yup.string(),
    actual_state: Yup.string(),
    tax_status: Yup.string(),
    status: Yup.string().required("Status is required"),
    type: Yup.string().required("Vehicle Type is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      SetLoading(true);
      if (id) {
        dispatch(updateVehicleBankDetails(id, values))
          .then((response) => {
            ResponseHandler(response);
            if (iSViewAndEdit) {
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${id}/update_vehicle?tk=${1}&view_edit=${true}`
              );
            } else {
              navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${id}/update_vehicle?tk=${1}`);
            }
          })
          .catch((error) => {
            ResponseHandler(error);
          })
          .finally(() => {
            SetLoading(false);
          });
      } else {
        dispatch(createVehicleBankDetails(values))
          .then((response) => {
            ResponseHandler(response);
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/${
                response.data.data.vbd_id
              }/update_vehicle?tk=${1}`
            );
          })
          .catch((error) => {
            ResponseHandler(error);
          })
          .finally(() => {
            SetLoading(false);
          });
      }
    },
  });
  function setFormikData(data) {
    const mappedData = Object.keys(initialValues).reduce((acc, key) => {
      const originalKey = `vbd_${key}`;
      acc[key] = data[originalKey] || initialValues[key];
      return acc;
    }, {});
    mappedData["application_name"] = data.vbd_application_name_id;
    mappedData["owner_name"] = data.vbd_owner_name_id;
    mappedData["status"] = data.vbd_status_id;
    mappedData["type"] = data.vbd_type_id;
    formik.setValues({
      ...formik.values,
      ...mappedData,
    });
  }
  
  const Privilages = useSelector((state) => state.userStore.Privilages);

  return (
    <div>
      
      <div className="BankDetails_form_main_Container">
        {
        Privilages && Privilages?.Vehicle && Privilages?.Vehicle.some((item) => item === "Update")
          ? Isview && (
              <div
                className="BankDetails_edit_details"
                onClick={() => {
                  SetIsview(false)
                  updateIsEditable(true)
                }}
              >
                <RiFileEditFill />
                <p>Edit</p>
              </div>
            )
          : null}
        <div className="BankDetails_formContainer">
          <p className="BankDetails_form_legend">Add Bank Details</p>
          <form id="BankDetailsform" onSubmit={formik.handleSubmit}>
            <div className="BankDetailsform_fields">
              <div>
                <div className="BankDetailsform_field_container">
                  <p className="BankDetailsform_fields_labels">
                    Applicant Name
                  </p>
                  <CustomAutoComplete
                    disabled={Isview}
                    name="application_name"
                    placeholder="Select application name"
                    options={ApplicationOwnerNames}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "application_name",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      ApplicationOwnerNames.find(
                        (application_name) =>
                          application_name.value ===
                          formik.values.application_name
                      ) || null
                    }
                    error={
                      formik.touched.application_name &&
                      Boolean(formik.errors.application_name)
                    }
                    helperText={
                      formik.touched.application_name &&
                      formik.errors.application_name
                    }
                  />
                </div>
                <div className="BankDetailsform_field_container">
                  <p className="BankDetailsform_fields_labels">Owner Name</p>
                  <CustomAutoComplete
                    disabled={Isview}
                    name="owner_name"
                    placeholder="Select owner name"
                    options={ApplicationOwnerNames}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "owner_name",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      ApplicationOwnerNames.find(
                        (owner_name) =>
                          owner_name.value === formik.values.owner_name
                      ) || null
                    }
                    error={
                      formik.touched.owner_name &&
                      Boolean(formik.errors.owner_name)
                    }
                    helperText={
                      formik.touched.owner_name && formik.errors.owner_name
                    }
                  />
                </div>
                <div className="BankDetailsform_field_container">
                  <p className="BankDetailsform_fields_labels">
                    Identification Number
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    
                    name="identification_number"
                    placeholder="Enter identification number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.identification_number}
                    error={
                      formik.touched.identification_number &&
                      Boolean(formik.errors.identification_number)
                    }
                    helperText={
                      formik.touched.identification_number &&
                      formik.errors.identification_number
                    }
                    inputProps={{
                      style: {
                        backgroundColor: Isview ? "#000000" : "inherit", // Change background color to black if Isview is true
                      },
                    }}
                  />
                </div>
                <div className="BankDetailsform_field_container">
                  <p className="BankDetailsform_fields_labels">Issue Date</p>
                  <CustomDatePicker
                    disabled={Isview}
                    name="issue_date"
                    onChange={(date) => {
                      formik.setFieldValue("issue_date", date);
                    }}
                    onBlur={formik.handleBlur}
                    value={dayjs(formik.values.issue_date, "MM/DD/YYYY")}
                    error={
                      formik.touched.issue_date &&
                      Boolean(formik.errors.issue_date)
                    }
                    helperText={
                      formik.touched.issue_date && formik.errors.issue_date
                    }
                  />
                </div>
              </div>
              <div>
                <div className="BankDetailsform_field_container">
                  <p className="BankDetailsform_fields_labels">
                    Treasury Value
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="treasure_value"
                    placeholder="Enter treasury value"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.treasure_value}
                    error={
                      formik.touched.treasure_value &&
                      Boolean(formik.errors.treasure_value)
                    }
                    helperText={
                      formik.touched.treasure_value &&
                      formik.errors.treasure_value
                    }
                  />
                </div>
                <div id="BankDetailsform_field_container">
                  <div className="BankDetailsform_field_container">
                    <p className="BankDetailsform_fields_labels">
                      Actual State
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="actual_state"
                      placeholder="Enter actual state"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.actual_state}
                      error={
                        formik.touched.actual_state &&
                        Boolean(formik.errors.actual_state)
                      }
                      helperText={
                        formik.touched.actual_state &&
                        formik.errors.actual_state
                      }
                    />
                  </div>
                  <div className="BankDetailsform_field_container">
                    <p className="BankDetailsform_fields_labels">Tax Status</p>
                    <CustomTextField
                      disabled={Isview}
                      name="tax_status"
                      placeholder="Enter tax status"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.tax_status}
                      error={
                        formik.touched.tax_status &&
                        Boolean(formik.errors.tax_status)
                      }
                      helperText={
                        formik.touched.tax_status && formik.errors.tax_status
                      }
                    />
                  </div>
                  <div className="BankDetailsform_dualfields">
                    <div className="BankDetailsform_field_container">
                      <p className="BankDetailsform_fields_labels">Status</p>
                      <CustomAutoComplete
                        disabled={Isview}
                        name="status"
                        placeholder="Select status"
                        options={Status}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            "status",
                            newValue ? newValue.value : ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                        value={
                          Status.find(
                            (status) => status.value === formik.values.status
                          ) || null
                        }
                        error={
                          formik.touched.status && Boolean(formik.errors.status)
                        }
                        helperText={
                          formik.touched.status && formik.errors.status
                        }
                      />
                    </div>
                    <div className="BankDetailsform_field_container">
                      <p className="BankDetailsform_fields_labels">
                        Vehicle Type
                      </p>
                      <CustomAutoComplete
                        disabled={Isview}
                        name="type"
                        placeholder="Select vehicle type"
                        options={AllVehicles(vehicleTypeData)}
                        getOptionLabel={(option) => option.label}
                        onChange={(event, newValue) => {
                          formik.setFieldValue(
                            "type",
                            newValue ? newValue.value : ""
                          );
                        }}
                        onBlur={formik.handleBlur}
                        value={
                          AllVehicles(vehicleTypeData).find(
                            (type) => type.value === formik.values.type
                          ) || null
                        }
                        isOptionEqualToValue={(option, value) =>
                          option.value.toString() === value.value.toString()
                        }
                        error={
                          formik.touched.type && Boolean(formik.errors.type)
                        }
                        helperText={formik.touched.type && formik.errors.type}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {!Isview && (
          <div className="BankDetailsform_action" > 
            <FormButton
              id="BankDetailsCreateCancel"
              variant="outlined"
              loading={false}
              text="Cancel"
              onClick={() => {
                navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/vehicles`);
              }}
            />
            <FormButton
              id="BankDetailsCreateSubmit"
              type="submit"
              form="BankDetailsform"
              variant="contained"
              loading={Loading}
              text="Save & Continue"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default BankDetailsForm;
