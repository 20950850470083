import * as React from "react";
import "./CustomToolTip.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {  AgentSubPagesEndPoints, PropertiesSubMenuEndPoints, UserSubPagesEndEndPoints, UsersOrStaffSubMenuEndPoints, VehicleSubMenuEndPoints, VehiclesSubMenuEndPoints } from "../../pages/homePage/subComponents/sliderMenu/routerEndPoints";
function CustomToolTip({ visibilityChange,text, initialRoute, Submenu }) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();
  function SubMenuHighlight(elementText){
    const lastPart = window.location.href.split("/")[window.location.href.split("/").length - 1];
    const lastPartWithoutQuery = lastPart.split("?")[0];
    let isValidRoute=false
    if(elementText===t("Vehicles")){
      isValidRoute=VehiclesSubMenuEndPoints.some(item => item === lastPartWithoutQuery)
    }else if(elementText===t("Properties")){
      isValidRoute=PropertiesSubMenuEndPoints.some(item => item === lastPartWithoutQuery)
    }else if(elementText===t("Users")){
      isValidRoute=UserSubPagesEndEndPoints.some(item => item === lastPartWithoutQuery)
    }
    else if(elementText===t("Agents")){
      isValidRoute=AgentSubPagesEndPoints.some(item => item === lastPartWithoutQuery)
    }
    return isValidRoute
  }
  return (
    <div className="CustomToolTip_MainConatiner">
      {Submenu ? (
        <>
          {Submenu.map((item) => {
            return (
              <p
              className="CustomToolTip_Text"
                style={{
                  color:location.pathname === item.subRoute ? "#005EB8" : SubMenuHighlight(item.text)?"#005EB8": "",
                }}
                onClick={()=>{
                    visibilityChange(false)
                    navigate(item.subRoute)
                }}
              >
                {item.text}
              </p>
            );
          })}
        </>
      ) : (
        <p
          className="CustomToolTip_Text"
          style={{
            color:
              location.pathname === initialRoute ? "#005EB8" : SubMenuHighlight(text)?"#005EB8": "",
          }}
          onClick={()=>{
            visibilityChange(false)
            navigate(initialRoute)
        }}
        >
          {text} 
        </p> 
      )}
    </div>
  );
}

export default CustomToolTip;
