import { ApiConfig } from "../apiConfig"
import ResponseHandler from "../../services/ResponseHandler";
import { setAllCurrency } from "../../store/LookUpStore";
export const getAllCurrency=(data)=>{
    return  async(dispatch)=>{
        try{
            const response=await ApiConfig.get('currency',data);
            dispatch(setAllCurrency(response.data.data))
           return response.data.data
        }catch(error){
            ResponseHandler(error)
            // throw error
        }
    }
}

export const createCurrency=(values)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.post('currency/create_new_currency',values)
           return response
        }catch(error){
            throw error
        }
    }
}
export const updateCurrency=(id,values)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.put(`currency/${id}/update_currency`,values)
           return response
        }catch(error){
            throw error
        }
    }
}

export const deleteCurrency=(id)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.delete(`currency/${id}/delete_currency`)
           return response
        }catch(error){
            throw error
        }
    }
}
