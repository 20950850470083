import React from 'react'
import'./customDashboardSmallCard.css'
function CustomDashboardSmallCard({ image, name, count }) {
    return (
        <div className='custom_dashboard_small_item_container'>
            <div>
                <img src={image} />
            </div>
            <div>
                <p className='custom_dashboard_small_item_count'>{count}</p>
                <p className='custom_dashboard_small_item_name'>{name}</p>
            </div>
        </div>
    )
}

export default CustomDashboardSmallCard