import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import { IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { updateCollapsedState } from "../../../../../store/homePageStore";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import { useDispatch, useSelector } from "react-redux";
import LanguageToggle from "../../../../loginPage/subComponents/languageToggle/LanguageToggle";
import { getAllNotifications } from "../../../../../api/apiServices/notificationApi";

function ProfileDropdown() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const userDetails = useSelector((state) => state.mainStore.user_details);
  const [user_Details, setUser_Details] = useState({
    name: "",
    role: "",
  });
  const diaptch = useDispatch();

  useEffect(() => {
    setUser_Details({
      ...user_Details,
      ["name"]: userDetails?.ud_name,
      ["role"]: userDetails?.up_role,
    });
    diaptch(getAllNotifications());
  }, [userDetails]);

  function changeCollapsedState() {
    diaptch(updateCollapsedState());
  }

  return (
    <div>
      <Tooltip title="Profile">
        <IconButton
          onClick={handleClick}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "profile" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <Avatar alt={user_Details.name} src={``}>
            {user_Details.name.charAt(0)}
          </Avatar>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="profile"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "& .MuiAvatar-root": {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            "&::before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem onClick={handleClose}>
          <div>
            <p className="homePage_userName">{user_Details.name}</p>
            <p className="homePage_userRole">{user_Details.role}</p>
          </div>
        </MenuItem>
        <Divider />

        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <LanguageToggle style={{ width: "100%", margin: "0px" }} />
        </MenuItem>
      </Menu>
    </div>
  );
}

export default ProfileDropdown;
