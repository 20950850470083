import React, { useState } from "react";
import "./AgentsPage.css";
import { useNavigate } from "react-router-dom";
import { LocalStorageServices } from "../../../../services/localStorageServices";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  deleteAgentProfile,
  getAllAgents,
} from "../../../../api/apiServices/agentApi";
import CustomChip from "../../../../components/customChip/CustomChip";
import SimpleTable from "../../../../components/simpleTable/SimpleTable3";
import CustomModal from "../../../../components/customModal/CustomModal";
import UpdatePassword from "./updatePassword/UpdatePassword";
import ResponseHandler from "../../../../services/ResponseHandler";
import AgentSearchFilter from "./searchFilter/AgentSearchFilter";
function AgentsPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [selectedId, setSelectedId] = useState("");
  const AgentsDetails = useSelector((state) => state.agentStore.Agents);
  const [deleteRowDetails, setDeleteRowDetails] = useState({
    id: "",
    loading: false,
  });
  useEffect(() => {
    dispatch(getAllAgents());
  }, []);
  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
    },
    {
      title: "Interests",
      dataIndex: "interest",
      key: "interest",
      action: (row) => (
        <p>
          {row.interest === 1
            ? "All"
            : row.interest === 2
            ? "Property"
            : row.interest === 3
            ? "Vehicle"
            : "-"}
        </p>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      action: (row) => (
        <p
          style={{
            color: row.status === "Active" ? "green" : "red",
          }}
        >
          {row.status}
        </p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      action: (row) => (
        <div className="user_action_chipset">
          <CustomChip
            text="View"
            bgColor="#F8893C"
            color="white"
            onClick={() => {
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/agent/${
                  row.id
                }/agent_details`
              );
            }}
          />
          <CustomChip
            text="Change Password"
            bgColor="#1976d2"
            color="white"
            onClick={() => handleChangePassword(row)}
          />
          <CustomChip
            text="Delete"
            bgColor="#ED5967"
            color="white"
            loading={
              deleteRowDetails.id === row.id ? deleteRowDetails.loading : false
            }
            onClick={() => {
              handleDeleteUser(row);
            }}
          />
        </div>
      ),
    },
  ];
  const Datas = () => {
    const result =
      AgentsDetails &&
      AgentsDetails.map((item, index) => {
        return {
          key: index + 1,
          id: item.ad_profile_id,
          name: item.ud_name,
          email: item.up_email,
          phoneNumber: item.ud_phone_no,
          address: item.ud_address,
          role: item.url_role,
          location: item.ad_location,
          status: item.usl_status,
          interest: item.ad_interest,
        };
      });
    return result;
  };
  const UpdateDeleteDetails = (id, loading) => {
    setDeleteRowDetails({
      ...deleteRowDetails,
      ["id"]: id,
      ["loading"]: loading,
    });
  };
  const handleDeleteUser = (row) => {
    UpdateDeleteDetails(row.id, true);
    dispatch(deleteAgentProfile(row.id))
      .then((response) => {
        ResponseHandler(response);
      })
      .catch((error) => {
        ResponseHandler(error);
      })
      .finally(() => {
        UpdateDeleteDetails(row.id, false);
      });
  };
  const handleChangePassword = (row) => {
    setSelectedId(row.id);
    handleClickOpen();
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (state) => {
    setOpen(state);
  };
  return (
    <div>
      <div className="Agent_page_header">
        <p className="page_title">Agents</p>
        <button
          className="add_Agent_button"
          onClick={() => {
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/agent/create_agent`
            );
          }}
        >
          Add Agent
        </button>
      </div>
      <div className="agent_search_filter_component">
        <AgentSearchFilter />
      </div>
      <div>
        <SimpleTable columns={Columns} rows={Datas()} />
        <CustomModal
          open={open}
          handleClose={handleClose}
          body={<UpdatePassword id={selectedId} onclose={handleClose} />}
          title={"Change Password"}
        />
      </div>
    </div>
  );
}

export default AgentsPage;
