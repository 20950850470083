import React, { useEffect, useState } from "react";
import "./CustomNotes.css";
import Button from "@mui/material/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomModal from "../customModal/CustomModal";
import { getVehicleTaskNote } from "../../api/apiServices/vehicleApi";
import AddNoteform from "../../pages/homePage/subPages/vehicle/vehicleTaskViewAndEdit/addnoteform/AddNotefrom";
import ResponseHandler from "../../services/ResponseHandler";
import { deleteVehicleTaskNotesByRunner } from "../../api/apiServices/vehicleApi";
import { LocalStorageServices } from "../../services/localStorageServices";
import { RiFileEditFill } from "react-icons/ri";
import { RiDeleteBinLine } from "react-icons/ri";
import { Divider } from "@mui/material";
import { DateTimeConvertionService } from "../../services/DateTimeConvertionService";
function CustomNotesContainer({ data, readOnly }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [dataToUpdate, setDataToUpdate] = useState(null);
  const [notes, setNotes] = useState([]); // State to store note data
  const [selectedItem, setSelectedItem] = useState("notes");

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };
  useEffect(() => {
    GetTaskNotes();
  }, [data]);

  function GetTaskNotes() {
    if (data && data.vt_vehicle_id && data.vehicle_task_id) {
      dispatch(
        getVehicleTaskNote(data.vt_vehicle_id, data.vehicle_task_id, data)
      )
        .then((response) => {
          setNotes(response);
        })
        .catch((error) => {
          console.error("Failed to fetch notes:", error);
          setNotes([]);
        });
    }
  }

  const handleDeleteNote = (rvtn_id) => {
    dispatch(deleteVehicleTaskNotesByRunner(rvtn_id))
      .then((response) => {
        GetTaskNotes();
        ResponseHandler(response);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  };
  const setIsUpdateMode = (value) => {
    setIsUpdateMode(value);
  };
  const handleClickOpen = (item) => {
    setOpen(true); // Open the modal
    setDataToUpdate(item);
  };

  const handleClose = () => {
    setOpen(false); // Close the modal
    setDataToUpdate(null); // Reset dataToUpdate
    GetTaskNotes();
  };
  return (
    <div className="Custom_notes_main_container">
      <div className="Navbar">
        <div className="Titile_notes">
          <p
            className={selectedItem === "notes" ? "note active" : "note"}
            onClick={() => handleItemClick("notes")}
          >
            Notes
          </p>
          <p
            className={
              selectedItem === "activity" ? "activity active" : "activity"
            }
            onClick={() => handleItemClick("activity")}
          >
            Activity
          </p>
        </div>
        <div>
          <Button
            variant="contained"
            size="small"
            style={{
              textTransform: "none",
              fontFamily: "Inter",
              fontSize: "14px",
              backgroundColor: readOnly ? "rgb(235, 235, 235)" :"#0F62FE",
              padding: "5px 25px",
              borderRadius: "5px",
              color: readOnly ? "rgb(133, 133, 133)" :  "white",
              pointerEvents: readOnly ? "none" : "auto",
              
            }}
            onClick={() => handleClickOpen(null)}
          >
            Add
          </Button>
          <CustomModal
            open={open}
            handleClose={handleClose}
            body={
              <AddNoteform
                data={dataToUpdate ? dataToUpdate : data}
                onClose={handleClose}
              />
            }
            title={dataToUpdate ? "EDIT NOTE" : "ADD NOTE"}
          />
        </div>
      </div>
      <div className="Details_container">
        {notes.length > 0 ? (
          notes.map((item, index) => (
            <div key={index} className="note_container">
              <div className="sub_container">
                <div className="Time_date">
                  {item.ud_name && (
                    <p
                      style={{
                        color: "rgba(0, 94, 184, 1)",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {item.ud_name}
                    </p>
                  )}
                  {item.up_email && (
                    <p
                      style={{
                        color: "rgba(0, 94, 184, 1)",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      ({item.up_email})
                    </p>
                  )}
                  {item.created_date && (
                    <p
                      style={{
                        color: "rgba(144, 147, 161, 1)",
                        fontSize: "12px",
                        fontWeight: 400,
                        marginLeft:"30px",
                      }}
                    >
                      {DateTimeConvertionService.DateTimeConverter(
                        item.created_date
                      )}
                    </p>
                  )}
                </div>
                <div className="note_icons">
                  {LocalStorageServices.getLocalStorageDataAndParse() &&
                    LocalStorageServices.getLocalStorageDataAndParse()?.up_id ==
                      item.runner_id && (
                      <>
                        <span
                          className="edit_icon"
                          style={{
                            color: readOnly ? "rgba(218, 218, 218, 1)" :  "rgba(0, 48, 135, 1)",
                            fontSize: "20px",
                            pointerEvents: readOnly ? "" : "auto",
                            cursor: readOnly ? "not-allowed" : "pointer", 
                          }}
                          onClick={() => handleClickOpen(item)}
                        >
                          <RiFileEditFill />
                        </span>
                        <span
                          className="delete_icon"
                          style={{ pointerEvents: readOnly ? "" : "auto",cursor: readOnly ? "not-allowed" : "pointer", }}
                          onClick={() => handleDeleteNote(item.rvtn_id)}
                        >
                          <RiDeleteBinLine
                            style={{color: readOnly ? "rgba(218, 218, 218, 1)" :  "red", fontSize: "20px" ,}}
                          />
                        </span>
                      </>
                    )}
                </div>
              </div>
              <Divider />
              <div className="Notes">
                <p
                  style={{
                    fontSize: "13px",
                    fontWeight: 400,
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {item.notes.length > 100
                    ? item.notes.match(/.{1,100}/g).map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))
                    : item.notes}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="No_notes">
          <p >No notes available</p>
           </div> 
        )}
      </div>
    </div>
  );
}

export default CustomNotesContainer;
