import React from 'react'
import './CustomBounceLoader.css'
function CustomBounceLoader() {
  return (
    <div className="bouncing-loader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
  )
}

export default CustomBounceLoader