import React,{useEffect, useState} from 'react'
import SimpleTable from "../../../../../../../components/simpleTable/SimpleTable";
import { useDispatch, useSelector  } from 'react-redux';
import CustomChip from "../../../../../../../components/customChip/CustomChip";
import CustomModal from "../../../../../../../components/customModal/CustomModal";
import ResponseHandler from '../../../../../../../services/ResponseHandler';
import { DeleteLiquidity, getAllLiquidity } from '../../../../../../../api/apiServices/liquidityApi';
import './liquidityLookUpStyle.css'
import LiquidityForm from './liquidity_form/LiquidityForm';
import LiquidityLookUpFilter from './LiquidityFilter';
function LiquidityLookUp() {
const dispatch=useDispatch()
const [open, setOpen] = useState(false);
const [existingValue,SetExistingValue]=useState(null)
const [rerenderUpdate,setrerenderUpdate]=useState(false)
const liquiditydata=useSelector((state)=>state.lookUpStore.Liquidity)
    useEffect(() => {
        dispatch(getAllLiquidity())
          // .then((response) => {
          //   setfuel(response)
          // })
          // .catch((error) => {
          //   console.log(error);
          // });
      }, [rerenderUpdate]);
      const Columns = [
        {
          title: "#",
          dataIndex: "key",
          key: "key",
        },
        {
          title: "Liquidity name",
          dataIndex: "liquidity_name",
          key: "liquidity_name",
        },
        {
          title: "Actions",
          dataIndex: "Actions",
          key: "Actions",
          action: (row) => (
            <div  className="liquidity_lookup_action_chipset">
              <CustomChip text="Edit" bgColor="#F8893C" color="white" onClick={()=>{
                SetExistingValue(row)
                handleClickOpen()
              }} />
              <CustomChip text="Delete" bgColor="#ED5967" color="white" onClick={()=>{
                dispatch(DeleteLiquidity(row.id)).then((response)=>{
                  ResponseHandler(response)
                  setrerenderUpdate(!rerenderUpdate)
                }).catch((error)=>{
                  ResponseHandler(error)
                })
              }}/>
            </div>
          ),
        },
      ];
     
      const Datas=()=>{
        const result= liquiditydata &&  liquiditydata.map((item,index)=>{
          return{
            key:index+1,
            id:item.ll_id,
            liquidity_name:item.ll_liquid
          }
        })
        return result
      }
      const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = (state) => {
        setOpen(state);
        SetExistingValue(null)
        setrerenderUpdate(!rerenderUpdate)
      };
  return (
    <div>
    <div className="liquidity_lookup_header">
       <div>
          <LiquidityLookUpFilter/>
       </div>
       <div>
       <button className="add_liquidity_button" onClick={()=>{handleClickOpen()}}>
          Add Liquidity
        </button>
       </div>
    </div>
    <CustomModal
          open={open}
          handleClose={handleClose}
          body={<LiquidityForm onClose={handleClose} existingvalue={existingValue}/>}
          title={existingValue?'Update Liquidity':"Add Liquidity"}
        />
     <SimpleTable columns={Columns} rows={Datas()}/>
  </div>
  )
}

export default LiquidityLookUp