import React, { useEffect, useState } from "react";
import { DateTimeConvertionService } from "../../../../../../services/DateTimeConvertionService";
import Button from "@mui/material/Button";
import "./RunnerViewNotes.css"
import { Divider } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getVehicleTaskNote } from "../../../../../../api/apiServices/vehicleApi";
function RunnerViewNotes({ data, readOnly }) {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const [notes, setNotes] = useState([]); // State to store note data
  const [selectedItem, setSelectedItem] = useState("notes");

  const handleItemClick = (item) => {
    setSelectedItem(item);
  };
  useEffect(() => {
    GetTaskNotes();
  }, [data]);

  function GetTaskNotes() {
    if (data && data.vt_vehicle_id && data.vehicle_task_id) {
      dispatch(
        getVehicleTaskNote(data.vt_vehicle_id, data.vehicle_task_id, data)
      )
        .then((response) => {
          setNotes(response);
        })
        .catch((error) => {
          console.error("Failed to fetch notes:", error);
          setNotes([]);
        });
    }
  }

  const handleClose = () => {
    setOpen(false); // Close the modal
 
  };
  return (
    <div className="Runner_Custom_notes_main_container">
      
      <div className="Runner_Details_container">
      
      {notes.length > 0 ? (
          notes.map((item, index) => (
            <div key={index} className="Runner_note_container">
              <div className="Runner_sub_container">
                <div className="Runner_Time_date">
                  {item.ud_name && (
                    <p
                      style={{
                        color: "rgba(0, 94, 184, 1)",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      {item.ud_name}
                    </p>
                  )}
                  {item.up_email && (
                    <p
                      style={{
                        color: "rgba(0, 94, 184, 1)",
                        fontSize: "14px",
                        fontWeight: 500,
                      }}
                    >
                      ({item.up_email})
                    </p>
                  )}
                  {item.created_date && (
                    <p
                    className="Runner_Date"
                    >
                      {DateTimeConvertionService.DateTimeConverter(
                        item.created_date
                      )}
                    </p>
                  )}
                </div>
               
              </div>
              <Divider />
              <div className="Runner_Notes">
                <p
                  style={{
                    fontSize: "13px",
                    fontWeight: 400,
                    whiteSpace: "pre-wrap",
                  }}
                >
                  {item.notes.length > 100
                    ? item.notes.match(/.{1,100}/g).map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      ))
                    : item.notes}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div className="Runner_No_notes">
          <p >No notes available</p>
           </div> 
        )}
        
      </div>
    </div>
  );
}

export default RunnerViewNotes;
