import React, { useEffect, useState } from "react";
import { BsShare } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import { HiArrowsPointingOut } from "react-icons/hi2";
import { Card, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "./AgentPropertyView.css";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { ApiConfig } from "../../../../../../api/apiConfig";
import { FaMapMarkerAlt } from "react-icons/fa";
import { PiBathtubBold } from "react-icons/pi";
import PropertyMap from "./PropertyMap";
import Propertyslide from "./PropertySlide";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoHeart } from "react-icons/io5";
import { ErrorToast } from "../../../../../../toasts/Toasts";
import { useSelector } from "react-redux";
import CustomSpin from "../../../../../../components/customSpin/CustomSpin";
import Skeleton from '@mui/material/Skeleton';
import { Helpers } from "../../../../../../services/Helpers"; 
import ResponseHandler from "../../../../../../services/ResponseHandler";

function AgentPropertyView() {
  const navigate = useNavigate();
  // Added store for getting token (to check is logined or not)
  const token = useSelector((state) => state.userStore.token);
  const { id } = useParams();
  const [SimilarPrperty, SetSimilarPrperty] = useState([]);
  const [SuggestedPrperty, SetSuggestedPrperty] = useState([]);
  const [wishlistIds, setWishlistIds] = useState([]);
  const [Propertydetails, SetPropertydetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [propertyid, setpropertyid] = useState(false);
  const [openmodal, setOpenModal] = useState(false);
  const [PropertyRecom, setPropertyRecom] = useState([]);
  const [hasRecommendations, setHasRecommendations] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (state) => {
    setOpen(state);
    // getCustomerDetails();
  };
 

  const [latitudeLongitude, SetlatitudeLongitude] = useState([0, 0]);

 

  const getPropertydetails = async () => {
    setLoading(true);
    try {
      if (id) {
        const result = await ApiConfig.get(
          `/customer/${id}/get_property_details`
        );
        const { status } = result;
        const { data } = result.data;
        if (status == 200) {
          SetPropertydetails(data);
          setpropertyid(data.Property_bank_details.pbd_id);
          // ResponseHandler(result);
        } else {
          ResponseHandler(result);
        }

        // Modified this logic for showing location in proper way
        const { pd_latitude, pd_longitude } = data.Property_details;
        if (pd_latitude && pd_longitude) {
          SetlatitudeLongitude([
            parseFloat(pd_longitude),
            parseFloat(pd_latitude),
          ]);
        }
      }
    } catch (error) {
      ResponseHandler(error);
    } finally {
      setLoading(false);
    }
  };
 
  const getProperty = async () => {
    try {
      if (id) {
        const result = await ApiConfig.get(
          `/customer/${id}/get_property_recommentation`
        );
        const { status } = result;
        const { data } = result.data;
        if (status === 200) {
          setPropertyRecom(data);
          if (data.length > 0) {
            setHasRecommendations(true);
          } else {
            setHasRecommendations(false);
          }
        } else {
          ResponseHandler(result);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  

  const handleClosemodal = () => {
    setOpenModal(false);
    // Optionally, reset location state to avoid reopening modal on refresh
    window.history.replaceState({}, document.title);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPropertydetails();
    getProperty();
  }, [id, token]);

  return (
    <>
      <CustomSpin loading={loading}>
      <div className="agent_view_header">
        <p className="page_title">  Property ID:{Propertydetails?.Property_bank_details?.pbd_id}{" "}</p>
        <div
          className="back_button"
          onClick={() => {
            navigate(-1);
          }}
        >
          <KeyboardBackspaceIcon />
          <p>back</p>
        </div>
      </div>

       <div style={{ padding: "24px 30px" }}>
          <Grid container spacing={6} marginTop={-3}>
            <Grid item xs={12} md={8}>
              <Propertyslide />
            </Grid>

            <Grid item xs={12} md={4}>
              <div className=" Property_details_Titile">
                <div className="titile_Property">
                  {Propertydetails?.Property_bank_details?.ps_sale_title}
                </div>
                
                {/* <SocialMediaShare
                  title={Propertydetails?.Property_bank_details?.ps_sale_title}
                  shareUrl={window.location.href}
                /> */}
              </div>
              <Typography
                sx={{
                  color: "#9093A1",
                  fontWeight: 300,
                  fontSize: 14,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 8,
                  }}
                >
                  <IoLocationSharp />
                  {Propertydetails?.Property_details?.pd_location}
                </div>
              </Typography>
              <div className="custom-typography">
                <div className="price">
                  $
                  {
                    Propertydetails?.Property_liquidity_rating_details
                      ?.plr_appraisal_amount
                  }{" "}
                </div>
                {/* <div className="text">
                  Property ID:{Propertydetails?.Property_bank_details?.pbd_id}{" "}
                </div> */}
              </div>
              <div
                className="Property_characteristics_container"
                style={{
                  backgroundColor: "#F6FAFF",
                  padding: "10px",
                  width: 185,
                }}
              >
                <div
                  className="characteristics"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 6,
                  }}
                >
                  <PiBathtubBold style={{ fontSize: 28 }} />
                  <p
                    style={{
                      marginTop: 12,
                      fontFamily: "Inter",
                      fontWeight: 600,
                      color: "#4A60A1 ",
                    }}
                  >
                    {
                      Propertydetails?.Property_construction_details
                        ?.pcd_bathroom
                    }
                  </p>
                </div>
                <div
                  className="characteristics"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: 6,
                  }}
                >
                  <HiArrowsPointingOut style={{ fontSize: 30 }} />
                  <p
                    style={{
                      marginTop: 12,
                      fontFamily: "Inter",
                      fontWeight: 600,
                      color: "#4A60A1 ",
                    }}
                  >
                    {
                      Propertydetails?.Property_construction_details
                        ?.pcd_coverage_percentage
                    }
                  </p>
                </div>
              </div>
             
              {/* {Array.isArray(vehiclesdetails) && vehiclesdetails.map((vehiclesdetail, index) => ( */}
              <Card
                sx={{
                  borderRadius: 4,
                  // margin: "10px 0",
                  p: 5,
                  boxShadow: " 1px 2px 5px rgba(0, 0, 0, 0.1)",
                  marginTop: 4,
                  overflow: "auto",
                }}
              >
                <Typography
                  sx={{
                    color: "#000000",
                    fontWeight: 600,
                    fontSize: 17,

                  }}
                >
                  Details
                </Typography>
                <Grid item xs={12} md={11}>
                  <div className="Property_details">
                    <div className="Property_data">Price</div>
                    <div className="Property_value">
                      ${" "}
                      {
                        Propertydetails?.Property_liquidity_rating_details
                          ?.plr_appraisal_amount
                      }
                    </div>
                  </div>
                  <div className="Property_details">
                    <div className="Property_data">Type</div>
                    <div className="Property_value">
                      {Propertydetails?.Property_bank_details?.ptl_type}
                    </div>
                  </div>

                  <div className="Property_details">
                    <div className="Property_data">Province</div>
                    <div className="Property_value">
                      {Propertydetails?.Property_details?.pd_province}
                    </div>
                  </div>
                  <div className="Property_details">
                    <div className="Property_data">Status</div>
                    <div className="Property_value">
                      {Propertydetails?.Property_bank_details?.sl_status}
                    </div>
                  </div>
                  <div className="Property_details">
                    <div className="Property_data">Owner Name</div>
                    <div className="Property_value">
                      {Propertydetails?.Property_bank_details?.pbd_owner_name}
                    </div>
                  </div>
                  {/* <div className="Property_details">
                <div className="Property_data">Issue Date</div>
                <div className="Property_value">
                  {Propertydetails?.Property_bank_details?.ps_application_start_date}
                </div>
              </div> */}

                  <div className="Property_details">
                    <div className="Property_data">ID No</div>
                    <div className="Property_value">
                      {Propertydetails?.Property_bank_details?.pbd_ced_legal}
                    </div>
                  </div>
                  <div className="Property_details">
                    <div className="Property_data"> Year built</div>
                    <div className="Property_value">
                      {
                        Propertydetails?.Property_construction_details
                          ?.pcd_year_of_construction
                      }
                    </div>
                  </div>
                </Grid>
              </Card>
              {/* ))} */}
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  borderRadius: 4,
                  // margin: "10px 0",
                  p: 5,
                  boxShadow: " 1px 2px 5px rgba(0, 0, 0, 0.1)",
                  height: 325,
                  overflow: "auto",
                }}
              >
                <Grid item xs={12} md={12}>
                  <div className="Property_card_titile">Property Details</div>

                  <div className="Property_details">
                    <div className="Property_data">Location</div>
                    <div className="Property_value">
                      {Propertydetails?.Property_details?.pd_location}
                    </div>
                  </div>
                  <div className="Property_details">
                    <div className="Property_data">Electricity</div>
                    <div className="Property_value">
                      {" "}
                      {Propertydetails?.Property_details?.pd_cable_tv}
                    </div>
                  </div>
                  <div className="Property_details">
                    <div className="Property_data">Roofs</div>
                    <div className="Property_value">
                      {
                        Propertydetails?.Property_construction_details
                          ?.pcd_roofs
                      }
                    </div>
                  </div>
                  <div className="Property_details">
                    <div className="Property_data">Walls</div>
                    <div className="Property_value">
                      {
                        Propertydetails?.Property_construction_details
                          ?.pcd_walls
                      }
                    </div>
                  </div>
                  <div className="Property_details">
                    <div className="Property_data">Bathrooms</div>
                    <div className="Property_value">
                      {
                        Propertydetails?.Property_construction_details
                          ?.pcd_bathroom
                      }
                    </div>
                  </div>
                  <div className="Property_details">
                    <div className="Property_data">GAS</div>
                    <div className="Property_value">
                      {
                        Propertydetails?.Property_construction_details
                          ?.pcd_lp_gas
                      }
                    </div>
                  </div>
                  <div className="Property_details">
                    <div className="Property_data">Floor</div>
                    <div className="Property_value">
                      {
                        Propertydetails?.Property_construction_details
                          ?.pcd_floor
                      }
                    </div>
                  </div>
                  <div className="Property_details">
                    <div className="Property_data">Water tank</div>
                    <div className="Property_value">
                      {
                        Propertydetails?.Property_construction_details
                          ?.pcd_water_collection_tank
                      }
                    </div>
                  </div>
                </Grid>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  borderRadius: 4,
                  // margin: "10px 0",
                  p: 5,
                  boxShadow: " 1px 2px 5px rgba(0, 0, 0, 0.1)",
                  height: 325,
                  overflow: "auto",
                }}
              >
                <div className="Property_card_titile">About Bank</div>
                <img
                  src={Propertydetails?.Property_bank_details?.logo} // Assuming this contains the URL of the image
                  style={{
                    width: "50%",
                    height: 150,
                    borderRadius: "7px",
                  }}
                />

                <div className="property_aboutbank_content">
                  {Propertydetails?.Property_bank_details?.aonl_about}
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  borderRadius: 4,
                  // margin: "10px 0",
                  p: 5,
                  boxShadow: " 1px 2px 5px rgba(0, 0, 0, 0.1)",
                  height: 350,
                  overflow: "auto",
                }}
              >
                <div className="Property_card_titile">About Property</div>
                <div className="Property_about">
                  {
                    Propertydetails?.Property_construction_details
                      ?.pcd_description
                  }
                </div>
              </Card>
            </Grid>

            <Grid item xs={12} md={6}>
              <Card
                sx={{
                  borderRadius: 4,
                  // margin: "10px 0",
                  p: 5,
                  boxShadow: " 1px 2px 5px rgba(0, 0, 0, 0.1)",
                  height: 350,
                }}
              >
                <Grid container spacing={5}>
                  <Grid item xs={12} md={12}>
                    <div className="Property_card_titile">Location</div>
                    <div className="property_about">
                      <PropertyMap
                        locationCoordinates={latitudeLongitude}
                        style={{ borderRadius: 5 }}
                      />
                    </div>
                  </Grid>
                </Grid>
              </Card>

              {/* <Divider style={{ marginTop: "20px" }} /> */}
            </Grid>
           
          </Grid>
          <Grid item xs={12} md={12} p={3}>
            <div className="Property_card_titile">Disclaimer</div>
            <div className="property_disclaimer">
              What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the
              printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen book. It has survived not only five centuries, but
              also the leap into electronic typesetting, remaining essentially
              unchanged. It was popularised in the 1960s with the release of
              Letraset sheets containing Lorem Ipsum passages, and more recently
              with desktop publishing software like Aldus PageMaker including
              versions of Lorem Ipsum. Why do we use it? It is a long
              established fact that a reader will be distracted by the readable
              content of a page when looking at its layout.
            </div>

            {/* <Divider style={{ marginTop: "20px" }} /> */}
          </Grid>
         
        </div>
      </CustomSpin>
    </>
  );
}

export default AgentPropertyView;
