import axios from 'axios'
import { ErrorToast } from '../toasts/Toasts';
import { useNavigate} from 'react-router-dom';
import NavigateUnauthorized from './NavigateUnauthorized';

const ApiConfig= axios.create({
    baseURL:`${process.env.REACT_APP_BASEURL}back_end/`,
    maxContentLength: Infinity,
})

ApiConfig.interceptors.request.use(
    (config) => {
        
        const accessToken = localStorage.getItem('user_details');
        if (accessToken) {
            let token=JSON.parse(localStorage.getItem('user_details')).up_token
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);
ApiConfig.interceptors.response.use(
    (response) => {
        const modifiedResponse = {
        data: response.data,
        status: response.status,
        };
    return modifiedResponse;
    },
    (error) => {

        if (error.response) {
            const status = error.response.status;
            if (status === 401) {
                ErrorToast("UnAuthorized")
                setTimeout(() => {
                    window.location.href='/admin_portal/login'
                }, 1000);
            } else if (status === 500) {
                ErrorToast("Internal server error")
            }
        } else {
            ErrorToast("Network Error")
        }

        return Promise.reject(error.response);
    }
);
export {ApiConfig}



