import React, { useEffect, useState } from "react";
import "./SliderMenu.css";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CustomToolTip from "../../../../components/customToolTip/CustomToolTip";
import { ResetStoreData } from "../../../../services/resetStoreServices";
import { useDispatch, useSelector } from "react-redux";
import { LocalStorageServices } from "../../../../services/localStorageServices";
import { updateCollapsedState } from "../../../../store/homePageStore";
import {
  AgentSubPagesEndPoints,
  PropertiesSubMenuEndPoints,
  PropertySubMenuEndPoints,
  UserSubPagesEndEndPoints,
  UsersOrStaffSubMenuEndPoints,
  VehicleSubMenuEndPoints,
  VehiclesSubMenuEndPoints,
  SettingsSubPagesEndPoints,
  CustomerportaInterestSubPagesEndPoints,
  CustomerportalLoanSubPagesEndPoints,
  TestimonialSubPagesEndPoints,
  CustomerportalTestimonialsSubPagesEndPoints,
} from "./routerEndPoints";
import { useTranslation } from "react-i18next";
import { CommentsDisabledOutlined } from "@mui/icons-material";
import { setRefresh } from "../../../../store/usersStore";
function SliderMenu({
  isPermitted,
  menukey,
  icon,
  text,
  initialRoute,
  subMenu,
  collapsed,
  isDisabled,
  // Add a prop to determine if sidebar should close on click
  handleSidebarClose,
}) {
  const Privilages = useSelector((state) => state.userStore.Privilages);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [showTooltip, setShowTooltip] = useState(false);
  const location = useLocation();

  const navigate = useNavigate();
  function menuToggle(id) {
    const element = document.getElementById(id);
    if (element && !isDisabled) {
      if (element.style.display === "none" || element.style.display === "") {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    }
  }
  function DefaultToggle(id) {
    const element = document.getElementById(id);
    if (element) {
      if (element.style.display === "none" || element.style.display === "") {
        element.style.display = "block";
      }
    }
  }
  const updateTooltipState = (isShow) => {
    setShowTooltip(isShow);
  };

  function handleClicksub(subRoute) {
    if (location.pathname !== subRoute) {
      ResetStoreData(dispatch, text);
    }
    if (isPermitted) {
      dispatch(updateCollapsedState());
      navigate(subRoute);

      // handleSidebarClose();
    }
  }
  const handleClick = () => {
    if (text === "Dashboard") {
      dispatch(updateCollapsedState());
    }

    if (text === "Tasks/Jobs") {
      dispatch(updateCollapsedState());
    }
    if (text === "Agents") {
      dispatch(updateCollapsedState());
    }
    if (text === "Notification") {
      dispatch(updateCollapsedState());
    }
  };
  useEffect(() => {
    if (window.innerWidth > 1000) {
      const lastPart =
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ];
      const lastPartWithoutQuery = lastPart.split("?")[0];
      let IsValidVehicleRoute = VehicleSubMenuEndPoints.some(
        (lastRoute) => lastRoute === lastPartWithoutQuery
      );
      let IsValidPropertyRoute = PropertySubMenuEndPoints.some(
        (lastRoute) => lastRoute === lastPartWithoutQuery
      );
      let IsValidUsersOrStaffRoute = UsersOrStaffSubMenuEndPoints.some(
        (lastRoute) => lastRoute === lastPartWithoutQuery
      );
      if (IsValidVehicleRoute) {
        if (document.getElementById(`Vehicle_submenu`)) {
          document.getElementById(`Vehicle_submenu`).style.display = "block";
        }
      } else if (IsValidPropertyRoute) {
        if (document.getElementById("Property_submenu")) {
          document.getElementById("Property_submenu").style.display = "block";
        }
      } else if (IsValidUsersOrStaffRoute) {
        if (document.getElementById("User/Staff_submenu")) {
          document.getElementById("User/Staff_submenu").style.display = "block";
        }
      }
    }
  }, [collapsed]);
  function checkSubMenuAndInitialRouteRelation(pathname, subMenu, menukey) {
    let isValid = subMenu.some((item) => item.subRoute === pathname);
    if (!isValid) {
      const lastPart =
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ];
      const lastPartWithoutQuery = lastPart.split("?")[0];

      if (menukey === "2") {
        isValid = VehiclesSubMenuEndPoints.some(
          (item) => item === lastPartWithoutQuery
        );
      } else if (menukey === "3") {
        isValid = PropertiesSubMenuEndPoints.some(
          (item) => item === lastPartWithoutQuery
        );
      } else if (menukey === "5") {
        isValid = UserSubPagesEndEndPoints.some(
          (item) => item === lastPartWithoutQuery
        );
      } else if (menukey === "4") {
        isValid = VehiclesSubMenuEndPoints.some(
          (item) => item === lastPartWithoutQuery
        );
      } else if (menukey === "12.1") {
        isValid = SettingsSubPagesEndPoints.some(
          (item) => item === lastPartWithoutQuery
        );
      } else if (menukey === "14.6") {
        isValid = CustomerportalTestimonialsSubPagesEndPoints.some(
          (item) => item === lastPartWithoutQuery
        );
      }
    }
    if (isValid && window.innerWidth > 1000) {
      return "#005EB8";
    }
    return "";
  }

  function checkMainSubPagesRouteRelation(menukey) {
    const lastPart =
      window.location.href.split("/")[
        window.location.href.split("/").length - 1
      ];
    const lastPartWithoutQuery = lastPart.split("?")[0];
    let isValid = false;

    if (LocalStorageServices.getUserRole() === "Runner") {
      if (menukey === "4") {
        isValid =
          VehiclesSubMenuEndPoints.some(
            (item) => item === lastPartWithoutQuery
          ) ||
          PropertiesSubMenuEndPoints.some(
            (item) => item === lastPartWithoutQuery
          );
      }
    }

    if (isValid && window.innerWidth > 1000) {
      return "#005EB8";
    }
    return "";
  }
  function SubMenuHighlight(elementText) {
    // submenus highlight 
    const lastPart = window.location.href.split("/").pop();
    const lastPartWithoutQuery = lastPart.split("?")[0];
    let isValidRoute = false;
   
    if (elementText === t("Vehicles")) {
      isValidRoute = VehiclesSubMenuEndPoints.includes(lastPartWithoutQuery);
    } else if (elementText === t("Properties")) {
      isValidRoute = PropertiesSubMenuEndPoints.includes(lastPartWithoutQuery);
    } else if (elementText === t("Users")) {
      isValidRoute = UserSubPagesEndEndPoints.includes(lastPartWithoutQuery);
    } else if (elementText === t("Settings")) {
      isValidRoute = SettingsSubPagesEndPoints.includes(lastPartWithoutQuery);
    } else if (elementText === t("Interest Applications")) {
      isValidRoute =
        CustomerportaInterestSubPagesEndPoints.includes(lastPartWithoutQuery);
    } else if (elementText === t("Loan Applications")) {
      isValidRoute =
        CustomerportalLoanSubPagesEndPoints.includes(lastPartWithoutQuery);
    } else if (elementText === t("Lookup")) {
      isValidRoute = SettingsSubPagesEndPoints.includes(lastPartWithoutQuery);
    } else if(elementText === t("Testimonials")){
      isValidRoute =CustomerportalTestimonialsSubPagesEndPoints.includes(lastPartWithoutQuery);
    }
    return isValidRoute;
  }

  return (
    <>
      {isPermitted && (
        <>
          <div
            id={menukey}
            className={`sliderMenus_main_menu_container ${
              isDisabled ? "disabled" : ""
            }`} // Apply "disabled" class if isDisabled is true
            // onClick={handleClick}
            style={{
              backgroundColor: isDisabled
                ? "rgba(24, 59, 123, 1)"
                : location.pathname === initialRoute
                ? "#005EB8"
                : collapsed && subMenu
                ? checkSubMenuAndInitialRouteRelation(
                    location.pathname,
                    subMenu,
                    menukey
                  )
                : checkMainSubPagesRouteRelation(menukey),
              display: window.innerWidth > 1000 && collapsed ? "flex" : "",
              justifyContent:
                window.innerWidth > 1000 && collapsed ? "center" : "",
              cursor: isDisabled ? "not-allowed" : "pointer", // Set cursor to "none" if isDisabled is true
              color: isDisabled
                ? "rgba(255, 255, 255, 0.6)"
                : "rgba(255, 255, 255, 1)", // Set text color based on isDisabled
              opacity: isDisabled ? 0.6 : 1,
            }}
            onClick={() => {
              if (window.innerWidth <= 1000) {
                handleClick();
              }
              if (isDisabled) return;
              if (subMenu) {
                menuToggle(`${text}_submenu`);
              } else {
                if (location.pathname !== initialRoute) {
                  ResetStoreData(dispatch, text);
                }
                if (isPermitted) {
                  // setTimeout(()=>{
                  //   dispatch(setRefresh())
                  // },500)
                  navigate(initialRoute);
                  if(initialRoute=='/admin_portal/login'){
                    window.location.reload()
                  }
                }
              }
            }}
            onMouseEnter={() => {
              if (window.innerWidth > 1000 && collapsed) {
                updateTooltipState(true);
              }
            }}
            onMouseLeave={() => {
              if (window.innerWidth > 1000 && collapsed) {
                updateTooltipState(false);
              }
            }}
          >
            <div className="sliderMenus_main_menu">
              <div className="sliderMenus_icon_text">
                <img className="sliderMenus_menuIcon" src={icon} alt={text} />
                {window.innerWidth <= 1000 || !collapsed ? (
                  <p className="menu_text">{text}</p>
                ) : (
                  <></>
                )}
              </div>
              {subMenu && (window.innerWidth <= 1000 || !collapsed) && (
                <ArrowDropDownIcon className="sliderMenu_dropdown_icon" />
              )}
              {window.innerWidth > 1000 && collapsed && showTooltip && (
                <div className="CustomToolTip_SliderMenu">
                  <CustomToolTip
                    visibilityChange={updateTooltipState}
                    text={text}
                    initialRoute={initialRoute}
                    Submenu={subMenu}
                  />
                </div>
              )}
            </div>
          </div>
          {(window.innerWidth < 1000 || !collapsed) && (
            <div id={`${text}_submenu`} className="sliderMenu_submenu">
              <>
                {subMenu &&
                  subMenu.map((item) => {
                    const lastPartWithoutQuery = item.subRoute.split("?")[0];
                    if (location.pathname === lastPartWithoutQuery) {
                      DefaultToggle(`${text}_submenu`);
                    }
                    return (
                      <>
                        {item.isPermitted && (
                          <p
                            key={item.text}
                            className="subMenu_text"
                            style={{
                              backgroundColor:
                                location.pathname ===
                                item.subRoute.split("?")[0]
                                  ? "#005EB8"
                                  : SubMenuHighlight(item.text)
                                  ? "#005EB8"
                                  : "",
                            }}
                            onClick={() => {
                              if (window.innerWidth <= 1000) {
                                handleClicksub(item.subRoute.split("?")[0]);
                              } else {
                                handleClick();
                                if (location.pathname !== item.subRoute) {
                                  ResetStoreData(dispatch, item.text);
                                }
                                if (item.isPermitted) {
                                  // setTimeout(()=>{
                                  //   dispatch(setRefresh())
                                  // },500)
                                  navigate(item.subRoute);
                                }
                              }
                            }}
                          >
                            - {item.text}
                          </p>
                        )}
                      </>
                    );
                  })}
              </>
            </div>
          )}
        </>
      )}
    </>
  );
}

export default SliderMenu;
