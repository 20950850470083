import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { updateSelectedTab } from "../../../../../store/propertiesStore";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import BankDetailsForm from "./bankDetailsForm/BankDetailsForm";
import PropertyDetailsForm from "./propertyDetailsForm/PropertyDetailsForm";
import PropertyAppraisalForm from "./propertyAppraisalForm/PropertyAppraisalForm";
import ConstructionDetailsForm from "./constructionDetailsForm/ConstructionDetailsForm";
import AttachmentForm from "./attachmentForm/AttachmentForm";
import LiquidityRatingForm from "./liquidityRatingForm/LiquidityRatingForm";
import "./PropertyFormPage.css";
import { LocalStorageServices } from "../../../../../services/localStorageServices";
function PropertyFormPage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const { property_id } = useParams();
  const tabKey = queryParams.get("tk");
  const iSViewAndEdit = queryParams.get("view_edit");
  const selectedTab = useSelector((state) => state.propertiesStore.selectedTab);
  useEffect(() => {
    if (tabKey) {
      if (selectedTab != Number(tabKey)) {
        dispatch(updateSelectedTab(Number(tabKey)));
      }
    }
  }, [tabKey]);

  const handleChange = (_, newValue) => {
    if (property_id) {
      if (iSViewAndEdit) {
        navigate(
          `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${newValue}&view_edit=${true}`
        );
      } else {
        navigate(
          `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${newValue}`
        );
      }
    }
    dispatch(updateSelectedTab(newValue));
  };

  const renderTabContent = (value) => {
    switch (value) {
      case 0:
        return <BankDetailsForm />;
      case 1:
        return <PropertyDetailsForm />;
      case 2:
        return <PropertyAppraisalForm />;
      case 3:
        return <ConstructionDetailsForm />;
      case 4:
        return <AttachmentForm />;
      case 5:
        return <LiquidityRatingForm />;
      default:
        return null;
    }
  };

  // responsive table start
  const IsCollapsed = useSelector((state) => state.mainStore.IsCollapsed);
  const [TableResp, setTableResp] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    if (windowWidth < 999) {
      setTableResp({});
    } else {
      setTableResp({
        overflowX: "auto",
        maxWidth: IsCollapsed ? "calc(100vw - 120px)" : "calc(100vw - 320px)",
      });
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth, IsCollapsed]);

  // responsive table end

  return (
    <div style={TableResp}>
      <div className="PropertieForm_header">
        <p className="page_title">
          {property_id ? "View Property" : "Add Property"}
        </p>
        <div
          className="back_button"
          onClick={() => {
            navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`);
          }}
        >
          <KeyboardBackspaceIcon />
          <p>Back</p>
        </div>
      </div>
      <div className="Propertie_tabs">
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          sx={{
            "& .MuiTabs-indicator": {
              backgroundColor: "#0F62FE",
              height: 3,
            },
            ".Mui-selected": {
              color: "#0F62FE !important",
            },
            "& .MuiTab-root": {
              marginRight: "50px", // Add margin between tabs
            },
          }}
        >
          <Tab className="mui_tab_style" label="Bank Details" value={0} />
          <Tab
            className="mui_tab_style"
            label="Property Details"
            value={1}
            disabled={property_id ? false : true}
          />
          <Tab
            className="mui_tab_style"
            label="Property Appraisal"
            value={2}
            disabled={property_id ? false : true}
          />
          <Tab
            className="mui_tab_style"
            label="Construction Details"
            value={3}
            disabled={property_id ? false : true}
          />
          <Tab
            className="mui_tab_style"
            label="Attachment"
            value={4}
            disabled={property_id ? false : true}
          />
          <Tab
            className="mui_tab_style"
            label="Liquidity Rating"
            value={5}
            disabled={property_id ? false : true}
          />
        </Tabs>
      </div>
      {renderTabContent(selectedTab)}
    </div>
  );
}

export default PropertyFormPage;
