import { ApiConfig } from "../apiConfig"

export const getAllPriority=()=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get('priority')
           return response.data.data
        }catch(error){
            throw error
        }
    }
}