import io from 'socket.io-client';
import { InfoToast } from '../toasts/Toasts';
import { getAllNotifications } from '../api/apiServices/notificationApi';
import { updateMessageReceivedState, updateMessageSendState, updateOnlineUsers } from '../store/messageStore';

const connectionOptions = {
  reconnection: true,
  reconnectionAttempts: Infinity,
  reconnectionDelay: 1000,
  timeout: 5000,
  transports: ['websocket', 'polling'],
  rejectUnauthorized: false
};

export class socketServices {
    static token=null
    constructor(serverUrl,dispatch) {
      socketServices.socket = io(serverUrl, connectionOptions);
      this.socket = io(serverUrl, connectionOptions);
      this.dispatch=dispatch
    }
  
    connect(token) {
      socketServices.token = token;
      this.socket.emit('token', token);
  
      this.socket.on('PropertyTaskAssign', (message) => {
        this.handleTaskAssign(message);
      });
      this.socket.on('VehicleTaskAssign', (message) => {
        this.handleTaskAssign(message);
      });
      this.socket.on("Notification",(message)=>{
        this.dispatch(getAllNotifications())
      })
      this.socket.on("msg-recieve",(message)=>{
        this.dispatch(updateMessageReceivedState(message))
      })
      this.socket.on("get-online-users",(response)=>{
        this.dispatch(updateOnlineUsers(response))
      })
      this.socket.on("msg-sended",(message)=>{
        this.dispatch(updateMessageSendState(message))
      })
      this.socket.on("failed-to-send",(message)=>{
      })
    }
  
    static disconnectSocket() {
      if(socketServices.socket && socketServices.token){
        socketServices.socket.emit('logout', socketServices.token);
        socketServices.socket.disconnect();
      }
    }
    
    static sendMessage(message) {
      socketServices.socket.emit('send-msg', message);
    }

    static sendAudioMessage(data) {
      socketServices.socket.emit('send-audio', data);
    }

    handleTaskAssign(message) {
      InfoToast(message)
    }
  }
  