import React, { useEffect, useState } from "react";
import SimpleTable from "../../../../../components/simpleTable/SimpleTable";
import Button from "@mui/material/Button";
import "./PropertyType.css";
import CustomChip from "../../../../../components/customChip/CustomChip";
import CustomModal from "../../../../../components/customModal/CustomModal";
import AddIcon from "@mui/icons-material/Add";
import PropertyTypeForm from "./subComponent/PropertyTypeForm";
import { useTranslation } from "react-i18next";
import SimpleFilter from "../../../../../components/simpleFilter/SimpleFilter";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePropertyType,
  getPropertyType,
} from "../../../../../api/apiServices/propertyTypeApi";
import ResponseHandler from "../../../../../services/ResponseHandler";
import CustomSpin from "../../../../../components/customSpin/CustomSpin";
function PropertyType() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const properTypeData = useSelector(
    (state) => state.propertyTypeStore.PropertyTypes
  );
  const TableLoading = useSelector(
    (state) => state.propertyTypeStore.PropertyTypeTableLoading
  );
  const [deleteRowDetails, setDeleteRowDetails] = useState({
    id: "",
    loading: false,
  });
  const FilterData = useSelector(
    (state) => state.propertyTypeStore.filterTypeDetails
  );

  useEffect(() => {
    dispatch(getPropertyType());
  }, []);

  const [open, setOpen] = React.useState(false);
  const [EditTypeDetails, SetEditTypeDetails] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (state) => {
    setOpen(state);
  };

  const handleEdit = (row) => {
    SetEditTypeDetails(row);
    handleClickOpen();
  };
  const UpdateDeleteDetails = (id, loading) => {
    setDeleteRowDetails({
      ...deleteRowDetails,
      ["id"]: id,
      ["loading"]: loading,
    });
  };
  const handleDelete = (rowKey) => {
    UpdateDeleteDetails(rowKey, true);
    dispatch(deletePropertyType(rowKey))
      .then((response) => {
        ResponseHandler(response);
      })
      .catch((error) => {
        ResponseHandler(error);
      })
      .finally(() => {
        UpdateDeleteDetails(rowKey, false);
      });
  };

  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Types",
      dataIndex: "types",
      key: "types",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      action: (row) => (
        <p style={{ color: row.status === "active" ? "green" : "red" }}>
          {row.status}
        </p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      action: (row) => (
        <div className="property_type_chipset">
          <CustomChip
            text="Edit"
            bgColor="#F8893C"
            color="white"
            onClick={() => handleEdit(row)}
          />
          <CustomChip
            loading={
              deleteRowDetails.id === row.id ? deleteRowDetails.loading : false
            }
            text="Delete"
            bgColor="#ED5967"
            color="white"
            onClick={() => handleDelete(row.id)}
          />
        </div>
      ),
    },
  ];
  const datas = () => {
    const result =
      properTypeData &&
      properTypeData.map((item, index) => {
        return {
          key: index + 1,
          id: item.ptl_id,
          types: item.ptl_type,
          status: item.ptl_status,
          attachment:item.ptl_attachment
        };
      });
    return result;
  };
  const Privilages = useSelector((state) => state.userStore.Privilages);

  return (
    <>
      <p className="page_title">{t("Property Type")}</p>
      {Privilages &&
      Privilages?.Property_Type &&
      Privilages?.Property_Type.some((item) => item === "View") ? (
        <CustomSpin loading={TableLoading}>
          <div className="propertyType_add_sort_container">
            <SimpleFilter id="PropertyTypeFilter" filterData={FilterData} />
            {Privilages?.Property_Type.some((item) => item === "Add") ? (
              <div className="propertyType_sort_select_container">
                <Button
                  onClick={() => {
                    SetEditTypeDetails(null);
                    handleClickOpen();
                  }}
                  variant="contained"
                  size="small"
                  style={{
                    textTransform: "none",
                    fontFamily: "Inter",
                    backgroundColor: "#0F62FE",
                    fontSize: "15px",
                  }}
                >
                  {t("Add")}
                </Button>
              </div>
            ) : null}
          </div>
          <SimpleTable columns={Columns} rows={datas()} />
          <CustomModal
            open={open}
            handleClose={handleClose}
            body={
              <PropertyTypeForm
                EditDetails={EditTypeDetails}
                onclose={handleClose}
              />
            }
            title={
              EditTypeDetails
                ? "Update property type"
                : "Create new property type"
            }
          />
        </CustomSpin>
      ) : (
        <>
          <div className="propertyType_add_sort_container">
            <SimpleFilter id="PropertyTypeFilter" filterData={FilterData} />
            {Privilages?.Property_Type.some((item) => item === "Add") ? (
              <div className="propertyType_sort_select_container">
                <Button
                  onClick={() => {
                    SetEditTypeDetails(null);
                    handleClickOpen();
                  }}
                  variant="contained"
                  size="small"
                  endIcon={<AddIcon />}
                  style={{ textTransform: "none" }}
                >
                  {t("Add")}
                </Button>
              </div>
            ) : null}
          </div>
          <SimpleTable columns={Columns} rows={datas()} />
          <CustomModal
            open={open}
            handleClose={handleClose}
            body={
              <PropertyTypeForm
                EditDetails={EditTypeDetails}
                onclose={handleClose}
              />
            }
            title={
              EditTypeDetails
                ? "Update property type"
                : "Create new property type"
            }
          />
        </>
      )}
    </>
  );
}

export default PropertyType;
