import React, { useEffect, useState } from "react";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Table } from "@mui/material";
import { useSelector } from "react-redux";
import './CustomTableWIthOutPaginationStyling.css'
function SimpleTable({ columns, rows }) {
  const { t } = useTranslation();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#E9F2FF",
      color: "black",
      borderBottom: "none",
      fontWeight: "600",
      fontFamily: "Inter",
      fontSize: "15px",
      padding: "15px 12px",
    },
    [`&.${tableCellClasses.body}`]: {
      maxWidth: "500px",
      overflowWrap: "break-word",
      fontSize: "15px",
      borderBottom: "none",
      fontFamily: "Inter",
      padding: "12px 15px",
      overflow: "auto",
      whiteSpace: "normal",
      wordWrap: "break-word",
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: "white",
      "& > *:first-of-type": {
        backgroundColor: "white",
      },
    },
    "&:nth-of-type(even)": {
      backgroundColor: "#E9F2FF",
      "& > *:first-of-type": {
        backgroundColor: "#E9F2FF",
      },
    },
  }));

  // responsive table start
  const IsCollapsed = useSelector((state) => state.mainStore.IsCollapsed);
  const [TableResp, setTableResp] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    if (window.innerWidth < 999) {
      setTableResp({});
    } else {
      setTableResp({
        maxWidth: IsCollapsed ? "calc(100vw-420px)" : "calc(100vw)",
      });
    }
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth, IsCollapsed]);

  return (
    <div style={TableResp}>
      <div className="customTable">
        <TableContainer className="simpleTable_mainContainer">
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {columns &&
                  columns.map((column) => (
                    <StyledTableCell
                      key={column.key}
                      align={column.dataIndex === "Actions" ? "right" : "left"}
                      style={{
                        width: column.dataIndex === "key" ? "20px" : "",
                        height: column.dataIndex === "key" ? "40px" : "40px",
                        // paddingRight:
                        //   column.dataIndex === "Actions" ? "55px" : "",
                        display: column.dataIndex ==="Actions"? "flex":"",
                        justifyContent:column.dataIndex === "Actions"? "center":"",
                        alignItems:column.dataIndex === "Actions"? "center":"",

                      }}
                    >
                      {t(column.title)}
                    </StyledTableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows.map((row) => (
                  <StyledTableRow key={row.key}>
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.key}
                        align={
                          column.dataIndex === "Actions" ? "center" : "left"
                        }
                        style={{
                          width: column.dataIndex === "key" ? "20px" : "",
                        
                        }}
                      >
                        {column.action ? (
                          column.action(row)
                        ) : (
                          <div
                            style={{
                              width:
                                column.dataIndex === "taskDescription"
                                  ? "400px"
                                  : "auto",
                            }}
                            className={
                              column.key !== "key" ? "textcontainer" : ""
                            }
                          >
                            <p>
                              {row[column.dataIndex]
                                ? row[column.dataIndex].length >= 40
                                  ? row[column.dataIndex].slice(0, 40) + "..."
                                  : row[column.dataIndex]
                                : " - "}
                            </p>
                          </div>
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))}
            </TableBody>
          </Table>
          {rows && rows.length === 0 && (
            <div className="noData_Container">
              <p className="noData_text">{t("No Data")}</p>
            </div>
          )}
        </TableContainer>
      </div>
       
       
     
    </div>
  );
}

export default SimpleTable;
