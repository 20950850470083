import { createSlice } from "@reduxjs/toolkit";

export const agentStoreSlice=createSlice({
    name:"agent",
    initialState:{
        WholeAgents: [],
        Agents: [],
        FilterDropDownList:{
            email:[],
            interest:[],
            status:[]
        },
        AgentFilterData:{
            name: "",
            email: null,
            location: "",
            interest: null,
            status: null
        }
    },
    reducers:{
        setAllAgents:(state,actions)=>{
            state.Agents=actions.payload
            state.WholeAgents=actions.payload
        },
        updateFilterDropdownData:(state)=>{
            const datas=JSON.parse(JSON.stringify(state.WholeAgents))
            const UniqueEmail=[...new Set(datas.map(item => item.up_email))].map(type => ({label: type,value: type}))
            const UniqueInterest = [...new Set(datas.map(item => item.ad_interest))].map(type => ({
                label:type === 1 ? "All" : type === 2 ? "Property" : type === 3 ? "Vehicle" : "",
                value: type
            }));
            const UniqueStatus=[...new Set(datas.map(item => item.usl_status))].map(type => ({label: type,value: type}))
            state.FilterDropDownList={email:UniqueEmail,interest:UniqueInterest,status:UniqueStatus}
          },
          setAgentFilterDatas:(state,actions)=>{
            state.AgentFilterData=actions.payload
            console.log(state.AgentFilterData,"state.AgentFilterData");
          },
          filterAgentData:(state,actions)=>{
            const WholeAgents = JSON.stringify(state.WholeAgents);
            const FilterData= JSON.stringify(state.AgentFilterData);
            const ParseWholeAgent=JSON.parse(WholeAgents)
            const ParseFilterData=JSON.parse(FilterData)
            let FilterdData=[]
            console.log(state.AgentFilterData,'-----98---1')
            if(ParseFilterData.name.trim()){
              FilterdData= ParseWholeAgent.filter((item)=>item.ud_name.toLowerCase().includes(ParseFilterData.name.toLowerCase()))
            }else{
              FilterdData= ParseWholeAgent
            }
            
            if(ParseFilterData.email){
              FilterdData = FilterdData.filter((item)=>item.up_email==ParseFilterData.email)
            }
            
            if(ParseFilterData.location.trim()){
              FilterdData = FilterdData.filter((item)=>item.ad_location.toLowerCase().includes(ParseFilterData.location.toLowerCase()))
            }
           
            if(ParseFilterData.interest){
              FilterdData = FilterdData.filter((item)=>item.ad_interest===ParseFilterData.interest)
            }

            if(ParseFilterData.status){
                FilterdData = FilterdData.filter((item)=>item.usl_status===ParseFilterData.status)
            }
            state.Agents=FilterdData
        },
        resetFilterData:(state,action)=>{
            state.Agents=state.WholeAgents
            state.AgentFilterData={
                name: "",
                email: null,
                location: "",
                interest: null,
                status: null
            }
        }
    }
})

export const {setAllAgents,updateFilterDropdownData,setAgentFilterDatas,filterAgentData,resetFilterData}=agentStoreSlice.actions
export default agentStoreSlice.reducer