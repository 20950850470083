import React, { useEffect, useState } from "react";
import "./TasksOrJobsPage.css";
import { useDispatch, useSelector } from "react-redux";
import { getvehicleTasksWithoutVehicleId } from "../../../../api/apiServices/vehicleApi";
import { getpropertyTasksWithoutPropertyId } from "../../../../api/apiServices/propertyApi";
import { SetAllPropertyTasks } from "../../../../store/propertiesStore";
import ResponseHandler from "../../../../services/ResponseHandler";
import PropertyTaskPage from "../property/propertiesTaskPage/PropertyTaskPage";
import { SetAllVehicleTasks } from "../../../../store/vehicleStore";
import VehicleTasks from "../vehicle/vehicleTaskPage/VehicleTasks";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useNavigate } from "react-router-dom";
import { LocalStorageServices } from "../../../../services/localStorageServices";
import { Select, MenuItem, Button, InputLabel } from "@mui/material";
import { updateSelectedToggleKey } from "../../../../store/tasksOrJobsStore";
import IconButton from "@mui/material/IconButton";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
function TasksOrJobsPage() {
  const dispatch = useDispatch();
  const selectedKey = useSelector(
    (state) => state.taskOrJobsStores.selectedToggleKey
  );
  const Privilages = useSelector((state) => state.userStore.Privilages);
  const navigate = useNavigate();
  useEffect(() => {
    if (selectedKey === 0) {
      dispatch(getvehicleTasksWithoutVehicleId());
    } else if (selectedKey === 1) {
      dispatch(getpropertyTasksWithoutPropertyId());
    }
  }, [selectedKey]);

  const handleToggleValue = (event, value) => {
    if (value !== null) {
      dispatch(updateSelectedToggleKey(value));
    }
  };

  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const icon = document.querySelector(".Select_task_xs");
      const container = document.querySelector(".filter_icon_mobile");
      const sort = document.querySelector(".add_go_to_main_button");
      const box = document.querySelector(".go_to_main_button");
      const searchButton = document.querySelector(
        ".vehicle_filter_field_container"
      );

      if (
        !container?.contains(event.target) &&
        !icon?.contains(event.target) &&
        !sort?.contains(event.target) &&
        !searchButton?.contains(event.target) &&
        !box?.contains(event.target)
      ) {
        setFilterOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  function UpdateFieldVisibility(id) {
    const element = document.getElementById(id);
    if (element) {
      if (element.style.display === "none" || element.style.display === "") {
        element.style.display = "block";
      } else {
        element.style.display = "";
      }
    }
  }
  const handleButtonClick = (key) => {
    dispatch(updateSelectedToggleKey(key));
    if (key === 0) {
      navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/vehicles`);
    } else if (key === 1) {
      navigate(
        `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`
      );
    }
  };
  return (
    <div>
      <div className="TasksOrJobsPage_page_header">
        <div className="TasksOrJobsPage_page_sub_header">
          <div className="Titile_task">
            <div className="page_title">
              <p>Tasks/Jobs</p>
            </div>
            <div className="Select_task_xs">
              <div className="filter_icon_mobile">
                <div
                className="Button_add_tsk"
                  color="primary"
                  aria-label="expand"
                  onClick={() => {
                    UpdateFieldVisibility("vehicle_filter_field_container");
                    setFilterOpen(!filterOpen);
                  }}
                >
                 
                  <p> Add task</p> <ArrowDropDownIcon />
                </div>
              </div>
              <div
                id="vehicle_filter_field_container"
                style={{ display: filterOpen ? "block" : "" }}
              >
                <div className="go_to_main_button_container">
                  <div
                    className="go_to_main_button"
                    onClick={() => handleButtonClick(0)}
                  >
                    <p className="buton_sample">Vehicle</p>
                  </div>
                  <div
                    className="go_to_main_button"
                    onClick={() => handleButtonClick(1)}
                  >
                    <p className="buton_sample">Property</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ToggleButtonGroup
            size="small"
            value={selectedKey}
            exclusive
            onChange={handleToggleValue}
            style={{
              border: "1.5px solid #0F62FE",
              borderRadius: "8px",
              backgroundColor: "white",
              textTransform: "none",
            }}
          >
            <ToggleButton
              value={0}
              className="custom_toggle_button_style"
              style={{
                backgroundColor: selectedKey === 0 ? "#0F62FE" : "",
                color: selectedKey === 0 ? "white" : "#0F62FE",
                textTransform: "none",
                fontFamily: "inter",
                padding: "8px 30px",
                fontSize: "15px",
                fontWeight: 600,
                lineHeight: "15px",
                letterSpacing: "0.4px",
                textAlign: "center",
              }}
            >
              Vehicle
            </ToggleButton>
            <ToggleButton
              value={1}
              className="custom_toggle_button_style"
              style={{
                backgroundColor: selectedKey === 1 ? "#0F62FE" : "",
                color: selectedKey === 1 ? "white" : "#0F62FE",
                textTransform: "none",
                padding: "8px 30px",
                fontFamily: "inter",
                fontSize: "15px",
                fontWeight: 600,
                lineHeight: "15px",
                letterSpacing: "0.4px",
                textAlign: "center",
              }}
            >
              Property
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        { Privilages &&
  Privilages?.Tasks &&
  Privilages?.Tasks.some((item) => item === "Add") && (
    <div className="Select_task">
      <button
        className="add_go_to_main_button"
        onClick={() => {
          if (selectedKey === 0) {
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/vehicles`
            );
          } else if (selectedKey === 1) {
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`
            );
          }
        }}
      >
        {selectedKey === 0
          ? "Add task in Vehicle"
          : selectedKey === 1
          ? "Add task in Property"
          : ""}
      </button>
    </div>
  )
}

      </div>
      {/* data pass through redux */}
      {selectedKey === 0 ? (
        <VehicleTasks />
      ) : selectedKey === 1 ? (
        <PropertyTaskPage />
      ) : (
        <></>
      )}
    </div>
  );
}

export default TasksOrJobsPage;
