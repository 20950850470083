import React from 'react'
import './CustomCard.css'
function CustomCard({children}) {
  return (
    <div className='CustomCard_main_container'>
        {children}
    </div>
  )
}

export default CustomCard