import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import FormButton from '../formButton/FormButton';
import CustomAutoComplete from '../customAutoComplete/CustomAutoComplete';
import { saleDropdown } from './dropdownData';
import { getAllHashTags } from '../../api/apiServices/hashTagApi';
import { createVehicleSale, getAllVehicles, getVehicleSaleDetailsById, updateVehicleSale } from '../../api/apiServices/vehicleApi';
import ResponseHandler from '../../services/ResponseHandler';
import { createPropertySale, getAllproperties, getPropertySaleDetailsById, updatePropertySale } from '../../api/apiServices/propertyApi';
import CustomDatePicker from '../customDatePicker/CustomDatePicker';
import dayjs from "dayjs";
import CustomTextField from '../customTextField/CustomTextField';
import { useDispatch } from 'react-redux';
function SaleStatus({id,type,onClose}) {
    const dispatch=useDispatch()
    const [Hashtag,SetHashTag]=useState([])
    const [data,Setdata]=useState(null)
    useEffect(()=>{
        if(id){
          if(type==="vehicle"){
            getVehicleSaleDetailsById(id).then(response => {
              Setdata(response)
              formik.setValues({
                  ...formik.values,
                  ["saleType"]: response.vs_sale_status,
                  ["hashTag"]: response.vs_hashtag_id,
                  ["applicationStartDate"]:response.vs_application_start_date,
                  ["applicationEndDate"]:response.vs_application_end_date,
                  ["saleTitle"]:response.vs_sale_title
                });
          })
          .catch(error => {
              console.log(error);
          });
          }else if(type==="property"){
            getPropertySaleDetailsById(id).then(response => {
              Setdata(response)
              formik.setValues({
                  ...formik.values,
                  ["saleType"]: response.ps_sale_status,
                  ["hashTag"]: response.ps_hashtag_id,
                  ["applicationStartDate"]:response.ps_application_start_date,
                  ["applicationEndDate"]:response.ps_application_end_date,
                  ["saleTitle"]:response.ps_sale_title
                });
          })
          .catch(error => {
              console.log(error);
          });
          }
            
        }
        getAllHashTags()
        .then(response => {
            reStructureHashTag(response)
        })
        .catch(error => {
            console.log(error);
        });
    },[id])
    function reStructureHashTag(response){
        const tags = response.map((item) => ({ label: item.lp_tagname, value: item.lp_id }));
       SetHashTag(tags); 
    }
    const inputStyles = {
        "& .MuiOutlinedInput-root": {
          padding: "0px",
          fontSize: "15px !important",
          fontFamily: "Inter",
          width: 200,
          "& .MuiAutocomplete-input": {
            padding: "7.5px 4px 7.5px 9px !important",
            fontFamily: "Inter",
          },
          "& fieldset": {
            borderColor: "rgba(119, 182, 243, 1) !important", // Change border color
          },
          "&:hover fieldset": {
            borderColor: "rgba(119, 182, 243, 1) !important", // Change border color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "rgba(119, 182, 243, 1) !important", // Change border color when focused
            outline: "none !important", // Remove outline when focused
          },
        },
      };
    const formik = useFormik({
      initialValues: {
        saleType: '0',
        saleTitle: '',
        hashTag: null,
        applicationStartDate: null,
        applicationEndDate: null,
    },
    validationSchema: Yup.object().shape({
        saleType: Yup.string().required("Sale type is required"),
        saleTitle: Yup.string().when("saleType", {
            is: '0',
            then: () => Yup.string().required("Sale title is required"),
           
        }),
        hashTag: Yup.string().nullable().when("saleType", {
            is: '0',
            then: () => Yup.string().nullable().required("Hash tag is required")
        }),
    }),
        onSubmit: (values) => {
            if(!data){
              if(type==='vehicle'){
                createVehicleSale(id,values).then((response)=>{
                  onClose()
                  dispatch(getAllVehicles());
                  ResponseHandler(response)
                }).catch((error)=>{
                    ResponseHandler(error)
                })
              }else if(type==="property"){
                createPropertySale(id,values).then((response)=>{
                  onClose()
                  dispatch(getAllproperties());
                  ResponseHandler(response)
                }).catch((error)=>{
                    ResponseHandler(error)
                })
              }
                
            }else{
              if(type==='vehicle'){
                updateVehicleSale(data.vs_id,values).then((response)=>{
                    onClose()
                    dispatch(getAllVehicles());
                    ResponseHandler(response)
                }).catch((error)=>{
                    ResponseHandler(error)
                })
              }else if(type==='property'){
                updatePropertySale(data.ps_id,values).then((response)=>{
                  onClose()
                  dispatch(getAllproperties());
                  ResponseHandler(response)
              }).catch((error)=>{
                  ResponseHandler(error)
              })
              }
            }
        },
      });
  return (
    <div className="update_property_task_assigne_Form">
    <form onSubmit={formik.handleSubmit}>
        <p className="update_property_task_assigne_Form_label">Sale type</p>
        <CustomAutoComplete
          name="saleType"
          placeholder="Select "
          options={saleDropdown}
          getOptionLabel={(option) => option.label}
          sx={{ ...inputStyles }}
          onChange={(event, newValue) => {
            formik.setFieldValue("saleType", newValue ? newValue.value : "");
            formik.setFieldValue("hashTag",null);
          }}
          onBlur={formik.handleBlur}
          value={
            saleDropdown.find(
              (saleType) => saleType.value === formik.values.saleType
            ) || null
          }
          isOptionEqualToValue={(option, value) =>
            option.value.toString() === value.value.toString()
          }
          error={formik.touched.saleType && Boolean(formik.errors.saleType)}
          helperText={formik.touched.saleType && formik.errors.saleType}
        />
        {formik.values.saleType===0?<>
          <p className="update_property_task_assigne_Form_label">Sale Title</p>
          <CustomTextField
                    name="saleTitle"
                    placeholder="Enter sale title"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.saleTitle}
                    error={
                      formik.touched.saleTitle && Boolean(formik.errors.saleTitle)
                    }
                    helperText={
                      formik.touched.saleTitle && formik.errors.saleTitle
                    }
                  />
            <p className="update_property_task_assigne_Form_label">Hash tag</p>
          <CustomAutoComplete
          name="hashTag"
          placeholder="Select tag"
          options={Hashtag}
          getOptionLabel={(option) => option.label}
          sx={{ ...inputStyles }}
          onChange={(event, newValue) => {
            formik.setFieldValue("hashTag", newValue ? newValue.value : "");
          }}
          onBlur={formik.handleBlur}
          value={
            Hashtag.find(
              (Hashtag) => Hashtag.value === formik.values.hashTag
            ) || null
          }
          isOptionEqualToValue={(option, value) =>
            option.value.toString() === value.value.toString()
          }
          error={formik.touched.hashTag && Boolean(formik.errors.hashTag)}
          helperText={formik.touched.hashTag && formik.errors.hashTag}
        />
        <p className="update_property_task_assigne_Form_label">Application Start Date</p>
        <CustomDatePicker
                      name="applicationStartDate"
                      onChange={(date) => {
                        formik.setFieldValue("applicationStartDate", date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.applicationStartDate, "MM/DD/YYYY")}
                      error={
                        formik.touched.applicationStartDate &&
                        Boolean(formik.errors.applicationStartDate)
                      }
                      helperText={
                        formik.touched.applicationStartDate && formik.errors.applicationStartDate
                      }
                    />
           <p className="vehicle_taskform_fields_labels">Application End Date</p>
                    <CustomDatePicker
                      disabled={formik.values.applicationStartDate ? false : true}
                      minDate={dayjs(formik.values.applicationStartDate, "MM/DD/YYYY")}
                      name="applicationEndDate"
                      onChange={(date) => {
                        formik.setFieldValue("applicationEndDate", date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.applicationEndDate, "MM/DD/YYYY")}
                      error={
                        formik.touched.applicationEndDate && Boolean(formik.errors.applicationEndDate)
                      }
                      helperText={
                        formik.touched.applicationEndDate && formik.errors.applicationEndDate
                      }
                    />
        </>:<></>}
       
        <div className="changePassword_form_action">
          <FormButton 
            id = "changePasswordCreateForm_update"
            type= "submit"
            variant="contained"
            loading={false}
            text= {"Update"}
            />
            <FormButton
            id = "changePasswordCreateForm_Cancel"
            variant="outlined"
            loading={false}
            text= "Cancel"
            onClick={() => onClose()}
            />
        </div>
      </form>
    </div>
  )
}

export default SaleStatus