import React, { useState, useEffect } from "react";
import { getAgentDashboardDetails } from "../../../../../api/apiServices/dashboardApis";
import { useDispatch } from "react-redux";
import "./agentDashboard.css";
import dummyIcon from "../../../../../assets/dummy_icon_for_dashboard.png";
import CustomDashboardSmallCard from "../../../../../components/customDashboardSmallCard/CustomDashboardSmallCard";
import { propertyPlotTags, vehiclePlotTags } from "./agentDashboardHelper";
import CustomYearsDropDown from "../../../../../components/customYearsDropDown/CustomYearsDropDown";
import CustomLineChart from "../../../../../components/customLineChart/CustomLineChart";
function AgentDashboard() {
  const dispatch = useDispatch();
  const [dashboardDetails, setDashboardDetails] = useState(null);
  const [selectedVehicleTag, setSelectedVehicleTag] = useState(null);
  const [selectedPropertyTag, setSelectedPropertyTag] = useState(null);
  const [selectedVehicleYear, setSelectedVehicleYear] = useState(null);
  const [selectedPropertyYear, setSelectedPropertyYear] = useState(null);

  useEffect(() => {
    dispatch(getAgentDashboardDetails())
      .then((response) => {
        setDashboardDetails(response);
        setSelectedVehicleTag("1");
        setSelectedPropertyTag("1");
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    vehiclePlotSelected(selectedVehicleTag);
    propertyPlotSelected(selectedPropertyTag);
    setSelectedVehicleYear(vehiclePlotSelectedYear(selectedVehicleTag));
    setSelectedPropertyYear(propertyPlotSelectedYear(selectedPropertyTag));
  }, [selectedVehicleTag, selectedPropertyTag]);

  const transformData = (data, year) => {
    const monthsOrder = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    if (!Array.isArray(data) || data.length === 0) return [];

    const filteredData = data.find((item) => item.year == year);
    if (!filteredData) return [];

    return monthsOrder.map((month) => filteredData[month] || 0);
  };

  const vehiclePlotSelected = (selectedVehicleTag) => {
    if (!dashboardDetails) return [];
    switch (selectedVehicleTag) {
      case "1":
        return dashboardDetails?.total_vehicle_interest_plot.map(
          (item) => item.year
        );
      case "2":
        return dashboardDetails?.total_vehicle_loan_plot.map(
          (item) => item.year
        );
      default:
        return [];
    }
  };

  const vehiclePlotSelectedYear = (selectedVehicleTag) => {
    if (!dashboardDetails) return "";
    switch (selectedVehicleTag) {
      case "1":
        return (
          dashboardDetails?.total_vehicle_interest_plot
            .map((item) => item.year)[0]
            ?.toString() || ""
        );
      case "2":
        return (
          dashboardDetails?.total_vehicle_loan_plot
            .map((item) => item.year)[0]
            ?.toString() || ""
        );
      default:
        return "";
    }
  };

  const vehiclePlotData = (selectedVehicleTag, year) => {
    if (!dashboardDetails) return [];
    switch (selectedVehicleTag) {
      case "1":
        return transformData(
          dashboardDetails?.total_vehicle_interest_plot,
          year
        );
      case "2":
        return transformData(dashboardDetails?.total_vehicle_loan_plot, year);
      default:
        return [];
    }
  };

  const propertyPlotSelected = (selectedPropertyTag) => {
    if (!dashboardDetails) return [];
    switch (selectedPropertyTag) {
      case "1":
        return dashboardDetails?.total_property_interest_plot.map(
          (item) => item.year
        );
      case "2":
        return dashboardDetails?.total_property_loan_plot.map(
          (item) => item.year
        );
      default:
        return [];
    }
  };

  const propertyPlotSelectedYear = (selectedPropertyTag) => {
    if (!dashboardDetails) return "";
    switch (selectedPropertyTag) {
      case "1":
        return (
          dashboardDetails?.total_property_interest_plot
            .map((item) => item.year)[0]
            ?.toString() || ""
        );
      case "2":
        return (
          dashboardDetails?.total_property_loan_plot
            .map((item) => item.year)[0]
            ?.toString() || ""
        );
      default:
        return "";
    }
  };

  const propertyPlotData = (selectedPropertyTag, year) => {
    if (!dashboardDetails) return [];
    switch (selectedPropertyTag) {
      case "1":
        return transformData(
          dashboardDetails?.total_property_interest_plot,
          year
        );
      case "2":
        return transformData(dashboardDetails?.total_property_loan_plot, year);
      default:
        return [];
    }
  };

  const setVehicleYear = (year) => {
    setSelectedVehicleYear(year);
  };

  const setPropertyYear = (year) => {
    setSelectedPropertyYear(year);
  };
  return (
    <div className="agent_dashboard_main_container">
      <div className="agent_dashboard_sub_listing_container_1">
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"Vehicles"}
          count={
            dashboardDetails?.total_vehicles?.total_vehicles_ready_for_sale
          }
        />
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"Properties"}
          count={
            dashboardDetails?.total_properties?.total_property_ready_for_sale
          }
        />
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"Vehicle Interest"}
          count={
            dashboardDetails?.total_vehicle_interest_applied
              ?.total_vehicles_interest_enquire
          }
        />
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"Vehicle Loan"}
          count={
            dashboardDetails?.total_vehicle_loan_enquirey
              ?.total_vehicles_loan_enquire
          }
        />
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"Properties Interest"}
          count={
            dashboardDetails?.total_property_interest_applied
              ?.total_property_interest_enquire
          }
        />
        <CustomDashboardSmallCard
          image={dummyIcon}
          name={"Properties Loan"}
          count={
            dashboardDetails?.total_property_loan_enquirey
              ?.total_property_loan_enquire
          }
        />
      </div>
      <div className="runner_dashboard_sub_listing_container_3">
        <div className="runner_dashboard_sub_item_container_3_1 ">
          <div className="runner_dashboard_sub_item_container_3_2">
            <p className="runner_dashboard_sub_item_container_overview_title">
              Vehicle Overview
            </p>
            <div>
              <CustomYearsDropDown
                selected={selectedVehicleYear}
                list={vehiclePlotSelected(selectedVehicleTag)}
                setSelectedYear={setVehicleYear}
              />
            </div>
          </div>
          <div className="runner_dashboard_sub_item_container_3_3">
            {Object.entries(vehiclePlotTags).map(([key, value]) => (
              <p
                key={key}
                className="runner_dashboard_sub_item_tag_3_3"
                style={{
                  color: selectedVehicleTag === key ? "white" : "black",
                  backgroundColor:
                    selectedVehicleTag === key ? "#1976d2" : "white",
                }}
                onClick={() => {
                  setSelectedVehicleTag(key);
                }}
              >
                {value}
              </p>
            ))}
          </div>
          <div className=" graph-container">
            <CustomLineChart
              plotData={vehiclePlotData(
                selectedVehicleTag,
                selectedVehicleYear
              )}
            />
            {!selectedVehicleYear && (
              <div className="no-data-overlay">No Data</div>
            )}
          </div>
        </div>
        <div className="runner_dashboard_sub_item_container_3_1">
          <div className="runner_dashboard_sub_item_container_3_2">
            <p className="runner_dashboard_sub_item_container_overview_title">
              Property Overview
            </p>
            <div>
              <CustomYearsDropDown
                selected={selectedPropertyYear}
                list={propertyPlotSelected(selectedPropertyTag)}
                setSelectedYear={setPropertyYear}
              />
            </div>
          </div>
          <div className="runner_dashboard_sub_item_container_3_3">
            {Object.entries(propertyPlotTags).map(([key, value]) => (
              <p
                key={key}
                className="runner_dashboard_sub_item_tag_3_3"
                style={{
                  color: selectedPropertyTag === key ? "white" : "black",
                  backgroundColor:
                    selectedPropertyTag === key ? "#1976d2" : "white",
                }}
                onClick={() => {
                  setSelectedPropertyTag(key);
                }}
              >
                {value}
              </p>
            ))}
          </div>
          <div className=" graph-container">
            <CustomLineChart
              plotData={propertyPlotData(
                selectedPropertyTag,
                selectedPropertyYear
              )}
            />
            {!selectedPropertyYear && (
              <div className="no-data-overlay">No Data</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AgentDashboard;
