import CustomTextField from "../../../../../../../../components/customTextField/CustomTextField";
import FormButton from "../../../../../../../../components/formButton/FormButton";
import {useDispatch} from 'react-redux'
import "./FuelFormStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import ResponseHandler from "../../../../../../../../services/ResponseHandler";
import { useEffect, useState } from "react";
import { CreateFuel, UpdateFuel } from "../../../../../../../../api/apiServices/fuelApi";

function FuelForm({onClose,existingvalue}) {
    const dispatch=useDispatch()
  const [loading,setLoading]=useState(false)
  useEffect(()=>{
      if(existingvalue){
        formik.setValues({
          ...formik.values,['fuel']:existingvalue.fuel_name
        });
      }
  },[existingvalue])
  const formik = useFormik({
    initialValues: {
      fuel: "",
    },
    validationSchema: Yup.object({
      fuel: Yup.string().required("Fuel name is required"),
    }),
    onSubmit: (values) => {
      if(!existingvalue){
        setLoading(true)
        dispatch(CreateFuel(values)).then((response)=>{
          ResponseHandler(response)
          onClose(false)
        }).catch((error)=>{
          ResponseHandler(error)
        }).finally(()=>{
          setLoading(false)
        })
      }else{
        setLoading(true)
        dispatch(UpdateFuel(existingvalue.id,values)).then((response)=>{
          ResponseHandler(response)
          onClose(false)
        }).catch((error)=>{
          ResponseHandler(error)
        }).finally(()=>{
          setLoading(false)
        })
      }
    },
  });
  return (
    <div className="FuelForm_mainContainer">
    <form onSubmit={formik.handleSubmit}>
      <p className="FuelForm_field_title">Fuel name</p>
      <CustomTextField
        placeholder={"Enter fuel name"}
        name="fuel"
        autoComplete="off"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.fuel}
        error={formik.touched.fuel && Boolean(formik.errors.fuel)}
        helperText={formik.touched.fuel && formik.errors.fuel}
      />
      <div className="FuelForm_form_action">
        <FormButton
          type="submit"
          variant="contained"
          loading={loading}
          text={existingvalue?"Update":"Add"}
        />
        <FormButton variant="outlined" loading={false} text="Cancel" onClick={()=>{onClose(false)}} />
      </div>
    </form>
  </div>
  )
}

export default FuelForm