import ResponseHandler from "../../services/ResponseHandler";
import { setContactUs } from "../../store/customerStore";
import { ApiConfig } from "../apiConfig";

export const getAllCustomers=()=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get('customer_portal/users_and_agents');
           return response.data.data
        }catch(error){
            throw error
        }
    }
}
export const getVehiclesBasedOnInterestApplication=(page_number,page_item_count)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get(`customer_portal/${page_number}/${page_item_count}/get_vehicle_lists_based_interest_application`);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}
export const getInterestApplicationsBasedOnVehicles=(vehicle_id,page_number,page_item_count)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get(`customer_portal/${vehicle_id}/${page_number}/${page_item_count}/get_interest_application_lists_based_on_vehicle`);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getPropertyBasedOnInterestApplication=(page_number,page_item_count)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get(`customer_portal/${page_number}/${page_item_count}/get_property_lists_based_interest_application`);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}
export const getInterestApplicationsBasedOnProperty=(property_id,page_number,page_item_count)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get(`customer_portal/${property_id}/${page_number}/${page_item_count}/get_interest_application_lists_based_on_property`);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getVehiclesBasedOnLoanApplication=(page_number,page_item_count)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get(`customer_portal/${page_number}/${page_item_count}/get_vehicle_lists_based_loan_application`);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getLoanApplicationsBasedOnVehicles=(vehicle_id,page_number,page_item_count)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get(`customer_portal/${vehicle_id}/${page_number}/${page_item_count}/get_loan_application_lists_based_on_vehicle`);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getPropertiesBasedOnLoanApplication=(page_number,page_item_count)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get(`customer_portal/${page_number}/${page_item_count}/get_property_lists_based_loan_application`);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getLoanApplicationsBasedOnProperty=(property_id,page_number,page_item_count)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get(`customer_portal/${property_id}/${page_number}/${page_item_count}/get_loan_application_lists_based_on_property`);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getVehicleEnquiries=(page_number,page_item_count)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get(`/customer_portal/${page_number}/${page_item_count}/get_vehicle_enquiries`);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getPropertyEnquiries=(page_number,page_item_count)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.get(`/customer_portal/${page_number}/${page_item_count}/get_property_enquiries`);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getContactUs=()=>{
    return  async(dispatch)=>{
        try{
            const response=await ApiConfig.get(`/customer_portal/get_contact_us`);
        
            dispatch(setContactUs(response.data.data))
        }catch(error){
            ResponseHandler(error)
        }
    }
}


export const getVehiclesBasedOnInterestApplicationFilter=(page_number,page_item_count,data)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.post(`customer_portal/${page_number}/${page_item_count}/get_vehicle_lists_based_interest_application_by_filter`,data);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getPropertyBasedOnInterestApplicationFilter=(page_number,page_item_count,data)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.post(`customer_portal/${page_number}/${page_item_count}/get_property_lists_based_interest_application_by_filter`,data);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getVehiclesBasedOnLoanApplicationFilter=(page_number,page_item_count,data)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.post(`customer_portal/${page_number}/${page_item_count}/get_vehicle_lists_based_loan_application_by_filter`,data);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getPropertyBasedOnLoanApplicationFilter=(page_number,page_item_count,data)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.post(`customer_portal/${page_number}/${page_item_count}/get_property_lists_based_loan_application_by_filter`,data);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getVehicleEnquiriesFilter=(page_number,page_item_count,data)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.post(`/customer_portal/${page_number}/${page_item_count}/get_vehicle_enquiries_filter`,data);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getPropertyEnquiriesFilter=(page_number,page_item_count,data)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.post(`/customer_portal/${page_number}/${page_item_count}/get_property_enquiries_filter`,data);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}


export const getInterestApplicationFilterBasedOnVehicle=(vehicle_id,page_number,page_item_count,data)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.post(`/customer_portal/${vehicle_id}/${page_number}/${page_item_count}/get_interest_application_lists_based_on_vehicle_by_filter`,data);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getInterestApplicationFilterBasedOnProperty=(property_id,page_number,page_item_count,data)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.post(`/customer_portal/${property_id}/${page_number}/${page_item_count}/get_interest_application_lists_based_on_property_by_filter`,data);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getLoanApplicationFilterBasedOnVehicle=(vehicle_id,page_number,page_item_count,data)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.post(`/customer_portal/${vehicle_id}/${page_number}/${page_item_count}/get_loan_application_lists_based_on_vehicle_filter`,data);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}

export const getLoanApplicationFilterBasedOnProperty=(property_id,page_number,page_item_count,data)=>{
    return  async()=>{
        try{
            const response=await ApiConfig.post(`/customer_portal/${property_id}/${page_number}/${page_item_count}/get_loan_application_lists_based_on_property_filter`,data);
           return response.data.data
        }catch(error){
            throw error
        }
    }
}