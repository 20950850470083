import React, { useEffect } from "react";
import CustomAutoComplete from "../../../../../../components/customAutoComplete/CustomAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAllUsers } from "../../../../../../api/apiServices/userApis";
import FormButton from "../../../../../../components/formButton/FormButton";
import "./PropertyReassign.css";
import {
  getPropertyTaskById,
  updatePropertyTaskAssigne,
} from "../../../../../../api/apiServices/propertyApi";
import ResponseHandler from "../../../../../../services/ResponseHandler";
function PropertyReassign({ taskId, propertyId, onclose }) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.userStore.WholeUsers);
  useEffect(() => {
    if (taskId) {
      dispatch(getAllUsers());
      dispatch(getPropertyTaskById(taskId))
        .then((response) => {
          formik.setValues({
            ...formik.values,
            re_assign: response.data.data.pt_assignto,
          });
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
  }, [taskId]);
  function SetUsersDropdown(users) {
    if (users && users.length > 0) {
      const filterUsers = users.filter(
        (item) =>
          item.url_role !== "Admin" &&
          item.url_role !== "SuperAdmin" &&
          item.url_role !== "Agent"
      );
      const data = filterUsers.map((item) => {
        return {
          label: item.up_email,
          value: item.up_id,
        };
      });
      return data;
    } else {
      return [];
    }
  }
  let formik = useFormik({
    initialValues: {
      re_assign: null,
    },
    validationSchema: Yup.object({
      re_assign: Yup.string().required("Reassign is required"),
    }),
    onSubmit: (values) => {
      dispatch(updatePropertyTaskAssigne(propertyId, taskId, values))
        .then((response) => {
          ResponseHandler(response);
          onclose(false);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    },
  });
  const inputStyles = {
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      fontSize: "15px !important",
      fontFamily: "Inter",
      width: 200,
      "& .MuiAutocomplete-input": {
        padding: "7.5px 4px 7.5px 9px !important",
        fontFamily: "Inter",
      },
      "& fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color
      },
      "&:hover fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color when focused
        outline: "none !important", // Remove outline when focused
      },
    },
  };
  return (
    <div className="update_property_task_assigne_Form">
      <form id="property_Reassign_taskform" onSubmit={formik.handleSubmit}>
        <p className="update_property_task_assigne_Form_label">Assigned To</p>
        <CustomAutoComplete
          name="re_assign"
          placeholder="Select assigned to"
          options={SetUsersDropdown(users)}
          getOptionLabel={(option) => option.label}
          sx={{ ...inputStyles }}
          onChange={(event, newValue) => {
            formik.setFieldValue("re_assign", newValue ? newValue.value : "");
          }}
          onBlur={formik.handleBlur}
          value={
            SetUsersDropdown(users).find(
              (re_assign) => re_assign.value === formik.values.re_assign
            ) || null
          }
          isOptionEqualToValue={(option, value) =>
            option.value.toString() === value.value.toString()
          }
          error={formik.touched.re_assign && Boolean(formik.errors.re_assign)}
          helperText={formik.touched.re_assign && formik.errors.re_assign}
        />
        <div className="update_property_task_assigne_Form_actions">
          <FormButton
            id="property_ReassignCreateCancel"
            variant="outlined"
            loading={false}
            text="Cancel"
            onClick={() => {
              onclose(false);
            }}
          />
          <FormButton
            id="property_ReassignCreateSubmit"
            type="submit"
            form="property_Reassign_taskform"
            variant="contained"
            text="Update Assigne"
          />
        </div>
      </form>
    </div>
  );
}

export default PropertyReassign;
