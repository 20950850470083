// src/contexts/LanguageContext.js
import React, { createContext, useState, useEffect } from 'react';
import { LocalStorageServices } from '../services/localStorageServices';

export const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(LocalStorageServices.getLanguage() || 'en');

  useEffect(() => {
    const handleLanguageChange = () => {
      const currentLang = LocalStorageServices.getLanguage();
      console.log(currentLang,"currentLang");
      setLanguage(currentLang);
    };

    window.addEventListener('languageChange', handleLanguageChange);

    return () => {
      window.removeEventListener('languageChange', handleLanguageChange);
    };
  }, []);

  return (
    <LanguageContext.Provider value={{ language, setLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
