import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAllApplicationOwnerNames } from "../../../../../../api/apiServices/applicationOwnerApi";
import { getAllStatus } from "../../../../../../api/apiServices/statusApi";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import { getAllVehicleType } from "../../../../../../api/apiServices/vehicleTypeApi";
import dayjs from "dayjs";
import * as Yup from "yup";
import CustomAutoComplete from "../../../../../../components/customAutoComplete/CustomAutoComplete";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import CustomDatePicker from "../../../../../../components/customDatePicker/CustomDatePicker";
import FormButton from "../../../../../../components/formButton/FormButton";
import { RiFileEditFill } from "react-icons/ri";
import { getPropertyType } from "../../../../../../api/apiServices/propertyTypeApi";
import "./BankDetailsForm.css";
import {
  createNewPropertyAndOthers,
  getPropertyBankDetails,
  updatePropertyBankDetails,
} from "../../../../../../api/apiServices/propertyApi";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";

function Property_BankDetailsForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iSViewAndEdit = queryParams.get("view_edit");
  const [ApplicationOwnerNames, SetApplicationOwnerNames] = useState([]);
  const [Status, SetStatus] = useState([]);
  const [Loading, SetLoading] = useState(false);
  const [Isview, SetIsview] = useState(false);
  const propertyTypeData = useSelector(
    (state) => state.propertyTypeStore.PropertyTypes
  );
  const { property_id } = useParams();
  useEffect(() => {
    AllApplicationOwnerNames();
    AllStatus();
    dispatch(getPropertyType());
    if (iSViewAndEdit) {
      SetIsview(true);
    }
    if (property_id) {
      dispatch(getPropertyBankDetails(property_id))
        .then((response) => {
          setFormikData(response.data.data);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
  }, [property_id]);

  function AllApplicationOwnerNames() {
    dispatch(getAllApplicationOwnerNames())
      .then((response) => {
        const data = response.map((item) => {
          return {
            label: item.aonl_name,
            value: item.aonl_id,
          };
        });
        SetApplicationOwnerNames(data);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }
  function AllStatus() {
    dispatch(getAllStatus())
      .then((response) => {
        const data = response.map((item) => {
          return {
            label: item.sl_status,
            value: item.sl_id,
          };
        });
        SetStatus(data);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }
  function AllPropertyType(propertyData) {
    if (propertyData && propertyData.length > 0) {
      const data = propertyData
        .filter((item) => item.ptl_status === "active")
        .map((item) => {
          return {
            label: item.ptl_type,
            value: item.ptl_id,
          };
        });
      return data;
    } else {
      return [];
    }
  }
  const initialValues = {
    application_name: null,
    owner_name: null,
    ced_legal: "",
    propotion_of_rights: "",
    status: null,
    type: null,
  };
  const validationSchema = Yup.object({
    // application_name: Yup.string().required("Applicant Name is required"),
    // owner_name: Yup.string().required("Owner Name is required"),
    ced_legal: Yup.string(),
    propotion_of_rights: Yup.string(),
    status: Yup.string().required("Status is required"),
    type: Yup.string().required("Property Type is required"),
  });
  function setFormikData(data) {
    const mappedData = Object.keys(initialValues).reduce((acc, key) => {
      const originalKey = `pbd_${key}`;
      acc[key] = data[originalKey] || initialValues[key];
      return acc;
    }, {});

    formik.setValues({
      ...formik.values,
      ...mappedData,
    });
  }
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      SetLoading(true);
      if (property_id) {
        dispatch(updatePropertyBankDetails(property_id, values))
          .then((response) => {
            ResponseHandler(response);
            if (iSViewAndEdit) {
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${1}&view_edit=${true}`
              );
            } else {
              navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${1}`);
            }
          })
          .catch((error) => {
            ResponseHandler(error);
          })
          .finally(() => {
            SetLoading(false);
          });
      } else {
        dispatch(createNewPropertyAndOthers(values))
          .then((response) => {
            ResponseHandler(response);
            navigate(
              `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${
                response.data.data.pbd_id
              }/update_properties?tk=${1}`
            );
          })
          .catch((error) => {
            ResponseHandler(error);
          })
          .finally(() => {
            SetLoading(false);
          });
      }
    },
  });

  const Privilages = useSelector((state) => state.userStore.Privilages);
  return (
    <div>
      <div className="Property_BankDetails_form_main_Container">
        {Privilages &&
        Privilages?.Property &&
        Privilages?.Property.some((item) => item === "Update")? Isview && (
              <div
                className="Property_BankDetails_edit_details"
                onClick={() => SetIsview(false)}
              >
                <RiFileEditFill />
                <p>Edit</p>
              </div>
            )
          : null}

        <div className="Property_BankDetails_formContainer">
          <p className="Property_BankDetails_form_legend">Add Bank Details</p>
          <form id="Property_BankDetailsform" onSubmit={formik.handleSubmit}>
            <div className="Property_BankDetailsform_fields">
              <div>
                <div className="Property_BankDetailsform_field_container">
                  <p className="Property_BankDetailsform_fields_labels">
                    Applicant Name
                  </p>
                  <CustomAutoComplete
                    disabled={Isview}
                    name="application_name"
                    placeholder="Select application name"
                    options={ApplicationOwnerNames}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "application_name",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      ApplicationOwnerNames.find(
                        (application_name) =>
                          application_name.value ===
                          formik.values.application_name
                      ) || null
                    }
                    error={
                      formik.touched.application_name &&
                      Boolean(formik.errors.application_name)
                    }
                    helperText={
                      formik.touched.application_name &&
                      formik.errors.application_name
                    }
                  />
                </div>
                <div className="Property_BankDetailsform_field_container">
                  <p className="Property_BankDetailsform_fields_labels">
                    Owner Name
                  </p>
                  <CustomAutoComplete
                    disabled={Isview}
                    name="owner_name"
                    placeholder="Select owner name"
                    options={ApplicationOwnerNames}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "owner_name",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      ApplicationOwnerNames.find(
                        (owner_name) =>
                          owner_name.value === formik.values.owner_name
                      ) || null
                    }
                    error={
                      formik.touched.owner_name &&
                      Boolean(formik.errors.owner_name)
                    }
                    helperText={
                      formik.touched.owner_name && formik.errors.owner_name
                    }
                  />
                </div>
                <div className="Property_BankDetailsform_field_container">
                  <p className="Property_BankDetailsform_fields_labels">
                    Ced. Legal
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="ced_legal"
                    placeholder="Enter Ced. Legal"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.ced_legal}
                    error={
                      formik.touched.ced_legal &&
                      Boolean(formik.errors.ced_legal)
                    }
                    helperText={
                      formik.touched.ced_legal && formik.errors.ced_legal
                    }
                  />
                </div>
              </div>
              <div>
                <div className="Property_BankDetailsform_field_container">
                  <p className="Property_BankDetailsform_fields_labels">
                    Propotion of Rights
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="propotion_of_rights"
                    placeholder="Enter propotion of rights"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.propotion_of_rights}
                    error={
                      formik.touched.propotion_of_rights &&
                      Boolean(formik.errors.propotion_of_rights)
                    }
                    helperText={
                      formik.touched.propotion_of_rights &&
                      formik.errors.propotion_of_rights
                    }
                  />
                </div>
                <div className="Property_BankDetailsform_dualfields">
                  <div className="Property_BankDetailsform_field_container">
                    <p className="Property_BankDetailsform_fields_labels">
                      Status
                    </p>
                    <CustomAutoComplete
                      disabled={Isview}
                      name="status"
                      placeholder="Select status"
                      options={Status}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          "status",
                          newValue ? newValue.value : ""
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        Status.find(
                          (status) => status.value === formik.values.status
                        ) || null
                      }
                      error={
                        formik.touched.status && Boolean(formik.errors.status)
                      }
                      helperText={formik.touched.status && formik.errors.status}
                    />
                  </div>
                  <div className="Property_BankDetailsform_field_container">
                    <p className="Property_BankDetailsform_fields_labels">
                      Property Type
                    </p>
                    <CustomAutoComplete
                      disabled={Isview}
                      name="type"
                      placeholder="Select property type"
                      options={AllPropertyType(propertyTypeData)}
                      getOptionLabel={(option) => option.label}
                      onChange={(event, newValue) => {
                        formik.setFieldValue(
                          "type",
                          newValue ? newValue.value : ""
                        );
                      }}
                      onBlur={formik.handleBlur}
                      value={
                        AllPropertyType(propertyTypeData).find(
                          (type) => type.value === formik.values.type
                        ) || null
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.value.toString() === value.value.toString()
                      }
                      error={formik.touched.type && Boolean(formik.errors.type)}
                      helperText={formik.touched.type && formik.errors.type}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {!Isview && (
          <div className="Property_BankDetailsform_action">
            <FormButton
              id="Property_BankDetailsCreateCancel"
              variant="outlined"
              loading={false}
              text="Cancel"
              onClick={() => {
                navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`);
              }}
            />
            <FormButton
              id="Property_BankDetailsCreateSubmit"
              type="submit"
              form="Property_BankDetailsform"
              variant="contained"
              loading={Loading}
              text="Save & Continue"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Property_BankDetailsForm;
