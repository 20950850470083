import React, { useEffect, useState } from "react";
import CustomChip from "../../../../../components/customChip/CustomChip";
import SimpleTable from "../../../../../components/simpleTable/SimpleTable";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import "./Roles.css";
import CustomModal from "../../../../../components/customModal/CustomModal";
import RoleForm from "./subComponents/roleForm/RoleForm";
import RoleFilter from "./subComponents/roleFilter/RoleFilter";
import {
  DeleteRole,
  GetAllRoles,
} from "../../../../../api/apiServices/roleApis";
import { useDispatch, useSelector } from "react-redux";
import CustomSpin from "../../../../../components/customSpin/CustomSpin";
import ResponseHandler from "../../../../../services/ResponseHandler";
function Roles() {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [EditRoleDetails, SetEditRoleDetails] = useState(null);
  const tableLoadingProp = useSelector(
    (state) => state.roleStore.roleTableLoading
  );
  const RolesData = useSelector((state) => state.roleStore.Roles);
  const [deleteRowDetails, setDeleteRowDetails] = useState({
    id: "",
    loading: false,
  });
  useEffect(() => {
    dispatch(GetAllRoles());
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (state) => {
    setOpen(state);
  };
  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Actions",
      dataIndex: "Actions",
      key: "Actions",
      action: (row) => (
        <div className="role_type_chipset">
          <CustomChip
            disabled={["SuperAdmin", "Admin", "Runner", "Agent","Customer_Portal_Agent","Customer_Portal_User"].includes(
              row.role
            )}
            text="Edit"
            bgColor="#F8893C"
            color="white"
            onClick={() => handleEdit(row)}
          />
          <CustomChip
            disabled={["SuperAdmin", "Admin", "Runner", "Agent","Customer_Portal_Agent","Customer_Portal_User"].includes(
              row.role
            )}
            loading={
              deleteRowDetails.id === row.id ? deleteRowDetails.loading : false
            }
            text="Delete"
            bgColor="#ED5967"
            color="white"
            onClick={() => handleDelete(row.id)}
          />
        </div>
      ),
    },
  ];
  const handleEdit = (row) => {
    SetEditRoleDetails({ ...EditRoleDetails, data: row });
    handleClickOpen();
  };
  const UpdateDeleteDetails = (id, loading) => {
    setDeleteRowDetails({
      ...deleteRowDetails,
      ["id"]: id,
      ["loading"]: loading,
    });
  };
  const handleDelete = (rowKey) => {
    UpdateDeleteDetails(rowKey, true);
    dispatch(DeleteRole(rowKey))
      .then((response) => {
        ResponseHandler(response);
      })
      .catch((error) => {
        ResponseHandler(error);
      })
      .finally(() => {
        UpdateDeleteDetails(rowKey, false);
      });
  };
  const datas = () => {
    const result =
      RolesData &&
      RolesData.map((item, index) => {
        return {
          key: index + 1,
          id: item.url_id,
          role: item.url_role,
        };
      });
    return result;
  };
  return (
    <div>
      <p className="page_title">Roles</p>
      <CustomSpin loading={tableLoadingProp}>
        <div className="role_filter_container">
          <RoleFilter />
          <Button
            variant="contained"
            size="small"
            style={{
              textTransform: "none",
              fontFamily: "Inter",
              fontSize: "15px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              padding: "8px 15px",
              backgroundColor: "#0f62fe",
              color: "white",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              SetEditRoleDetails(null);
              handleClickOpen();
            }}
          >
            Add Role
          </Button>
        </div>
        <SimpleTable columns={Columns} rows={datas()} />
        <CustomModal
          open={open}
          handleClose={handleClose}
          body={
            <RoleForm EditDetails={EditRoleDetails} onclose={handleClose} />
          }
          title={EditRoleDetails ? "Update role" : "Create new role"}
        />
      </CustomSpin>
    </div>
  );
}

export default Roles;
