import CustomTextField from "../../../../../../../../components/customTextField/CustomTextField";
import FormButton from "../../../../../../../../components/formButton/FormButton";
import {useDispatch} from 'react-redux'
import "./currencyFormStyle.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createCurrency, updateCurrency } from "../../../../../../../../api/apiServices/currencyApi";
import ResponseHandler from "../../../../../../../../services/ResponseHandler";
import { useEffect, useState } from "react";
function CurrencyForm({onClose,existingvalue}) {
  const dispatch=useDispatch()
  const [loading,setLoading]=useState(false)
  useEffect(()=>{
      if(existingvalue){
        formik.setValues({
          ...formik.values,['currency']:existingvalue.currency_name
        });
      }
  },[existingvalue])
  const formik = useFormik({
    initialValues: {
      currency: "",
    },
    validationSchema: Yup.object({
      currency: Yup.string().required("Currency name is required"),
    }),
    onSubmit: (values) => {
      if(!existingvalue){
        setLoading(true)
        dispatch(createCurrency(values)).then((response)=>{
          ResponseHandler(response)
          onClose(false)
        }).catch((error)=>{
          ResponseHandler(error)
        }).finally(()=>{
          setLoading(false)
        })
      }else{
        setLoading(true)
        dispatch(updateCurrency(existingvalue.id,values)).then((response)=>{
          ResponseHandler(response)
          onClose(false)
        }).catch((error)=>{
          ResponseHandler(error)
        }).finally(()=>{
          setLoading(false)
        })
      }
      
    },
  });
  return (
    <div className="CurrencyForm_mainContainer">
      <form onSubmit={formik.handleSubmit}>
        <p className="CurrencyForm_field_title">Currency name</p>
        <CustomTextField
          placeholder={"Enter currency name"}
          name="currency"
          autoComplete="off"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.currency}
          error={formik.touched.currency && Boolean(formik.errors.currency)}
          helperText={formik.touched.currency && formik.errors.currency}
        />
        <div className="CurrencyForm_form_action">
          <FormButton
            type="submit"
            variant="contained"
            loading={loading}
            text={existingvalue?"Update":"Add"}
          />
          <FormButton variant="outlined" loading={false} text="Cancel" onClick={()=>{onClose(false)}} />
        </div>
      </form>
    </div>
  );
}

export default CurrencyForm;
