import React, { useState, useEffect } from "react";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import FormButton from "../../../../../../components/formButton/FormButton";
import CustomTextArea from "../../../../../../components/customTextArea/CustomTextArea";
import "./LiquidityRatingForm.css";
import CustomDatePicker from "../../../../../../components/customDatePicker/CustomDatePicker";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import dayjs from "dayjs";
import { getAllLiquidity } from "../../../../../../api/apiServices/liquidityApi";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import CustomAutoComplete from "../../../../../../components/customAutoComplete/CustomAutoComplete";
import {
  getPropertyLiquidityRating,
  updatePropertyLiquidityRating,
} from "../../../../../../api/apiServices/propertyApi";
import { RiFileEditFill } from "react-icons/ri";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
function LiquidityRatingForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { property_id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iSViewAndEdit = queryParams.get("view_edit");
  const [loading, setLoading] = useState(false);
  const [Isview, SetIsview] = useState(true);
  const [liquidity, Setliquidity] = useState([]);
  useEffect(() => {
    AllLiquidity();
    if (iSViewAndEdit) {
      SetIsview(true);
    }
    if (property_id) {
      dispatch(getPropertyLiquidityRating(property_id))
        .then((response) => {
          setFormikData(response.data.data);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
  }, [property_id]);
  function AllLiquidity() {
    dispatch(getAllLiquidity())
      .then((response) => {
        const data = response.map((item) => {
          return {
            label: item.ll_liquid,
            value: item.ll_id,
          };
        });
        Setliquidity(data);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }
  const initialValues = {
    liquidity_rating: null, //
    appraisal_report_no: "", //
    applicant_name: "", //
    name_of_expert: "", //
    name_and_signature_of_person: "", //
    emp_no: "", //
    application_date_1: null, //
    date_of_application_2: null, //
    appraisal_amount: "", //
    identification_amount: "", //
    company: "", //
    background_review_applies: "",
    requested_amount: "",
    explanation: "",
  };
  const validationSchema = Yup.object({
    // liquidity_rating: Yup.string(),
    appraisal_report_no: Yup.string(),
    applicant_name: Yup.string(),
    name_of_expert: Yup.string(),
    name_and_signature_of_person: Yup.string(),
    emp_no: Yup.string(),
    // application_date_1: Yup.string(),
    // date_of_application_2: Yup.string(),
    appraisal_amount: Yup.string(),
    identification_amount: Yup.string(),
    company: Yup.string(),
    background_review_applies: Yup.string(),
    requested_amount: Yup.string(),
    explanation: Yup.string(),
  });
  function setFormikData(data) {
    const mappedData = Object.keys(initialValues).reduce((acc, key) => {
      const originalKey = `plr_${key}`;
      acc[key] = data[originalKey] || initialValues[key];
      return acc;
    }, {});
    formik.setValues({
      ...formik.values,
      ...mappedData,
    });
  }
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(updatePropertyLiquidityRating(property_id, values))
        .then((response) => {
          ResponseHandler(response);
          navigate(
            `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`
          );
        })
        .catch((error) => {
          ResponseHandler(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const Privilages = useSelector((state) => state.userStore.Privilages);
  return (
    <div>
      <div className="LiquidityRating_form_main_Container">
        {Privilages &&
        Privilages?.Property &&
        Privilages?.Property.some((item) => item === "Update")
          ? Isview && (
              <div
                className="LiquidityRatingform_edit_details"
                onClick={() => SetIsview(false)}
              >
                <RiFileEditFill />
                <p>Edit</p>
              </div>
            )
          : null}
        <form id="LiquidityRatingform" onSubmit={formik.handleSubmit}>
          <div className="LiquidityRating_formContainer">
            <p className="LiquidityRating_form_legend">
              Liquidity Rating Real Estate Appraisals
            </p>
            <div className="LiquidityRatingform_fields">
              <div>
                <div className="LiquidityRatingform_field_container">
                  <p className="LiquidityRatingform_fields_labels">
                    Liquidity Rating
                  </p>
                  <CustomAutoComplete
                    disabled={Isview}
                    name="liquidity_rating"
                    placeholder="Liquidity Rating"
                    options={liquidity}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "liquidity_rating",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      liquidity.find(
                        (Liquidity) =>
                          Liquidity.value === formik.values.liquidity_rating
                      ) || null
                    }
                    error={
                      formik.touched.liquidity_rating &&
                      Boolean(formik.errors.liquidity_rating)
                    }
                    helperText={
                      formik.touched.liquidity_rating &&
                      formik.errors.liquidity_rating
                    }
                  />
                </div>
                <div className="LiquidityRatingform_field_container">
                  <p className="LiquidityRatingform_fields_labels">
                    Appraisal report No
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="appraisal_report_no"
                    placeholder="Enter Appraisal report No"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.appraisal_report_no}
                    error={
                      formik.touched.appraisal_report_no &&
                      Boolean(formik.errors.appraisal_report_no)
                    }
                    helperText={
                      formik.touched.appraisal_report_no &&
                      formik.errors.appraisal_report_no
                    }
                  />
                </div>
                <div className="LiquidityRatingform_field_container">
                  <p className="LiquidityRatingform_fields_labels">
                    Applicant's name
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="applicant_name"
                    placeholder="Enter Applicant's name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.applicant_name}
                    error={
                      formik.touched.applicant_name &&
                      Boolean(formik.errors.applicant_name)
                    }
                    helperText={
                      formik.touched.applicant_name &&
                      formik.errors.applicant_name
                    }
                  />
                </div>
                <div className="LiquidityRatingform_field_container">
                  <p className="LiquidityRatingform_fields_labels">
                    Name of the Expert who assessed
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="name_of_expert"
                    placeholder="Enter Name of the Expert who assessed"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_of_expert}
                    error={
                      formik.touched.name_of_expert &&
                      Boolean(formik.errors.name_of_expert)
                    }
                    helperText={
                      formik.touched.name_of_expert &&
                      formik.errors.name_of_expert
                    }
                  />
                </div>
                <div className="LiquidityRatingform_field_container">
                  <p className="LiquidityRatingform_fields_labels">
                    Name and signature of the person applying the matrix
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="name_and_signature_of_person"
                    placeholder="Enter Name and signature of the person applying the matrix"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name_and_signature_of_person}
                    error={
                      formik.touched.name_and_signature_of_person &&
                      Boolean(formik.errors.name_and_signature_of_person)
                    }
                    helperText={
                      formik.touched.name_and_signature_of_person &&
                      formik.errors.name_and_signature_of_person
                    }
                  />
                </div>
                <div className="LiquidityRatingform_field_container">
                  <p className="LiquidityRatingform_fields_labels">Emp. No.</p>
                  <CustomTextField
                    disabled={Isview}
                    name="emp_no"
                    placeholder="Enter Emp. No."
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.emp_no}
                    error={
                      formik.touched.emp_no && Boolean(formik.errors.emp_no)
                    }
                    helperText={formik.touched.emp_no && formik.errors.emp_no}
                  />
                </div>
                <div className="LiquidityRatingform_field_container">
                  <p className="LiquidityRatingform_fields_labels">
                    Application Date 1 (Assessment)
                  </p>
                  <CustomDatePicker
                    disabled={Isview}
                    name="application_date_1"
                    onChange={(date) => {
                      formik.setFieldValue("application_date_1", date);
                    }}
                    onBlur={formik.handleBlur}
                    value={dayjs(
                      formik.values.application_date_1,
                      "MM/DD/YYYY"
                    )}
                    error={
                      formik.touched.application_date_1 &&
                      Boolean(formik.errors.application_date_1)
                    }
                    helperText={
                      formik.touched.application_date_1 &&
                      formik.errors.application_date_1
                    }
                  />
                </div>
                <div className="LiquidityRatingform_field_container">
                  <p className="LiquidityRatingform_fields_labels">
                    Date of application 2 (Corrections)
                  </p>
                  <CustomDatePicker
                    disabled={Isview}
                    name="date_of_application_2"
                    placeholder="Enter Date of application 2 (Corrections)"
                    onChange={(date) => {
                      formik.setFieldValue("date_of_application_2", date);
                    }}
                    onBlur={formik.handleBlur}
                    value={dayjs(
                      formik.values.date_of_application_2,
                      "MM/DD/YYYY"
                    )}
                    error={
                      formik.touched.date_of_application_2 &&
                      Boolean(formik.errors.date_of_application_2)
                    }
                    helperText={
                      formik.touched.date_of_application_2 &&
                      formik.errors.date_of_application_2
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="LiquidityRatingform_field_container">
                    <p className="LiquidityRatingform_fields_labels">
                      Appraisal amount
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="appraisal_amount"
                      placeholder="Enter Appraisal amount"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.appraisal_amount}
                      error={
                        formik.touched.appraisal_amount &&
                        Boolean(formik.errors.appraisal_amount)
                      }
                      helperText={
                        formik.touched.appraisal_amount &&
                        formik.errors.appraisal_amount
                      }
                    />
                  </div>
                  <div className="LiquidityRatingform_field_container">
                    <p className="LiquidityRatingform_fields_labels">
                      Identification number
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="identification_amount"
                      placeholder="Enter Identification number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.identification_amount}
                      error={
                        formik.touched.identification_amount &&
                        Boolean(formik.errors.identification_amount)
                      }
                      helperText={
                        formik.touched.identification_amount &&
                        formik.errors.identification_amount
                      }
                    />
                  </div>
                  <div className="LiquidityRatingform_field_container">
                    <p className="LiquidityRatingform_fields_labels">Company</p>
                    <CustomTextField
                      disabled={Isview}
                      name="company"
                      placeholder="Enter Company"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.company}
                      error={
                        formik.touched.company && Boolean(formik.errors.company)
                      }
                      helperText={
                        formik.touched.company && formik.errors.company
                      }
                    />
                  </div>
                  <div className="LiquidityRatingform_field_container">
                    <p className="LiquidityRatingform_fields_labels">
                      Background review applies
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="background_review_applies"
                      placeholder="Enter Background review applies"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.background_review_applies}
                      error={
                        formik.touched.background_review_applies &&
                        Boolean(formik.errors.background_review_applies)
                      }
                      helperText={
                        formik.touched.background_review_applies &&
                        formik.errors.background_review_applies
                      }
                    />
                  </div>
                  <div className="LiquidityRatingform_field_container">
                    <p className="LiquidityRatingform_fields_labels">
                      Requested amount
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="requested_amount"
                      placeholder="Enter Requested amount"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.requested_amount}
                      error={
                        formik.touched.requested_amount &&
                        Boolean(formik.errors.requested_amount)
                      }
                      helperText={
                        formik.touched.requested_amount &&
                        formik.errors.requested_amount
                      }
                    />
                  </div>
                  <div className="LiquidityRatingform_field_container">
                    <p className="LiquidityRatingform_fields_labels">
                      Explanation if the recommendation is accepted, rejected or
                      conditioned
                    </p>
                    <CustomTextArea
                      disabled={Isview}
                      maxRows={5}
                      name="explanation"
                      placeholder="Enter Explanation if the recommendation is accepted, rejected or conditioned"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.explanation}
                      error={
                        formik.touched.explanation &&
                        Boolean(formik.errors.explanation)
                      }
                      helperText={
                        formik.touched.explanation && formik.errors.explanation
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {!Isview && (
            <div className="LiquidityRatingform_action">
              <FormButton
                id="LiquidityRatingformCreateCancel"
                variant="outlined"
                loading={false}
                text="Cancel"
                onClick={() => {
                  navigate(
                    `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`
                  );
                }}
              />
              <FormButton
                id="LiquidityRatingformCreateSubmit"
                type="submit"
                form="LiquidityRatingform"
                variant="contained"
                loading={loading}
                text="Save & Continue"
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default LiquidityRatingForm;
