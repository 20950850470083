import ResponseHandler from "../../services/ResponseHandler"
import { setAllVehicleTypes, updateVehicleTableLoading } from "../../store/vehicleTypeStore"
import { ApiConfig } from "../apiConfig"

export const getAllVehicleType=()=>{
    return async (dispatch)=>{
        try {
            dispatch(updateVehicleTableLoading(true))
            const response = await ApiConfig.get('vechicle_type/vechicle_types')
            dispatch(setAllVehicleTypes(response.data.data))
            dispatch(updateVehicleTableLoading(false))
        } catch (error) {
            ResponseHandler(error)
        }
    }
}
export const createVehicleType=(data)=>{
    return async (dispatch)=>{
        try {
            const response = await ApiConfig.post('vechicle_type/create_new_type',data,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                }
              })
            dispatch(getAllVehicleType())
            return response
        } catch (error) {
            throw error
        }
    }

}

export const updateVehicleType=(id,data)=>{
    return async (dispatch)=>{
        try {
            const response = await ApiConfig.put(`vechicle_type/${id}/update_type`,data,{
                headers: {
                  'Content-Type': 'multipart/form-data',
                }
              })
            dispatch(getAllVehicleType())
            return response
        } catch (error) {
            throw error
        }
    }
}

export const deleteVehicleType=(id,data)=>{
    return async (dispatch)=>{
        try {
            const response = await ApiConfig.delete(`vechicle_type/${id}/delete_type`,data)
            dispatch(getAllVehicleType())
            return response
        } catch (error) {
            throw error
        }
    }
}