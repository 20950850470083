import { createSlice } from "@reduxjs/toolkit";

const messageSlice=createSlice({
    name:"messageStore",
    initialState:{
        messageContainerIsUpdated:false,
        messageSended:false,
        messageReceived:false,
        onlineUsers:[]
    },
    reducers:{
        updateMessageSendState:(state,actions)=>{
            state.messageSended=!state.messageSended
        },
        updateMessageReceivedState:(state,actions)=>{
            state.messageReceived=!state.messageReceived
        },
        updateMessageContainerIsUpdated:(state,actions)=>{
            state.messageContainerIsUpdated=!state.messageContainerIsUpdated
        },
        updateOnlineUsers:(state,actions)=>{
            state.onlineUsers=actions.payload
        }
    }
})

export const {updateMessageSendState,updateMessageReceivedState,updateMessageContainerIsUpdated,updateOnlineUsers} = messageSlice.actions;
export default messageSlice.reducer;