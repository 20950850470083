import React, { useEffect, useState } from "react";
import "./ConstructionDetailsForm.css";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import FormButton from "../../../../../../components/formButton/FormButton";
import CustomTextArea from "../../../../../../components/customTextArea/CustomTextArea";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getPropertyConstructionDetails,
  updatePropertyConstructionDetails,
} from "../../../../../../api/apiServices/propertyApi";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import { RiFileEditFill } from "react-icons/ri";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
function ConstructionDetailsForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { property_id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iSViewAndEdit = queryParams.get("view_edit");
  const [loading, setLoading] = useState(false);
  const [Isview, SetIsview] = useState(true);

  useEffect(() => {
    if (iSViewAndEdit) {
      SetIsview(true);
    }
    if (property_id) {
      dispatch(getPropertyConstructionDetails(property_id))
        .then((response) => {
          setFormikData(response.data.data);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
  }, [property_id]);
  const initialValues = {
    existing_construction_type: "",
    building_status: "",
    professional_responsibility: "",
    developer_name: "",
    cfia_contact_number: "",
    additional_construction_document: "",
    property_used_by: "",
    tearm_of_the_lease: "",
    main_construction_area: "",
    predominant_use: "",
    progress: "",
    budget: "",
    construction_permit: "",
    coverage_percentage: "",
    year_of_construction: "",
    construction_type_similar: "",
    structure: "",
    walls: "",
    mezzanine: "",
    roofs: "",
    desk: "",
    floor: "",
    apartment: "",
    bathroom: "",
    other: "",
    conduit_casting: "",
    percentage: "",
    breaker_box: "",
    pcd_switch: "",
    over_all_status: "",
    lp_gas: "",
    wall_condition: "",
    celling_condition: "",
    floor_condition: "",
    cover_condition: "",
    hot_water_network: "",
    water_collection_tank: "",
    repelios_fissures: "",
    floors_fissures: "",
    walls_fissures: "",
    heavens_fissures: "",
    description: "",
    observation: "",
  };
  const validationSchema = Yup.object({
    existing_construction_type: Yup.string(),
    building_status: Yup.string(),
    professional_responsibility: Yup.string(),
    developer_name: Yup.string(),
    cfia_contact_number: Yup.string(),
    additional_construction_document: Yup.string(),
    property_used_by: Yup.string(),
    tearm_of_the_lease: Yup.string(),
    main_construction_area: Yup.string(),
    predominant_use: Yup.string(),
    progress: Yup.string(),
    budget: Yup.string(),
    construction_permit: Yup.string(),
    coverage_percentage: Yup.string(),
    year_of_construction: Yup.string(),
    construction_type_similar: Yup.string(),
    structure: Yup.string(),
    walls: Yup.string(),
    mezzanine: Yup.string(),
    roofs: Yup.string(),
    desk: Yup.string(),
    floor: Yup.string(),
    appartment: Yup.string(),
    bathroom: Yup.string(),
    other: Yup.string(),
    conduit_casting: Yup.string(),
    percentage: Yup.string(),
    breaker_box: Yup.string(),
    pcd_switch: Yup.string(),
    over_all_status: Yup.string(),
    lp_gas: Yup.string(),
    wall_condition: Yup.string(),
    celling_condition: Yup.string(),
    floor_condition: Yup.string(),
    cover_condition: Yup.string(),
    hot_water_network: Yup.string(),
    water_collection_tank: Yup.string(),
    repelios_fissures: Yup.string(),
    floors_fissures: Yup.string(),
    walls_fissures: Yup.string(),
    heavens_fissures: Yup.string(),
    description: Yup.string(),
    observation: Yup.string(),
  });
  function setFormikData(data) {
    const mappedData = Object.keys(initialValues).reduce((acc, key) => {
      const originalKey = `pcd_${key}`;
      if (key === "pcd_switch") {
        acc[key] = data.pcd_switch;
      } else {
        acc[key] = data[originalKey] || initialValues[key];
      }
      return acc;
    }, {});
    formik.setValues({
      ...formik.values,
      ...mappedData,
    });
  }
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);
      dispatch(updatePropertyConstructionDetails(property_id, values))
        .then((response) => {
          ResponseHandler(response);
          // navigate(
          //   `/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${4}`
          // );
          if(iSViewAndEdit){
            navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${4}&view_edit=${true}`)
          }else{
            navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${property_id}/update_properties?tk=${4}`)
          }
          // navigate(`/${LocalStorageServices.getUserRole()}/home/vehicle/${id}/update_vehicle?tk=${3}`)
        })
        .catch((error) => {
          ResponseHandler(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });
  const Privilages = useSelector((state) => state.userStore.Privilages);
  return (
    <div>
      <div className="ConstructionDetails_form_main_Container">
        {Privilages &&
        Privilages?.Property &&
        Privilages?.Property.some((item) => item === "Update")
          ? Isview && (
              <div
                className="ConstructionDetails_edit_details"
                onClick={() => SetIsview(false)}
              >
                <RiFileEditFill />
                <p>Edit</p>
              </div>
            )
          : null}
        <form id="ConstructionDetailsform" onSubmit={formik.handleSubmit}>
          <div className="ConstructionDetails_formContainer">
            <p className="ConstructionDetails_form_legend">Appraisal</p>
            <div className="ConstructionDetailsform_fields">
              <div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Existing construction type
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="existing_construction_type"
                    placeholder="Enter Existing construction type"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.existing_construction_type}
                    error={
                      formik.touched.existing_construction_type &&
                      Boolean(formik.errors.existing_construction_type)
                    }
                    helperText={
                      formik.touched.existing_construction_type &&
                      formik.errors.existing_construction_type
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Building status
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="building_status"
                    placeholder="Enter Building status"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.building_status}
                    error={
                      formik.touched.building_status &&
                      Boolean(formik.errors.building_status)
                    }
                    helperText={
                      formik.touched.building_status &&
                      formik.errors.building_status
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Name of the Professional Responsible for the work
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="professional_responsibility"
                    placeholder="Enter Name of the Professional Responsible for the work"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.professional_responsibility}
                    error={
                      formik.touched.professional_responsibility &&
                      Boolean(formik.errors.professional_responsibility)
                    }
                    helperText={
                      formik.touched.professional_responsibility &&
                      formik.errors.professional_responsibility
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Developer Name
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="developer_name"
                    placeholder="Enter Developer Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.developer_name}
                    error={
                      formik.touched.developer_name &&
                      Boolean(formik.errors.developer_name)
                    }
                    helperText={
                      formik.touched.developer_name &&
                      formik.errors.developer_name
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    CFIA Contract Number
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="cfia_contact_number"
                    placeholder="Enter CFIA Contract Number"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.cfia_contact_number}
                    error={
                      formik.touched.cfia_contact_number &&
                      Boolean(formik.errors.cfia_contact_number)
                    }
                    helperText={
                      formik.touched.cfia_contact_number &&
                      formik.errors.cfia_contact_number
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Additional construction documents
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="additional_construction_document"
                    placeholder="Enter Additional construction documents"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.additional_construction_document}
                    error={
                      formik.touched.additional_construction_document &&
                      Boolean(formik.errors.additional_construction_document)
                    }
                    helperText={
                      formik.touched.additional_construction_document &&
                      formik.errors.additional_construction_document
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Property used by
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="property_used_by"
                    placeholder="Enter Property used by"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.property_used_by}
                    error={
                      formik.touched.property_used_by &&
                      Boolean(formik.errors.property_used_by)
                    }
                    helperText={
                      formik.touched.property_used_by &&
                      formik.errors.property_used_by
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Term of the lease
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="tearm_of_the_lease"
                    placeholder="Enter Term of the lease"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.tearm_of_the_lease}
                    error={
                      formik.touched.tearm_of_the_lease &&
                      Boolean(formik.errors.tearm_of_the_lease)
                    }
                    helperText={
                      formik.touched.tearm_of_the_lease &&
                      formik.errors.tearm_of_the_lease
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Main construction area
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="main_construction_area"
                      placeholder="Enter Main construction area"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.main_construction_area}
                      error={
                        formik.touched.main_construction_area &&
                        Boolean(formik.errors.main_construction_area)
                      }
                      helperText={
                        formik.touched.main_construction_area &&
                        formik.errors.main_construction_area
                      }
                    />
                  </div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Predominant use
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="predominant_use"
                      placeholder="Enter Predominant use"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.predominant_use}
                      error={
                        formik.touched.predominant_use &&
                        Boolean(formik.errors.predominant_use)
                      }
                      helperText={
                        formik.touched.predominant_use &&
                        formik.errors.predominant_use
                      }
                    />
                  </div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Progress
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="progress"
                      placeholder="Enter Progress"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.progress}
                      error={
                        formik.touched.progress &&
                        Boolean(formik.errors.progress)
                      }
                      helperText={
                        formik.touched.progress && formik.errors.progress
                      }
                    />
                  </div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Budget
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="budget"
                      placeholder="Enter Budget"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.budget}
                      error={
                        formik.touched.budget && Boolean(formik.errors.budget)
                      }
                      helperText={formik.touched.budget && formik.errors.budget}
                    />
                  </div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Construction permit number
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="construction_permit"
                      placeholder="Enter Construction permit number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.construction_permit}
                      error={
                        formik.touched.construction_permit &&
                        Boolean(formik.errors.construction_permit)
                      }
                      helperText={
                        formik.touched.construction_permit &&
                        formik.errors.construction_permit
                      }
                    />
                  </div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Coverage percentage
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="coverage_percentage"
                      placeholder="Enter Coverage percentage"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.coverage_percentage}
                      error={
                        formik.touched.coverage_percentage &&
                        Boolean(formik.errors.coverage_percentage)
                      }
                      helperText={
                        formik.touched.coverage_percentage &&
                        formik.errors.coverage_percentage
                      }
                    />
                  </div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Year of construction
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="year_of_construction"
                      placeholder="Enter Year of construction"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.year_of_construction}
                      error={
                        formik.touched.year_of_construction &&
                        Boolean(formik.errors.year_of_construction)
                      }
                      helperText={
                        formik.touched.year_of_construction &&
                        formik.errors.year_of_construction
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ConstructionDetails_formContainer">
            <p className="ConstructionDetails_form_legend">
              Details of Elements
            </p>
            <div className="ConstructionDetailsform_fields">
              <div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Construction type Similar To
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="construction_type_similar"
                    placeholder="Enter Construction type Similar To"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.construction_type_similar}
                    error={
                      formik.touched.construction_type_similar &&
                      Boolean(formik.errors.construction_type_similar)
                    }
                    helperText={
                      formik.touched.construction_type_similar &&
                      formik.errors.construction_type_similar
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Structure
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="structure"
                    placeholder="Enter Structure"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.structure}
                    error={
                      formik.touched.structure &&
                      Boolean(formik.errors.structure)
                    }
                    helperText={
                      formik.touched.structure && formik.errors.structure
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">Walls</p>
                  <CustomTextField
                    disabled={Isview}
                    name="walls"
                    placeholder="Enter Walls"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.walls}
                    error={formik.touched.walls && Boolean(formik.errors.walls)}
                    helperText={formik.touched.walls && formik.errors.walls}
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Mezzanine
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="mezzanine"
                    placeholder="Enter Mezzanine"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.mezzanine}
                    error={
                      formik.touched.mezzanine &&
                      Boolean(formik.errors.mezzanine)
                    }
                    helperText={
                      formik.touched.mezzanine && formik.errors.mezzanine
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">Roofs</p>
                  <CustomTextField
                    disabled={Isview}
                    name="roofs"
                    placeholder="Enter Roofs"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.roofs}
                    error={formik.touched.roofs && Boolean(formik.errors.roofs)}
                    helperText={formik.touched.roofs && formik.errors.roofs}
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">Deck</p>
                  <CustomTextField
                    disabled={Isview}
                    name="desk"
                    placeholder="Enter Deck"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.desk}
                    error={formik.touched.desk && Boolean(formik.errors.desk)}
                    helperText={formik.touched.desk && formik.errors.desk}
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Floors
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="floor"
                      placeholder="Enter Floors"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.floor}
                      error={
                        formik.touched.floor && Boolean(formik.errors.floor)
                      }
                      helperText={formik.touched.floor && formik.errors.floor}
                    />
                  </div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Apartments
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="apartment"
                      placeholder="Enter Apartments"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.apartment}
                      error={
                        formik.touched.apartment &&
                        Boolean(formik.errors.apartment)
                      }
                      helperText={
                        formik.touched.apartment && formik.errors.apartment
                      }
                    />
                  </div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Bathrooms
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="bathroom"
                      placeholder="Enter Bathrooms"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.bathroom}
                      error={
                        formik.touched.bathroom &&
                        Boolean(formik.errors.bathroom)
                      }
                      helperText={
                        formik.touched.bathroom && formik.errors.bathroom
                      }
                    />
                  </div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Other
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="other"
                      placeholder="Enter Other"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.other}
                      error={
                        formik.touched.other && Boolean(formik.errors.other)
                      }
                      helperText={formik.touched.other && formik.errors.other}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ConstructionDetails_formContainer">
            <div className="ConstructionDetailsform_fields">
              <div style={{ position: "relative" }}>
                <p
                  className="ConstructionDetails_form_legend"
                  style={{ top: "-35px" }}
                >
                  Electric System
                </p>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Conduit casing
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="conduit_casting"
                    placeholder="Enter Conduit casing"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.conduit_casting}
                    error={
                      formik.touched.conduit_casting &&
                      Boolean(formik.errors.conduit_casting)
                    }
                    helperText={
                      formik.touched.conduit_casting &&
                      formik.errors.conduit_casting
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Percentage
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="percentage"
                    placeholder="Enter Percentage"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.percentage}
                    error={
                      formik.touched.percentage &&
                      Boolean(formik.errors.percentage)
                    }
                    helperText={
                      formik.touched.percentage && formik.errors.percentage
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Breaker box
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="breaker_box"
                    placeholder="Enter Breaker box"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.breaker_box}
                    error={
                      formik.touched.breaker_box &&
                      Boolean(formik.errors.breaker_box)
                    }
                    helperText={
                      formik.touched.breaker_box && formik.errors.breaker_box
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Switch
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="pcd_switch"
                    placeholder="Enter Switch"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.pcd_switch}
                    error={
                      formik.touched.pcd_switch &&
                      Boolean(formik.errors.pcd_switch)
                    }
                    helperText={
                      formik.touched.pcd_switch && formik.errors.pcd_switch
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Overall status
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="over_all_status"
                    placeholder="Enter Overall status"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.over_all_status}
                    error={
                      formik.touched.over_all_status &&
                      Boolean(formik.errors.over_all_status)
                    }
                    helperText={
                      formik.touched.over_all_status &&
                      formik.errors.over_all_status
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    LP Gas
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="lp_gas"
                    placeholder="Enter LP Gas"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.lp_gas}
                    error={
                      formik.touched.lp_gas && Boolean(formik.errors.lp_gas)
                    }
                    helperText={formik.touched.lp_gas && formik.errors.lp_gas}
                  />
                </div>
              </div>

              <div>
                <div style={{ position: "relative" }}>
                  <p
                    className="ConstructionDetails_form_legend"
                    style={{ top: "-35px" }}
                  >
                    Physical Condition and Maintenance
                  </p>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Walls Condition
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="wall_condition"
                      placeholder="Enter wall_condition"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.wall_condition}
                      error={
                        formik.touched.wall_condition &&
                        Boolean(formik.errors.wall_condition)
                      }
                      helperText={
                        formik.touched.wall_condition &&
                        formik.errors.wall_condition
                      }
                    />
                  </div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Ceilings Condition
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="celling_condition"
                      placeholder="Enter Ceilings condition"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.celling_condition}
                      error={
                        formik.touched.celling_condition &&
                        Boolean(formik.errors.celling_condition)
                      }
                      helperText={
                        formik.touched.celling_condition &&
                        formik.errors.celling_condition
                      }
                    />
                  </div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Floors Condition
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="floor_condition"
                      placeholder="Enter Floors"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.floor_condition}
                      error={
                        formik.touched.floor_condition &&
                        Boolean(formik.errors.floor_condition)
                      }
                      helperText={
                        formik.touched.floor_condition &&
                        formik.errors.floor_condition
                      }
                    />
                  </div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Cover Condition
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="cover_condition"
                      placeholder="Enter Cover"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.cover_condition}
                      error={
                        formik.touched.cover_condition &&
                        Boolean(formik.errors.cover_condition)
                      }
                      helperText={
                        formik.touched.cover_condition &&
                        formik.errors.cover_condition
                      }
                    />
                  </div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Hot water network
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="hot_water_network"
                      placeholder="Enter Hot water network"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.hot_water_network}
                      error={
                        formik.touched.hot_water_network &&
                        Boolean(formik.errors.hot_water_network)
                      }
                      helperText={
                        formik.touched.hot_water_network &&
                        formik.errors.hot_water_network
                      }
                    />
                  </div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Water collection tank
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="water_collection_tank"
                      placeholder="Enter Water collection tank"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.water_collection_tank}
                      error={
                        formik.touched.water_collection_tank &&
                        Boolean(formik.errors.water_collection_tank)
                      }
                      helperText={
                        formik.touched.water_collection_tank &&
                        formik.errors.water_collection_tank
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ConstructionDetails_formContainer">
            <p className="ConstructionDetails_form_legend">Fissures</p>
            <div className="ConstructionDetailsform_fields">
              <div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Repellos Fissures
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="repelios_fissures"
                    placeholder="Enter Repellos Fissures"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.repelios_fissures}
                    error={
                      formik.touched.repelios_fissures &&
                      Boolean(formik.errors.repelios_fissures)
                    }
                    helperText={
                      formik.touched.repelios_fissures &&
                      formik.errors.repelios_fissures
                    }
                  />
                </div>
                <div className="ConstructionDetailsform_field_container">
                  <p className="ConstructionDetailsform_fields_labels">
                    Floors Fissures
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="floors_fissures"
                    placeholder="Enter Floors Fissures"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.floors_fissures}
                    error={
                      formik.touched.floors_fissures &&
                      Boolean(formik.errors.floors_fissures)
                    }
                    helperText={
                      formik.touched.floors_fissures &&
                      formik.errors.floors_fissures
                    }
                  />
                </div>
              </div>

              <div>
                <div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Walls Fissures
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="walls_fissures"
                      placeholder="Enter Walls Fissures"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.walls_fissures}
                      error={
                        formik.touched.walls_fissures &&
                        Boolean(formik.errors.walls_fissures)
                      }
                      helperText={
                        formik.touched.walls_fissures &&
                        formik.errors.walls_fissures
                      }
                    />
                  </div>
                  <div className="ConstructionDetailsform_field_container">
                    <p className="ConstructionDetailsform_fields_labels">
                      Heavens
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="heavens_fissures"
                      placeholder="Enter Heavens"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.heavens_fissures}
                      error={
                        formik.touched.heavens_fissures &&
                        Boolean(formik.errors.heavens_fissures)
                      }
                      helperText={
                        formik.touched.heavens_fissures &&
                        formik.errors.heavens_fissures
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="ConstructionDetails_formContainer">
            <p className="ConstructionDetails_form_legend">Additional Works</p>

            <div className="ConstructionDetailsform_field_container">
              <p className="ConstructionDetailsform_fields_labels">
                Description
              </p>
              <CustomTextArea
                disabled={Isview}
                maxRows={5}
                name="description"
                placeholder="Enter Description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
              />
            </div>
          </div>

          <div className="ConstructionDetails_formContainer">
            <p className="ConstructionDetails_form_legend">Observation</p>

            <div className="ConstructionDetailsform_field_container">
              <p className="ConstructionDetailsform_fields_labels">
                Observation
              </p>
              <CustomTextArea
                disabled={Isview}
                maxRows={5}
                name="observation"
                placeholder="Enter observation"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.observation}
                error={
                  formik.touched.observation &&
                  Boolean(formik.errors.observation)
                }
                helperText={
                  formik.touched.observation && formik.errors.observation
                }
              />
            </div>
          </div>
          {!Isview && (
            <div className="ConstructionDetailsform_action">
              <FormButton
                id="ConstructionDetailsformCreateCancel"
                variant="outlined"
                loading={false}
                text="Cancel"
                onClick={()=>{
                  navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`);
                }}
              />
              <FormButton
                id="ConstructionDetailsformCreateSubmit"
                type="submit"
                form="ConstructionDetailsform"
                variant="contained"
                loading={loading}
                text="Save & Continue"
              />
            </div>
          )}
        </form>
      </div>
    </div>
  );
}

export default ConstructionDetailsForm;
