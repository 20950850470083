import React, { useState, useRef, useEffect } from "react";
import { Autocomplete , TextField} from "@mui/material";
import "./FuelFilter.css"
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { useFormik } from "formik";
// import { updateFilterDropdownData,setFuelFilterDatas,searchFuelData } from "../../../../../../../store/vehicleStore";
import { updateFuelFilterDropdown,setFuelFilterDatas,searchFuelData, resetFuelLookupData } from "../../../../../../../store/LookUpStore";
function FuelLookUpFilter() {
    const dispatch = useDispatch();
    const wholeFuelData = useSelector(
        (state) => state.lookUpStore.WholeFuels
      );
      const FuelFilterDropDownList = useSelector(
        (state) => state.lookUpStore.FuelFilterDropdownList
      );
      const FilterData = useSelector(
        (state) => state.lookUpStore.FuelFilterData
      );
      useEffect(() => {
        dispatch(updateFuelFilterDropdown());
      }, [wholeFuelData]);
    const inputStyles = {
        "& .MuiOutlinedInput-root": {
          padding: "0px",
          fontSize: "15px !important",
          fontFamily: "Inter",
          width: 200,
          "& .MuiAutocomplete-input": {
            padding: "7.5px 4px 7.5px 9px !important",
            fontFamily: "Inter",
          },
          "& fieldset": {
            borderColor: "rgba(119, 182, 243, 1) !important", // Change border color
          },
          "&:hover fieldset": {
            borderColor: "rgba(119, 182, 243, 1) !important", // Change border color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "rgba(119, 182, 243, 1) !important", // Change border color when focused
          },
        },
      };
      const initialValues = {
        FuelName: FilterData.FuelName,
        

      };
      const formik = useFormik({
        initialValues,
        onSubmit: (values) => {
          dispatch(setFuelFilterDatas(values));
          dispatch(searchFuelData());
        },
      });
      const [filterOpen, setFilterOpen] = useState(false);
      useEffect(() => {
        const handleClickOutside = (event) => {
          const icon = document.querySelector(".filter_icon_mobile");
          const container = document.querySelector(".filter_field_container");
          const datePicker = document.querySelector(
            ".custom-date-picker.rdrDateRangePickerWrapper"
          );
    
          const isDatePickerOpen = datePicker && datePicker.style.display == "flex";
    
          if (
            !isDatePickerOpen &&
            !icon.contains(event.target) &&
            !container.contains(event.target)
          ) {
            const datePickerContainer = document.getElementById(
              "custom_date_picker_container"
            );
            if (datePickerContainer) {
              if (datePickerContainer.style.display !== "block") {
                setFilterOpen(false);
              }
            }
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
    
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [filterOpen]);
    
      function UpdateFieldVisibility(id) {
        const element = document.getElementById(id);
        if (element) {
          if (element.style.display === "none" || element.style.display === "") {
            element.style.display = "block";
          } else {
            element.style.display = "";
          }
        }
      }
    
  return (
    <div>
        <div className="filter_icon_mobile">
        <IconButton
          color="primary"
          onClick={() => {
            UpdateFieldVisibility("vehicle_filter_field_container");
            setFilterOpen(!filterOpen);
          }}
        >
          <FilterAltOutlinedIcon />
        </IconButton>
      </div>

          <div
        id="vehicle_filter_field_container"
        style={{ display: filterOpen ? "block" : "" }}
      >
        <div id="filter_field_container" className="filter_field_container">
          <form className="filter_BankLookUp_bar" onSubmit={formik.handleSubmit}>
             <div id="BankLookUp_filter" className="filter_tool_field_width">
              <Autocomplete
                name="FuelName"
                componentsProps={{ paper: { sx: { wordBreak: "break-word" } } }}
                sx={{ ...inputStyles }}
                disablePortal
                id="combo-box-demo"
                options={FuelFilterDropDownList.FuelName}
                getOptionLabel={(option) => option.label}
                onBlur={formik.handleBlur}
                onChange={(event, newValue) => {
                  formik.setFieldValue("FuelName", newValue ? newValue.label : "");
                }}
                value={
                    FuelFilterDropDownList.FuelName.find(
                    (FuelName) => FuelName.value === formik.values.FuelName
                  ) || null
                }
                ListboxProps={{
                  style: { minHeight: 0, fontSize: 15, fontFamily: "Inter" },
                }}
                menuProps={{ style: { minHeight: 0, fontFamily: "Inter" } }}
                renderInput={(params) => (
                  <TextField {...params} label="" placeholder="Select Fuel" />
                )}
              />
            </div>
           
            <button className="toolbar_Search_button" type="submit">
              Search
            </button>
            <button
              type="button"
              className="toolbar_Reset_button"
              onClick={() => {
                dispatch(resetFuelLookupData());
               
                formik.setValues({
                  ...formik.values,
                  FuelName: "",
                  
                });
              }}
            >
              Reset
            </button>
            </form>
      </div>
    </div>
    </div>
  );
}

export default FuelLookUpFilter;
