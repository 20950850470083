import React, { useEffect } from 'react'
import './LoginPage.css'
import BackgroundImage from '../../assets/LoginSideImage.png'
import LoginForm from './subComponents/loginForm/LoginForm'
import { LocalStorageServices } from '../../services/localStorageServices'
function LoginPage() {
  useEffect(()=>{
    LocalStorageServices.deleteLocalStorageData()
  },[])
  return (
    <div className='login_page_main_container'>
        <div className='login_page_imageContainer'>
            <img src={BackgroundImage} alt="BackgroundImage" className='login_backgroundImage'/>
            <p className='imagecontainer_subtitle'>bankrealty.com</p>
        </div>
        <div className='login_page_loginFormContainer'>
            <LoginForm/>
        </div>
    </div>
  )
}

export default LoginPage