import React, { useEffect, useState } from 'react'
import { getLoanApplicationFilterBasedOnVehicle, getLoanApplicationsBasedOnVehicles } from '../../../../../../api/apiServices/customerPortalApis';
import { useDispatch } from 'react-redux'
import ResponseHandler from '../../../../../../services/ResponseHandler';
import { useNavigate, useParams } from 'react-router-dom';
import { Pagination, styled } from '@mui/material';
import CustomTableWithOutPagination from '../../../../../../components/CustomTableWithOutPagination/CustomTableWithOutPagination'
import CustomChip from '../../../../../../components/customChip/CustomChip';
import { LocalStorageServices } from '../../../../../../services/localStorageServices';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CustomLoanApplicationFilter from '../CustomLoanApplicationFilter/CustomLoanApplicationFilter';
import { setRefresh } from '../../../../../../store/usersStore';
import { Button, Box } from "@mui/material";

function VehicleLoanApplicationDetails() {
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const { vehicle_id } = useParams();
    const [PageNumber,SetPageNumber]=useState(1)
    const [pageCount,SetPageCount]=useState(0)
    const [VehicleLoanApplicationDetails, SetVehicleLoanApplicationDetails] = useState([]);
    const [isFiltered,setFiltered]=useState(false)
    const [values,setValues]=useState({
      "name": "",
      "email": "",
      "contact_number": "",
      "preferred_bank": "",
      "employer_status": "",
      "visit_required": ""
    })
    const hasAnyValue = (obj) => {
      return Object.values(obj).some(value => value !== "");
    };
    useEffect(()=>{
      if(hasAnyValue(values)){
        dispatch(getLoanApplicationFilterBasedOnVehicle(vehicle_id,PageNumber,10,values)).then((response)=>{
          const {loanApplications, count} = response
          SetPageCount(Math.ceil(count/10))
          SetVehicleLoanApplicationDetails(loanApplications);
      }).catch((error)=>{
        ResponseHandler(error)
      })
      }else{
        dispatch(getLoanApplicationsBasedOnVehicles(vehicle_id,PageNumber,10)).then((response)=>{
          const {loanApplications, count} = response
          SetPageCount(Math.ceil(count/10))
          SetVehicleLoanApplicationDetails(loanApplications);
      }).catch((error)=>{
        ResponseHandler(error)
      })
      }
     dispatch(setRefresh())
    },[PageNumber,isFiltered])
    const CustomPagination = styled(Pagination)(({ theme }) => ({
      '& .MuiPaginationItem-root': {
        backgroundColor:"#EDEFF6",
        color: '#4A60A1', // Color for the pagination items
        '&.Mui-selected': {
          backgroundColor: '#1976d2', // Background color for selected item
          color: 'white', // Text color for selected item
        },
        '&:hover': {
          backgroundColor: '#fff', // Background color for selected item
          color: '#000', // Background color on hover
        },
      
      },
    }));
    const Columns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
        
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "Contact Number",
        dataIndex: "contactNumber",
        key: "contactNumber"
      },
      {
        title: "Residential Address",
        dataIndex: "residentialAddress",
        key: "residentialAddress",
      },
      {
        title: "Message",
        dataIndex: "message",
        key: "message",
      },
      {
        title: "Employer Status",
        dataIndex: "employerStatus",
        key: "employerStatus",
      },
      {
        title: "Visit Required",
        dataIndex: "visitRequired",
        key: "visitRequired",
      },
      {
        title: "preferred Bank",
        dataIndex: "preferredBank",
        key: "preferredBank",
      },
      {
        title: "Loan Amount",
        dataIndex: "loanAmount",
        key: "loanAmount",
      },
      // {
      //   title: "KYC Doc",
      //   dataIndex: "Actions",
      //   key: "Actions",
      //   action: (row) => (
      //     <div>
      //       <CustomChip
      //         text="Kyc"
      //         bgColor="#F8893C"
      //         color="white"
      //       />
      //     </div>
      //   ),
      // },
    ];
    const Datas = () => {
      const result =
        VehicleLoanApplicationDetails &&
        VehicleLoanApplicationDetails.map((item, index) => {
          return {
            key: ((PageNumber*5)-(5-(index + 1))) ,
            id: item.la_id,
            id: item.la_id,
            vehicleID:item.la_property_or_vehicle_id,
            name: item.la_name,
            email: item.la_email_address,
            contactNumber: item.la_contact_number,
            residentialAddress: item.la_residential_address,
            employerStatus: item.la_employer_status===0?'Salaried':"Self Employed",
            visitRequired: item.la_visit_required===0?'No':"Yes",
            preferredBank: item.la_preferred_bank,
            loanAmount: item.la_loan_amount,
          };
        });
      return result;
    };
    const translateText = async (text) => {
      const currentLang = localStorage.getItem("currentLanguage") || "en";
      if (currentLang === "es") {
        try {
          const response = await fetch(
            `https://api.mymemory.translated.net/get?q=${encodeURIComponent(text)}&langpair=es|en`
          );
          const data = await response.json();
          if (data.responseStatus === 200) {
            return data.responseData.translatedText;
          } else {
            return text;
          }
        } catch (error) {
          console.error("Error translating text:", error);
          return text; // Fallback to original text if translation fails
        }
      }
      return text;
    };
    
    const onchangeValues = async (event) => {
      const { value, name } = event.target;
      const currentLang = localStorage.getItem("currentLanguage") || "en";
      const isSpanish = currentLang === "es";
    
      if (isSpanish) {
        const translatedValue = await translateText(value);
        setValues({ ...values, [name]: translatedValue });
        console.log(translatedValue,"translatedValue");
      } else {
        setValues({ ...values, [name]: value });
      }
    };
    const onResetValues=()=>{
      setValues({
        "name": "",
        "email": "",
        "contact_number": "",
        "preferred_bank": "",
        "employer_status": "",
        "visit_required": ""
     })
     SetPageNumber(1)
     setFiltered(!isFiltered)
    }
  
    const onSearch=()=>{
      SetPageNumber(1)
      setFiltered(!isFiltered)
    }
    const renderCustomPagination = () => {
      const pageRange = 3;
      let startPage = Math.max(1, PageNumber - Math.floor(pageRange / 2));
      const endPage = Math.min(pageCount, startPage + pageRange - 1);
      if (endPage - startPage + 1 < pageRange) {
        startPage = Math.max(1, endPage - pageRange + 1);
      }
  
      const pages = [];
  
      if (PageNumber > 1) {
        pages.push(
          <Button
            key="prev"
            variant="outlined"
            onClick={() => SetPageNumber(PageNumber - 1)}
            sx={{
              minWidth: '30px',
              padding: '3px',
              margin: '0 5px',
              backgroundColor: '#EDEFF6',
              color: '#4A60A1',
              '&:hover': {
                backgroundColor: '#EDEFF6',
                color: '#4A60A1',
              },
            }}
          >
            {'<'}
          </Button>
        );
      }
  
      for (let i = startPage; i <= endPage; i++) {
        pages.push(
          <Button
            key={i}
            variant={PageNumber === i ? 'contained' : 'outlined'}
            onClick={() => SetPageNumber(i)}
            sx={{
              minWidth: '30px',
              padding: '3px',
              margin: '0 5px',
              backgroundColor: PageNumber === i ? '#005EB8' : '#EDEFF6',
              color: PageNumber === i ? 'white' : '#4A60A1',
              '&:hover': {
                backgroundColor: PageNumber === i ? '#005EB8' : '#EDEFF6',
                color: PageNumber === i ? 'white' : '#4A60A1',
              },
            }}
          >
            {i}
          </Button>
        );
      }
  
      if (PageNumber < pageCount) {
        pages.push(
          <Button
            key="next"
            variant="outlined"
            onClick={() => SetPageNumber(PageNumber + 1)}
            sx={{
              minWidth: '30px',
              padding: '3px',
              margin: '0 5px',
              backgroundColor: '#EDEFF6',
              color: '#4A60A1',
              '&:hover': {
                backgroundColor: '#EDEFF6',
                color: '#4A60A1',
              },
            }}
          >
            {'>'}
          </Button>
        );
      }
  
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px 0' }}>
          {pages}
        </Box>
      );
    };

    return (
      <div>
        <div  style={{display:'flex',justifyContent:'end',alignItems:'center',margin:'10px 0px'}}>
        <div
            className="back_button"
            onClick={() => {
              navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/loan_applications?selected_loan_type=1`);
            }}
          >
            <KeyboardBackspaceIcon />
            <p>Back</p>
          </div>
        </div>
        <CustomLoanApplicationFilter values={values} onChange={onchangeValues} onReset={onResetValues} onSubmit={onSearch}/>
      <CustomTableWithOutPagination columns={Columns} rows={Datas()}/>
      <div style={{display:'flex',justifyContent:'center',alignItems:'center',margin:'10px 0px'}}>
      {renderCustomPagination()}
      </div>
     
  </div>
    )
  }

export default VehicleLoanApplicationDetails