import React from 'react'
import { Button, TextField } from "@mui/material";
import './PropertyInterestFilterStyle.css'
function PropertyInterestFilter({values,onChange,onReset,onSubmit}) {
    return (
        <div>
                <div className="PropertyInterest_input_action_container">
                    <TextField
                        name="property_id"
                        value={values.property_id}
                        fullWidth
                        className="PropertyInterest_input"
                        autoComplete="off"
                        style={{ fontSize: "15px" }}
                        size="small"
                        placeholder={"Enter property id"}
                        onChange={onChange}
                        variant="outlined"
                        inputProps={{
                            style: {
                                padding: "3.5px 7px", // Add padding to input
                                fontSize: "15px",
                                fontWeight: 400,
                                fontFamily: "Inter",
                                borderRadius: "4px",
                            },
                        }}
                        sx={{
                            "& fieldset": { border: "none" },
                        }}
                    />
                    <TextField
                        name="title"
                        value={values.title}
                        fullWidth
                        className="PropertyInterest_input"
                        autoComplete="off"
                        style={{ fontSize: "15px" }}
                        size="small"
                        placeholder={"Enter title"}
                        onChange={onChange}
                        inputProps={{
                            style: {
                                padding: "3.5px 7px", // Add padding to input
                                fontSize: "15px",
                                fontWeight: 400,
                                fontFamily: "Inter",
                                borderRadius: "4px",
                            },
                        }}
                        sx={{
                            "& fieldset": { border: "none" },
                        }}
                    />
                    <TextField
                        name="property_type"
                        value={values.property_type}
                        fullWidth
                        className="PropertyInterest_input"
                        autoComplete="off"
                        style={{ fontSize: "15px" }}
                        size="small"
                        placeholder={"Enter property type"}
                        onChange={onChange}
                        inputProps={{
                            style: {
                                padding: "3.5px 7px", // Add padding to input
                                fontSize: "15px",
                                fontWeight: 400,
                                fontFamily: "Inter",
                                borderRadius: "4px",
                            },
                        }}
                        sx={{
                            "& fieldset": { border: "none" },
                        }}
                    />
                    <TextField
                        name="location"
                        value={values.location}
                        fullWidth
                        className="PropertyInterest_input"
                        autoComplete="off"
                        style={{ fontSize: "15px" }}
                        size="small"
                        placeholder={"Enter location"}
                        onChange={onChange}
                        inputProps={{
                            style: {
                                padding: "3.5px 7px", // Add padding to input
                                fontSize: "15px",
                                fontWeight: 400,
                                fontFamily: "Inter",
                                borderRadius: "4px",
                            },
                        }}
                        sx={{
                            "& fieldset": { border: "none" },
                        }}
                    />
                     <TextField
                        name="amount"
                        value={values.amount}
                        fullWidth
                        className="PropertyInterest_input"
                        autoComplete="off"
                        style={{ fontSize: "15px" }}
                        size="small"
                        placeholder={"Enter amount"}
                        onChange={onChange}
                        inputProps={{
                            style: {
                                padding: "3.5px 7px", // Add padding to input
                                fontSize: "15px",
                                fontWeight: 400,
                                fontFamily: "Inter",
                                borderRadius: "4px",
                            },
                        }}
                        sx={{
                            "& fieldset": { border: "none" },
                        }}
                    />
                    <div className="PropertyInterest_action">
                        <Button
                            className="users_Search_button"
                            onClick={onSubmit}
                            variant="contained"
                            size="small"
                            style={{
                                textTransform: "none",
                                textTransform: "none",
                                fontFamily: "Inter",
                                fontSize: "15px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                padding: "8px 15px",
                                backgroundColor: "#0f62fe",
                                color: "white",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer"
                            }}
                        >
                            {"Search"}
                        </Button>
                        <Button
                            onClick={onReset}
                            className="users_Reset_button"
                            variant="outlined"
                            size="small"
                            style={{
                                textTransform: "none",
                                cursor: 'pointer',
                                fontFamily: 'inter',
                                border: '1px solid #005eb8',
                                backgroundColor: 'white',
                                borderRadius: '5px',
                                padding: '7px 25px',
                                color: '#0f62fe',
                                fontSize: '14px'
                            }}

                        >
                            {"Reset"}
                        </Button>
                    </div>
                </div>
            </div>
    )
}

export default PropertyInterestFilter