import React, { useEffect, useState } from "react";
import "./PropertiesPage.css";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllProperty,
  updateDefaultChecked,
  updateSelectedTab,
} from "../../../../../store/propertiesStore";

import {
  deletePropery,
  getAllproperties,
} from "../../../../../api/apiServices/propertyApi";
import { setRefresh } from "../../../../../store/usersStore";
import SimpleTable from "../../../../../components/simpleTable/SimpleTable3";
import CustomChip from "../../../../../components/customChip/CustomChip";
import ResponseHandler from "../../../../../services/ResponseHandler";
import CustomAdvanceTable from "../../../../../components/customAdvanceTable/CustomAdvanceTable";
import CustomSpin from "../../../../../components/customSpin/CustomSpin";
import { LocalStorageServices } from "../../../../../services/localStorageServices";
import PropertyPageSearchFilter from "./propertyPageSearchFilter/PropertyPageSearchFilter";
import CustomModal from "../../../../../components/customModal/CustomModal";
import SaleStatus from "../../../../../components/saleStatus/SaleStatus";
import { saleitemList } from "../../../../../constant/SaleStatusList";
function PropertiesPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [selectedId,setSelectedId]=useState(null)
  const { role } = useParams();
  const defaultColumnChecked = useSelector(
    (state) => state.propertiesStore.defaultChecked
  );
  const AllProperties = useSelector((state) => state.propertiesStore.property);
  const tableLoading = useSelector(
    (state) => state.propertiesStore.PropertyTaskTableLoading
  );
  const [deletePropertyData, setDeletePropertyData] = useState({
    id: "",
    loading: false,
  });

  const updateDefaultCheckedColumn = (data) => {
    dispatch(updateDefaultChecked(data));
  };

  useEffect(() => {
    dispatch(getAllproperties());
  }, []);
  const UpdateDeleteDetails = (id, loading) => {
    setDeletePropertyData({
      ...deletePropertyData,
      ["id"]: id,
      ["loading"]: loading,
    });
  };
  const handleDelete = (property_id) => {
    UpdateDeleteDetails(property_id, true);
    dispatch(deletePropery(property_id))
      .then((response) => {
        ResponseHandler(response);
      })
      .catch((error) => {
        ResponseHandler(error);
      })
      .finally(() => {
        UpdateDeleteDetails(property_id, false);
      });
  };
  const [rowChecked, setRowChecked] = useState(-1);
  const updateRowChecked = (index) => {
    if (index === rowChecked) {
      setRowChecked(-1);
    } else {
      setRowChecked(index);
    }
  };
  const handleClickOpen = (id) => {
    dispatch(setRefresh())
    setSelectedId(id)
    setOpen(true);
  };

  const handleClose = () => {
    dispatch(setRefresh())
    setOpen(false);
  };
  const Columns = [
    { title: "#", dataIndex: "key", key: "key" },
    {
      title: "Application Name",
      dataIndex: "application_name",
      key: "application_name",
    },
    { title: "Owner Name", dataIndex: "owner_name", key: "owner_name" },

    { title: "Id", dataIndex: "id", key: "id" },
    { title: "User Id", dataIndex: "user_id", key: "user_id" },
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Ced Legal", dataIndex: "ced_legal", key: "ced_legal" },
    {
      title: "Propotion Of Rights",
      dataIndex: "propotion_of_rights",
      key: "propotion_of_rights",
    },
    { title: "Province", dataIndex: "province", key: "province" },
    { title: "Canton", dataIndex: "canton", key: "canton" },
    { title: "District", dataIndex: "district", key: "district" },
    {
      title: "Exact Direction",
      dataIndex: "exact_direction",
      key: "exact_direction",
    },
    { title: "Location", dataIndex: "location", key: "location" },
    { title: "Route Type", dataIndex: "route_type", key: "route_type" },
    { title: "Material", dataIndex: "material", key: "material" },
    {
      title: "Track Width",
      dataIndex: "track_width",
      key: "track_width",
    },
    { title: "Payment", dataIndex: "payment", key: "payment" },
    { title: "Spout", dataIndex: "spout", key: "spout" },
    { title: "Ditch", dataIndex: "ditch", key: "ditch" },
    { title: "Cord", dataIndex: "cord", key: "cord" },
    {
      title: "Sanitary System",
      dataIndex: "sanitary_system",
      key: "sanitary_system",
    },
    { title: "Storm Tank", dataIndex: "storm_tank", key: "storm_tank" },
    {
      title: "Access To Estate",
      dataIndex: "access_to_estate",
      key: "access_to_estate",
    },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "Lighting", dataIndex: "lighting", key: "lighting" },
    {
      title: "Electricity",
      dataIndex: "electricity",
      key: "electricity",
    },
    {
      title: "Drinking Water",
      dataIndex: "drinking_water",
      key: "drinking_water",
    },
    {
      title: "Cellular Signal",
      dataIndex: "cellular_signal",
      key: "cellular_signal",
    },
    { title: "Cable Tv", dataIndex: "cable_tv", key: "cable_tv" },
    {
      title: "Meters Installed",
      dataIndex: "meters_Installed",
      key: "meters_Installed",
    },
    {
      title: "Cable Installed",
      dataIndex: "cable_Installed",
      key: "cable_Installed",
    },
    {
      title: "Garden And Parks",
      dataIndex: "garden_and_parks",
      key: "garden_and_parks",
    },
    {
      title: "Public Transport",
      dataIndex: "public_transport",
      key: "public_transport",
    },
    {
      title: "Commercial Building",
      dataIndex: "commercial_Building",
      key: "commercial_Building",
    },
    {
      title: "Garbage Collection",
      dataIndex: "garbage_collection",
      key: "garbage_collection",
    },
    {
      title: "Public Or Communal Building",
      dataIndex: "public_or_communal_building",
      key: "public_or_communal_building",
    },
    {
      title: "Middle Class",
      dataIndex: "middle_class",
      key: "middle_class",
    },
    {
      title: "Average Population Density",
      dataIndex: "average_population_density",
      key: "average_population_density",
    },
    {
      title: "Socio Class Near By Area",
      dataIndex: "socio_class_near_by_area",
      key: "socio_class_near_by_area",
    },
    {
      title: "Site Activity",
      dataIndex: "site_activity",
      key: "site_activity",
    },
    {
      title: "Residential",
      dataIndex: "residential",
      key: "residential",
    },
    {
      title: "Agriculture",
      dataIndex: "agriculture",
      key: "agriculture",
    },
    { title: "Main Front", dataIndex: "main_front", key: "main_front" },
    { title: "Background", dataIndex: "background", key: "background" },
    {
      title: "Relationship",
      dataIndex: "relationship",
      key: "relationship",
    },
    {
      title: "Services S1",
      dataIndex: "services_s1",
      key: "services_s1",
    },
    {
      title: "Services S2",
      dataIndex: "services_s2",
      key: "services_s2",
    },
    {
      title: "Level Above Street",
      dataIndex: "level_above_street",
      key: "level_above_street",
    },
    { title: "Earning", dataIndex: "earning", key: "earning" },
    {
      title: "Type Of Road",
      dataIndex: "type_of_road",
      key: "type_of_road",
    },
    {
      title: "Large Trees Or Antennas",
      dataIndex: "large_trees_or_antennas",
      key: "large_trees_or_antennas",
    },
    {
      title: "Physical Condition",
      dataIndex: "physical_condition",
      key: "physical_condition",
    },
    { title: "Slip Risk", dataIndex: "slip_risk", key: "slip_risk" },
    { title: "Typography", dataIndex: "typography", key: "typography" },
    {
      title: "Damage Chance",
      dataIndex: "damage_chance",
      key: "damage_chance",
    },
    {
      title: "Urbanization Of Area",
      dataIndex: "urabanization_of_area",
      key: "urabanization_of_area",
    },
    { title: "Study Date", dataIndex: "study_date", key: "study_date" },
    {
      title: "Transfer Easement Appointment",
      dataIndex: "transfer_easement_appointment",
      key: "transfer_easement_appointment",
    },
    {
      title: "Dominant Easement Appointment",
      dataIndex: "dominant_easement_appointment",
      key: "dominant_easement_appointment",
    },
    {
      title: "Observation",
      dataIndex: "observation",
      key: "observation",
    },
    {
      title: "Body Of Water Near Distance",
      dataIndex: "body_of_water_near_distance",
      key: "body_of_water_near_distance",
    },
    { title: "Office", dataIndex: "office", key: "office" },
    {
      title: "Purpose Of Appraisal",
      dataIndex: "purpose_of_appraisal",
      key: "purpose_of_appraisal",
    },
    {
      title: "Appraisal Report No",
      dataIndex: "appraisal_report_no",
      key: "appraisal_report_no",
    },
    { title: "Landvalue", dataIndex: "landvalue", key: "landvalue" },
    {
      title: "Value Of Construction",
      dataIndex: "value_of_construction",
      key: "value_of_construction",
    },
    {
      title: "Total Value Of The Goods",
      dataIndex: "total_value_of_the_goods",
      key: "total_value_of_the_goods",
    },
    {
      title: "Value In Letter",
      dataIndex: "value_in_letter",
      key: "value_in_letter",
    },
    {
      title: "Estimated Time",
      dataIndex: "estimated_time",
      key: "estimated_time",
    },
    {
      title: "Predominant Use Of Property",
      dataIndex: "predominant_use_of_property",
      key: "predominant_use_of_property",
    },
    {
      title: "Policy Amount",
      dataIndex: "policy_amount",
      key: "policy_amount",
    },
    {
      title: "Maximum Scope",
      dataIndex: "maximum_scope",
      key: "maximum_scope",
    },
    { title: "Specify", dataIndex: "specify", key: "specify" },
    {
      title: "General Observation",
      dataIndex: "general_observation",
      key: "general_observation",
    },
    {
      title: "Name Of The Expert",
      dataIndex: "name_of_the_expert",
      key: "name_of_the_expert",
    },
    {
      title: "Type Of Professional",
      dataIndex: "type_of_professional",
      key: "type_of_professional",
    },
    {
      title: "SIACC Expect Code",
      dataIndex: "siacc_expect_code",
      key: "siacc_expect_code",
    },
    { title: "EMP", dataIndex: "emp", key: "emp" },
    { title: "License", dataIndex: "license", key: "license" },
    {
      title: "Telephone Number",
      dataIndex: "telephone_number",
      key: "telephone_number",
    },
    {
      title: "Email Or Web",
      dataIndex: "email_or_web",
      key: "email_or_web",
    },
    {
      title: "Company Name",
      dataIndex: "company_name",
      key: "company_name",
    },
    {
      title: "Company Code",
      dataIndex: "company_code",
      key: "company_code",
    },
    {
      title: "Inspected Date",
      dataIndex: "inspected_date",
      key: "inspected_date",
    },
    {
      title: "Reported Date",
      dataIndex: "reported_date",
      key: "reported_date",
    },
    {
      title: "Garbage Collection",
      dataIndex: "garbage_collection",
      key: "garbage_collection",
    },
    {
      title: "Public Or Communal Building",
      dataIndex: "public_or_communal_building",
      key: "public_or_communal_building",
    },
    {
      title: "Registration Of Farm",
      dataIndex: "registartion_of_farm",
      key: "registartion_of_farm",
    },
    {
      title: "Cadastre Plane No",
      dataIndex: "cadastre_plane_no",
      key: "cadastre_plane_no",
    },
    {
      title: "Property Identity",
      dataIndex: "property_identity",
      key: "property_identity",
    },
    { title: "Percentage", dataIndex: "percentage", key: "percentage" },
    {
      title: "According To The Plane",
      dataIndex: "according_to_the_plane",
      key: "according_to_the_plane",
    },
    {
      title: "Measurement",
      dataIndex: "measurement",
      key: "measurement",
    },
    { title: "Too Much", dataIndex: "too_much", key: "too_much" },
    {
      title: "Conclusive Value",
      dataIndex: "conclusive_value",
      key: "conclusive_value",
    },
    {
      title: "Sections Factor",
      dataIndex: "sections_factor",
      key: "sections_factor",
    },
    {
      title: "Final Unit Value",
      dataIndex: "final_unit_value",
      key: "final_unit_value",
    },
    {
      title: "Total Value Of The Land",
      dataIndex: "total_value_of_the_land",
      key: "total_value_of_the_land",
    },
    {
      title: "Adjusted Value",
      dataIndex: "adjusted_value",
      key: "adjusted_value",
    },
    { title: "Area", dataIndex: "area", key: "area" },
    {
      title: "Unit Value Land Improvement",
      dataIndex: "unit_value_land_improvement",
      key: "unit_value_land_improvement",
    },
    { title: "North", dataIndex: "north", key: "north" },
    { title: "South", dataIndex: "south", key: "south" },
    { title: "East", dataIndex: "east", key: "east" },
    { title: "West", dataIndex: "west", key: "west" },
    {
      title: "Project Coordinates",
      dataIndex: "project_coordinates",
      key: "project_coordinates",
    },
    { title: "Vertex", dataIndex: "vertex", key: "vertex" },
    {
      title: "Existing Construction Type",
      dataIndex: "existing_construction_type",
      key: "existing_construction_type",
    },
    {
      title: "Building Status",
      dataIndex: "building_status",
      key: "building_status",
    },
    {
      title: "Professional Responsibility",
      dataIndex: "professional_responsibility",
      key: "professional_responsibility",
    },
    {
      title: "Developer Name",
      dataIndex: "developer_name",
      key: "developer_name",
    },
    {
      title: "CFIA Contact Number",
      dataIndex: "cfia_contact_number",
      key: "cfia_contact_number",
    },
    {
      title: "Additional Construction Document",
      dataIndex: "additional_construction_document",
      key: "additional_construction_document",
    },
    {
      title: "Property Used By",
      dataIndex: "property_used_by",
      key: "property_used_by",
    },
    {
      title: "Term Of The Lease",
      dataIndex: "tearm_of_the_lease",
      key: "tearm_of_the_lease",
    },
    {
      title: "Main Construction Area",
      dataIndex: "main_construction_area",
      key: "main_construction_area",
    },
    {
      title: "Predominant Use",
      dataIndex: "predominant_use",
      key: "predominant_use",
    },
    {
      title: "Progress",
      dataIndex: "progress",
      key: "progress",
    },
    {
      title: "Budget",
      dataIndex: "budget",
      key: "budget",
    },
    {
      title: "Construction Permit",
      dataIndex: "construction_permit",
      key: "construction_permit",
    },
    {
      title: "Coverage Percentage",
      dataIndex: "coverage_percentage",
      key: "coverage_percentage",
    },
    {
      title: "Year of Construction",
      dataIndex: "year_of_construction",
      key: "year_of_construction",
    },
    {
      title: "Construction Type Similar",
      dataIndex: "construction_type_similar",
      key: "construction_type_similar",
    },
    {
      title: "Structure",
      dataIndex: "structure",
      key: "structure",
    },
    {
      title: "Walls",
      dataIndex: "walls",
      key: "walls",
    },
    {
      title: "Mezzanine",
      dataIndex: "mezzanine",
      key: "mezzanine",
    },
    {
      title: "Roofs",
      dataIndex: "roofs",
      key: "roofs",
    },
    {
      title: "Desk",
      dataIndex: "desk",
      key: "desk",
    },
    {
      title: "Floor",
      dataIndex: "floor",
      key: "floor",
    },
    {
      title: "Apartment",
      dataIndex: "apartment",
      key: "apartment",
    },
    {
      title: "Bathroom",
      dataIndex: "bathroom",
      key: "bathroom",
    },
    {
      title: "Other",
      dataIndex: "other",
      key: "other",
    },
    {
      title: "Conduit Casting",
      dataIndex: "conduit_casting",
      key: "conduit_casting",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
    },
    {
      title: "Breaker Box",
      dataIndex: "breaker_box",
      key: "breaker_box",
    },
    {
      title: "Switch",
      dataIndex: "switch",
      key: "switch",
    },
    {
      title: "Overall Status",
      dataIndex: "over_astatus",
      key: "over_astatus",
    },
    {
      title: "LP Gas",
      dataIndex: "lp_gas",
      key: "lp_gas",
    },
    {
      title: "Wall Condition",
      dataIndex: "wacondition",
      key: "wacondition",
    },
    {
      title: "Ceiling Condition",
      dataIndex: "celling_condition",
      key: "celling_condition",
    },
    {
      title: "Floor Condition",
      dataIndex: "floor_condition",
      key: "floor_condition",
    },
    {
      title: "Cover Condition",
      dataIndex: "cover_condition",
      key: "cover_condition",
    },
    {
      title: "Hot Water Network",
      dataIndex: "hot_water_network",
      key: "hot_water_network",
    },
    {
      title: "Water Collection Tank",
      dataIndex: "water_collection_tank",
      key: "water_collection_tank",
    },
    {
      title: "Repelios Fissures",
      dataIndex: "repelios_fissures",
      key: "repelios_fissures",
    },
    {
      title: "Floors Fissures",
      dataIndex: "floors_fissures",
      key: "floors_fissures",
    },
    {
      title: "Walls Fissures",
      dataIndex: "walls_fissures",
      key: "walls_fissures",
    },
    {
      title: "Heavens Fissures",
      dataIndex: "heavens_fissures",
      key: "heavens_fissures",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Observation",
      dataIndex: "observation",
      key: "observation",
    },
    {
      title: "Liquid",
      dataIndex: "liquid",
      key: "liquid",
    },
    {
      title: "Appraisal Report No",
      dataIndex: "appraisal_report_no",
      key: "appraisal_report_no",
    },
    {
      title: "Applicant Name",
      dataIndex: "applicant_name",
      key: "applicant_name",
    },
    {
      title: "Name Of Expert",
      dataIndex: "name_of_expert",
      key: "name_of_expert",
    },
    {
      title: "Name And Signature Of Person",
      dataIndex: "name_and_signature_of_person",
      key: "name_and_signature_of_person",
    },
    {
      title: "Employee Number",
      dataIndex: "emp_no",
      key: "emp_no",
    },
    {
      title: "Application Date 1",
      dataIndex: "application_date_1",
      key: "application_date_1",
    },
    {
      title: "Appraisal Amount",
      dataIndex: "appraisal_amount",
      key: "appraisal_amount",
    },
    {
      title: "Identification Amount",
      dataIndex: "identification_amount",
      key: "identification_amount",
    },
    {
      title: "Company",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Background Review Applies",
      dataIndex: "background_review_applies",
      key: "background_review_applies",
    },
    {
      title: "Requested Amount",
      dataIndex: "requested_amount",
      key: "requested_amount",
    },
    {
      title: "Explanation",
      dataIndex: "explanation",
      key: "explanation",
    },
    { title: "Sale Status", dataIndex: "sale_status", key: "sale_status", action: (row) => (
      <p
      className="tagLayout"
      style={{
        border:
        row?.sale_status == 0? "2px solid #0A8A50": 
        row?.sale_status == 1? "2px solid #E63E4B":
        row?.sale_status == 2? "2px solid #4ca5fb":'2px solid #FFB84C',
        color: row?.sale_status == 0? "#0A8A50": 
        row?.sale_status == 1? "#E63E4B":
        row?.sale_status == 2? "#4ca5fb":'#FFB84C'
      }}
      >
        {row.sale_status!==null?saleitemList.filter((item)=>item.value==row.sale_status).map((item)=>item.label):"Incompleted"} 
      </p>
    ), },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      action: (row) => (
        <p
          style={{
            color: row.status === "Completed" ? "green" : "blue",
            width: "100px",
          }}
        >
          {row.status}
        </p>
      ),
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      action: (row) => (
        <div className={Privilages && Privilages.url_role === "Agent" ? "property_chipset_agent" : "property_chipset"}>

          {row.status==="Completed" && Privilages && (Privilages.url_role === "SuperAdmin" ||  Privilages.url_role === "Admin")?<>
          <CustomChip
                text="Sale"
                bgColor="#228b22"
                color="white"
                onClick={()=>{
                  handleClickOpen(row.propertyId)
                }}
              />
          </>:<></>}
          {Privilages &&
          Privilages?.Tasks &&
          Privilages?.Tasks.some((item) => item === "View") ? (
            <CustomChip
              text="Task"
              bgColor="#573CFA"
              color="white"
              onClick={() => {
                navigate(
                  `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${
                    row.propertyId
                  }/property_task`
                );
              }}
            />
          ) : (
            <></>
          )}

          <CustomChip
            text="View"
            bgColor="#F8893C"
            color="white"
            onClick={() => {
              updateSelectedTab(0);
              const url =
                Privilages && Privilages.url_role === "Agent"
                  ? `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${
                      row.id
                    }/AgentProperty_View`
                  : `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/${
                      row.propertyId
                    }/update_properties?tk=${0}&view_edit=${true}`;
              navigate(url);
            }}
          />

          {Privilages &&
          Privilages?.Property &&
          Privilages?.Property.some((item) => item === "Delete") ? (
            <CustomChip
              loading={
                deletePropertyData.id === row.taskId
                  ? deletePropertyData.loading
                  : false
              }
              text="Delete"
              bgColor="#ED5967"
              color="white"
              onClick={() => handleDelete(row.propertyId)}
            />
          ) : null}
          {role === "Agent" ? (
            <>
              {/* <CustomChip
               disabled={JSON.parse(row.isApplied)}
                text="Apply"
                bgColor="#ED5967"
                color="white"
                onClick={() => {
                  navigate(
                    `/${LocalStorageServices.getUserRole()}/home/property/${
                      row.propertyId
                    }/apply?module=property`
                  );
                }}
              /> */}
            </>
          ) : (
            <></>
          )}
        </div>
      ),
    },
  ];

  const datas = () => {
    const result =
      AllProperties &&
      AllProperties.map((item, index) => {
        const newObj = {};
        for (const key in item) {
          // Remove prefixes pbd_, pd_, sl_, pcd_, plr_ from the keys
          const newKey = key.replace(/^(pbd_|pd_|pa_|sl_|pcd_|plr_|ps_)/, "");
          newObj[newKey] = item[key];
        }
        newObj.propertyId = item.pbd_id;
        newObj.type=item.ptl_type
        newObj.isApplied=item?.is_applied
        newObj.key = index + 1;
        return newObj;
      });
    return result;
  };
  const Privilages = useSelector((state) => state.userStore.Privilages);

  return (
    <div>
      <div className="Properties_page_header">
        <p className="page_title">Properties</p>
        {Privilages &&
        Privilages?.Property &&
        Privilages?.Property.some((item) => item === "Add") ? (
          <button
            className="add_Properties_button"
            onClick={() => {
              dispatch(updateSelectedTab(0));
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/add_properties`
              );
            }}
          >
            Add Properties
          </button>
        ) : null}
      </div>
      <div>
        <CustomSpin loading={tableLoading}>
          <CustomAdvanceTable
            toolbar={<PropertyPageSearchFilter />}
            columns={Columns}
            rows={datas()}
            defaultCheck={defaultColumnChecked}
            onChangeChecked={updateDefaultCheckedColumn}
            rowChecked={rowChecked}
            onchangeRowchecked={updateRowChecked}
          />
        </CustomSpin>
      </div>
      <CustomModal
            open={open}
            handleClose={handleClose}
            body={
              <SaleStatus
                id={selectedId}
                type="property"
                onClose={handleClose}
              />
            }
            title={'SALE'}
          />
    </div>
  );
}

export default PropertiesPage;
