import CustomTextField from "../../../../../../../../components/customTextField/CustomTextField";
import FormButton from "../../../../../../../../components/formButton/FormButton";
import {useDispatch} from 'react-redux'
import { createFinancialSupportBank,updateFinancialSupportBank } from "../../../../../../../../api/apiServices/financialSupportBankApi";
import "./financialFormStyle.css";
import CustomTextArea from "../../../../../../../../components/customTextArea/CustomTextArea";
import { useFormik } from "formik";
import ResponseHandler from "../../../../../../../../services/ResponseHandler";
import * as Yup from "yup";

import { useEffect, useState } from "react";

function FinancialForm({onClose,existingvalue}) {
    const dispatch=useDispatch()
  const [loading,setLoading]=useState(false)
  useEffect(()=>{
      if(existingvalue){
        formik.setValues({
          ...formik.values,
          ['name']:existingvalue.name,
          ['contact_number']:existingvalue.contact_number,
          ['email_id']:existingvalue.email_id,
          ['address']:existingvalue.address
        });
      }
  },[existingvalue])
  const formik = useFormik({
    initialValues: {
      name: "",
      contact_number:"",
      email_id:"",
      address:"",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Bank name is required"),
      contact_number: Yup.string().required("Contact Number is required"),
      email_id: Yup.string().required("Email Id is required"),
      address: Yup.string().required("Address is required"),
    }),
    onSubmit: (values) => {
      if(!existingvalue){
        setLoading(true)
        dispatch(createFinancialSupportBank(values)).then((response)=>{
          ResponseHandler(response)
          onClose(false)
        }).catch((error)=>{
          ResponseHandler(error)
        }).finally(()=>{
          setLoading(false)
        })
      }else{
        setLoading(true)
        dispatch(updateFinancialSupportBank(existingvalue.id,values)).then((response)=>{
          ResponseHandler(response)
          onClose(false)
        }).catch((error)=>{
          ResponseHandler(error)
        }).finally(()=>{
          setLoading(false)
        })
      }
      
    },
  });
  return (
    <div className="Financialform_mainContainer">
    <form onSubmit={formik.handleSubmit}>
    
      <p className="Financialform_field_title">Bank name</p>
      <CustomTextField
        placeholder={"Enter bank name"}
        name="name"
        autoComplete="off"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.name}
        error={formik.touched.name && Boolean(formik.errors.name)}
        helperText={formik.touched.name && formik.errors.name}
      />
      
      
  
      <p className="Financialform_field_title">Contract Number</p>
      <CustomTextField
        placeholder={"Enter Contract Number"}
        name="contact_number"
        autoComplete="off"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.contact_number}
        error={formik.touched.contact_number && Boolean(formik.errors.contact_number)}
        helperText={formik.touched.contact_number && formik.errors.contact_number}
      />
    

      <p className="Financialform_field_title">Email-Id</p>
      <CustomTextField
        placeholder={"Enter Email-Id"}
        name="email_id"
        autoComplete="off"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.email_id}
        error={formik.touched.email_id && Boolean(formik.errors.email_id)}
        helperText={formik.touched.email_id && formik.errors.email_id}
      />
     
     
      <p className="Financialform_field_title">Address </p>
      <CustomTextArea
        placeholder={"Enter Address"}
        maxRows={3}
        name="address"
        autoComplete="off"
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values.address}
        error={formik.touched.address && Boolean(formik.errors.address)}
        helperText={formik.touched.address && formik.errors.address}
      />

      <div className="Financialform_form_action">
        <FormButton
          type="submit"
          variant="contained"
          loading={loading}
          text={existingvalue?"Update":"Add"}
        />
        <FormButton variant="outlined" loading={false} text="Cancel" onClick={()=>{onClose(false)}} />
      </div>
    </form>
  </div>
  )
}

export default FinancialForm