import React from 'react'
import { Button, TextField } from "@mui/material";
import { useDispatch, useSelector } from 'react-redux';
import { filterContactUsData, resetFilterContactUsData, setFilterContactUsDatas } from '../../../../../../store/customerStore';
import './ContactUsStyle.css'
import { setRefresh } from '../../../../../../store/usersStore';
function ContactUsFilter() {
    const dispatch=useDispatch()
    const contactUsFilteredData=useSelector((state)=>state.customerStore.filterContactUsDetails)
   // ------------------------translation code--------------------------------------
  const translateText = async (text) => {
    const currentLang = localStorage.getItem("currentLanguage") || "en";
    if (currentLang === "es") {
      try {
        const response = await fetch(
          `https://api.mymemory.translated.net/get?q=${encodeURIComponent(text)}&langpair=es|en`
        );
        const data = await response.json();
        if(data.responseStatus==200){
            return data.responseData.translatedText;
        }else{
            return text; 
        }
       
      } catch (error) {
        console.error("Error translating text:", error);
        return text; // Fallback to original text if translation fails
      }
    }
    return text;
  };

    const UpdateFieldData = (event) => {
        const { name, value } = event.target;
        dispatch(setFilterContactUsDatas({ name, value }));
    };
    const SearchData =async () => {
        const currentLang = localStorage.getItem("currentLanguage") || "en";
        const isSpanish = currentLang === "es";
    
        if (isSpanish) {
          const translationPromises = Object.entries(contactUsFilteredData).map(([key, value]) => {
            return translateText(value).then((text) => {
              dispatch(setFilterContactUsDatas({ name: key, value: text }));
            });
          });
      
          await Promise.all(translationPromises);
        }
    
        dispatch(filterContactUsData());
        setTimeout(() => {
          dispatch(setRefresh());
        }, 500);
        // dispatch(filterContactUsData());
      };
    
      const ResetFilter = () => {
        // dispatch(resetFilterContactUsData());
        dispatch(resetFilterContactUsData());
        setTimeout(() => {
          dispatch(setRefresh());
        }, 500);
      };
  return (
    <div>
    <div className="contactUsFilter_input_action_container">
        <TextField
            name="name"
            value={contactUsFilteredData.name}
            fullWidth
            className="contactUsFilter_input"
            autoComplete="off"
            style={{ fontSize: "15px" }}
            size="small"
            placeholder={"Enter name"}
            onChange={UpdateFieldData}
            variant="outlined"
            inputProps={{
                style: {
                    padding: "3.5px 7px", // Add padding to input
                    fontSize: "15px",
                    fontWeight: 400,
                    fontFamily: "Inter",
                    borderRadius: "4px",
                },
            }}
            sx={{
                "& fieldset": { border: "none" },
            }}
        />
        <TextField
            name="email"
            value={contactUsFilteredData.email}
            fullWidth
            className="contactUsFilter_input"
            autoComplete="off"
            style={{ fontSize: "15px" }}
            size="small"
            placeholder={"Enter email"}
            onChange={UpdateFieldData}
            inputProps={{
                style: {
                    padding: "3.5px 7px", // Add padding to input
                    fontSize: "15px",
                    fontWeight: 400,
                    fontFamily: "Inter",
                    borderRadius: "4px",
                },
            }}
            sx={{
                "& fieldset": { border: "none" },
            }}
        />
        <TextField
            name="contactNumber"
            value={contactUsFilteredData.contactNumber}
            fullWidth
            className="contactUsFilter_input"
            autoComplete="off"
            style={{ fontSize: "15px" }}
            size="small"
            placeholder={"Enter contact number"}
            onChange={UpdateFieldData}
            inputProps={{
                style: {
                    padding: "3.5px 7px", // Add padding to input
                    fontSize: "15px",
                    fontWeight: 400,
                    fontFamily: "Inter",
                    borderRadius: "4px",
                },
            }}
            sx={{
                "& fieldset": { border: "none" },
            }}
        />
        <div className="contactUsFilter_action">
            <Button
                className="users_Search_button"
                onClick={SearchData}
                variant="contained"
                size="small"
                style={{
                    textTransform: "none",
                    textTransform: "none",
                    fontFamily: "Inter",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    padding: "8px 15px",
                    backgroundColor: "#0f62fe",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer"
                }}
            >
                {"Search"}
            </Button>
            <Button
                onClick={ResetFilter}
                className="users_Reset_button"
                variant="outlined"
                size="small"
                style={{
                    textTransform: "none",
                    cursor: 'pointer',
                    fontFamily: 'inter',
                    border: '1px solid #005eb8',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    padding: '7px 25px',
                    color: '#0f62fe',
                    fontSize: '14px'
                }}

            >
                {"Reset"}
            </Button>
        </div>
    </div>
</div>
  )
}

export default ContactUsFilter