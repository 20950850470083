import React from 'react'

function RegisteredBidder() {
    return (
        <div>
            <p>Page Under Development</p>
        </div>
    )
}

export default RegisteredBidder
