import React from 'react'
import './DummyModal.css'
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
function DummyModal({ open, onClose, children }) {
    const closeModal = () => {
        onClose && onClose();
      };
      
      return (
        <div className={`custom-modal ${open ? 'open' : ''}`}>
          <div className="modal-overlay" onClick={closeModal}></div>
          <div className="modal-content">
            <div className='modal_cancel_icon' onClick={closeModal}>
                <CancelOutlinedIcon/>
            </div>
            {children}
          </div>
        </div>
      );
}

export default DummyModal