import React, { useEffect, useState } from "react";
import "./AgentForm.css";
import { Button, TextField, Autocomplete, Paper } from "@mui/material";
import FormButton from "../../../../../components/formButton/FormButton";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { GetAllRoles } from "../../../../../api/apiServices/roleApis";
import { setRolesDropDownData } from "../../../../../store/usersStore";
import { AgentInterestDropDown } from "./agentFormDropdown";
import { getUserStatus } from "../../../../../api/apiServices/userApis";
import { useNavigate, useParams } from "react-router-dom";
import CustomTextField from "../../../../../components/customTextField/CustomTextField";
import CustomAutoComplete from "../../../../../components/customAutoComplete/CustomAutoComplete";
import CustomTextArea from "../../../../../components/customTextArea/CustomTextArea";
import {
  createNewAgent,
  getAgentById,
  updateAgentDetails,
} from "../../../../../api/apiServices/agentApi";
import ResponseHandler from "../../../../../services/ResponseHandler";
import { RiFileEditFill } from "react-icons/ri";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
function AgentForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedRole, SetselectedRole] = useState(null);
  const [userStatus, SetUserStatus] = useState([]);
  const [isEdit, SetIsEdit] = useState(false);
  let { agent_id } = useParams();
  useEffect(() => {
    getAllUserStatus();
    if (agent_id) {
      getAgentDetailsById(agent_id);
    } else {
      getAllRoles();
    }
  }, [agent_id]);

  function getAllRoles() {
    dispatch(GetAllRoles()).then((Roleresponse) => {
      if (Roleresponse) {
        const filterRole = Roleresponse.filter(
          (item) => item.url_role === "Agent"
        );
        if (filterRole.length > 0) {
          formik.setValues({
            ...formik.values,
            ["role"]: filterRole[0].url_role,
          });
          SetselectedRole(filterRole[0]);
        }
      }
    });
  }

  function getAllUserStatus() {
    dispatch(getUserStatus())
      .then((response) => {
        const userStatusDropDown = response.data.map((item) => ({
          value: item.usl_id,
          label: item.usl_status,
        }));
        SetUserStatus(userStatusDropDown);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  function getAgentDetailsById(agent_id) {
    dispatch(getAgentById(agent_id))
      .then((response) => {
        const data = response.data.data[0];
        formik.setValues({
          ...formik.values,
          ["name"]: data.ud_name,
          ["phone_no"]: data.ud_phone_no,
          ["email"]: data.up_email,
          ["interests"]: data.ad_interest,
          ["location"]: data.ad_location,
          ["address"]: data.ud_address,
          ["role"]: data.url_role,
          ["status"]: data.usl_id,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  let formik = useFormik({
    initialValues: {
      name: "",
      phone_no: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: selectedRole ? selectedRole.url_role : null,
      location: "",
      address: "",
      paramId: agent_id || false,
      interests: null,
      status: null,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      phone_no: Yup.string().required("Phone Number is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string().when("paramId", {
        is: (paramId) => !paramId,
        then: (schema) =>
          schema
            .required("Password is required")
            .min(6, "Password must be at least 6 characters"),
        otherwise: (schema) => schema,
      }),
      confirmPassword: Yup.string().when("paramId", {
        is: (paramId) => !paramId,
        then: (schema) =>
          schema
            .required("Confirm Password is required")
            .oneOf([Yup.ref("password"), null], "Passwords must match"),
        otherwise: (schema) => schema,
      }),
      role: Yup.string().required("Role is required"),
      interests: Yup.string().nullable().required("Interests is required"),
      status: Yup.string().nullable().required("Status is required"),
    }),
    onSubmit: (values) => {
      const formValues = JSON.parse(JSON.stringify(values));
      if (agent_id) {
        dispatch(updateAgentDetails(agent_id, formValues))
          .then((response) => {
            ResponseHandler(response);
            navigate(-1);
          })
          .catch((error) => {
            ResponseHandler(error);
          });
      } else {
        formValues.role = selectedRole.url_id;
        dispatch(createNewAgent(formValues))
          .then((response) => {
            ResponseHandler(response);
            navigate(-1);
          })
          .catch((error) => {
            ResponseHandler(error);
          });
      }
    },
  });

  return (
    <div>
      <div className="agent_form_header">
        <p className="page_title"> {agent_id ? "Update Agent" : "Add Agent"}</p>
        <div
          className="back_button"
          onClick={() => {
            navigate(-1);
          }}
        >
          <KeyboardBackspaceIcon />
          <p>back</p>
        </div>
      </div>

      <div className="agent_form_main_Container">
        {agent_id && !isEdit && (
          <div
            className="agent_form_edit_details"
            onClick={() => {
              SetIsEdit(true);
            }}
          >
            <RiFileEditFill />
            <p>Edit</p>
          </div>
        )}
        <div className="agent_formContainer">
          <p className="agent_form_legend">
            {agent_id ? "Agent Details" : "Add Details"}
          </p>
          <form id="agentForm" onSubmit={formik.handleSubmit}>
            <div className="agent_form_row">
              <div>
                <p className="agent_form_label">Name</p>
                <CustomTextField
                  disabled={agent_id && !isEdit}
                  name="name"
                  autoComplete="off"
                  className="agent_form_input"
                  placeholder={"Enter name"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </div>
              <div>
                <label className="agent_form_label">Phone Number</label>
                <CustomTextField
                  disabled={agent_id && !isEdit}
                  name="phone_no"
                  autoComplete="off"
                  className="agent_form_input"
                  placeholder={"Enter phone number"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone_no}
                  error={
                    formik.touched.phone_no && Boolean(formik.errors.phone_no)
                  }
                  helperText={formik.touched.phone_no && formik.errors.phone_no}
                />
              </div>
            </div>
            <div className="agent_form_row">
              <div>
                <label className="agent_form_label">Email Address</label>
                <CustomTextField
                  disabled={agent_id ? true : false}
                  name="email"
                  autoComplete="off"
                  className="agent_form_input"
                  placeholder={"Enter email address"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </div>
              {!agent_id ? (
                <div>
                  <label className="agent_form_label">Password</label>
                  <CustomTextField
                    name="password"
                    autoComplete="off"
                    className="agent_form_input"
                    type="password"
                    placeholder={"Enter password"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                  />
                </div>
              ) : (
                <div>
                  <p className="agent_form_label">Role</p>
                  <CustomTextField
                    name="role"
                    disabled={true}
                    fullWidth
                    autoComplete="off"
                    className="agent_form_input"
                    placeholder={"Enter role"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.role}
                    error={formik.touched.role && Boolean(formik.errors.role)}
                    helperText={formik.touched.role && formik.errors.role}
                  />
                </div>
              )}
            </div>
            {!agent_id && (
              <div className="agent_form_row">
                <div>
                  <label className="agent_form_label">Confirm Password</label>
                  <CustomTextField
                    name="confirmPassword"
                    autoComplete="off"
                    className="agent_form_input"
                    type="password"
                    placeholder={"Confirm password"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.confirmPassword}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                  />
                </div>
                <div>
                  <p className="agent_form_label">Role</p>
                  <CustomTextField
                    name="role"
                    disabled={true}
                    fullWidth
                    autoComplete="off"
                    className="agent_form_input"
                    placeholder={"Enter role"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.role}
                    error={formik.touched.role && Boolean(formik.errors.role)}
                    helperText={formik.touched.role && formik.errors.role}
                  />
                </div>
              </div>
            )}
            <div className="agent_form_row">
              <div>
                <label className="agent_form_label">Interests</label>
                <CustomAutoComplete
                  disabled={agent_id && !isEdit}
                  name="interests"
                  options={AgentInterestDropDown}
                  getOptionLabel={(option) => option.label}
                  className="agent_form_autoinput"
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      "interests",
                      newValue ? newValue.value : ""
                    );
                  }}
                  onBlur={formik.handleBlur}
                  value={
                    AgentInterestDropDown.find(
                      (interests) => interests.value === formik.values.interests
                    ) || null
                  }
                  placeholder="Select agent interest"
                  error={
                    formik.touched.interests && Boolean(formik.errors.interests)
                  }
                  helperText={
                    formik.touched.interests && formik.errors.interests
                  }
                />
              </div>
              <div>
                <label className="agent_form_label">Status</label>
                <CustomAutoComplete
                  disabled={agent_id && !isEdit}
                  name="status"
                  options={userStatus}
                  getOptionLabel={(option) => option.label}
                  className="agent_form_autoinput"
                  onChange={(event, newValue) => {
                    formik.setFieldValue(
                      "status",
                      newValue ? newValue.value : ""
                    );
                  }}
                  onBlur={formik.handleBlur}
                  value={
                    userStatus.find(
                      (status) => status.value === formik.values.status
                    ) || null
                  }
                  placeholder="Select status"
                  error={formik.touched.status && Boolean(formik.errors.status)}
                  helperText={formik.touched.status && formik.errors.status}
                />
              </div>
            </div>
            <div className="agent_form_row">
              <div>
                <label className="agent_form_label">Location</label>
                <CustomTextField
                  disabled={agent_id && !isEdit}
                  name="location"
                  className="agent_form_multinput"
                  placeholder={"Enter location"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.location}
                />
              </div>
              <div>
                <label className="agent_form_label">Address</label>
                <CustomTextArea
                  disabled={agent_id && !isEdit}
                  name="address"
                  className="agent_form_multinput"
                  maxRows={3}
                  placeholder={"Enter address"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address}
                />
              </div>
            </div>
          </form>
        </div>
        {(!agent_id || isEdit) && (
          <div className="agent_form_action_button">
            <FormButton
              id="userCreateSubmit"
              type="submit"
              form="agentForm"
              variant="contained"
              text={agent_id ? "Update" : "Save"}
            />
            <FormButton
              id="userCreateCancel"
              variant="outlined"
              loading={false}
              text="Cancel"
              onClick={() => navigate(-1)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default AgentForm;
