import { createSlice } from "@reduxjs/toolkit";
import { LocalStorageServices } from "../services/localStorageServices";

export const userSlice=createSlice({
    name:"user",
    initialState:{
        userTableLoading:false,
        Users:[],
        WholeUsers:[],
        RolesDropDownData:[],
        filterUserDetails:{
            name:"",
            email:"",
            role:""
        },
        Privilages:null,
        Refresh:false,
    },
    reducers:{
        setPrivilages:(state,action)=>{
            state.Privilages=action.payload
        },
        setRolesDropDownData:(state,actions)=>{
            const RestrutureData=actions.payload.map((item)=>{
                return {
                    label:item.url_role,
                    value:item.url_id
                }
            })
            state.RolesDropDownData=RestrutureData
        },

        setAllUsers:(state,actions)=>{
           const data = LocalStorageServices.getLocalStorageData()
           if(data){
             let parseLocalData=JSON.parse(data)
             const RemovedCurrentUser=actions.payload.filter((item)=>item.up_email !==parseLocalData.up_email)
             state.Users=RemovedCurrentUser
              state.WholeUsers=RemovedCurrentUser
           }
        },
        updateUserTableLoading:(state,actions)=>{
            state.userTableLoading=actions.payload
        },
        setFilterDatas:(state,actions)=>{
            const {name,value}=actions.payload
            state.filterUserDetails[name]=value
        },
        filterData:(state)=>{
            let FilterRoles=[]
            if(state.filterUserDetails.name.trim()){
                FilterRoles=state.WholeUsers.filter((item)=>item.ud_name.toLowerCase().includes(state.filterUserDetails.name.toLocaleLowerCase()))
            }else{
                FilterRoles=state.WholeUsers
            }
            if(state.filterUserDetails.email.trim()){
                if(FilterRoles.length>0){
                    FilterRoles=FilterRoles.filter((item)=>item.up_email.toLowerCase().includes(state.filterUserDetails.email.toLocaleLowerCase()))
                }else{
                    FilterRoles=state.WholeUsers.filter((item)=>item.up_email.toLowerCase().includes(state.filterUserDetails.email.toLocaleLowerCase()))
                }
            }else{
                if(FilterRoles.length===0){
                    FilterRoles=state.WholeUsers
                }
            }

            if(state.filterUserDetails.role.trim()){
                if(FilterRoles.length>0){
                    FilterRoles=FilterRoles.filter((item)=>item.url_role.toLowerCase().includes(state.filterUserDetails.role.toLocaleLowerCase()))
                }else{
                    FilterRoles=state.WholeUsers.filter((item)=>item.url_role.toLowerCase().includes(state.filterUserDetails.role.toLocaleLowerCase()))
                }
            }
            state.Users=FilterRoles
        },
        resetFilterData:(state)=>{
            state.Users=state.WholeUsers
            state.filterUserDetails={name:"",email:"",role:""}
        },
        ResetUserData:(state)=>{
            state.userTableLoading=false
            state.Users=[]
            state.WholeUsers=[]
            state.RolesDropDownData=[]
            state.filterUserDetails={
                name:"",
                email:"",
                role:""
            }
        },
        setRefresh:(state)=>{
            state.Refresh=!state.Refresh
        }
        
    }
})

export const {setRolesDropDownData,setAllUsers,updateUserTableLoading,setFilterDatas,filterData,resetFilterData,ResetUserData,setPrivilages,setRefresh} = userSlice.actions

export default userSlice.reducer