import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FormButton from "../../../../../../components/formButton/FormButton";
import { useDispatch } from "react-redux";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import CustomTextArea from "../../../../../../components/customTextArea/CustomTextArea";
import { updateVehicleTaskNotesByRunner } from "../../../../../../api/apiServices/vehicleApi";
import "./AddNotefrom.css"
import { createVehicleTaskNotesByRunner } from "../../../../../../api/apiServices/vehicleApi";
function AddNoteform({data, onAddNote, onClose, onUpdateNote }) {

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      note: data?.notes || "",
    },
    validationSchema: Yup.object({
      note: Yup.string().required("Note is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      const apiCall = data?.rvtn_id
        ? updateVehicleTaskNotesByRunner
        : createVehicleTaskNotesByRunner;
      
      const apiArgs = data?.rvtn_id
        ? [data.rvtn_id, values]
        : [data.vt_vehicle_id, data.vehicle_task_id, values];
      dispatch(apiCall(...apiArgs))
        .then((response) => {
          ResponseHandler(response);
          if (data?.rvtn_id) {
            onUpdateNote && onUpdateNote(values.note);
          } else {
            onAddNote && onAddNote(values.note);
          }
          onClose && onClose(false);
        })
        .catch((error) => {
          ResponseHandler(error);
        })
        .finally(() => {
          setLoading(false);// Reset the form submission state
        });
    },
    
    
  });

  useEffect(() => {
    formik.setValues({
      note: data?.notes || "",
    });
  }, [data]);

  return (
    <div className="note_Form">
      <form onSubmit={formik.handleSubmit}>
        <p className="role_form_label">Note</p>
        <CustomTextArea
          name="note"
          maxRows={3}
          className="note_form_input"
          autoComplete="off"
          value={formik.values.note}
          size="small"
          placeholder="Enter Note"
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.note && Boolean(formik.errors.note)}
          helperText={formik.touched.note && formik.errors.note}
          FormHelperTextProps={{
            style: { marginLeft: 0 },
          }}
        />
        <div className="note_form_action">
          <FormButton
            id="roleCreateForm"
            type="submit"
            variant="contained"
            loading={loading}
            text={data?.rvtn_id ? "Update" : "Add"} 
          />
          <FormButton
            id="roleCreateForm"
            variant="outlined"
            loading={false}
            text="Cancel"
            onClick={() => onClose && onClose(false)}
          />
        </div>
      </form>
    </div>
  );
}

export default AddNoteform;
