import { useState, useEffect } from 'react';

const usePagination = (defaultPage, tableId) => {
  const [currentPage, setCurrentPage] = useState(() => {
    return parseInt(localStorage.getItem(`currentPage_${tableId}`)) || defaultPage;
  });
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    localStorage.setItem(`currentPage_${tableId}`, currentPage);
  }, [currentPage, tableId]);

  return [currentPage, setCurrentPage, pageCount, setPageCount];
};

export default usePagination;
