import { Button, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import "./UsersFilter.css";
import IconButton from "@mui/material/IconButton";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  filterData,
  resetFilterData,
  setFilterDatas,
  updateFilterData,
} from "../../../../../../../store/usersStore";
import { setRefresh } from "../../../../../../../store/usersStore";

function UsersFilter() {
  const dispatch = useDispatch();
  const filterFieldData = useSelector(
    (item) => item.userStore.filterUserDetails
  );

  function UpdateFieldVisibility(id) {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = element.style.display === "none" || element.style.display === "" ? "block" : "";
    }
  }

  const UpdateFieldData = (event) => {
    const { name, value } = event.target;
    dispatch(setFilterDatas({ name, value }));
  };

  const [filterOpen, setFilterOpen] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      const icon = document.querySelector(".user_filter_Icon");
      const container = document.querySelector(".user_filter_field_container");
      const sort = document.querySelector(".user_filter_input_action_container");
      const autocompletePopper = document.querySelector(".MuiAutocomplete-popper");
      const searchButton = document.querySelector(".user_filter_input");
      if (
        !container?.contains(event.target) &&
        !icon?.contains(event.target) &&
        !sort?.contains(event.target) &&
        !autocompletePopper?.contains(event.target) &&
        !searchButton?.contains(event.target)
      ) {
        setFilterOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
// ------------------------translation code--------------------------------------
  const translateText = async (text) => {
    const currentLang = localStorage.getItem("currentLanguage") || "en";
    console.log("Translating text:", text, "to language:", currentLang); 
    if (currentLang === "es") {
      try {
        const response = await fetch(
          `https://api.mymemory.translated.net/get?q=${encodeURIComponent(text)}&langpair=es|en`
        );
        const data = await response.json();
        return data.responseData.translatedText;
      } catch (error) {
        console.error("Error translating text:", error);
        return text; // Fallback to original text if translation fails
      }
    }
    return text;
  };

  const SearchData = async () => {
    try {
      console.log("Filter data before translation:", filterFieldData); // Debug log
  
      const translatedData = {
        name: await translateText(filterFieldData.name),
        email: await translateText(filterFieldData.email),
        role: await translateText(filterFieldData.role),
      };
  
      console.log("Translated data:", translatedData); // Debug log
  
      dispatch(filterData(translatedData));
    } catch (error) {
      console.error("Error processing search data:", error);
      // Optionally handle the error or dispatch default data
    }
  };
  
  const ResetFilter = () => {
    dispatch(resetFilterData());
    setTimeout(()=>{
      dispatch(setRefresh())
    },500)
    dispatch(setRefresh())
  };

  return (
    <div>
      <div className="user_filter_Icon">
        <IconButton
          color="primary"
          onClick={() => {
            UpdateFieldVisibility("user_filter_field_container");
            setFilterOpen(!filterOpen);
          }}
        >
          <FilterAltOutlinedIcon />
        </IconButton>
      </div>
      <div
        id="user_filter_field_container"
        className="user_filter_field_container"
        style={{ display: filterOpen ? "block" : "" }}
      >
        <div className="user_filter_input_action_container">
          <TextField
            name="name"
            value={filterFieldData.name}
            fullWidth
            className="user_filter_input"
            autoComplete="off"
            style={{ fontSize: "15px" }}
            size="small"
            placeholder={"Enter name"}
            onChange={UpdateFieldData}
            variant="outlined"
            inputProps={{
              style: {
                padding: "3.5px 7px", // Add padding to input
                fontSize: "15px",
                fontWeight: 400,
                fontFamily: "Inter",
                borderRadius: "4px",
              },
            }}
            sx={{
              "& fieldset": { border: "none" },
            }}
          />
          <TextField
            name="email"
            value={filterFieldData.email}
            fullWidth
            className="user_filter_input"
            autoComplete="off"
            style={{ fontSize: "15px" }}
            size="small"
            placeholder={"Enter email"}
            onChange={UpdateFieldData}
            inputProps={{
              style: {
                padding: "3.5px 7px", // Add padding to input
                fontSize: "15px",
                fontWeight: 400,
                fontFamily: "Inter",
                borderRadius: "4px",
              },
            }}
            sx={{
              "& fieldset": { border: "none" },
            }}
          />
          <TextField
            name="role"
            value={filterFieldData.role}
            fullWidth
            className="user_filter_input"
            autoComplete="off"
            style={{ fontSize: "15px" }}
            size="small"
            placeholder={"Enter Role"}
            onChange={UpdateFieldData}
            inputProps={{
              style: {
                padding: "3.5px 7px", // Add padding to input
                fontSize: "15px",
                fontWeight: 400,
                fontFamily: "Inter",
                borderRadius: "4px",
              },
            }}
            sx={{
              "& fieldset": { border: "none" },
            }}
          />
          <div className="user_filter_action">
            <Button
              className="users_Search_button"
              onClick={SearchData}
              variant="contained"
              size="small"
              style={{
                textTransform: "none",
                fontFamily: "Inter",
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                padding: "8px 15px",
                backgroundColor: "#0f62fe",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer"
              }}
            >
              {"Search"}
            </Button>
            <Button
              onClick={ResetFilter}
              className="users_Reset_button"
              variant="outlined"
              size="small"
              style={{
                textTransform: "none",
                cursor: 'pointer',
                fontFamily: 'Inter',
                border: '1px solid #005eb8',
                backgroundColor: 'white',
                borderRadius: '5px',
                padding: '7px 25px',
                color: '#0f62fe',
                fontSize: '14px'
              }}
            >
              {"Reset"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsersFilter;
