import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
function CustomModal({open,handleClose,title,close,body,footer}) {


  return (
    <div>
        <Dialog
    
        open={open}
        onClose={()=>{handleClose(false)}}
      >
        <DialogTitle fontSize={18} fontWeight={'bold'} fontFamily={"Inter"}>
          {title}
        </DialogTitle>
         {close}
        <DialogContent>
           {body}
        </DialogContent>
        {/* <DialogActions>
          {footer}
        </DialogActions> */}
      </Dialog>
    </div>
  )
}

export default CustomModal