import React from 'react'
import CustomModal from '../../../../../components/customModal/CustomModal'
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import './TermsAndConditions.css'
function TermsAndConditions({open,handleClose}) {
    
  return (
    <div>
         <CustomModal
           fullWidth={true}
           maxWidth={"xl"}
            open={open}  
            handleClose={()=>{handleClose(false)}} 
            title={'Terms and Conditions'} 
            close={ <IconButton
                aria-label="close"
                onClick={()=>{handleClose(false)}}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              > <CloseIcon />
              </IconButton>}
             body={
             <div>
                <div className='condition_header_container'>
                    <p className='condition_title'>DIRECT PURCHASE OFFER-SALE CONTRACT</p>
                    <p className='condition_sub_title'>Conditions governing this sale</p>
                </div>
                <div>
                    <div className='terms_and_conditions_text'>
                        <p>1. </p>
                        <p>For the type of sale by competition, the offeror undertakes to submit only one offer for this good, and if two or more appear in his name, only the one with the highest value will be considered.</p>
                    </div>
                    <div className='terms_and_conditions_text'>
                        <p>2. </p>
                        <p>Once the deadline for receiving offers has expired, no modification will be made to this offer, except for financing for a lower sum than that indicated in this offer.</p>
                    </div >
                    <div className='terms_and_conditions_text'>
                        <p>3. </p>
                        <p>The validity of this offer is 30 business days from the date of receipt.</p>
                    </div>
                    <div className='terms_and_conditions_text'>
                        <p>4. </p>
                        <p>The Bank reserves the right to award all or part of the offers that it considers most convenient to its interests, or to reject all of them if it deems necessary.</p>
                    </div>
                    <div className='terms_and_conditions_text'>
                        <p>5. </p>
                        <p>The buyer accepts the goods object of this sale in the material and legal conditions in which they are found, waiving any subsequent claim. Transfer expenses and fees for assets registered in the public registry will be borne by the buyer. If the purchase is paid in cash, the buyer may register the property before the notary of his choice.</p>
                    </div>
                    <div className='terms_and_conditions_text'>
                        <p>6. </p>
                        <p>The real estate must be received in the state and condition in which it is found, and the offeror must previously inspect the property for this purpose. The Bank does not guarantee the measurements of the property. Once the corresponding deed of sale has been signed, the buyer exonerates the Bank from any liability that may be attributed to it in this regard.</p>
                    </div>
                    <div className='terms_and_conditions_text'>
                        <p>7. </p>
                        <p>The movable property must be received in the state and condition in which it is found, exonerating the Bank from any responsibility that may be attributed to it once removed from the Bank's warehouses, which must be done within a period of no more than 3 business days after the pay. If they are not removed on time, the Bank is exempt from any responsibility regarding the state and conservation of the assets and will not particularly be liable for any deterioration suffered by them.</p>
                    </div>
                    <div className='terms_and_conditions_text'>
                        <p>8. </p>
                        <p>The person who is awarded the property must deposit 5% of the amount offered as a sign of agreement within the next five business days of notification of the award (whether financed or cashed), in the event that the sale does not materialize due to reasons of winning bidder, the deal signal will be executed in favor of the bank and if it does not comply, it will be applied as damages for non-compliance. If the successful bidder does not appear within five business days following the notification of the award to deposit the deal deposit or the cash amount offered, said award will be void.</p>
                    </div>
                    <div className='terms_and_conditions_text'>
                        <p>9. </p>
                        <p>If the favored offer requests financing from an external financial entity, it will be given a maximum period of 60 calendar days to pay the price, and after this period the offer will be rejected, unless it is extended for an equal period by the of the Head of the Asset Management Office.</p>
                    </div>
                    <div className='terms_and_conditions_text'>
                        <p>10. </p>
                        <p>From the notice of approval of the offer, a period of 10 business days is granted to the buyer to deposit the cash amount offered and present the requirements requested in case of financing. The approval of said credit will be at the discretion of the Bank, in accordance with current policies and regulations, therefore, it cannot be taken as an obligation of the Bank.</p>
                    </div>
                    <div className='terms_and_conditions_text'>
                        <p>11. </p>
                        <p>I declare that I accept in all their aspects the conditions established by the Bank in this sale, and that I know the property for which I am bidding, as well as the documents of the property (catastrated plan, appraisal and registration study).</p>
                    </div>
                    <div className='terms_and_conditions_text'>
                        <p>12. </p>
                        <p>I declare that I know and accept that the calculation of the payment of transfer taxes, stamp duty and fees is calculated according to the tax value or the sale value of the property, whichever is higher.</p>
                    </div>
                    <div className='terms_and_conditions_text'>
                        <p>13. </p>
                        <p>I expressly authorize the Bank to proceed to debit any of my BCR brand accounts from any of my BCR brand accounts in the event that I am declared the winner of the property, any sum necessary to pay the corresponding expenses, complete the amount of the sale, or to satisfy the capital requirements of the investment plan.</p>
                    </div>
                    <div className='terms_and_conditions_text'>
                        <p>14. </p>
                        <p> I declare under oath that the limitations contained in article 1068 of the Civil Code do not apply to me, which indicates: They cannot buy directly, nor through an intermediary: 1). Public employees, brokers, experts, guardians, curators and other people who manage other people's property, the things in whose sale they intervene as such employees, brokers, etc. 2). Lawyers and attorneys, those that are auctioned off from the executed person whom they defend. 3). The Judges before whom the lawsuit is pending or should be pending, as well as the employees of the Court and the lawyers and attorneys who intervene in the litigation, the rights or physical things in dispute. The prohibition of this article includes not only the aforementioned persons, but also
                           their consorts, ascendants, descendants and blood and related brothers.</p>
                    </div>
                </div>
             </div>
             }
         />
    </div>
  )
}

export default TermsAndConditions