import React, { useState } from "react";
import "./customAttachmentContainerV1Style.css";
import { Button, TextField } from "@mui/material";
import { FiUpload } from "react-icons/fi";
import CustomTextField from "../customTextField/CustomTextField";
import CancelIcon from "@mui/icons-material/Cancel";
import Pdf_Logo from "../../assets/Pdf_logo.png";
function CustomAttachmentContainerV1({
  Isview,
  attachments,
  uploadAttachments,
  deleteAttachments,
}) {
  const [seletedAttachment, SetSelectedAttachment] = useState({
    filetype: "",
    title: "",
    file: "",
    uploadedDateTime: "",
    isPublish:false
  });
  const [ValidText, SetValidText] = useState({
    IsNotValid: false,
    message: "",
  });
  const [validFile, SetValidFile] = useState({
    IsNotValid: false,
    message: "File is required",
  });
  function handleFileSelect(event) {
    const fileInput = event.target;
    const fileInfo = document.getElementById("CutomV1_fileInfo");
    if (fileInput.files.length > 0) {
      const fileName = fileInput.files[0].name;
      SetSelectedAttachment({
        ...seletedAttachment,
        ["file"]: fileInput.files[0],
        uploadedDateTime: new Date().toISOString(),
        filetype: fileInput.files[0].type.split("/")[1],
      });
      fileInfo.textContent = `Selected file: ${fileName}`;
    } else {
      fileInfo.textContent = "Choose file";
    }
    if (validFile.IsNotValid) {
      SetValidFile({ ...validFile, ["IsNotValid"]: false });
    }
  }
  function setAttachment(name, value) {
    SetSelectedAttachment({ ...seletedAttachment, [name]: value });
    if (ValidText.IsNotValid) {
      SetValidText({
        ...ValidText,
        ["IsNotValid"]: false,
        message: "Name is required",
      });
    }
  }
  function uploadFile() {
    const getExistingName = attachments.filter((item) =>
      seletedAttachment.title
        .trim()
        .toLowerCase()
        .includes(item.title.trim().toLowerCase())
    );
    if (getExistingName.length > 0) {
      SetValidText({
        ...ValidText,
        ["IsNotValid"]: true,
        message: "Name alreday exists",
      });
    } else if (
      !seletedAttachment.title.trim() &&
      seletedAttachment.file.toString().length <= 0
    ) {
      SetValidText({
        ...ValidText,
        ["IsNotValid"]: true,
        message: "Name is required",
      });
      SetValidFile({ ...validFile, ["IsNotValid"]: true });
    } else if (!seletedAttachment.title.trim()) {
      SetValidText({
        ...ValidText,
        ["IsNotValid"]: true,
        message: "Name is required",
      });
    } else if (seletedAttachment.file.toString().length <= 0) {
      SetValidFile({ ...validFile, ["IsNotValid"]: true });
    } else {
      uploadAttachments(seletedAttachment);
      SetSelectedAttachment({
        filetype: "",
        title: "",
        file: "",
        uploadedDateTime: "",
        isPublish:false
      });
      const fileInfo = document.getElementById("CutomV1_fileInfo");
      fileInfo.textContent = "Choose file";
    }
  }
  function calculateBase64FileSize(base64String) {
    const base64WithoutPrefix = base64String.replace(
      /^(data:[^;]+;base64,)/,
      ""
    );
    const sizeInBytes =
      Math.ceil((base64WithoutPrefix.length * 3) / 4) -
      base64WithoutPrefix.split("=")[1].length;

    if (sizeInBytes < 1024) {
      return sizeInBytes + "Bytes";
    } else if (sizeInBytes < 1024 * 1024) {
      return (sizeInBytes / 1024).toFixed(2) + "KB";
    } else {
      return (sizeInBytes / (1024 * 1024)).toFixed(2) + "MB";
    }
  }
  function DeleteAttachment(index) {
    return deleteAttachments(index);
  }
  return (
    <div className="CutomV1_Main-Div">
      {!Isview && (
        <div className="CutomV1_input_file_container">
          <div id="CutomV1_task-input-file">
            <label htmlFor="files" className="choose_file_field">
              <FiUpload className="CutomV1_file_upload_icon" />
              <span id="CutomV1_fileInfo"> Choose file</span>
            </label>

            <input
              id="files"
              style={{ display: "none" }}
              type="file"
              onChange={handleFileSelect}
            />
             {validFile.IsNotValid ? <p className="CutomV1_field_error">{validFile.message}</p> : <></>}
          </div>
          <div id="CutomV1_task-input-title">
            <CustomTextField
              value={seletedAttachment.title}
              placeholder={"Title"}
              onChange={(e) => setAttachment("title", e.target.value)}
            />
              {ValidText.IsNotValid ? <p className="CutomV1_field_error">{ValidText.message}</p> : <></>}
          </div>
          <div id="CutomV1_task-input-upload-btn">
            <Button
              size="small"
              variant="contained"
              onClick={uploadFile}
              style={{ textTransform: "none", lineHeight: 1.5 }}
            >
              Upload
            </Button>
          </div>
        </div>
      )}

      <div className="CutomV1_attachment_main_container_main" style={{ display: attachments?.length == 0 ? "none" : "flex" }}>
        <div
          className="CutomV1_attachment_main_container1"
          style={{ display: attachments?.length === 0 ? "none" : "flex"}}
        >
          {attachments &&
            attachments.map((item, index) => {
              return (
                <>
                  {item.filetype !== "pdf" && (
                    <div className="CutomV1_img-div-attachment">
                      <img
                        key={index}
                        src={
                          item.file.toString().startsWith("http://") ||
                          item.file.toString().startsWith("https://")
                            ? item.file
                            : URL.createObjectURL(item.file)
                        }
                        alt={`Uploaded Image ${index + 1}`}
                        className="CutomV1_image_attachment"
                      />
                      <p style={{ textAlign: "center" }}>{item.title}</p>
                      {!Isview && (
                        <div
                          className="CutomV1_attachment_close_icon"
                          onClick={() => {
                            DeleteAttachment(index);
                          }}
                        >
                          <CancelIcon className="CutomV1_attachemnt_cancelIcon" />
                        </div>
                      )}
                    </div>
                  )}
                </>
              );
            })}
        </div>
        <div
          className="CutomV1_attachment_main_container2"
          style={{ display: attachments?.length === 0 ? "none" : "flex" }}
        >
          {attachments &&
            attachments.map((item, index) => {
              return (
                <>
                  {item.filetype === "pdf" && (
                    <div className="CutomV1_file_attachment_container">
                      <div className="CutomV1_attachment_sub_container">
                        <img
                          key={index}
                          src={Pdf_Logo}
                          alt={`Uploaded Image ${index + 1}`}
                          className="CutomV1_file_attachment"
                        />
                        <div>
                          <p className="CutomV1_file_title">{item.title + ".pdf"}</p>
                          {/* <p className="file_size">{calculateBase64FileSize(item.file)}</p> */}
                        </div>
                      </div>
                      {!Isview && (
                        <div
                          className="CutomV1_attachment_close_icon"
                          onClick={() => {
                            DeleteAttachment(index);
                          }}
                        >
                          <CancelIcon className="CutomV1_attachemnt_cancelIcon" />
                        </div>
                      )}
                    </div>
                  )}
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default CustomAttachmentContainerV1;
