import React, { useEffect, useRef, useState } from "react";
import Map from "ol/Map.js";
import View from "ol/View.js";
import TileLayer from "ol/layer/Tile.js";
import OSM from "ol/source/OSM.js";
import Feature from "ol/Feature.js";
import Point from "ol/geom/Point.js";
import { Vector as VectorLayer } from "ol/layer.js";
import { Vector as VectorSource } from "ol/source.js";
import { Icon, Style } from "ol/style.js";
import Geolocation from "ol/Geolocation.js";
import "ol/ol.css"; 
import location_marker from "../../../../../../assets/LocationMarker.png";
import DummyModal from "./dummyModal/DummyModal";
import { fromLonLat ,toLonLat} from 'ol/proj'
function CustomMap({locationCordinates,open, handleClose,UpdateLocationCordinates }) {
  const mapTargetElement = useRef(null);
  // const [currentLocation, setCurrentLocation] = useState(null);
  let map, geolocation, locationLayer;

  const handleMapClick = (event) => {
    const coordinates = toLonLat(event.coordinate)
    UpdateLocationCordinates(coordinates)
    // setCurrentLocation(coordinates);
    const locationFeature = new Feature({
      geometry: new Point(fromLonLat(coordinates)),
    });

    locationLayer.getSource().clear();
    locationLayer.getSource().addFeature(locationFeature);
    
  };

  useEffect(() => {
    if (open) {
      map = new Map({
      target: 'map',
      layers: [new TileLayer({ source: new OSM() })],
      view:  new View({
        center: fromLonLat(locationCordinates),
        zoom: 10,
      }),
      });

      geolocation = new Geolocation({
        tracking: true,
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: map.getView().getProjection(),
      });

      locationLayer = new VectorLayer({
        source: new VectorSource(),
      });

      locationLayer.setStyle(
        new Style({
          image: new Icon({
            anchor: [0.5, 1],
            anchorXUnits: "fraction",
            anchorYUnits: "fraction",
            src: location_marker,
          }),
        })
      );

      map.addLayer(locationLayer);

      map.on("click", handleMapClick);

      if(locationCordinates){
        UpdateLocationCordinates(locationCordinates)
        // setCurrentLocation(locationCordinates);
        const locationFeature = new Feature({
          geometry: new Point(fromLonLat(locationCordinates)),
        });
        locationLayer.getSource().clear();
        locationLayer.getSource().addFeature(locationFeature);
      }
      // geolocation.on("change:position", (event) => {
      //   const coordinates = geolocation.getPosition();
      //   UpdateLocationCordinates(coordinates)
      //   console.log("Geolocation Position:", coordinates);
      //   setCurrentLocation(coordinates);

      //   if (coordinates) {
      //     const locationFeature = new Feature({
      //       geometry: new Point(coordinates),
      //     });

      //     locationLayer.getSource().clear();
      //     locationLayer.getSource().addFeature(locationFeature);
      //   }
      // });

      geolocation.on("error", (error) => {
        console.error("Geolocation Error:", error.message);
      });

      map.setTarget(mapTargetElement.current || "");
    }

    return () => {
      if (map) {
        map.un("click", handleMapClick);
        map.setTarget("");
      }

      if (geolocation) {
        geolocation.setTracking(false);
      }

      // setCurrentLocation(null);
    };
  }, [mapTargetElement, open]);

  return (
    <DummyModal open={open} onClose={handleClose}>
        <p>{locationCordinates ? `Current Location: ${locationCordinates}` : "No location selected"}</p>
        <div
            ref={mapTargetElement}
            className="map"
            style={{ width: "100%", height: "95%" }}
         />
    </DummyModal>
  );
}

export default CustomMap;
