import React, { useEffect, useState } from "react";
import {
  getAllPrivilages,
  getPrivilageByRoleId,
  updateRolePrivilage,
} from "../../../../../api/apiServices/privilagesApi";
import { useDispatch, useSelector } from "react-redux";
import "./PrivilagesPage.css";
import { useFormik, Field } from "formik";
import * as Yup from "yup";
import FormButton from "../../../../../components/formButton/FormButton";
import { GetAllRoles } from "../../../../../api/apiServices/roleApis";
import { setRolesDropDownData } from "../../../../../store/usersStore";
import CustomAutoComplete from "../../../../../components/customAutoComplete/CustomAutoComplete";
import ResponseHandler from "../../../../../services/ResponseHandler";
import { CropLandscapeOutlined } from "@mui/icons-material";
import { Autocomplete, Paper, TextField } from "@mui/material";
function PrivilagesPage() {
  const [Privilages, SetPrivilages] = useState(null);
  const RolesDropdown = useSelector((state) => state.userStore.RolesDropDownData);
  const [originalRoleName, setOriginalRoleName] = useState(null);
  const [translatedRoleName, setTranslatedRoleName] = useState('');
  const dispatch = useDispatch();
  const translateText = async (text) => {
    const currentLang = localStorage.getItem('currentLanguage') || 'en';
    if (currentLang === 'es') {
      try {
        const response = await fetch(
          `https://api.mymemory.translated.net/get?q=${encodeURIComponent(text)}&langpair=en|es`
        );
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        return data.responseData.translatedText;
      } catch (error) {
        console.error('Error translating text:', error);
        return text; // Fallback to original text if translation fails
      }
    }
    return text;
  };
  useEffect(() => {
    console.log(RolesDropdown)
    const updateTranslation = async () => {
      console.log("Translating text:", originalRoleName); // Log original role name
      try {
        const translatedRole = await translateText(originalRoleName);
        console.log("Translated text:", translatedRole); // Log translated text
        setTranslatedRoleName(translatedRole);
      } catch (error) {
        console.error('Error in updateTranslation:', error);
      }
    };
    if (originalRoleName) { // Only translate if there's a value
      updateTranslation();
    } else {
      setTranslatedRoleName(''); // Clear translation if input is empty
    }
  }, [originalRoleName]);

  useEffect(() => {
    dispatch(GetAllRoles()).then((Roleresponse) => {
      if (Roleresponse) {
        dispatch(
          setRolesDropDownData(
            Roleresponse.filter((item) => item.url_role !== "SuperAdmin")
          )
        );
      }
    });
    dispatch(getAllPrivilages()).then((response) => {
      let reversedData = {};
      response.forEach((privilege) => {
        let moduleArray = privilege.p_module
          .substring(1, privilege.p_module.length - 1)
          .split(",");
        moduleArray.forEach((module) => {
          if (!reversedData[module]) {
            reversedData[module] = [];
          }
          reversedData[module].push({
            p_id: privilege.p_id,
            p_privilage: privilege.p_privilage,
          });
        });
      });
      SetPrivilages(reversedData);
    });
  }, []);
  const initialValues = {
    role: null,
    User: null,
    Role: null,
    Property: null,
    Property_Type: null,
    Vehicle: null,
    Vehicle_Type: null,
    Tasks: null,
    Agent: null,
    Bidder: null,
    Privilage: null,
    Report: null,
    Message: null,
    Setting: null,
  };
  const inputStyles = {
    "& .MuiOutlinedInput-root": {
      padding: "3px 8px !important",
      fontFamily: "Inter",
      fontSize: 15,
      "& fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color
      },
      "&:hover fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color when focused
      },
      "& .MuiAutocomplete-input": {
        padding: "7.5px 4px 7.5px 9px !important",
      },
    },
  };
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      role: Yup.string().required("Role is required"),
    }),
    onSubmit: (values) => {
      dispatch(updateRolePrivilage(values.role, JSON.stringify(values)))
        .then((response) => {
          ResponseHandler(response);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    },
  });
  function setFormValue(roleId) {
    if (roleId) {
      dispatch(getPrivilageByRoleId(roleId))
        .then((response) => {
          if (response.mrap_privilages) {
            let ParsePrivilage = JSON.parse(response.mrap_privilages);
            formik.setValues({
              ...formik.values,
              ...ParsePrivilage,
            });
          } else {
            const resetFormData = {
              role: roleId,
              User: null,
              Role: null,
              Property: null,
              Property_Type: null,
              Vehicle: null,
              Vehicle_Type: null,
              Tasks: null,
              Agent: null,
              Bidder: null,
              Privilage: null,
              Report: null,
              Message: null,
              Setting: null,
            };
            formik.setValues({
              ...formik.values,
              ...resetFormData,
            });
          }
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    } else {
      formik.setValues({
        ...formik.values,
        ...initialValues,
      });
    }
  }
  const setSelectedRole=()=>{
    console.log(RolesDropdown.find((role) => role.value === formik.values.role))
   return RolesDropdown.find((role) => role.value === formik.values.role) || null
  }
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="Role_tool_field_width" style={{ width: "100%" }}>
        <p className="role_form_label">Role</p>
        <Autocomplete
          name="role"
          placeholder="Select role"
          options={RolesDropdown}
          getOptionLabel={(option) => option.label}
          sx={{ ...inputStyles }}
          size="small"
          onChange={(event, newValue) => {
            formik.setFieldValue("role", newValue ? newValue.value : "");
            setFormValue(newValue ? newValue.value : null);
          }}
          onBlur={formik.handleBlur}
          value={setSelectedRole()}
          PaperComponent={(props) => (
            <Paper
              {...props}
              style={{
                ...props.style,
                fontFamily: "Inter",
                fontSize: "15px",
                margin: "0px",
              }}
            />
          )}
          isOptionEqualToValue={(option, value) =>
            option.value.toString() === value.value.toString()
          }
          error={formik.touched.role && Boolean(formik.errors.role)}
          helperText={formik.touched.role && formik.errors.role}
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              placeholder="Role"
              variant="outlined"
            />
          )}
          style={{
            fontFamily: "Inter",
            fontSize: "15px",
            width: "100%",
          }}
        />
      </div>
      <div className="privilage_sub_container">
        {Privilages &&
          Object.entries(Privilages).map(([module, modulePrivilages]) => (
            <div key={module}>
              <p className="privilage_header">
                {module.replace("_", " ")} Management
              </p>
              <div className="privilage_section_container">
                {modulePrivilages &&
                  modulePrivilages?.map((item) => (
                    <div className="checkbox_with_text" key={item.p_privilage}>
                      <input
                        type="checkbox"
                        name={module}
                        value={item.p_id}
                        checked={
                          formik.values[module] &&
                          formik.values[module].includes(item.p_id)
                        }
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          const id = parseInt(e.target.value);
                          let currentValues = formik.values[module]
                            ? formik.values[module]
                            : [];
                          if (isChecked) {
                            formik.setFieldValue(module, [
                              ...currentValues,
                              id,
                            ]);
                          } else {
                            formik.setFieldValue(
                              module,
                              currentValues.filter((value) => value !== id)
                            );
                          }
                        }}
                      />
                      <label htmlFor={item.p_id}>
                        {item.p_privilage} {module.replace("_", " ")}
                      </label>
                    </div>
                  ))}
              </div>
            </div>
          ))}
      </div>
      <div className="privilage_form_actions">
        <FormButton
          id="privilageForm"
          variant="outlined"
          loading={false}
          text="Cancel"
        />
        <FormButton
          id="privilageForm"
          type="submit"
          variant="contained"
          text="Save"
        />
      </div>
    </form>
  );
}

export default PrivilagesPage;
