import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./ConservationStatusAndLiquidityForm.css";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import CustomDatePicker from "../../../../../../components/customDatePicker/CustomDatePicker";
import FormButton from "../../../../../../components/formButton/FormButton";
import { getAllLiquidity } from "../../../../../../api/apiServices/liquidityApi";
import { useDispatch, useSelector } from "react-redux";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import CustomAutoComplete from "../../../../../../components/customAutoComplete/CustomAutoComplete";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getVehicleOtherDetailsId,
  updateVehicleOtherDetails,
} from "../../../../../../api/apiServices/vehicleApi";
import dayjs from "dayjs";
import { RiFileEditFill } from "react-icons/ri";
import CustomTextArea from "../../../../../../components/customTextArea/CustomTextArea";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
function ConservationStatusAndLiquidityForm({ updateIsEditable }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [liquidity, Setliquidity] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iSViewAndEdit = queryParams.get("view_edit");
  const [Isview, SetIsview] = useState(false);
  useEffect(() => {
    AllLiquidity();
    if (id) {
      dispatch(getVehicleOtherDetailsId(id))
        .then((response) => {
          setFormikData(response.data.data);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
    if (iSViewAndEdit) {
      SetIsview(true);
    }
  }, [id]);
  function AllLiquidity() {
    dispatch(getAllLiquidity())
      .then((response) => {
        const data = response.map((item) => {
          return {
            label: item.ll_liquid,
            value: item.ll_id,
          };
        });
        Setliquidity(data);
      })
      .catch((error) => {
        ResponseHandler(error);
      });
  }

  const initialValues = {
    value: "",
    inspection_date: null,
    appraisal: "",
    identification_card: "",
    general_state_of_conversation: "",
    observation_and_note: "",
    liquidity: null,
    order: "",
    manifacture: "",
    appraisal_reference: "",
    rtv: null,
  };
  function setFormikData(data) {
    const mappedData = Object.keys(initialValues).reduce((acc, key) => {
      const originalKey = `vod_${key}`;
      acc[key] = data[originalKey] || initialValues[key];
      return acc;
    }, {});
    formik.setValues({
      ...formik.values,
      ...mappedData,
    });
  }
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape({
      value: Yup.string(),
      appraisal: Yup.string(),
      identification_card: Yup.string(),
      general_state_of_conversation: Yup.string(),
      observation_and_note: Yup.string(),
      order: Yup.string(),
      manifacture: Yup.string(),
      appraisal_reference: Yup.string(),
    }),
    onSubmit: (values) => {
      setLoading(true);
      dispatch(updateVehicleOtherDetails(id, values))
        .then((response) => {
          ResponseHandler(response);
          navigate(
            `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/vehicles`
          );
        })
        .catch((error) => {
          ResponseHandler(error);
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  const Privilages = useSelector((state) => state.userStore.Privilages);

  return (
    <div>
      <div className="ConservationStatus_form_main_Container">
        {Privilages &&
        Privilages?.Vehicle &&
        Privilages?.Vehicle.some((item) => item === "Update")
          ? Isview && (
              <div
                className="ConservationStatus_edit_details"
                onClick={() => {
                  SetIsview(false);
                  updateIsEditable(true);
                }}
              >
                <RiFileEditFill />
                <p>Edit</p>
              </div>
            )
          : null}

        <div className="ConservationStatus_formContainer">
          <p className="ConservationStatus_form_legend">Add Details</p>
          <form id="ConservationStatusform" onSubmit={formik.handleSubmit}>
            <div className="ConservationStatusform_fields">
              <div>
                <div className="ConservationStatusform_field_container">
                  <p className="ConservationStatusform_fields_labels">
                    Value (₡)
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="value"
                    placeholder="Enter Value (₡)"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.value}
                    error={formik.touched.value && Boolean(formik.errors.value)}
                    helperText={formik.touched.value && formik.errors.value}
                  />
                </div>
                <div className="ConservationStatusform_field_container">
                  <p className="ConservationStatusform_fields_labels">
                    Inspection Date
                  </p>
                  <CustomDatePicker
                    disabled={Isview}
                    name="inspection_date"
                    onChange={(date) => {
                      formik.setFieldValue("inspection_date", date);
                    }}
                    onBlur={formik.handleBlur}
                    value={dayjs(formik.values.inspection_date, "MM/DD/YYYY")}
                    error={
                      formik.touched.inspection_date &&
                      Boolean(formik.errors.inspection_date)
                    }
                    helperText={
                      formik.touched.inspection_date &&
                      formik.errors.inspection_date
                    }
                  />
                </div>
                <div className="ConservationStatusform_field_container">
                  <p className="ConservationStatusform_fields_labels">
                    Appraiser
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="appraisal"
                    placeholder="Enter Appraiser"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.appraisal}
                    error={
                      formik.touched.appraisal &&
                      Boolean(formik.errors.appraisal)
                    }
                    helperText={
                      formik.touched.appraisal && formik.errors.appraisal
                    }
                  />
                </div>
                <div className="ConservationStatusform_field_container">
                  <p className="ConservationStatusform_fields_labels">
                    Identification Card
                  </p>
                  <CustomTextField
                    disabled={Isview}
                    name="identification_card"
                    placeholder="Enter Identification Card"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.identification_card}
                    error={
                      formik.touched.identification_card &&
                      Boolean(formik.errors.identification_card)
                    }
                    helperText={
                      formik.touched.identification_card &&
                      formik.errors.identification_card
                    }
                  />
                </div>
                <div className="ConservationStatusform_field_container">
                  <p className="ConservationStatusform_fields_labels">
                    General State of Conversations
                  </p>
                  <CustomTextArea
                    maxRows={3}
                    disabled={Isview}
                    name="general_state_of_conversation"
                    placeholder="Enter General State of Conversations"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.general_state_of_conversation}
                    error={
                      formik.touched.general_state_of_conversation &&
                      Boolean(formik.errors.general_state_of_conversation)
                    }
                    helperText={
                      formik.touched.general_state_of_conversation &&
                      formik.errors.general_state_of_conversation
                    }
                  />
                </div>
                <div className="ConservationStatusform_field_container">
                  <p className="ConservationStatusform_fields_labels">
                    Observations & Notes
                  </p>
                  <CustomTextArea
                    maxRows={3}
                    disabled={Isview}
                    name="observation_and_note"
                    placeholder="Enter Observations & Notes"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.observation_and_note}
                    error={
                      formik.touched.observation_and_note &&
                      Boolean(formik.errors.observation_and_note)
                    }
                    helperText={
                      formik.touched.observation_and_note &&
                      formik.errors.observation_and_note
                    }
                  />
                </div>
              </div>
              <div>
                <div className="ConservationStatusform_field_container">
                  <p className="ConservationStatusform_fields_labels">
                    Liquidity
                  </p>
                  <CustomAutoComplete
                    disabled={Isview}
                    name="liquidity"
                    placeholder="Select liquidity"
                    options={liquidity}
                    getOptionLabel={(option) => option.label}
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "liquidity",
                        newValue ? newValue.value : ""
                      );
                    }}
                    onBlur={formik.handleBlur}
                    value={
                      liquidity.find(
                        (Liquidity) =>
                          Liquidity.value === formik.values.liquidity
                      ) || null
                    }
                    error={
                      formik.touched.liquidity &&
                      Boolean(formik.errors.liquidity)
                    }
                    helperText={
                      formik.touched.liquidity && formik.errors.liquidity
                    }
                  />
                </div>
                <div className="ConservationStatusform_field_container">
                  <p className="ConservationStatusform_fields_labels">Order</p>
                  <CustomTextField
                    disabled={Isview}
                    name="order"
                    placeholder="Enter Order"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.order}
                    error={formik.touched.order && Boolean(formik.errors.order)}
                    helperText={formik.touched.order && formik.errors.order}
                  />
                </div>
                <div id="ConservationStatusform_field_container">
                  <div className="ConservationStatusform_field_container">
                    <p className="ConservationStatusform_fields_labels">
                      Manufacturer
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="manifacture"
                      placeholder="Enter Manufacturer"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.manifacture}
                      error={
                        formik.touched.manifacture &&
                        Boolean(formik.errors.manifacture)
                      }
                      helperText={
                        formik.touched.manifacture && formik.errors.manifacture
                      }
                    />
                  </div>
                  <div className="ConservationStatusform_field_container">
                    <p className="ConservationStatusform_fields_labels">
                      Appraisal Ref
                    </p>
                    <CustomTextField
                      disabled={Isview}
                      name="appraisal_reference"
                      placeholder="Enter Appraisal Ref"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.appraisal_reference}
                      error={
                        formik.touched.appraisal_reference &&
                        Boolean(formik.errors.appraisal_reference)
                      }
                      helperText={
                        formik.touched.appraisal_reference &&
                        formik.errors.appraisal_reference
                      }
                    />
                  </div>
                  <div className="ConservationStatusform_field_container">
                    <p className="ConservationStatusform_fields_labels">RTV</p>
                    <CustomDatePicker
                      disabled={Isview}
                      name="rtv"
                      onChange={(date) => {
                        formik.setFieldValue("rtv", date);
                      }}
                      onBlur={formik.handleBlur}
                      value={dayjs(formik.values.rtv, "MM/DD/YYYY")}
                      error={formik.touched.rtv && Boolean(formik.errors.rtv)}
                      helperText={formik.touched.rtv && formik.errors.rtv}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
        {!Isview && (
          <div className="ConservationStatusform_action">
            <FormButton
              id="ConservationStatusCreateCancel"
              variant="outlined"
              loading={false}
              text="Cancel"
              onClick={() => {
                navigate(
                  `/admin_portal/${LocalStorageServices.getUserRole()}/home/vehicle/vehicles`
                );
              }}
            />
            <FormButton
              id="ConservationStatusCreateSubmit"
              type="submit"
              form="ConservationStatusform"
              variant="contained"
              text="Submit"
              loading={loading}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ConservationStatusAndLiquidityForm;
